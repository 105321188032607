export * from './account.service';
import { AccountService } from './account.service';
export * from './accountFile.service';
import { AccountFileService } from './accountFile.service';
export * from './assistance.service';
import { AssistanceService } from './assistance.service';
export * from './authentication.service';
import { AuthenticationService } from './authentication.service';
export * from './certification.service';
import { CertificationService } from './certification.service';
export * from './diploma.service';
import { DiplomaService } from './diploma.service';
export * from './job.service';
import { JobService } from './job.service';
export * from './network.service';
import { NetworkService } from './network.service';
export * from './parameter.service';
import { ParameterService } from './parameter.service';
export * from './skill.service';
import { SkillService } from './skill.service';
export * from './training.service';
import { TrainingService } from './training.service';
export const APIS = [AccountService, AccountFileService, AssistanceService, AuthenticationService, CertificationService, DiplomaService, JobService, NetworkService, ParameterService, SkillService, TrainingService];
