<div class="row">
  <sm-alert [alert]="alert"></sm-alert>
  <div class="col-12 p-3">
    <strong>{{ 'edit_profile.add' | translate }} {{ 'edit_profile.certification' | translate }}</strong>
  </div>
  <div class="col-12">
    <sm-input (smInputEvent)="getCertificationTitle($event)"
              [errorMessage]="errorMessage('TITLE')"
              [inputStatus]="getCssStatus('TITLE')"
              [mandatory]="true"
              [showErrorMessage]="!isValid('TITLE')"
              [type]="'text'"
              [value]="certificationRequest.title!"
              placeholder="{{'edit_profile.certification_title_label' | translate}}"
              title="{{'edit_profile.certification_title_label' | translate}}">
    </sm-input>
    <sm-input (smInputEvent)="getCertificationSchoolName($event)"
              [errorMessage]="errorMessage('SCHOOL')"
              [inputStatus]="getCssStatus('SCHOOL')"
              [mandatory]="true"
              [showErrorMessage]="!isValid('SCHOOL')"
              [type]="'text'"
              [value]="certificationRequest.schoolName!"
              placeholder="{{'edit_profile.certification_school_label' | translate}}"
              title="{{'edit_profile.certification_school_label' | translate}}">
    </sm-input>
    <sm-input (smInputEvent)="getActivityArea($event)"
              [errorMessage]="errorMessage('DOMAIN')"
              [inputStatus]="getCssStatus('DOMAIN')"
              [mandatory]="true"
              [showErrorMessage]="!isValid('DOMAIN')"
              [type]="'text'"
              [value]="certificationRequest.activityArea!"
              placeholder="{{'edit_profile.certification_area_label' | translate}}"
              title="{{'edit_profile.certification_area_label' | translate}}">
    </sm-input>
    <sm-input (smInputEvent)="getSchoolType($event)"
              [errorMessage]="errorMessage('SCHOOL_TYPE')"
              [inputStatus]="getCssStatus('SCHOOL_TYPE')"
              [mandatory]="true"
              [showErrorMessage]="!isValid('SCHOOL_TYPE')"
              [type]="'text'"
              [value]="certificationRequest.schoolType!"
              placeholder="{{'edit_profile.certification_school_type_label' | translate}}"
              title="{{'edit_profile.certification_school_type_label' | translate}}">
    </sm-input>
    <div class="row">
      <div class="col">
        <sm-input
          (smInputEvent)="getStartDate($event)"
          [errorMessage]="errorMessage('START')"
          [inputStatus]="getCssStatus('START')"
          [mandatory]="true"
          [name]="'start_date'"
          [showErrorMessage]="!isValid('START')"
          [type]="'date'"
          [value]="formatDate(certificationRequest.startDate!)"
          placeholder="{{'edit_profile.start_date' | translate}}"
          title="{{'edit_profile.start_date' | translate}}">
        </sm-input>
      </div>
      <div class="col">
        <sm-input
          (smInputEvent)="getEndDate($event)"
          [disabled]="certificationRequest.permanent!"
          [errorMessage]="errorMessage('END')"
          [inputStatus]="getCssStatus('END')"
          [mandatory]="true"
          [name]="'end_date'"
          [showErrorMessage]="!isValid('END')"
          [type]="'date'"
          [value]="formatDate(certificationRequest.endDate!)"
          placeholder="{{'edit_profile.end_date' | translate}}"
          title="{{'edit_profile.end_date' | translate}}">
        </sm-input>
      </div>
      <div class="col">
        <sm-toggle-button
          (smCheckEvent)="isChecked($event)"
          [checked]="certificationRequest.permanent!"
          title="{{'edit_profile.permanent' | translate}}">
        </sm-toggle-button>
      </div>
    </div>
    <sm-textarea
      (smInputEvent)="getCertificationDescription($event)"
      [value]="certificationRequest.description ? certificationRequest.description : ''"
      placeholder="{{'edit_profile.description' | translate}}"
      title="{{'edit_profile.description' | translate}}">
    </sm-textarea>
  </div>

  <div class="row">
    <div class="col m-t-10">
      <sm-button (click)="onClickCancel()" text="{{'edit_profile.cancel' | translate}}"></sm-button>
    </div>
    <div *ngIf="!processing" class="col m-t-10">
      <sm-button (click)="onClickSave()" [type]="'success'" text="{{'edit_profile.validate' | translate}}"></sm-button>
    </div>
    <div *ngIf="processing" class="col m-t-10">
      <sm-processing [loading]="processing"/>
    </div>
  </div>
</div>
