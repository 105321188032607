import {Component, Input, OnInit} from '@angular/core';
import {SmSelection} from "../../models/smSelection";
import {AccountDetailsResource} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {StringHelper} from "../../helpers/string-helper";
import {Route} from "../../enums/route.enum";

@Component({
  selector: 'sm-selection-account',
  templateUrl: './selection-account.component.html',
  styleUrls: ['./selection-account.component.scss']
})
export class SelectionAccountComponent implements OnInit {
  @Input() selection!: SmSelection;
  @Input() isLastOne!: boolean;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  Route = Route;

  constructor(private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
  }

  public name(account: AccountDetailsResource): string {
    return StringHelper.truncateName(account, 18);
  }
}
