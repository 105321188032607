<div class="container skillsmates-page-wrapper">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
      <a routerLink="/">
        <img alt="Skillsmates logo" class="img-fluid" src="{{ 'logo-skillsmates' | image }}"
             style="max-height: 120px;"/>
      </a>
      <p class="skillsmates-slogan" style="line-height: 1em">améliore et partage <br/>tes connaissances avec <br/>la
        communauté</p>
      <img alt="landing-page-people" class="img-fluid" src="{{ 'sm-persona' | image }}"/>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" style="padding: 20px;">
      <sm-alert [alert]="alert"></sm-alert>
      <div *ngIf="currentMode==PageMode.LOGIN" class="skillsmates-box-shadow" style="padding: 20px;">
        <div class="login-title text-center">{{ 'login.sign_in' | translate }}</div>
        <div class="" style="margin-top: 20px">
          <sm-input
            (smInputEvent)="getEmail($event)"
            [errorMessage]="errorMessageEmail"
            [inputStatus]="inputStatusEmail"
            [name]="'email'"
            [placeholder]="'Email'"
            [showErrorMessage]="showErrorMessageEmail"
            [type]="'email'"/>
          <sm-input
            (smInputEvent)="getPassword($event)"
            [errorMessage]="errorMessagePassword"
            [inputStatus]="inputStatusPassword"
            [name]="'password'"
            [placeholder]="'Mot de passe'"
            [showErrorMessage]="showErrorMessagePassword"
            [showEye]="true"
            [type]="'password'"/>

          <div class="form-group">
            <label> <input type="checkbox"/> Memoriser </label>
            <label class="text-right pointer" style="float: right">
              <a (click)="changeMode(PageMode.PASSWORD_RESET_INIT)">Mot de passe oublié?</a>
            </label>
          </div>
          <div class="form-group text-center">

            <sm-button
              (smButtonEvent)="onClickLogin()"
              [type]="'info'"
              text="{{ 'login.log_in' | translate }}">
            </sm-button>

            <br/>
            <br/>

            <sm-horizontal-line></sm-horizontal-line>
            <br/>
            <sm-button (smButtonEvent)="changeMode(PageMode.REGISTER)"
                       *ngIf="!loading"
                       [type]="'success'"
                       style="margin-top: 5px;"
                       text="{{ 'login.register' | translate }}">
            </sm-button>
            <sm-processing *ngIf="loading" [loading]="loading"></sm-processing>
          </div>
          <a [href]="apkUrl"
             style="font-weight: bold; color: #16152d" target="_blank">
            <img src="../../../assets/images/google-play.svg">
            Telecharger l'application Android
          </a>
        </div>
      </div>
      <div *ngIf="currentMode==PageMode.REGISTER" class="skillsmates-box-shadow" style="padding: 20px;">
        <div class="login-title text-center">{{ 'login.register' | translate }}</div>
        <sm-dropdown
          (smInputEvent)="getAccountType($event)"
          [list]="getEducationLabels()"
          [selected]="getEducationLabels()[0]"
          title="{{'login.account_type' | translate}}">
        </sm-dropdown>
        <sm-input
          (smInputEvent)="getFirstname($event)"
          [errorMessage]="errorMessageFirstname"
          [inputStatus]="inputStatusFirstname"
          [name]="'Prénom'"
          [placeholder]="'Prénom'"
          [showErrorMessage]="showErrorMessageFirstname!"
          [type]="'text'"
          [value]="accountCreationRequest.firstname"/>
        <sm-input
          (smInputEvent)="getLastname($event)"
          [errorMessage]="errorMessageLastname"
          [inputStatus]="inputStatusLastname"
          [name]="'text'"
          [placeholder]="'Nom'"
          [showErrorMessage]="showErrorMessageLastname"
          [type]="'Nom'"
          [value]="accountCreationRequest.lastname!"/>
        <sm-input
          (smInputEvent)="getEmail($event)"
          [errorMessage]="errorMessageEmail"
          [inputStatus]="inputStatusEmail"
          [name]="'email'"
          [placeholder]="'Email'"
          [showErrorMessage]="showErrorMessageEmail"
          [type]="'email'"
          [value]="accountCreationRequest.email!"/>
        <sm-input
          (valueChange)="getPassword($event)"
          [errorMessage]="errorMessagePassword"
          [inputStatus]="inputStatusPassword"
          [name]="'password'"
          [placeholder]="'Mot de passe'"
          [showErrorMessage]="showErrorMessagePassword!"
          [showEye]="true"
          [type]="'password'"
          [value]="accountCreationRequest.password!"/>
        <sm-input
          (valueChange)="getConfirmPassword($event)"
          [errorMessage]="errorMessageConfirmPassword"
          [inputStatus]="inputStatusConfirmPassword"
          [name]="'password'"
          [placeholder]="'Confirmation du mot de passe'"
          [showErrorMessage]="showErrorMessageConfirmPassword"
          [showEye]="true"
          [type]="'password'"
          [value]="accountCreationRequest.confirmPassword!"/>

        <div style="font-size: x-small;">
          <span *ngFor="let validation of validations"
                [ngClass]="validation.cssClass()">{{ validation.message }}<br></span>
        </div>
        <br>
        <div class="form-group">
          <label>
            <input [(ngModel)]="checked" [ngModelOptions]="{ standalone: true }" type="checkbox"/>
            <span style="font-size: smaller">{{ 'login.cgu_message_1' | translate }}
              <a (click)="onClickCGU()" [routerLink]="[]">
                  {{ 'login.cgu_message_2' | translate }}
                </a>
            </span>
            <small *ngIf="showCguErrorMessage && !checked"
                   class="text-danger">{{ 'login.cgu_error_message' | translate }}</small>
          </label>
        </div>

        <div class="form-group text-center">
          <button (click)="onClickRegister()"
                  *ngIf="!loading"
                  class="btn btn-primary sm-border-radius login-button">
            {{ 'login.register' | translate }}
          </button>
          <sm-processing *ngIf="loading" [loading]="loading"></sm-processing>
          <br/><br/>
          <sm-horizontal-line/>
          <br/>
          <button
            (click)="changeMode(PageMode.LOGIN)"
            class="btn btn-primary sm-border-radius register-button">
            {{ 'login.log_in' | translate }}
          </button>
          <br><br>
          <sm-processing [loading]="loading"></sm-processing>
        </div>
      </div>
      <div *ngIf="currentMode==PageMode.PASSWORD_RESET_INIT" class="skillsmates-box-shadow"
           style="padding: 20px;">
        <div class="login-title text-center">{{ 'login.password_reset.init.title' | translate }}</div>
        <div class="form-group">
          <label>{{ 'login.password_reset.init.message' | translate }}</label><br>
          <b><label>{{ 'login.password_reset.init.advise' | translate }}</label></b><br>
        </div>
        <sm-input
          (smInputEvent)="getEmailForPasswordReset($event)"
          [errorMessage]="errorMessageEmail"
          [inputStatus]="inputStatusEmail"
          [name]="'email'"
          [placeholder]="'Email'"
          [showErrorMessage]="showErrorMessageEmail"
          [type]="'email'"
          [value]="passwordEditEmail"/>
        <button (click)="doInitPasswordReset()"
                *ngIf="!loading"
                class="btn btn-primary sm-border-radius register-button">
          {{ 'login.button_send' | translate }}
        </button>
        <sm-processing *ngIf="loading" [loading]="loading"></sm-processing>
        <br>
        <br>
        <span (click)="changeMode(PageMode.LOGIN)" class="pointer" style="font-weight: bold; color: #16152d">
          {{ 'login.log_in' | translate }}
        </span>
      </div>
    </div>
  </div>
  <div class="row" style="">
    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-center" style="margin: auto;">
      <span><a (click)="onClickAbout()" class="footer-text pointer">{{ 'login.about' | translate }}</a></span> -
      <!--<span><a class="footer-text pointer" href="#">{{ 'login.help' | translate }}</a></span> -   -->
      <!--<span><a class="footer-text pointer" href="#">{{ 'login.confidentiality_policy' | translate }}</a></span> - -->
      <span><a (click)="onClickCGU()"
               class="footer-text pointer">{{ 'login.terms_and_conditions' | translate }}</a></span> -
      <span><a (click)="onClickFAQ()" class="footer-text pointer">{{ 'login.faq' | translate }}</a></span>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center" style="margin: auto; font-size: small;">

      © <strong><a [routerLink]="Route.ROOT" class="std" target="_blank">{{ 'sm.app_name' | translate }}</a></strong>.
      <strong><span style="color: #000;"> {{ year }} <br>Version : </span></strong> <strong
      style="color:#F00;">{{ version }}</strong>

      <!--      <a [routerLink]="Route.ROOT" class="logo" style="text-decoration: none;">-->
      <!--        <img src="{{ 'symbole-skills-mates' | image }}" height="35" alt="" style="border-radius: 5px"/>-->
      <!--        <span-->
      <!--          style="font-weight: bold; font-size: small; color: #16152d">© {{ 'sm.app_name' | translate }} {{year}}</span>.-->
      <!--        <strong><span style="color: #000;"> {{year}} <br>Version : </span></strong>-->
      <!--        <strong style="color:#F00;">{{version}}</strong>-->
      <!--      </a>-->
    </div>
  </div>
</div>

