<div class="row">
  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 skillsmates-box-padding ">
    <div class="skillsmates-stick-top">
      <sm-image-text
        (click)="onClickReturnButton()"
        [bgColor]="'#D3D3D3'"
        image="{{'left-arrow' | image}}"
        text="{{'assistance.return' | translate}}">
      </sm-image-text>

      <br>
      <br>

      <div class="frequently-asked-questions text-right">les questions les <br> plus récurrentes</div>
      <div>
        <img src="{{'assistance-question-mark-people' | image}}"
             class="assistance-contact-us-img"
             alt="question mark people">
      </div>

    </div>
  </div>
  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
    <div class="accordion" id="accordionExample">

      <div class="card" style="background-color: #DADADA" *ngFor="let faq of faqs; index as i;">
        <div class="card-header" role="tab" [ngClass]="faq.accordionClass" (click)="onClickAccordion(i, faq)">
          <h2 class="mb-0">
            <button class="btn btn-link faq-header" type="button" data-toggle="collapse" data-target="#collapseOne"
                    aria-expanded="true" aria-controls="collapseOne">
              {{faq.question}}
            </button>
          </h2>
        </div>

        <div *ngIf="faq.showBody">
          <div class="card-body faq-body">
            {{faq.answer}}
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
