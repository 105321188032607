/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SearchParam { 
    account?: string;
    content?: string;
    city?: string;
    country?: string;
    documentTypes?: Array<string>;
    statuses?: Array<string>;
    direction?: SearchParam.DirectionEnum;
    networkType?: string;
}
export namespace SearchParam {
    export type DirectionEnum = 'ASC' | 'DESC';
    export const DirectionEnum = {
        Asc: 'ASC' as DirectionEnum,
        Desc: 'DESC' as DirectionEnum
    };
}


