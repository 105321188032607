import {Component, Input, OnInit} from '@angular/core';
import {InteractionResource, InteractionResponse, InteractionService, PostDetailsResource} from "ApiModulePost";
import {AccountDetailsResource} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {CommentsService} from 'src/app/services/comments.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'sm-post-interactions',
  templateUrl: './post-interactions.component.html',
  styleUrls: ['./post-interactions.component.scss']
})
export class PostInteractionsComponent implements OnInit {
  @Input() interactionType!: string;
  @Input() post: PostDetailsResource = {} as PostDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  loading: boolean = false;
  interactions: InteractionResource[] = [];
  interactionResponse: InteractionResponse = {} as InteractionResponse;
  subscription: Subscription;

  constructor(private interactionService: InteractionService,
              private storageService: LocalStorageService,
              private commentService: CommentsService) {

    this.subscription = commentService.missionAnnounced$.subscribe(value => {
      this.interactions = value
    })
  }

  ngOnInit(): void {
    this.loggedAccount = this.storageService.getLoggedAccount();
    this.fetchInteractions();
    this.commentService.missionAnnounced$.subscribe({
      next(value) {
      },
    })
  }

  removeInteractionEvent(comment: InteractionResource): void {
    this.interactions = this.interactions.filter(obj => obj !== comment);
  }

  fetchInteractions(): void {
    this.interactionService.findInteractionsByTypeAndEntity(this.interactionType, this.post.uuid!, 0, 10).subscribe({
      next: response => {
        this.interactionResponse = response;
        this.interactions = this.interactionResponse.resources!;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }
}
