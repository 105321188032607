import {Component, Input, OnInit} from '@angular/core';
import {variables} from "../../../environments/variables";
import {StringHelper} from "../../helpers/string-helper";
import {LocalStorageService} from "../../services/local-storage.service";
import {Router} from "@angular/router";
import {Route} from "../../enums/route.enum";
import {AccountDetailsResource} from "ApiModuleAccount";


@Component({
  selector: 'sm-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss']
})
export class AccountProfileComponent implements OnInit {
  @Input() account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  countryName!: string;

  constructor(public localStorageService: LocalStorageService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.get(variables.logged_account);
  }

  public name(account: AccountDetailsResource): string {
    return StringHelper.truncateName(account, 21);
  }

  onClickFollowees(): void {
    localStorage.setItem(variables.tab, '3'); // 3 = position for followee
  }

  onClickFollowers(): void {
    localStorage.setItem(variables.tab, '2'); // 2 = position for follower
  }

  onClickProfile(account: AccountDetailsResource): void {
    if(!this.localStorageService.isOffline()){
      this.localStorageService.add(variables.profile_account, account);
      this.router.navigate([Route.PROFILE]);
    }
  }
}
