<div class="row">
  <div *ngIf="account.uuid === loggedAccount.uuid" class="col-12">
    <sm-info-text text="{{'edit_profile.info_text_biography' | translate}}"></sm-info-text>
  </div>
  <div *ngIf="!editMode && account.uuid === loggedAccount.uuid" class="col-12">
    <sm-image-text
      (click)="onClickUpdate()"
      *ngIf="account.uuid === loggedAccount.uuid"
      [bgColor]="'white'"
      [color]="'#00ACED'"
      image="{{'plus-square-color' | image}}"
      style="font-weight: bolder; font-size: x-large"
      text="{{'edit_profile.update_biography' | translate}}">
    </sm-image-text>
  </div>

  <div *ngIf="!editMode" class="col-12 p-3">
    <sm-long-text-display [text]="account.biography"></sm-long-text-display>
  </div>


  <div *ngIf="editMode && account.uuid === loggedAccount.uuid" class="row p-3">
    <div class="col-12 m-b-10">
      <ckeditor [(ngModel)]="account.biography" [editor]="Editor" [maxlength]="maxCharacters"
                name="content"></ckeditor>
      <small *ngIf="account.biography" class="text-info float-right">
        {{ account.biography?.length }} / {{ maxCharacters }}
      </small>
    </div>
    <div class="col">
      <sm-button (click)="onClickCancel()" text="{{'edit_profile.cancel' | translate}}"></sm-button>
    </div>
    <div *ngIf="!processing" class="col">
      <sm-button (click)="onClickValidate()" [type]="'success'"
                 text="{{'edit_profile.validate' | translate}}"></sm-button>
    </div>
    <div *ngIf="processing" class="col">
      <sm-processing [loading]="processing"/>
    </div>
  </div>
</div>
