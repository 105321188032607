import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Alert} from "../../models/alert";
import {
  AccountDetailsResource,
  AccountService,
  AccountUpdateDetailsRequest,
  ParameterResource,
  ParameterService
} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {AccountHelper} from "../../helpers/account-helper";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'sm-edit-profile-details',
  templateUrl: './edit-profile-details.component.html',
  styleUrls: ['./edit-profile-details.component.scss']
})
export class EditProfileDetailsComponent implements OnInit {

  @Input() account!: AccountDetailsResource;
  @Output() smDoneEvent = new EventEmitter<boolean>;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  alert: Alert = {} as Alert;
  showErrorMessageFirstname: boolean = false;
  inputStatusFirstname: string = '';
  showErrorMessageLastname: boolean = false;
  showErrorMessageBirthdate: boolean = false
  inputStatusLastname: string = '';
  inputStatusBirthdate: string = ''
  selectedDate: string = ''
  statuses: string[] = AccountHelper.statuses;
  genders: string[] = AccountHelper.genders;
  accountUpdateDetailsRequest: AccountUpdateDetailsRequest = {} as AccountUpdateDetailsRequest;
  countries: ParameterResource[] = [];

  constructor(private localStorageService: LocalStorageService,
              private toastrService: ToastrService,
              private accountService: AccountService,
              private parameterService: ParameterService) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
    this.selectedDate = this.convertDate(this.account.birthdate!)
    this.fetchCountries()
    this.initRequest()
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
    this.selectedDate = this.convertDate(this.account.birthdate!)
    this.fetchCountries()
    this.initRequest()
  }

  getCountryName(code: string): string {
    return this.countries.find(value => value.code == code)?.label!
  }

  getCountryCode(name: string): string {
    return this.countries.find(value => value.label?.includes(name))?.code!
  }

  getCountryLabels(): string[] {
    return this.countries.map(value => value.label!)
  }

  initRequest() {
    this.accountUpdateDetailsRequest.uuid = this.account.uuid!
    this.accountUpdateDetailsRequest.lastname = this.account.lastname
    this.accountUpdateDetailsRequest.firstname = this.account.firstname
    this.accountUpdateDetailsRequest.address = this.account.address
    this.accountUpdateDetailsRequest.phoneNumber = this.account.phoneNumber
    this.accountUpdateDetailsRequest.birthdate = this.convertDate(this.account.birthdate!)
    this.accountUpdateDetailsRequest.hideBirthdate = this.account.hideBirthdate
    this.accountUpdateDetailsRequest.gender = this.account.gender
    this.accountUpdateDetailsRequest.city = this.account.city
    this.accountUpdateDetailsRequest.country = this.account.country
    this.accountUpdateDetailsRequest.status = this.account.status
  }

  fetchCountries() {
    this.parameterService.findCountries().subscribe({
      next: response => {
        this.countries = response.resources!;
      },
      error: error => {
      },
      complete: () => {
      }
    });


  }

  getFirstname(event: string) {
    this.accountUpdateDetailsRequest.firstname = event;
  }

  getLastname(event: string) {
    this.accountUpdateDetailsRequest.lastname = event;
  }

  getBirthdate(event: string): void {
    this.selectedDate = event;
    this.accountUpdateDetailsRequest.birthdate = event;
  }

  convertDate(dateString: string): string {
    const dateObj = new Date(dateString);
    const annee = dateObj.getFullYear();
    const mois = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Mois commence à 0, donc ajoutez 1
    const jour = dateObj.getDate().toString().padStart(2, '0');
    const nouvelleDateString = `${annee}-${mois}-${jour}`;
    return nouvelleDateString;
  }

  getHideBirthdate(event: boolean): void {
    this.accountUpdateDetailsRequest.hideBirthdate = event;
  }

  getCity(event: string) {
    this.accountUpdateDetailsRequest.city = event;
  }

  getCountry(event: any) {
    this.accountUpdateDetailsRequest.country = this.getCountryCode(event.target.value);
  }

  getStatus(event: string): void {
    this.accountUpdateDetailsRequest.status = AccountHelper.getStatusEnum(event);
  }

  getGender(event: string): void {
    this.accountUpdateDetailsRequest.gender = AccountHelper.getGenderEnum(event);
  }

  getCurrentStatus(): string {
    return AccountHelper.getStatusString(this.account.status)!;
  }

  getCurrentGender(): string {
    return AccountHelper.getGenderString(this.account.gender)!;
  }

  onClickCancel(): void {
    this.smDoneEvent.emit(false);
  }

  onClickSave(): void {
    this.accountUpdateDetailsRequest.uuid = this.loggedAccount.uuid!;

    if (!this.accountUpdateDetailsRequest.firstname) {
      this.accountUpdateDetailsRequest.firstname = this.loggedAccount.firstname;
    }
    if (!this.accountUpdateDetailsRequest.lastname) {
      this.accountUpdateDetailsRequest.lastname = this.loggedAccount.lastname;
    }

    this.accountService.updateAccount(this.loggedAccount.uuid!, this.accountUpdateDetailsRequest).subscribe({
      next: async response => {
        this.toastrService.success("Vos informations ont été mises à jour avec succés", 'Mis à jour informations');
        this.localStorageService.updateLoggedAccount(response.resource?.uuid!)
        this.localStorageService.updateProfileAccount(response.resource?.uuid!)
        await this.sleep(2000);
        this.smDoneEvent.emit(true);
        window.location.reload();
      },
      error: error => {
        this.toastrService.error("Erreur lors de la mis à jour de vos informations", 'Mis à jour informations');
      },
      complete: () => {
      }
    });
  }

  sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}
