import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {variables} from "../../../environments/variables";
import {Route} from "../../enums/route.enum";
import {Router} from "@angular/router";
import {AccountDetailsResource, AccountService, NetworkService, SearchParam} from "ApiModuleAccount";

@Component({
  selector: 'sm-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements OnInit {

  Route = Route;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  accounts: AccountDetailsResource[] = [];
  searchParam: SearchParam = {} as SearchParam;

  constructor(private localStorageService: LocalStorageService,
              private accountService: AccountService,
              private networkService: NetworkService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.searchParam.account = this.loggedAccount.uuid;
    this.searchParam.networkType = 'SUGGESTIONS';
    this.findAccounts(this.searchParam, 0, 20);
  }

  findAccounts(searchParam: SearchParam, offset: number, limit: number) {
    this.networkService.searchNetworkAccounts(offset, limit, searchParam).subscribe({
      next: response => {
        this.accounts = response.resources!;
        this.localStorageService.add(variables.suggestions, response.meta?.total)
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  onClick(position: number): void {
    this.localStorageService.add(variables.tab, position.toString(10));
  }

}
