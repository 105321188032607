import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountDetailsResource, SkillRequest, SkillResource, SkillResponse, SkillService} from "ApiModuleAccount";
import {Alert} from "../../models/alert";
import {LocalStorageService} from "../../services/local-storage.service";
import {variables} from "../../../environments/variables";
import {ToastrService} from "ngx-toastr";
import {ValidationCondition} from "../../models/validationCondition";

@Component({
  selector: 'sm-edit-skill',
  templateUrl: './edit-skill.component.html',
  styleUrls: ['./edit-skill.component.scss']
})
export class EditSkillComponent implements OnInit {

  @Input() skillResource!: SkillResource;
  @Output() smDoneEvent = new EventEmitter<boolean>;
  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  skillResponse: SkillResponse = {} as SkillResponse;
  skillRequest: SkillRequest = {} as SkillRequest;
  alert: Alert = {} as Alert;
  validations: ValidationCondition[] = []
  processing: boolean = false

  constructor(private localStorageService: LocalStorageService,
              private toastrService: ToastrService,
              private skillService: SkillService) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
  }

  ngOnInit(): void {
    this.initValidation()
    if (this.skillResource) {
      this.skillRequest.title = this.skillResource.title!;
      this.skillRequest.discipline = this.skillResource.discipline!;
      this.skillRequest.description = this.skillResource.description;
      this.skillRequest.level = this.skillResource.level!;
      this.skillRequest.category = this.skillResource.category!;
      this.skillRequest.account = this.skillResource.account!;
    }
  }

  initValidation() {
    this.validations = []
    this.validations.push(new ValidationCondition(true, 'DISCIPLINE', ''))
    this.validations.push(new ValidationCondition(true, 'TITLE', ''))
    this.validations.push(new ValidationCondition(true, 'LEVEL', ''))
  }

  updateValidation(code: string, newValue: boolean, message: string) {
    this.validations.forEach(value => {
      if (value.code === code) {
        value.valid = newValue
        value.message = message
      }
    })
  }

  getCssStatus(code: string): string {
    return this.validations.find(value => value.code === code)?.cssFormStatus()!
  }

  isValid(code: string) {
    return this.validations.find(value => value.code === code)?.valid!
  }

  errorMessage(code: string) {
    return this.validations.find(value => value.code === code)?.message!
  }

  checkFieldIsPresent(field?: string) {
    return field ? field.trim().length != 0 : false
  }

  checkTitle() {
    let condition = this.checkFieldIsPresent(this.skillRequest.title)
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('TITLE', condition, message)
  }

  checkDiscipline() {
    let condition = this.checkFieldIsPresent(this.skillRequest.discipline)
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('DISCIPLINE', condition, message)
  }

  checkLevel() {
    let condition = this.skillRequest.level != undefined
    let message = condition ? '' : 'Veuillez choisir un niveau'
    this.updateValidation('LEVEL', condition, message)
  }

  checkAllFields() {
    this.checkTitle()
    this.checkDiscipline()
    this.checkLevel()
  }

  displayErrorAlert(message: string) {
    this.alert = {display: true, class: 'danger', title: 'Erreur', message: message}
  }

  iSFormValid() {
    this.checkAllFields()
    let result = true;
    this.validations.forEach(value => result = result && value.valid)
    if (!result) {
      this.displayErrorAlert("Certains champs sont invalides")
    }
    return result;
  }

  allLevelsEnum() {
    return Object.values(SkillRequest.LevelEnum)
  }

  levelImage(level: SkillRequest.LevelEnum) {
    switch (level) {
      case "LEVEL_1":
        return 'red-arrow'
      case "LEVEL_2":
        return 'orange-arrow'
      case "LEVEL_3":
        return 'green-arrow'
      case "LEVEL_4":
        return 'green-arrow-plus'
    }
  }

  levelLabel(level: SkillRequest.LevelEnum) {
    switch (level) {
      case "LEVEL_1":
        return 'edit_profile.level_1'
      case "LEVEL_2":
        return 'edit_profile.level_2'
      case "LEVEL_3":
        return 'edit_profile.level_3'
      case "LEVEL_4":
        return 'edit_profile.level_4'
    }
  }

  levelToCategory(level?: SkillRequest.LevelEnum) {
    if (level == undefined)
      return undefined
    switch (level) {
      case "LEVEL_1":
        return SkillRequest.CategoryEnum._1
      case "LEVEL_2":
        return SkillRequest.CategoryEnum._1
      case "LEVEL_3":
        return SkillRequest.CategoryEnum._2
      case "LEVEL_4":
        return SkillRequest.CategoryEnum._2
    }
  }

  labelToEnum(label: string) {
    switch (label) {
      case "LEVEL_1":
        return SkillRequest.LevelEnum._1
      case "LEVEL_2":
        return SkillRequest.LevelEnum._2
      case "LEVEL_3":
        return SkillRequest.LevelEnum._3
      case "LEVEL_4":
        return SkillRequest.LevelEnum._4
      default:
        return undefined
    }
  }

  getSkillTitle(event: string): void {
    this.skillRequest.title = event;
    this.checkTitle()
  }

  getSkillDiscipline(event: string): void {
    this.skillRequest.discipline = event;
    this.checkDiscipline()
  }

  getSkillDescription(event: string) {
    this.skillRequest.description = event;
  }

  onClickCancel(): void {
    this.smDoneEvent.emit(false);
    this.localStorageService.delete(variables.account_attribute);
  }

  onClickSave(): void {
    if (this.iSFormValid()) {
      this.skillRequest.account = this.account.uuid!;
      if (this.skillResource) {
        this.sendUpdateSkillsRequest();
      } else {
        this.sendCreateSkillsRequest();
      }
    }
  }

  onClickLevel(level: string): void {
    this.skillRequest.level = this.labelToEnum(level);
    this.skillRequest.category = this.levelToCategory(this.skillRequest.level)
    this.checkLevel()
  }

  private sendCreateSkillsRequest() {
    this.processing = true
    this.skillService.createSkill(this.skillRequest).subscribe({
      next: response => {
        this.processing = false
        this.toastrService.success("La compétence a été créé avec succés", 'Création compétence');
        this.smDoneEvent.emit(true);
      },
      error: error => {
        this.processing = false
        this.toastrService.error("Erreur lors de la compétence de la formation", 'Création compétence');
      },
      complete: () => {
        this.processing = false
      }
    });
  }

  private sendUpdateSkillsRequest() {
    this.processing = true
    this.skillService.updateSkill(this.skillResource.uuid!, this.skillRequest).subscribe({
      next: response => {
        this.processing = false
        this.toastrService.success("La compétence a été mise à jour avec succés", 'Mis à jour compétence');
        this.smDoneEvent.emit(true);
      },
      error: error => {
        this.processing = false
        this.toastrService.error("Erreur lors de la mis à jour de la compétence", 'Mis à jour compétence');
      },
      complete: () => {
        this.processing = false
      }
    });
  }

}
