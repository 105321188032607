import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AccountDetailsResource,
  DiplomaRequest,
  DiplomaResource,
  DiplomaService,
  ParameterRequest,
  ParameterResource,
  ParameterService,
  TrainingRequest
} from "ApiModuleAccount";
import {Alert} from "../../models/alert";
import {AccountHelper} from "../../helpers/account-helper";
import {LocalStorageService} from "../../services/local-storage.service";
import {variables} from "../../../environments/variables";
import {ToastrService} from "ngx-toastr";
import {ValidationCondition} from "../../models/validationCondition";
import EducationEnum = TrainingRequest.EducationEnum;

@Component({
  selector: 'sm-edit-diploma',
  templateUrl: './edit-diploma.component.html',
  styleUrls: ['./edit-diploma.component.scss']
})
export class EditDiplomaComponent implements OnInit {
  @Input() diplomaResource!: DiplomaResource;
  @Output() smDoneEvent = new EventEmitter<boolean>;
  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  alert: Alert = {} as Alert;
  diplomaRequest: DiplomaRequest = {} as DiplomaRequest;
  validations: ValidationCondition[] = []
  higherEducationLabel: string = "Enseignement Supérieur"
  secondaryEducationLabel: string = "Enseignement Secondaire"
  activityAreas: ParameterResource[] = [];
  schoolTypes: ParameterResource[] = [];
  diplomaLevels: ParameterResource[] = [];
  level: ParameterRequest = {} as ParameterRequest;
  processing: boolean = false

  constructor(private localStorageService: LocalStorageService,
              private diplomaService: DiplomaService,
              private toastrService: ToastrService,
              private parameterService: ParameterService) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
  }

  ngOnInit(): void {
    this.initValidation()
    this.populateRequest();
    this.fetchActivityAreas();
    this.fetchDiplomaLevels();
    this.fetchSchoolTypes();
  }

  getDiplomaLabels(): string[] {
    return Object.values(DiplomaRequest.EducationEnum)
      .map(value => this.enumToEducation(value))
  }

  populateRequest(): void {
    if (this.diplomaResource) {
      this.diplomaRequest.title = this.diplomaResource.title!;
      this.diplomaRequest.description = this.diplomaResource.description!;
      this.diplomaRequest.account = this.diplomaResource.account!;
      this.diplomaRequest.schoolName = this.diplomaResource.schoolName!;
      this.diplomaRequest.startDate = this.diplomaResource.startDate!;
      this.diplomaRequest.endDate = this.diplomaResource.endDate!;
      this.diplomaRequest.city = this.diplomaResource.city!;
      this.diplomaRequest.education = this.diplomaResource.education!;
      this.diplomaRequest.activityArea = this.diplomaResource.activityArea;
      this.diplomaRequest.level = this.diplomaResource.level ? this.diplomaResource.level : ""
    } else {
      this.diplomaRequest.education = DiplomaResource.EducationEnum.Secondary;
      this.diplomaRequest.level = ""
    }
  }

  getSchoolType(event: any): void {
    this.diplomaRequest.schoolType = AccountHelper.stringToParameter(this.schoolTypes, event.target.value);
  }

  getDiplomaCity(event: string): void {
    this.diplomaRequest.city = event;
    this.checkCity()
  }

  getDiplomaDescription(event: string): void {
    this.diplomaRequest.description = event;
  }

  getDiplomaTitle(event: string): void {
    this.diplomaRequest.title = event;
    this.checkTitle()
  }

  getActivityArea(event: string) {
    this.diplomaRequest.activityArea = event;
    this.checkDomain()
  }

  getEndDate(event: string): void {
    this.diplomaRequest.endDate = event;
  }

  getStartDate(event: string): void {
    this.diplomaRequest.startDate = event;
    this.checkDate()
  }

  getDiplomaSchoolName(event: string) {
    this.diplomaRequest.schoolName = event;
    this.checkSchoolName()
  }

  getLevel(event: any) {
    this.diplomaRequest.level = event
    this.checkLevel()
  }

  toStringList(parameters: ParameterResource[]): string[] {
    return AccountHelper.parameterToStringList(parameters);
  }

  levelLabels(): string[] {
    let result: string[] = []
    for (let i = 1; i <= 12; i++) result.push("Baccalauréat + " + i)
    return result
  }

  fetchActivityAreas(): void {
    this.parameterService.findActivityAreas().subscribe({
      next: response => {
        this.activityAreas = response.resources!;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  formatDate(inputDate: string): string {
    if (inputDate) {
      const date = new Date(inputDate);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1; // Months are 0-based
      const year = date.getUTCFullYear();
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    } else {
      return '';
    }
  }

  fetchDiplomaLevels(): void {
    this.parameterService.findDiplomaLevels().subscribe({
      next: response => {
        this.diplomaLevels = response.resources!;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchSchoolTypes(): void {
    this.parameterService.findSchoolTypes().subscribe({
      next: response => {
        this.schoolTypes = response.resources!;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  initValidation() {
    this.validations = []
    this.validations.push(new ValidationCondition(true, 'TITLE', ''))
    this.validations.push(new ValidationCondition(true, 'CITY', ''))
    this.validations.push(new ValidationCondition(true, 'SCHOOL', ''))
    this.validations.push(new ValidationCondition(true, 'LEVEL', ''))
    this.validations.push(new ValidationCondition(true, 'DATE', ''))
    this.validations.push(new ValidationCondition(true, 'DOMAIN', ''))
  }

  updateValidation(code: string, newValue: boolean, message: string) {
    this.validations.forEach(value => {
      if (value.code === code) {
        value.valid = newValue
        value.message = message
      }
    })
  }

  getCssStatus(code: string): string {
    return this.validations.find(value => value.code === code)?.cssFormStatus()!
  }

  isValid(code: string) {
    return this.validations.find(value => value.code === code)?.valid!
  }

  errorMessage(code: string) {
    return this.validations.find(value => value.code === code)?.message!
  }

  getCssStatusFinal(code: string): string {
    return 'is-'.concat(this.getCssStatus(code))
  }

  checkFieldIsPresent(field?: string) {
    return field ? field.trim().length != 0 : false
  }

  checkTitle() {
    let condition = this.checkFieldIsPresent(this.diplomaRequest.title)
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('TITLE', condition, message)
  }

  checkDomain() {
    let condition = this.checkFieldIsPresent(this.diplomaRequest.activityArea)
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('DOMAIN', condition, message)
  }

  checkCity() {
    let condition = this.checkFieldIsPresent(this.diplomaRequest.city)
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('CITY', condition, message)
  }

  checkSchoolName() {
    let condition = this.checkFieldIsPresent(this.diplomaRequest.schoolName)
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('SCHOOL', condition, message)
  }

  checkLevel() {
    let condition = false
    switch (this.diplomaRequest.education) {
      case "HIGHER":
        condition = this.checkFieldIsPresent(this.diplomaRequest.level)
        break;
      case "SECONDARY":
        this.diplomaRequest.level = undefined
        condition = true
        break
    }
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('LEVEL', condition, message)
  }

  checkDate() {
    let condition = this.checkFieldIsPresent(this.diplomaRequest.startDate)
    let message = condition ? '' : 'Ce champs est obligatoire'
    if (condition) {
      condition = this.checkDateIsInPast(this.diplomaRequest.startDate)
      message = condition ? '' : 'Date invalide'
    }
    this.updateValidation('DATE', condition, message)
  }

  checkDateIsInPast(field?: string) {
    let now: Date = new Date();
    let tested: Date = new Date(field!)
    return now.getTime() > tested.getTime()
  }

  checkAllFields() {
    this.checkTitle()
    this.checkDomain()
    this.checkCity()
    this.checkSchoolName()
    this.checkLevel()
    this.checkDate()
  }

  displayErrorAlert(message: string) {
    this.alert = {display: true, class: 'danger', title: 'Erreur', message: message}
  }

  iSFormValid() {
    this.checkAllFields()
    let result = true;
    this.validations.forEach(value => result = result && value.valid)
    if (!result) {
      this.displayErrorAlert("Certains champs sont invalides")
    }
    return result;
  }

  onClickCancel(): void {
    this.smDoneEvent.emit(false);
    this.localStorageService.delete(variables.account_attribute);
  }

  onClickSave(): void {
    if (this.iSFormValid()) {
      this.diplomaRequest.account = this.account.uuid!;
      if (this.diplomaResource) {
        this.sendUpdateDiplomaRequest();
      } else {
        this.sendCreateDiplomaRequest();
      }
    }
  }

  getCurrentEducation(): string {
    return this.enumToEducation(this.diplomaRequest.education);
  }

  getEducation(event: string): void {
    this.diplomaRequest.education = this.educationToEnum(event)!;
    this.checkLevel()
  }

  educationToEnum(education: string) {
    switch (education) {
      case this.secondaryEducationLabel:
        return DiplomaRequest.EducationEnum.Secondary
      case this.higherEducationLabel:
        return DiplomaRequest.EducationEnum.Higher
      default:
        return undefined;
    }
  }

  enumToEducation(edu: EducationEnum): string {
    switch (edu) {
      case "SECONDARY":
        return this.secondaryEducationLabel;
      case "HIGHER":
        return this.higherEducationLabel;
    }
  }

  private sendCreateDiplomaRequest() {
    this.processing = true
    this.diplomaService.createDiploma(this.diplomaRequest).subscribe({
      next: response => {
        this.processing = false
        this.toastrService.success("Le diplôme a été créé avec succés", 'Création du diplôme');
        this.smDoneEvent.emit(true);
      },
      error: error => {
        this.processing = false
        this.toastrService.error("Erreur lors de la création du diplôme", 'Création diplôme');
      },
      complete: () => {
        this.processing = false
      }
    });
  }

  private sendUpdateDiplomaRequest() {
    this.processing = true
    this.diplomaService.updateDiploma(this.diplomaResource.uuid!, this.diplomaRequest).subscribe({
      next: response => {
        this.processing = false
        this.toastrService.success("Le diplôme a été mis à jour avec succés", 'Mis à jour du diplôme');
        this.smDoneEvent.emit(true);
      },
      error: error => {
        this.processing = false
        this.toastrService.error("Erreur lors de la mis à jour du diplôme", 'Mis à jour diplôme');
      },
      complete: () => {
        this.processing = false
      }
    });
  }
}
