import {variables} from '../../environments/variables';
import {Status} from "../enums/status.enum";
import {Route} from "../enums/route.enum";
import {AccountCreationRequest, AccountDetailsResource, SkillRequest, SkillResource} from "ApiModuleAccount";
import {PasswordError} from "../models/passwordError";
import LevelEnum = SkillRequest.LevelEnum;
import CategoryEnum = SkillResource.CategoryEnum;

export class StringHelper {

  static truncateName(account?: AccountDetailsResource, nb: number = 10): string {
    if (!account) {
      return "";
    }
    let name = ' ' + account.firstname + ' ' + account.lastname;
    if (name.length > nb) {
      name = name.slice(0, nb - 1) + '...';
    }
    return name;
  }

  static truncateString(value: string, nb: number): string {
    if (value.length > nb) {
      value = value.slice(0, nb - 1) + '...';
    }
    return value;
  }

  static toNumber(str: string): number {
    return +str;
  }

  static getMaxFileSize(): number {
    return variables.max_file_size * 1048576;
  }

  static formatBytes(bytes: number, decimals = 2): string {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  static truncateText(text: string, nb: number): string {
    if (text && text.length > nb) {
      text = text.slice(0, nb - 1) + '...';
    }
    return text;
  }

  static validateEmail(email: string): boolean {
    const regExp = /^[a-z][a-zA-Z0-9_.]*(\.[a-zA-Z][a-zA-Z0-9_.]*)?@[a-z][a-zA-Z-0-9]*\.[a-z]+(\.[a-z]+)?$/;
    return regExp.test(email);
  }

  static validatePassword(password: string, account: AccountCreationRequest): PasswordError[] {
    let passwordErrors: PasswordError[] = [];

    // const regExp = /^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    const regExpCa = /^(?=^.{8,16})/; // 8 - 16 caracteres
    const regExpLCh = /^((?=.*\d)|(?=.*[!&%$]))/; // Chiffre ou caractere special
    const regExpLm = /^(?=.*[a-z])/; // Lettre mininuscule
    const regExpLM = /^(?=.*[A-Z])/; // Lettre majuscule


    passwordErrors.push({
      validated: regExpCa.test(password),
      message: "- Mot de passe doit contenir entre 8 et 16 caractères"
    });
    passwordErrors.push({
      validated: regExpLCh.test(password),
      message: "- Mot de passe doit contenir au moins 1 chiffre et un caractère spécial : !&%$"
    });
    passwordErrors.push({
      validated: !(password.toLowerCase().includes(account!.lastname!.toLowerCase()) || password.toLowerCase().includes(account.firstname.toLowerCase()) || password.toLowerCase().includes(account.email.toLowerCase())),
      message: "- Mot de passe ne doit pas contenir votre nom ou email"
    });
    passwordErrors.push({
      validated: regExpLM.test(password),
      message: "- Mot de passe doit contenir au moins une lettre majuscule"
    });
    passwordErrors.push({
      validated: regExpLm.test(password),
      message: "- Mot de passe doit contenir au moins une lettre miniscule"
    });

    return passwordErrors;
  }

  static validatePassword2(password: string): PasswordError[] {
    let passwordErrors: PasswordError[] = [];

    // const regExp = /^(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
    const regExpCa = /^(?=^.{8,16})/; // 8 - 16 caracteres
    const regExpLCh = /^((?=.*\d)|(?=.*[!&%$]))/; // Chiffre ou caractere special
    const regExpLm = /^(?=.*[a-z])/; // Lettre mininuscule
    const regExpLM = /^(?=.*[A-Z])/; // Lettre majuscule

    passwordErrors.push({
      validated: regExpCa.test(password),
      message: "- Mot de passe doit contenir entre 8 et 16 caractères"
    });
    passwordErrors.push({
      validated: regExpLCh.test(password),
      message: "- Mot de passe doit contenir au moins 1 chiffre et un caractère spécial : !&%$"
    });
    passwordErrors.push({
      validated: regExpLM.test(password),
      message: "- Mot de passe doit contenir au moins une lettre majuscule"
    });
    passwordErrors.push({
      validated: regExpLm.test(password),
      message: "- Mot de passe doit contenir au moins une lettre miniscule"
    });

    return passwordErrors;
  }

  static initPasswordErrors(): PasswordError[] {
    let passwordErrors: PasswordError[] = [];

    passwordErrors.push({
      validated: false,
      message: "- Mot de passe doit contenir entre 8 et 16 caractères"
    });
    passwordErrors.push({
      validated: false,
      message: "- Mot de passe doit contenir au moins 1 chiffre et un caractère spécial : !&%$"
    });
    passwordErrors.push({
      validated: false,
      message: "- Mot de passe ne doit pas contenir votre nom ou email"
    });
    passwordErrors.push({
      validated: false,
      message: "- Mot de passe doit contenir au moins une lettre majuscule"
    });
    passwordErrors.push({
      validated: false,
      message: "- Mot de passe doit contenir au moins une lettre miniscule"
    });

    return passwordErrors;
  }

  static validateUrl(url: string): boolean {
    const regExp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    return regExp.test(url);
  }

  static urlToTab(url: string): string {
    let tab = Route.DASHBOARD;
    if (url.indexOf(Route.DASHBOARD) >= 0) {
      tab = Route.DASHBOARD;
    }

    if (url.indexOf(Route.MESSAGES) >= 0) {
      tab = Route.MESSAGES;
    }

    if (url.indexOf(Route.PROFILE) >= 0) {
      tab = Route.PROFILE;
    }

    if (url.indexOf(Route.NOTIFICATIONS) >= 0) {
      tab = Route.NOTIFICATIONS;
    }

    if (url.indexOf(Route.NETWORK) >= 0) {
      tab = Route.NETWORK;
    }

    if (url.indexOf(Route.SEARCH) >= 0) {
      tab = Route.SEARCH;
    }

    if (url.indexOf(Route.ASSISTANCE) >= 0) {
      tab = Route.ASSISTANCE;
    }

    if (url.indexOf(Route.SETTINGS) >= 0) {
      tab = Route.SETTINGS;
    }

    if (url.indexOf(Route.EDIT_POST) >= 0) {
      tab = Route.EDIT_POST;
    }
    return tab;
  }

  static getCompanyIcon(account: AccountDetailsResource): string {
    if (account && account.status) {
      if (account.status === Status[Status.PROFESSIONAL]) {
        return 'company';
      }

      if (account.status === Status[Status.TEACHER]) {
        return 'school';
      }

      if (account.status === Status[Status.STUDENT]) {
        return 'school';
      }
    }
    return 'company';
  }

  static generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * max) + min;
  }

  static getLevel(level: string): LevelEnum {
    if (level) {
      switch (level) {
        case LevelEnum._1:
          return LevelEnum._1;
        case LevelEnum._2:
          return LevelEnum._2;
        case LevelEnum._3:
          return LevelEnum._3;
        case LevelEnum._4:
          return LevelEnum._4;
        default:
          return LevelEnum._1;
      }
    } else {
      return LevelEnum._1;
    }
  }

  static getCategory(category: string): CategoryEnum {
    if (category) {
      switch (category) {
        case CategoryEnum._1:
          return CategoryEnum._1;
        case CategoryEnum._2:
          return CategoryEnum._2;
        default:
          return CategoryEnum._1;
      }
    } else {
      return CategoryEnum._1;
    }
  }
}
