<div>
  <sm-header *ngIf="!isOffline"></sm-header>
  <sm-offline-header *ngIf="isOffline"></sm-offline-header>
  <div class="page-wrapper sm-responsive">
    <div class="content" style="padding-top: 40px;">
      <div class="row skillsmates-container post-detail-row">
        <!--   partie gauche du dashboard -->
        <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 post-detail-column"
             style="padding-bottom: 20px;">
          <sm-image-text
            *ngIf="previousRoute"
            (click)="onClickReturn()"
            [bgColor]="'#D3D3D3'"
            image="{{'left-arrow' | image}}"
            text="{{'post.return' | translate}}">
          </sm-image-text>

          <div style="height: 20px;"></div>
          <sm-account-profile
            [account]="post.account!">
          </sm-account-profile>
          <br>
          <p *ngIf="isOffline"><b
            style="color: black">{{ 'offline.join_message' | translate }}</b></p>
          <sm-button *ngIf="isOffline"
                     style="margin-top: 5px;"
                     [type]="'success'"
                     text="{{ 'login.register' | translate }}"
                     (smButtonEvent)="handleButton1()">
          </sm-button>
        </div>

        <!-- partie centrale du dashboard -->
        <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 post-detail-column" *ngIf="post">
          <sm-post
            [post]="post">
          </sm-post>
        </div>

        <!-- partie droite du dashboard -->
        <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 post-detail-column">
          <p
            class="text-danger">{{'post.other_posts' | translate}} <span
            class="text-danger skillsmates-label">{{post.account!.firstname}} {{post.account!.lastname}}</span>
          </p>
          <div class="sm-profile-attributes-1">
            <sm-other-post *ngFor="let p of postResponse.resources" [post]="p"
                           (click)="onClickPost(p)"></sm-other-post>
          </div>
          <div class="row bg-white scrolling-wrapper sm-profile-attributes-2"
               style="margin-bottom: 10px;  border-radius: 0 0 10px 10px;">
            <carousel style="height: 350px; z-index: -0;">
              <div class="carousel-cell col-9 col-sm-9 col-md-9 col-lg-3" style="padding: 3px;"
                   *ngFor="let p of postResponse.resources">
                <sm-other-post [post]="p"></sm-other-post>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
  <sm-bottom-nav-bar></sm-bottom-nav-bar>
</div>
