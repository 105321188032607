<div class="row" style="padding-bottom: 10px; padding-top: 10px;">
  <div class="col-2">
    <span class="user-img">
        <img class="" src="{{selection.image! | image}}" style="background-color: whitesmoke;" width="36"
             alt="{{selection.image}}">
    </span>
  </div>
  <div class="col-10 text-left">
    <div class="text skillsmates-two-lines">
      <strong>{{selection.description}}</strong>
    </div>
    <div class="date-post" style="font-style: italic; font-size: 10px;">
      <span>
        <img height="14" src="{{'liked' | image}}" alt=""> {{selection.liked}} &nbsp;
      </span>
      <span>
        <img height="14" src="{{'share' | image}}" alt=""> {{selection.shared}} &nbsp;
      </span>
      <span>
        <img height="14" src="{{'comment-active' | image}}" alt=""> {{selection.comments}} &nbsp;
      </span>
      <span>
        <img height="14" src="{{'download' | image}}" alt=""> {{selection.downloads}} &nbsp;
      </span>
    </div>
  </div>
</div>

<div class="row" style="padding-bottom: 10px; padding-top: 10px;">
  <div class="col-2">
    <span class="user-img">
      <a *ngIf="selection.account.uuid === loggedAccount.uuid" [routerLink]=Route.PROFILE>
        <img class="rounded-circle" src="{{selection.account | avatar}}" style="background-color: whitesmoke;"
             width="36" alt="{{selection.account.lastname}}">
      </a>
      <a *ngIf="selection.account.uuid !== loggedAccount.uuid"
         [routerLink]=Route.PROFILE>
        <img class="rounded-circle" src="{{selection.account | avatar}}" style="background-color: whitesmoke;"
             width="36" alt="{{selection.account.lastname}}">
      </a>
      <span *ngIf="selection.account.connected" class="status online"></span>
      <span *ngIf="!selection.account.connected" class="status offline"></span>
    </span>
  </div>
  <div class="col-10 text-left">
    <div class="text">
      <strong>{{name(selection.account)}}</strong>
    </div>
    <div class="date-post" style="font-style: italic; font-size: 10px;">
      {{selection.account.createdAt | timeAgo}}
    </div>
  </div>
</div>

<sm-horizontal-line *ngIf="!isLastOne" [color]="'#DADADA'"></sm-horizontal-line>
