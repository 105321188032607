<div class="form-group">
  <small class="text-info">{{ title ? title : placeholder }}</small>
  <strong *ngIf="mandatory" class="text-danger" style="font-size: larger;">*</strong>
  <div class="input-group mb-3">
    <input (change)="handleChange($event)" (ngModelChange)="onInputChange($event)" [(ngModel)]="value"
           [disabled]="disabled"
           [maxLength]="maxInputLength"
           [ngClass]="{'is-valid': inputStatus=='valid','is-invalid': inputStatus=='invalid'}"
           autocomplete="off"
           autofocus=""
           class="form-control"
           name="{{name}}"
           placeholder={{placeholder}} style="border-radius: 6px"
           type="{{type}}"
           value="{{value}}"/>
    <span (click)="onClickEye()" *ngIf="showEye" class="input-group-text pointer">
      <i [ngClass]="type=='text' ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
    </span>
  </div>
  <small *ngIf="fieldSize()>0 && showCounter" class="text-info float-right">
    {{ fieldSize() }} / {{ maxInputLength }}
  </small>
  <small *ngIf="showErrorMessage" class="text-danger">{{ errorMessage }}</small>
</div>
