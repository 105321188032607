import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sm-collapsible-tile',
  templateUrl: './collapsible-tile.component.html',
  styleUrls: ['./collapsible-tile.component.scss']
})
export class CollapsibleTileComponent implements OnInit {

  @Input() isActive: boolean = false;
  @Input() title: string = "";
  @Input() image: string = "";
  @Input() list: string[] = [];
  @Input() checkedItems: string[] = [];
  @Input() bgColor: string = "#F5F5F5FF";
  @Output() smItemEvent = new EventEmitter<string>();
  expand: boolean = false;
  headTileImage: string = "arrow-open-up";

  ngOnInit(): void {
  }

  onClickTitle(): void {
    this.expand = !this.expand;
    this.headTileImage = this.expand ? "arrow-open-down" : "arrow-open-up";
  }

  onClickItem(item: string): void {
    this.smItemEvent.emit(item);
  }

  isItemSelected(item:string): boolean {
    return this.isActive && (this.checkedItems.includes(item))
  }

}
