<div class="row">
  <sm-alert [alert]="alert"></sm-alert>
  <div class="col-12 p-3">
    <strong>{{ 'edit_profile.add' | translate }} {{ 'edit_profile.skill' | translate }}</strong>
  </div>
  <div class="col-12">
    <sm-input (smInputEvent)="getSkillTitle($event)"
              [errorMessage]="errorMessage('TITLE')"
              [inputStatus]="getCssStatus('TITLE')"
              [mandatory]="true"
              [showErrorMessage]="!isValid('TITLE')"
              [type]="'text'"
              [value]="skillRequest.title!"
              placeholder="{{'edit_profile.title' | translate}} {{'edit_profile.skill' | translate}}"
              title="{{'edit_profile.title' | translate}} {{'edit_profile.skill' | translate}}">
    </sm-input>
    <sm-input (smInputEvent)="getSkillDiscipline($event)"
              [errorMessage]="errorMessage('DISCIPLINE')"
              [inputStatus]="getCssStatus('DISCIPLINE')"
              [mandatory]="true"
              [showErrorMessage]="!isValid('DISCIPLINE')"
              [type]="'text'"
              [value]="skillRequest.discipline!"
              placeholder="{{'edit_profile.discipline' | translate}}, {{'edit_profile.domain' | translate}} {{'edit_profile.skill' | translate}}"
              title="{{'edit_profile.discipline' | translate}}, {{'edit_profile.domain' | translate}} {{'edit_profile.skill' | translate}}">
    </sm-input>
    <sm-textarea
      (smInputEvent)="getSkillDescription($event)"
      [value]="skillRequest.description ? skillRequest.description : ''"
      placeholder="{{'edit_profile.description' | translate}}, {{'edit_profile.keywords' | translate}}"
      title="{{'edit_profile.description' | translate}}, {{'edit_profile.keywords' | translate}}">
    </sm-textarea>
  </div>

  <!--<div class="col-12 m-b-10">
    <div class="text-info">{{ 'edit_profile.category' | translate }}  {{ 'edit_profile.skill' | translate }}</div>
  </div>
  <span *ngIf="showErrorMessageCategory" class="alert alert-danger">Veuillez choisir une catégorie</span>
  <div class="col scroll">
    <sm-image-text
      (click)="onClickCategory('CATEGORY_1')"
      [bgColor]="skillRequest.category === 'CATEGORY_1' ? 'green' : '#F5F5F5FF'"
      text="{{'edit_profile.skill' | translate}} {{'edit_profile.category_1' | translate}}">
    </sm-image-text>
    <sm-image-text
      (click)="onClickCategory('CATEGORY_2')"
      [bgColor]="skillRequest.category === 'CATEGORY_2' ? 'green' : '#F5F5F5FF'"
      text="{{'edit_profile.skill' | translate}} {{'edit_profile.category_2' | translate}}">
    </sm-image-text>
  </div>-->

  <div class="col-12 m-b-10">
    <div class="text-info">{{ 'edit_profile.current_level' | translate }}</div>
  </div>
  <span *ngIf="!isValid('LEVEL')" class="alert alert-danger">{{ errorMessage('LEVEL') }}</span>
  <div class="col scroll">
    <sm-image-text (click)="onClickLevel(levelEnum)"
                   *ngFor="let levelEnum of allLevelsEnum()"
                   [bgColor]="skillRequest.level === levelEnum ? 'green' : '#F5F5F5FF'"
                   image="{{levelImage(levelEnum) | image}}"
                   text="{{levelLabel(levelEnum) | translate}}">
    </sm-image-text>
  </div>

  <div class="row">
    <div class="col m-t-10">
      <sm-button (click)="onClickCancel()" text="{{'edit_profile.cancel' | translate}}"></sm-button>
    </div>
    <div *ngIf="!processing" class="col m-t-10">
      <sm-button (click)="onClickSave()" [type]="'success'" text="{{'edit_profile.validate' | translate}}"></sm-button>
    </div>
    <div *ngIf="processing" class="col m-t-10">
      <sm-processing [loading]="processing"/>
    </div>
  </div>
</div>
