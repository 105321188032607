export class ValidationCondition {
  valid!: boolean;
  message!: string;
  code!: string

  constructor(valid: boolean, code: string, message: string) {
    this.valid = valid
    this.code = code
    this.message = message
  }


  cssClass() {
    return this.valid ? 'text-success' : 'text-danger'
  }

  cssFormStatus() {
    return this.valid ? 'valid' : 'invalid'
  }
}
