import {Component, Input} from '@angular/core';

@Component({
  selector: 'sm-processing',
  templateUrl: './processing.component.html',
  styleUrls: ['./processing.component.scss']
})
export class ProcessingComponent {
  @Input() loading: boolean = false;
  @Input() width = '50';

  constructor() {
  }

  ngOnInit(): void {
  }
}
