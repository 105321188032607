<button
  [ngClass]="getCssClass()"
  class="btn btn-primary sm-border-radius button-style">
  <div class="row button-text">
    <div class="col skillsmates-single-line">{{ text }}</div>
    <div *ngIf="image" class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3">
      <img alt="{{image}}" src="{{image}}" style="width: 16px; height: 16px;">
    </div>
  </div>
</button>


