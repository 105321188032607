<div class="row">
  <div class="col-1">
    <span class="user-img">
      <a *ngIf="loggedAccount && comment.emitter?.uuid === loggedAccount.uuid"
         [routerLink]="[Route.PROFILE]">
        <img class="rounded-circle" src="{{comment.emitter?.avatar}}"
             style="background-color: whitesmoke;" width="35" alt="{{comment.emitter?.lastname}}">
      </a>
      <span class="status online"></span>
    </span>
  </div>
  <div class="col-10">
    <div class="comment">
      <div
        style="font-weight: bold">{{comment.emitter?.firstname}} {{comment.emitter?.lastname}}</div>
      <div>{{comment.content}}</div>
    </div>
    <div style="font-size: 12px; text-align: right">{{comment.createdAt | timeAgo}}</div>
  </div>
  <span class="col-1"
        *ngIf="loggedAccount && comment.emitter!.uuid === loggedAccount.uuid">
    <a class="icon-action" style="" data-bs-toggle="modal" data-bs-target="#delete_attribute">
      <span class="action-circle large">
        <em class="material-icons">delete</em>
      </span>
    </a>
  </span>
</div>

<div id="delete_attribute" class="modal fade delete-modal" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <img src="{{'symbole-skills-mates' | image}}" alt="" width="50" height="46">
        <h3>Voulez-vous supprimer ce commentaire?</h3>
        <div class="m-t-20"><a class="btn btn-white" data-bs-dismiss="modal">NON</a>
          <button type="submit" class="btn btn-danger" data-bs-dismiss="modal" (click)="onClickDelete()">OUI
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
