<div *ngIf="!editMode" class="row">
  <div class="col-12">
    <sm-info-text text="{{'edit_profile.info_text_details' | translate}}"></sm-info-text>
  </div>
  <div *ngIf="account.uuid === loggedAccount.uuid" class="col-12">
    <sm-image-text
      (click)="onClickUpdate()"
      [bgColor]="'white'"
      [color]="'#00ACED'"
      image="{{'user' | image}}"
      style="font-weight: bolder; font-size: x-large"
      text="{{'edit_profile.update_profile' | translate}}">
    </sm-image-text>
  </div>
  <div class="col-12" style="margin-top: 20px;">
    <div class="row">
      <div class="col">
        <div style="font-size: x-large; color: #16152d;">
          <div>{{ account.firstname }}</div>
          <strong>{{ account.lastname }}</strong>
        </div>
        <div style=" color: #16152d;">
          <p>{{ 'edit_profile.gender' | translate }} :
            <strong>{{ accountHelper.getGenderString(account.gender) }}</strong>
          </p>
          <p>{{ 'edit_profile.status' | translate }} :
            <strong>{{ accountHelper.getStatusString(account.status) }}</strong>
          </p>
          <p>{{ 'edit_profile.birthdate' | translate }} : <strong>{{ convertDate(account.birthdate!) }}</strong></p>
          <p>{{ 'edit_profile.home_country' | translate }} : <strong>{{ account.country! }}</strong></p>
          <p>{{ 'edit_profile.home_city' | translate }} : <strong>{{ account.city }}</strong></p>
        </div>
      </div>
      <div class="col">

        <div (click)="open(modalProfileImage)" class="profile-img" style="margin: auto; padding-bottom: 0">
          <a>
            <img (error)="setDefault()" alt="" class="avatar" src="{{account.avatar}}"
                 style="border: white solid 2px;">
          </a>
          <div *ngIf="account.uuid === loggedAccount.uuid" class="avatar-edit"
               style="background-color: white; padding: 5px; border-radius: 50%; z-index: 99;">
            <img alt="" height="24" src="{{'edit-photo' | image}}" width="24">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<sm-edit-profile-details
  (smDoneEvent)="onClickDone($event)"
  *ngIf="editMode">
</sm-edit-profile-details>

<ng-template #modalProfileImage let-modal>
  <sm-edit-avatar [account]="loggedAccount"></sm-edit-avatar>
</ng-template>


