<div class="row">
  <sm-alert [alert]="alert"></sm-alert>
  <div class="col-12 p-3">
    <strong>{{ 'edit_profile.add' | translate }} {{ 'edit_profile.job' | translate }}</strong>
  </div>
  <div class="col-12">
    <sm-input
      (smInputEvent)="getJobCompany($event)"
      [errorMessage]="errorMessage('COMPANY')"
      [inputStatus]="getCssStatus('COMPANY')"
      [mandatory]="true"
      [showErrorMessage]="!isValid('COMPANY')"
      [type]="'text'"
      [value]="jobRequest.company"
      placeholder="{{'edit_profile.company_title' | translate}}"
      title="{{'edit_profile.company_title' | translate}}">
    </sm-input>
    <sm-input
      (smInputEvent)="getActivitySector($event)"
      [errorMessage]="errorMessage('SECTOR')"
      [inputStatus]="getCssStatus('SECTOR')"
      [mandatory]="true"
      [showErrorMessage]="!isValid('SECTOR')"
      [type]="'text'"
      [value]="jobRequest.activitySector!"
      placeholder="{{'edit_profile.job_activity_sector_label' | translate}}"
      title="{{'edit_profile.job_activity_sector_label' | translate}}">
    </sm-input>
    <sm-input
      (smInputEvent)="getJobTitle($event)"
      [errorMessage]="errorMessage('TITLE')"
      [inputStatus]="getCssStatus('TITLE')"
      [mandatory]="true"
      [showErrorMessage]="!isValid('TITLE')"
      [type]="'text'"
      [value]="jobRequest.title"
      placeholder="{{'edit_profile.title' | translate}} {{'edit_profile.position' | translate}}"
      title="{{'edit_profile.title' | translate}} {{'edit_profile.position' | translate}}">
    </sm-input>
    <sm-input
      (smInputEvent)="getActivityArea($event)"
      [errorMessage]="errorMessage('AREA')"
      [inputStatus]="getCssStatus('AREA')"
      [mandatory]="true"
      [showErrorMessage]="!isValid('AREA')"
      [type]="'text'"
      [value]="jobRequest.activityArea!"
      placeholder="{{'edit_profile.job_activity_area_label' | translate}}"
      title="{{'edit_profile.job_activity_area_label' | translate}}">
    </sm-input>
    <div class="row">
      <div class="col">
        <sm-input
          (smInputEvent)="getStartDate($event)"
          [errorMessage]="errorMessage('START')"
          [inputStatus]="getCssStatus('START')"
          [mandatory]="true"
          [name]="'start_date'"
          [showErrorMessage]="!isValid('START')"
          [type]="'date'"
          [value]="formatDate(jobRequest.startDate)"
          placeholder="{{'edit_profile.start_date' | translate}}"
          title="{{'edit_profile.start_date' | translate}}">
        </sm-input>
      </div>
      <div class="col">
        <sm-input
          (smInputEvent)="getEndDate($event)"
          [disabled]="jobRequest.currentJob!"
          [errorMessage]="errorMessage('END')"
          [inputStatus]="getCssStatus('END')"
          [mandatory]="true"
          [name]="'end_date'"
          [showErrorMessage]="!isValid('END')"
          [type]="'date'"
          [value]="formatDate(jobRequest.endDate!)"
          placeholder="{{'edit_profile.end_date' | translate}}"
          title="{{'edit_profile.end_date' | translate}}">
        </sm-input>
      </div>
      <div class="col">
        <sm-toggle-button
          (smCheckEvent)="isChecked($event)"
          [checked]="jobRequest.currentJob!"
          title="{{'edit_profile.ongoing' | translate}}">
        </sm-toggle-button>
      </div>
    </div>
    <sm-input
      (smInputEvent)="getJobCity($event)"
      [errorMessage]="errorMessage('CITY')"
      [inputStatus]="getCssStatus('CITY')"
      [mandatory]="true"
      [showErrorMessage]="!isValid('CITY')"
      [type]="'text'"
      [value]="jobRequest.city!"
      placeholder="{{'edit_profile.location' | translate}}"
      title="{{'edit_profile.location' | translate}}">
    </sm-input>
    <sm-textarea
      (smInputEvent)="getJobDescription($event)"
      [value]="jobRequest.description ? jobRequest.description : ''"
      placeholder="{{'edit_profile.description' | translate}}"
      title="{{'edit_profile.description' | translate}}">
    </sm-textarea>
  </div>
  <div class="row">
    <div class="col m-t-10">
      <sm-button (click)="onClickCancel()" text="{{'edit_profile.cancel' | translate}}"></sm-button>
    </div>
    <div *ngIf="!processing" class="col m-t-10">
      <sm-button (click)="onClickSave()" [type]="'success'" text="{{'edit_profile.validate' | translate}}"></sm-button>
    </div>
    <div *ngIf="processing" class="col m-t-10">
      <sm-processing [loading]="processing"/>
    </div>
  </div>
</div>
