import {Component, Input, OnInit} from '@angular/core';
import {DashboardPostResource} from "ApiModulePost";

@Component({
  selector: 'sm-dashboard-post',
  templateUrl: './dashboard-post.component.html',
  styleUrls: ['./dashboard-post.component.scss']
})
export class DashboardPostComponent implements OnInit {

  @Input() dashboardPost: DashboardPostResource = {} as DashboardPostResource;

  ngOnInit(): void {
  }

  isShared(): boolean {
    return this.dashboardPost.shareAuthor != undefined
  }

}
