import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {Location} from "@angular/common";

@Component({
  selector: 'sm-assistance-dashboard',
  templateUrl: './assistance-dashboard.component.html',
  styleUrls: ['./assistance-dashboard.component.scss']
})
export class AssistanceDashboardComponent implements OnInit {

  @Output() selectedBlock = new EventEmitter<string>();

  constructor(private location: Location) {
  }

  ngOnInit(): void {
  }

  onClickFaq(): void {
    this.selectedBlock.emit('faq');
  }

  onClickContactUs(): void {
    this.selectedBlock.emit('contactUs');
  }


  onClickReturnButton(): void {
   this.location.back()
  }


}
