<div class="row skillsmates-container">
  <div class="col-12">
    <sm-info-text text="{{'edit_post.info_text_new_post_title' | translate}}"></sm-info-text>
  </div>
  <sm-alert [alert]="alert"></sm-alert>

  <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
    <strong>{{ 'edit_post.new_content' | translate }}</strong>
    <sm-horizontal-line></sm-horizontal-line>
    <br>
    <strong style="font-size: small;">{{ 'edit_post.select_post_type' | translate }}</strong>
    <strong class="text-danger" style="font-size: small;">*</strong>
    <div *ngIf="mediaTypes && mediaTypes.length > 0" class="row bg-white media-icon">
      <div (click)="onClickMediaType(mediaType)" *ngFor="let mediaType of mediaTypes" class="col no-margin-padding">
        <sm-media-type
          [mediaType]="mediaType"
          [selected]="mediaType.code === selectedMediaType.code">
        </sm-media-type>
      </div>
      <small *ngIf="showErrorMessageDocumentType"
             class="text-danger">{{ 'edit_post.select_post_category' | translate }}</small>
    </div>
    <strong style="font-size: small;">
      {{ 'edit_post.select_post_category' | translate }}
      <span class="text-danger" style="font-size: large;">*</span>
    </strong>
    <sm-dropdown
      (smInputEvent)="getDocumentType($event)"
      [inputStatus]="inputStatusDocumentType"
      [list]="selectedDocumentTypesList"
      [selected]="selectedDocumentTypesString"
      [showErrorMessage]="showErrorMessageDocumentType"
      errorMessage="{{'edit_post.select_post_category' | translate}}">
    </sm-dropdown>
    <strong style="font-size: small;">{{ 'edit_post.select_post_media' | translate }}</strong>

    <sm-input
      (smInputEvent)="getLink($event)"
      [disabled]="isMediaFile"
      [inputStatus]="inputStatusLink"
      [mandatory]="true"
      [showErrorMessage]="showErrorMessageLink"
      [type]="'text'"
      [value]="postRequest.link!"
      errorMessage="{{'edit_post.paste_url' | translate}}"
      placeholder="{{'edit_post.paste_url' | translate}}"
      title="{{'edit_post.paste_url' | translate}}">
    </sm-input>

    <small class="text-info">{{ 'edit_post.choose_file' | translate }}</small>
    <strong class="text-danger" style="font-size: larger;">*</strong>
    <input #file (change)="onFileSelected($event)" [hidden]="true" type="file">
    <sm-button
      #upload (click)="file.click()"
      disabled="true"
      image="{{'document' | image}}"
      text="{{'edit_post.choose_file' | translate}}">
    </sm-button>
    <small *ngIf="!selectedFile" class="text-danger">Taille max {{maxFileSize}}Mb</small>
    <small *ngIf="selectedFile">{{ selectedFile?.name }} - {{ sizeDisplayText() }}</small>
    <small *ngIf="showErrorMessageFile" class="text-danger">{{ 'edit_post.choose_file' | translate }}</small>
    <br>
    <small *ngIf="!isSelectedFileValid()" class="text-danger">{{ 'edit_post.invalid_file_size' | translate }}</small>
  </div>

  <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9 skillsmates-box-shadow bg-white">
    <strong>{{ 'edit_post.description_content' | translate }}</strong>
    <sm-horizontal-line></sm-horizontal-line>
    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-9 col-xl-9">
        <sm-input
          (valueChange)="getTitle($event)"
          [inputStatus]="inputStatusTitle"
          [mandatory]="true"
          [maxInputLength]="titleMaxLength"
          [showCounter]="true"
          [showErrorMessage]="showErrorMessageTitle"
          [type]="'text'"
          [value]="postRequest.title!"
          errorMessage="{{'edit_post.document_title' | translate}}"
          placeholder="{{'edit_post.document_title' | translate}}"
          title="{{'edit_post.document_title' | translate}}">
        </sm-input>
        <sm-textarea
          (valueChange)="getKeywords($event)"
          [maxInputLength]="KeywordMaxLength"
          [rows]="3"
          [showCounter]="true"
          [value]="postRequest.keywords ? postRequest.keywords : ''"
          placeholder="{{'edit_post.document_keywords_placeholder' | translate}}"
          hint="{{'edit_post.document_keywords_hint'|translate}}"
          title="{{'edit_post.document_keywords' | translate}}">
        </sm-textarea>
        <sm-textarea
          (valueChange)="getDescription($event)"
          [maxInputLength]="descriptionMaxLength"
          [showCounter]="true"
          [value]="postRequest.description ? postRequest.description : ''"
          placeholder="{{'edit_post.document_description_placeholder' | translate}}"
          title="{{'edit_post.document_description' | translate}}">
          [rows]="8">
        </sm-textarea>
        <div class="row">
          <div class="col" data-bs-target="#delete_attribute" data-bs-toggle="modal">
            <sm-button
              text="{{ 'edit_post.cancel' | translate }}">
            </sm-button>
          </div>
          <div *ngIf="!processing" class="col">
            <sm-button (smButtonEvent)="onClickPublish()" [type]="'success'"
                       text="{{ 'edit_post.publish' | translate }}"/>
          </div>
          <div *ngIf="processing" class="col">
            <sm-processing [loading]="processing"/>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3">
        <!--        <sm-preview-post-media [post]="postRequest" [image]="image"></sm-preview-post-media>-->
        <div class="image">
          <img [src]="image ? image : previewLink" alt="" class="oversize" ngxViewer>
          <small style="word-wrap: break-word;">{{ postRequest ? postRequest.title : '' }}</small>
        </div>
      </div>
    </div>
    <br>

  </div>

</div>

<div class="modal fade delete-modal" id="delete_attribute" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <img alt="" height="46" src="{{'symbole-skills-mates' | image}}" width="50">
        <h3>Voulez-vous annuler cette publication?</h3>
        <div class="m-t-20"><a class="btn btn-white" data-bs-dismiss="modal">NON</a>
          <button (click)="onClickCancel()" class="btn btn-danger" data-bs-dismiss="modal" type="submit">OUI
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
