<div class="row m-t-30 m-b-10" style=" " *ngIf="!editPassword">
  <div class="col-9">{{'settings.change_password' | translate}} :</div>
  <div class="col-3">
    <sm-button text="{{'settings.edit' | translate}}" (smButtonEvent)="onClickEdit('password')"></sm-button>
  </div>
  <div class="col-12">
    <small>{{'settings.change_password_text' | translate}}</small>
  </div>
</div>
<div class="row m-b-10" *ngIf="editPassword">
  <div class="col-12">
    <sm-input
      [showEye]="true"
      placeholder="{{'settings.old_password' | translate}}"
      name="{{'settings.old_password' | translate}}"
      [type]="'password'"
      (smInputEvent)="getPassword($event)">
    </sm-input>
    <div class="row">
      <div class="col">
        <sm-input
          [showEye]="true"
          [type]="'password'"
          title="{{'settings.new_password' | translate}}"
          placeholder="{{'settings.new_password' | translate}}"
          errorMessage="{{'settings.new_password' | translate}}"
          (smInputEvent)="getNewPassword($event)">
        </sm-input>
      </div>
      <div class="col">
        <sm-input
          [showEye]="true"
          [type]="'password'"
          title="{{'settings.confirm_new_password' | translate}}"
          placeholder="{{'settings.confirm_new_password' | translate}}"
          errorMessage="{{'settings.confirm_new_password' | translate}}"
          (smInputEvent)="getConfirmNewPassword($event)">
        </sm-input>
      </div>
    </div>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col m-t-10">
        <sm-button text="{{'edit_profile.cancel' | translate}}" (click)="onClickCancel()"></sm-button>
      </div>
      <div class="col m-t-10">
        <sm-button text="{{'edit_profile.validate' | translate}}" [type]="'success'"
                   (click)="onClickSave()"></sm-button>
      </div>
    </div>
  </div>
</div>


<sm-horizontal-line></sm-horizontal-line>
<div class="row m-t-10 m-b-10" style=" " *ngIf="!deleteAccount">
  <div class="col-9">{{'settings.deactivate_delete_account' | translate}} :</div>
  <div class="col-3">
    <sm-button text="{{'settings.edit' | translate}}" (smButtonEvent)="onClickEdit('account')"></sm-button>
  </div>
</div>
<div class="row m-t-10 m-b-10" *ngIf="deleteAccount">
  <div class="col-12">
    <strong class="m-b-10">{{'settings.deactivate_delete_account' | translate}}</strong>
    <div class="row m-t-10">
      <div class="col">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
          <label class="form-check-label" for="flexRadioDefault1">
            {{'settings.deactivate' | translate}}
          </label>
        </div>
      </div>
      <div class="col">
        <div class="form-check">
          <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
          <label class="form-check-label" for="flexRadioDefault2">
            {{'settings.delete' | translate}}
          </label>
        </div>
      </div>
    </div>

    <sm-input
      [showEye]="true"
      placeholder="{{'sm.password' | translate}}"
      name="{{'sm.password' | translate}}"
      [type]="'password'"
      (smInputEvent)="getPassword($event)">
    </sm-input>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col m-t-10">
        <sm-button text="{{'edit_profile.cancel' | translate}}" (click)="onClickCancel()"></sm-button>
      </div>
      <div class="col m-t-10" *ngIf="!processing">
        <sm-button text="{{'edit_profile.validate' | translate}}" [type]="'success'" (click)="onClickSave()"/>
      </div>
      <div class="col m-t-10" *ngIf="processing">
        <sm-processing [loading]="processing"/>
      </div>
    </div>
  </div>
</div>
<sm-horizontal-line></sm-horizontal-line>
