import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountDetailsResource, TrainingResponse, TrainingService} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {variables} from "../../../environments/variables";

@Component({
  selector: 'sm-profile-ongoing-trainings',
  templateUrl: './profile-ongoing-trainings.component.html',
  styleUrls: ['./profile-ongoing-trainings.component.scss']
})
export class ProfileOngoingTrainingsComponent implements OnInit {
  @Input() editMode: boolean = false;
  @Output() smEditModeEvent = new EventEmitter<string>;
  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  trainingResponse: TrainingResponse = {} as TrainingResponse;

  constructor(private localStorageService: LocalStorageService,
              private trainingService: TrainingService,) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
  }

  ngOnInit() {
    this.fetchTrainings();
  }

  fetchTrainings(): void {
    this.trainingService.findTrainingByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.trainingResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  onClickAdd(event: string): void {
    this.smEditModeEvent.emit(event);
    this.editMode = true;
  }

  onClickDone(event: boolean): void {
    this.editMode = false;
    if (event) {
      this.fetchTrainings();
    }
    this.smEditModeEvent.emit('');
  }

  onClickUpdate(event: string): void {
    this.smEditModeEvent.emit(event);
    this.trainingResponse!.resource = this.localStorageService.get(variables.account_attribute);
    this.editMode = true;
  }
}
