import {Component, Input, OnInit} from '@angular/core';
import {MediaType} from "../../models/mediaType";
import {LocalStorageService} from "../../services/local-storage.service";
import {MimetypeHelper} from "../../helpers/mimetype-helper";
import {MediaHelper} from "../../helpers/media-helper";
import {AccountDetailsResource, PostDetailsResource} from "ApiModulePost";

@Component({
  selector: 'sm-account-documents',
  templateUrl: './account-documents.component.html',
  styleUrls: ['./account-documents.component.scss']
})
export class AccountDocumentsComponent implements OnInit {

  @Input() mediaType!: MediaType;
  @Input() account?: AccountDetailsResource;
  @Input() posts?: PostDetailsResource[];
  @Input() total?: number;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;

  constructor(private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
  }

  onClickMore(mediaType: MediaType): void {

  }

  isImage(post: PostDetailsResource): boolean {
    return MimetypeHelper.isImageLink(post.thumbnail!);
  }

  isPdf(post: PostDetailsResource): boolean {
    return MimetypeHelper.isPdfLink(post.thumbnail!);
  }

  isYoutube(post: PostDetailsResource): boolean {
    return MediaHelper.isYoutubeLink(post.thumbnail!);
  }

  getYoutubeVideoId(post: PostDetailsResource): string | null {
    return MediaHelper.getYoutubeVideoId(post.thumbnail!);
  }
}
