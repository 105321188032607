<div class="row" style="padding-top: 10px;">
  <div class="col-12">
    <span><a (click)="onClickAbout()" class="footer-text pointer">{{ 'login.about' | translate }}</a></span> -
    <!--    <span><a class="footer-text pointer" href="#">{{ 'login.help' | translate }}</a></span> - -->
    <!--    <span><a class="footer-text pointer" href="#">{{ 'login.confidentiality_policy' | translate }}</a></span> - -->
    <span><a (click)="onClickCGU()"
             class="footer-text pointer">{{ 'login.terms_and_conditions' | translate }}</a></span> -
    <span><a (click)="onClickFAQ()" class="footer-text pointer">{{ 'login.faq' | translate }}</a></span>
  </div>
  <div class="col-12">
    <a [routerLink]="[Route.DASHBOARD]">
      <img alt="" height="48" src="{{'symbole-skills-mates' | image}}" style="border-radius: 5px;"> <span></span>
    </a>
  </div>
  <div class="col-12">
    © <strong><a class="std" routerLink="/" style="text-decoration: none;"
                 target="_blank">{{ 'sm.app_name' | translate }}</a></strong>. <strong><span
    style="color: #000;"> {{ year }} <br>Version : </span></strong> <strong style="color:#F00;">{{ version }}</strong>
  </div>
</div>

