<div class="skillsmates-container">
  <div class="row">
    <div class="col text-center" style="margin: 15px;">
      <a [routerLink]="[Route.PROFILE]" style="margin: auto;">
        <!--        <img class="avatar" height="12" src="{{account.avatar}}" alt="">-->
        <!--        <sm-image-->
        <!--          [class]="'avatar'"-->
        <!--          default="{{'user' | image}}"-->
        <!--          image="{{account.avatar}}">-->
        <!--        </sm-image>-->
        <img (error)="setDefault()" alt="" class="avatar" src="{{account.avatar}}"
             style="border: white solid 2px; z-index: 99; height: 48px; width: 48px">
        <label class="title vertical-center" style="text-transform: none;">{{ title }}</label>
      </a>
    </div>
  </div>

  <div class="bg-white skillsmates-box-shadow">
    <div class="row no-margin-padding" style="">
      <div (click)="onClickTab(networkType)" *ngFor="let networkType of networkTypes"
           class="col center no-margin-padding d-flex justify-content-center">
          <span [ngStyle]="{'background-color': currentNetworkType === networkType.type ? 'lightgray' : '#FFFFFF'}"
                class="text-center"
                style="margin: 5px; border-radius: 10px; width: 100%; border: 1px solid lightgray;">
            <a class="btn" style="">
              <div class="row">
                <div class="col-12 no-margin-padding">
                  <span class="user-img">
                    <img alt="" height="32" src="{{networkType.picture}}">
                  </span>
                </div>
                <div class="col-12 no-margin-padding">
                  <h5 [ngClass]="{'selected-network-type': currentNetworkType === networkType.type}"
                      class="doctor-name">
                    {{ networkType.title }} <strong>({{ networkType.value }})</strong>
                  </h5>
                </div>
              </div>
            </a>
          </span>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <sm-processing [loading]="loading"></sm-processing>
        <nav class="mt-10 pr-0 d-flex justify-content-start m-r-2">
          <select (change)="updatePagination()" [(ngModel)]="perPage" id="perPageP1" name="perPageP1">
            <option value="6">6</option>
            <option value="12">12</option>
            <option value="18">18</option>
            <option value="24">24</option>
          </select> /page
        </nav>
      </div>

      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right">
        <nav class="mt-2 pr-0 d-flex justify-content-end m-r-10">
          <ngb-pagination
            (pageChange)="loadPage($event)"
            [(page)]="page" [boundaryLinks]="true" [collectionSize]="collectionSize" [disabled]="false"
            [ellipses]="false" [maxSize]="maxSize" [pageSize]="perPage" [rotate]="true" size="sm">
            <ng-template ngbPaginationFirst><i class="fa fa-angle-double-left"></i></ng-template>
            <ng-template ngbPaginationPrevious><i class="fa fa-angle-left"></i></ng-template>
            <ng-template ngbPaginationNext><i class="fa fa-angle-right"></i></ng-template>
            <ng-template ngbPaginationLast><i class="fa fa-angle-double-right"></i></ng-template>
          </ngb-pagination>
        </nav>
      </div>
    </div>

    <div class="row" style="margin-top: 10px;">
      <div *ngFor="let account of accounts"
           class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 sm-profile-attributes-1">
        <sm-profile-card (click)="onClickProfile(account)" [account]="account"></sm-profile-card>
      </div>
      <div *ngFor="let account of accounts" class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 sm-profile-attributes-2"
           style="margin-bottom: 5px;">
        <sm-profile-mobile-card [account]="account"></sm-profile-mobile-card>
        <sm-horizontal-line [color]="'#DADADA'" [height]="2"></sm-horizontal-line>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <nav class="mt-10 pr-0 d-flex justify-content-start m-r-2">
          <select (change)="updatePagination()" [(ngModel)]="perPage" id="perPageP1" name="perPageP1">
            <option value="6">6</option>
            <option value="12">12</option>
            <option value="18">18</option>
            <option value="24">24</option>
          </select> /page
        </nav>
      </div>
      <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 text-right">
        <nav class="mt-2 pr-0 d-flex justify-content-end m-r-10">
          <ngb-pagination
            (pageChange)="loadPage($event)"
            [(page)]="page" [boundaryLinks]="true" [collectionSize]="collectionSize" [disabled]="false"
            [ellipses]="false" [maxSize]="maxSize" [pageSize]="perPage" [rotate]="true" size="sm">
            <ng-template ngbPaginationFirst><i class="fa fa-angle-double-left"></i></ng-template>
            <ng-template ngbPaginationPrevious><i class="fa fa-angle-left"></i></ng-template>
            <ng-template ngbPaginationNext><i class="fa fa-angle-right"></i></ng-template>
            <ng-template ngbPaginationLast><i class="fa fa-angle-double-right"></i></ng-template>
          </ngb-pagination>
        </nav>
      </div>
    </div>
  </div>
</div>
