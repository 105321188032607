import {Injectable} from '@angular/core';
import {PostDetailsResource} from '../api/posts/model/postDetailsResource';
import {Subject} from 'rxjs';
import {InteractionService} from '../api/posts/api/interaction.service';
import {InteractionResponse} from '../api/posts/model/interactionResponse';
import {InteractionResource} from '../api/posts/model/interactionResource';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {
  interactions: InteractionResource[] = [];
  interactionResponse: InteractionResponse = {} as InteractionResponse;
  // Observable string sources
  private missionAnnouncedSource = new Subject<PostDetailsResource[]>();
  // Observable string streams
  missionAnnounced$ = this.missionAnnouncedSource.asObservable();
  private missionConfirmedSource = new Subject<string>();
  missionConfirmed$ = this.missionConfirmedSource.asObservable();

  constructor(private interactionService: InteractionService) {
  }

  // Service message commands
  announceMission(mission: string) {
    this.fetchInteractions(mission);
  }

  confirmMission(astronaut: string) {
    this.missionConfirmedSource.next(astronaut);
  }

  fetchInteractions(postId: string): void {
    this.interactionService.findInteractionsByTypeAndEntity('INTERACTION_TYPE_COMMENT', postId, 0, 10).subscribe({
      next: response => {
        this.interactionResponse = response;
        this.interactions = this.interactionResponse.resources!;
        this.missionAnnouncedSource.next(this.interactions!);
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }
}
