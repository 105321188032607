/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ParameterResponse } from './parameterResponse';


export interface DiplomaResource { 
    uuid?: string;
    deleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    title?: string;
    description?: string;
    account?: string;
    education?: DiplomaResource.EducationEnum;
    level?: string;
    schoolType?: ParameterResponse;
    activityArea?: string;
    schoolName?: string;
    city?: string;
    startDate?: string;
    endDate?: string;
}
export namespace DiplomaResource {
    export type EducationEnum = 'SECONDARY' | 'HIGHER';
    export const EducationEnum = {
        Secondary: 'SECONDARY' as EducationEnum,
        Higher: 'HIGHER' as EducationEnum
    };
}


