<sm-bottom-sheet [options]="options" #bottomSheet>
  <div class="row">
    <div class="col-12">
      <sm-input [type]="'text'" placeholder="{{'search.research' | translate}}"
                (smInputEvent)="getSearchText($event)"></sm-input>
    </div>
    <div *ngFor="let item of selectedDocumentTypesList">
      <label class="collapsible-item">
        <input type="checkbox" [checked]="checkedItems.includes(item)" (click)="onClickItem(item)"/> {{item}}
      </label>
    </div>
    <div class="col-12">
      <sm-button [type]="'info'" text="{{'document.apply_filters' | translate}}"
                 (smButtonEvent)="onApplyFilters()"></sm-button>
    </div>
  </div>
</sm-bottom-sheet>
