<div class="form-group" *ngIf="options && options.length > 0">
  <small class="text-info">{{title ? title : placeholder}}</small>
  <strong class="text-danger" style="font-size: larger;" *ngIf="mandatory">*</strong>
  <input style="border-radius: 6px" autocomplete="off" autofocus="" class="form-control" name="{{name}}" list="options"
         placeholder={{placeholder}} required
         value="{{value}}"
         (change)="handleChange($event)"
         [ngClass]="{'is-valid': inputStatus=='valid','is-invalid': inputStatus=='invalid'}"/>

  <datalist id="options">
    <option *ngFor="let opt of options" [value]="opt">
  </datalist>

  <small class="text-danger" *ngIf="showErrorMessage">{{errorMessage}}</small>
</div>
