<div class="">
  <div class="row">
    <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
      <img height="32" src="{{image}}" alt="image" class="">
    </div>
    <div class="col">
      <strong *ngIf="title" class="skillsmates-single-line">{{title}}</strong>
      <p *ngIf="subtitle" class="skillsmates-single-line">{{subtitle}}</p>
    </div>
  </div>
</div>
