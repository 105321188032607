import {Mimetype} from "../models/mimetype";


export class MimetypeHelper {

  static mimetypes: Mimetype[] = [
    {extension: 'audio', type: 'audio/'},
    {extension: 'image', type: 'image/'},
    {extension: 'video', type: 'video/'},
    {extension: 'pdf', type: 'application/pdf'},
    {extension: 'txt', type: 'text/'},
    {extension: 'doc', type: 'application/msword'},
    {extension: 'xls', type: 'application/vnd.ms-excel'},
    {extension: 'ppt', type: 'application/vnd.ms-powerpoint'},
    {extension: 'mdb', type: 'application/vnd.ms-access'},
    {extension: 'docx', type: 'application/vnd.openxmlformats-officedocument'},
  ];

  static isMimetypeAllowed(mimetype: string): boolean {
    let allowed = false;
    MimetypeHelper.mimetypes.forEach(elt => {
      if (mimetype.startsWith(elt.type!)) {
        allowed = true;
      }
    });
    return allowed;
  }

  static isAudioFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('audio/'));
  }

  static isImageFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('image/'));
  }

  static isVideoFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('video/'));
  }

  static isWordFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('application/msword') || mimetype.startsWith('application/vnd.openxmlformats-officedocument.wordprocessingml'));
  }

  static isPowerPointFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('application/vnd.ms-powerpoint') || mimetype.startsWith('application/vnd.openxmlformats-officedocument.presentationml'));
  }

  static isExcelFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('application/vnd.ms-excel') || mimetype.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml'));
  }

  static isAccessFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('application/vnd.ms-access'));
  }

  static isPdfFile(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('application/pdf'));
  }


  static isAudioLink(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('audio/'));
  }

  static isImageLink(url: string): boolean {
    return url != undefined && (url.match(/\.(jpeg|jpg|gif|png)$/) != null);
  }

  static isVideoLink(url: string): boolean {
    return url != undefined && (url.match(/\.(mp4|avi|mov|wmv|flv|webm|mkv|m4v)$/) != null);
  }

  static isWordLink(url: string): boolean {
    const fileExtension = url.split('.').pop()?.toLowerCase();
    return fileExtension === 'docx' || fileExtension === 'doc';
  }

  static isPowerPointLink(url: string): boolean {
    const fileExtension = url.split('.').pop()?.toLowerCase();
    return fileExtension === 'pptx' || fileExtension === 'ppt';
  }

  static isExcelLink(url: string): boolean {
    const fileExtension = url.split('.').pop()?.toLowerCase();
    return fileExtension === 'xlsx' || fileExtension === 'xls';
  }

  static isAccessLink(mimetype: string): boolean {
    return mimetype != undefined && (mimetype.startsWith('application/vnd.ms-access'));
  }

  static isPdfLink(url: string): boolean {
    return url != undefined && (url.match(/\.(pdf)$/) != null);
  }

  static isOfficeDocumentUrl(url: string): boolean {
    const fileExtension = url.split('.').pop()?.toLowerCase();
    if (
      fileExtension === 'docx' ||   // Word document (2007 and later)
      fileExtension === 'doc' ||    // Word document (97-2003)
      fileExtension === 'xlsx' ||   // Excel workbook (2007 and later)
      fileExtension === 'xls' ||    // Excel workbook (97-2003)
      fileExtension === 'pptx' ||   // PowerPoint presentation (2007 and later)
      fileExtension === 'ppt'       // PowerPoint presentation (97-2003)
    ) {
      return true;
    }

    return false;
  }
}
