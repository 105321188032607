<div class="skillsmates-box-shadow">
  <div class="row bg-white" style="padding: 10px; margin: 0;" (click)="onClickAddStatus()">
    <div class="col-9" style="background-color: whitesmoke; border-radius: 20px; cursor: pointer;">
      <p style="padding-top: 10px;">{{loggedAccount.firstname}}, {{ 'edit_post.write_to_network' | translate }}</p>
    </div>
    <div class="col-3 text-center ask-community">
      <p><strong>{{ 'edit_post.ask_community' | translate }}</strong></p>
      <img class="" src="{{'forum' | image}}" width="36" alt="forum">
    </div>
  </div>
  <sm-horizontal-line [height]="2" [color]="'#DADADA'"></sm-horizontal-line>
  <div class="row bg-white" style="margin: auto; padding-top: 5px;">
    <div class="col-12">
      <img height="18" src="./assets/images/plus-colored.png" alt="Créer une publication"> &nbsp;
      <strong>{{ 'edit_post.select_post_type' | translate }}</strong>
    </div>
  </div>
  <div class="row bg-white media-icon" *ngIf="mediaTypes && mediaTypes.length > 0">
    <div *ngFor="let mediaType of mediaTypes" class="col no-margin-padding">
      <sm-media-type
        [mediaType]="mediaType">
      </sm-media-type>
    </div>
  </div>
</div>

<ng-template #add_status let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"><b>STATUS</b></h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
  </div>
  <div class="modal-body">

    <sm-preview-post-media [post]="postRequest" [image]="image"></sm-preview-post-media>

    <sm-textarea
      title="{{'edit_post.write_to_network' | translate}}"
      placeholder="{{'edit_post.write_to_network' | translate}}"
      [value]="''"
      (smInputEvent)="getStatusDescription($event)">
      [rows]="8">
    </sm-textarea>

    <div class="row">
      <div class="col-9 col-sm-9 col-md-6 col-lg-4 col-xl-4">
        <input [hidden]="true" #file type="file" accept="image/*" (change)="onFileSelected($event)">
        <sm-button
          #upload (click)="file.click()"
          text="{{'edit_post.choose_file' | translate}}"
          image="{{'photo' | image}}">
        </sm-button>
      </div>
    </div>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success"
            (click)=onClickPublish()>{{ 'edit_post.publish' | translate }}
    </button>
  </div>
</ng-template>
