<div class="row skillsmates-container">
  <!--   partie gauche du dashboard -->
  <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3  sm-side-menu">
    <sm-account-profile
      [account]="loggedAccount">
    </sm-account-profile>
    <sm-accounts-online></sm-accounts-online>
    <div class="skillsmates-stick-top">
      <sm-about></sm-about>
    </div>
  </div>

  <!-- partie centrale du dashboard -->
  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
    <sm-add-post></sm-add-post>
    <sm-suggestions></sm-suggestions>
    <sm-posts [account]="loggedAccount.uuid!" [isDashboard]="true"></sm-posts>
  </div>

  <!-- partie droite du dashboard -->
  <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3  sm-side-menu">
    <sm-forum></sm-forum>
    <sm-selections></sm-selections>
    <div class="skillsmates-stick-top">
      <sm-ambassadors></sm-ambassadors>
      <sm-partners></sm-partners>
    </div>

    <div (click)="scrollToTop()" class="scroll-to-top">
      <button class="btn"><em class="fa fa-arrow-up"></em></button>
    </div>
  </div>
</div>
