<sm-cursus *ngIf="jobResponse && !editMode"
           [elements]="jobResponse"
           (smAddEvent)="onClickAdd($event)"
           (smUpdateEvent)="onClickUpdate($event)"
           (smDoneEvent)="onClickDone(true)"
           addText="{{'edit_profile.add' | translate}} {{'edit_profile.job' | translate}}"
           title="{{'edit_profile.jobs' | translate}}">
</sm-cursus>

<sm-edit-job
  *ngIf="editMode"
  [jobResource]="jobResponse.resource!"
  (smDoneEvent)="onClickDone($event)">
</sm-edit-job>
