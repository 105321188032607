<div class="form-group">
  <label class="text-info" for="{{name}}">{{ title ? title : placeholder }}</label>
  <span *ngIf="mandatory" class="text-danger">*</span>
  <textarea (change)="handleChange($event)" (ngModelChange)="onInputChange($event)" [(ngModel)]="value"
            [maxLength]="maxInputLength"
            [ngClass]="{'is-valid': inputStatus=='valid','is-invalid': inputStatus=='invalid'}" autocomplete="off"
            class="form-control" name="{{name}}"
            placeholder={{placeholder}}
            required
            rows="{{rows}}"
            style="border-radius: 6px">
  </textarea>
  <label *ngIf="hint!=''" class="text-danger" [innerText]="hint" style="color: red"></label>
  <small *ngIf="fieldSize()>0 && showCounter" class="text-info float-right">
    {{ fieldSize() }} / {{ maxInputLength }}
  </small>
  <small *ngIf="showErrorMessage" class="text-danger">{{ errorMessage }}</small>
</div>
