import {Component, OnInit} from '@angular/core';
import {PostService, ProfileDocumentsResource} from "ApiModulePost";
import {LocalStorageService} from "../../services/local-storage.service";
import {ActivatedRoute, Router} from "@angular/router";
import {AccountDetailsResource, AccountService, SearchParam} from "ApiModuleAccount";
import {MediaType} from "../../models/mediaType";
import {variables} from "../../../environments/variables";
import {Route} from "../../enums/route.enum";
import {MediaHelper} from "../../helpers/media-helper";

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.scss']
})
export class AccountDetailsComponent implements OnInit {

  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  loading: boolean = false;
  id: string | null = "";
  pageYoffset = 0;
  posts: string = variables.posts;
  documents: string = variables.documents;
  about: string = variables.about;
  tab: string = this.posts;
  mediaTypes = MediaHelper.mediaTypes;
  currentTab: string = "publication";
  tabs: string[] = ["Mes publications", "Mon classeur", "Mon cursus"];

  searchParam: SearchParam = {} as SearchParam;
  profileDocuments!: ProfileDocumentsResource;
  isOffline: boolean = true;

  constructor(private localStorageService: LocalStorageService,
              private accountService: AccountService,
              private postService: PostService,
              private activateRoute: ActivatedRoute,
              private router: Router) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
  }

  ngOnInit(): void {
    this.isOffline = this.localStorageService.isOffline()
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.currentTab = this.tabs[0];
    this.activateRoute.queryParams.subscribe({
      next: value => {
        this.id = value['id'];
        if (this.id) {
          this.fetchAccount(this.id);
        }
      }
    });
  }

  fetchAccount(id: string): void {
    this.accountService.findAccount(id).subscribe({
      next: response => {
        this.account = response.resource!;
        this.fetchPosts();
      },
      error: error => {
      },
      complete: () => {
      }
    })
  }

  onClickTab(tab: string): void {
    this.currentTab = tab;
  }

  getIcon(tab: string): string {
    if (tab == this.tabs[0]) {
      return "publication";
    } else if (tab == this.tabs[1]) {
      return "shelf";
    } else if (tab == this.tabs[2]) {
      return "user";
    } else {
      return "publication";
    }
  }

  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  fetchPosts(): void {
    this.searchParam.account = this.account.uuid;
    this.postService.researchDocuments(0, 3, this.searchParam).subscribe({
      next: response => {
        this.profileDocuments = response.resource!;
      },
      error: error => {

      },
      complete: () => {
      }
    });
  }

  onClickDocuments(mediaType: MediaType) {
    if (this.isLogged()) {
      this.localStorageService.add(variables.media_type, mediaType);
      this.localStorageService.update(variables.profile_account, this.account);
      this.router.navigate([Route.DOCUMENTS]);
    }
  }

  onClickProfile(account: AccountDetailsResource): void {
    if (this.isLogged()) {
      this.localStorageService.update(variables.profile_account, account);
      this.router.navigate([Route.EDIT_PROFILE]);
    }
  }

  isLogged(): boolean {
    return this.loggedAccount != undefined
  }

  handleButton1() {
    this.router.navigate([Route.LOGIN]);
  }

}
