<div class="contain">
  <div class="row">
    <div class="col-5 entete">
      <img alt="logo skillsmates" src="assets/images/logo-skillsmates.svg"/>
    </div>
    <div class="col-7">
      <img class="page" src="assets/images/landing-page-people.svg">
    </div>
  </div>
  <div class="row mon-texte">
    <p class="compte"><img src="assets/images/images.jpeg"> compte activé</p>
    <p class="created">Votre compte SkillsMates a bien été créé.</p>
    <p class="bouton">
      <button [routerLink]="['/login']" class="btn-primary">Cliquez ici</button>
      pour accéder à la page de connexion de la plateforme
    </p>
    <p class="message"> Si le bouton ci-dessus ne fonctionne pas, cliquez directement sur ce lien ou copiez-le
    <p class="message"> Si le bouton ci-dessus ne fonctionne pas, cliquez directement sur ce lien ou copiez-le
      et collez-le dans votre navigteur : <a [routerLink]="['/login']">https://skills-mates.com/</a>
    </p>
  </div>
  <div class="row bottom">
    <div class="col-6">
      <p>Apropos - Aide - Politique de confidentilité <br> conditions d'utilisation</p>
    </div>
    <div class="col-6">
      <img alt="logo skillsmates" src="assets/images/symbole_skillsmates.svg"/><span>&copy; Skills Mates 2023</span>
    </div>
  </div>
</div>
