import {Component} from '@angular/core';

@Component({
  selector: 'sm-settings-confidentiality',
  templateUrl: './settings-confidentiality.component.html',
  styleUrls: ['./settings-confidentiality.component.scss']
})
export class SettingsConfidentialityComponent {

}
