import {Pipe, PipeTransform} from '@angular/core';
import {byIso, SearchOutput} from 'country-code-lookup';

@Pipe({
  name: 'country'
})
export class CountryPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    let country: SearchOutput = byIso(value);
    return country ? country.country : "";
  }

}
