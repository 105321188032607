import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sm-image-text',
  templateUrl: './image-text.component.html',
  styleUrls: ['./image-text.component.scss']
})
export class ImageTextComponent implements OnInit {
  @Input() image!: string;
  @Input() text!: string;
  @Input() bgColor: string = "#F9F9F9";
  @Input() color: string = "#16152D";

  ngOnInit() {
  }
}
