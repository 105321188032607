import {MediaType} from "../models/mediaType";
import {MimetypeHelper} from "./mimetype-helper";
import {variables} from "../../environments/variables";

export class MediaHelper {

  static mediaTypes: MediaType[] = [
    {
      uuid: 'application/pdf',
      deleted: false,
      createdAt: new Date(),
      updatedAt: new Date(),
      code: 'MEDIA_TYPE_DOCUMENT',
      label: 'Documents',
      color: '#476db4',
      picture: 'document-bleu',
      image: 'document',
      description: 'Partagez des cours, exercices, mémoires, astuces, etc.',
      subtitle: 'Documents partagés'
    },
    {
      uuid: '',
      deleted: false,
      createdAt: new Date(),
      updatedAt: new Date(),
      code: 'MEDIA_TYPE_VIDEO',
      label: 'Vidéos',
      color: '#e14033',
      picture: 'video-rouge',
      image: 'photogram',
      description: 'Partagez des tutoriels, des conférences ou des actualités.',
      subtitle: 'Vidéos partagées',
    },
    {
      uuid: '',
      deleted: false,
      createdAt: new Date(),
      updatedAt: new Date(),
      code: 'MEDIA_TYPE_LINK',
      label: 'Liens',
      color: '#f2c600',
      picture: 'lien-jaune',
      image: 'lien',
      description: 'Partagez un article, une revue ou un site web.',
      subtitle: 'Liens partagés',
    },
    {
      uuid: '',
      deleted: false,
      createdAt: new Date(),
      updatedAt: new Date(),
      code: 'MEDIA_TYPE_IMAGE',
      label: 'Images',
      color: '#20b197',
      picture: 'photo-vert',
      image: 'photo',
      description: 'Partagez des scans ou vos photos personnelles.',
      subtitle: 'Images partagées',
    },
    {
      uuid: '',
      deleted: false,
      createdAt: new Date(),
      updatedAt: new Date(),
      code: 'MEDIA_TYPE_AUDIO',
      label: 'Audios',
      color: '#dc5f9f',
      picture: 'audio-rose',
      image: 'audio',
      description: 'Partagez des conférences ou des actualités.',
      subtitle: 'Audios partagés',
    }
  ];

  static profile: MediaType = {
    uuid: '',
    deleted: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    code: 'MEDIA_TYPE_PROFILE',
    color: '#AAAAAA',
    picture: 'user',
    image: 'user',
    label: 'Profils',
    description: '',
    subtitle: '',
  };

  static all: MediaType = {
    uuid: '',
    deleted: false,
    createdAt: new Date(),
    updatedAt: new Date(),
    code: 'all',
    color: '#16162D',
    picture: './assets/images/select-all.svg',
    image: './assets/images/select-all.svg',
    label: 'Tous',
    description: '',
    subtitle: '',
  };

  static getInfoMediaTypeByType(type: string): any {
    let selectedMediaType;
    MediaHelper.mediaTypes.forEach(elt => {
      if (elt.code!.toLowerCase() === type.toLowerCase()) {
        selectedMediaType = elt;
      }
    });
    return selectedMediaType ? selectedMediaType : null;
  }

  static getMediaType(type: string): string {
    if (MimetypeHelper.isImageFile(type)) {
      return 'image';
    } else if (MimetypeHelper.isPdfFile(type)) {
      return 'pdf';
    } else if (MimetypeHelper.isAudioFile(type)) {
      return 'audio';
    } else if (MimetypeHelper.isVideoFile(type)) {
      return 'video';
    } else if (MimetypeHelper.isWordFile(type)) {
      return 'word';
    } else if (MimetypeHelper.isExcelFile(type)) {
      return 'excel';
    } else if (MimetypeHelper.isPowerPointFile(type)) {
      return 'powerpoint';
    } else if (MimetypeHelper.isAccessFile(type)) {
      return 'access';
    } else {
      return variables.document;
    }
  }

  static getYoutubeVideoId(url: string): string | null {
    if (!url) return null;
    const p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    const matches = url.match(p);
    if (matches) {
      return matches[1];
    }
    return null;
  }

  static isYoutubeLink(url: string): boolean {
    return !!MediaHelper.getYoutubeVideoId(url);
  }

  static isOfficeDocumentLink(url: string): boolean {
    return url ? MimetypeHelper.isOfficeDocumentUrl(url) : false;
  }
}
