import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {LocalStorageService} from "../../services/local-storage.service";
import {variables} from "../../../environments/variables";

@Component({
  selector: 'sm-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.scss']
})
export class CguComponent implements OnInit {
  constructor(private router: Router, private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
  }

  onClickReturn() {
    this.router.navigate([this.localStorageService.get(variables.url)])
  }
}
