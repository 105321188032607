import {Component, OnInit, ViewChild} from '@angular/core';
import {MediaType} from "../../models/mediaType";
import {MediaHelper} from "../../helpers/media-helper";
import {
  DocumentTypeResource,
  ParameterService,
  PostDetailsResource,
  PostService,
  ProfileDocumentsResource,
  SearchParam
} from "ApiModulePost";
import {AccountDetailsResource, AccountService} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {BottomSheetComponent} from "../../modules/bottom-sheet/bottom-sheet/bottom-sheet.component";
import {BottomSheetConfig} from "../../modules/bottom-sheet/utility/bottom-sheet.interface";

@Component({
  selector: 'sm-account-shelf',
  templateUrl: './account-shelf.component.html',
  styleUrls: ['./account-shelf.component.scss']
})
export class AccountShelfComponent implements OnInit {

  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  tabs: MediaType[] = MediaHelper.mediaTypes;
  currentTab: MediaType = MediaHelper.mediaTypes[0];
  documents: PostDetailsResource[] = [];
  total: number = 0;
  profileDocuments!: ProfileDocumentsResource;
  searchParam: SearchParam = {} as SearchParam;

  checkedItems: string[] = []
  documentTypes: DocumentTypeResource[] = []
  selectedDocumentTypesList: string[] = []
  selectedDocumentTypes: DocumentTypeResource[] = []

  @ViewChild('bottomSheet') bottomSheet!: BottomSheetComponent;
  options!: BottomSheetConfig;

  constructor(private accountService: AccountService,
              private localStorageService: LocalStorageService,
              private postParameterService: ParameterService,
              private postService: PostService) {
  }

  openBottomSheet() {
    this.bottomSheet.open();
  }

  ngOnInit(): void {

    this.options = {
      title: "Tous les filtres",
      backgroundColor: "#ffffff",
      fontColor: "#363636"
    }

    this.account = this.localStorageService.getProfileAccount();
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.searchParam.account = this.account.uuid;
    this.searchParam.documentTypes = [];
    this.currentTab = MediaHelper.mediaTypes[0];
    this.researchProfileDocuments();
    this.findDocumentTypes()
  }

  onClickTab(tab: MediaType): void {
    this.currentTab = tab;
    this.searchParam.documentTypes = [];
    this.researchProfileDocuments();
    this.loadDocumentTypesDropdown(this.currentTab)
  }

  findDocumentTypes(): void {
    this.postParameterService.getDocumentTypes().subscribe({
      next: response => {
        this.documentTypes = response.resources!;
        this.loadDocumentTypesDropdown(this.currentTab)
      },
      error: error => {

      },
      complete: () => {
      }
    });
  }

  loadDocumentTypesDropdown(mediaType: MediaType): string[] {
    this.selectedDocumentTypesList = [];
    this.selectedDocumentTypes = [];
    this.documentTypes.forEach(value => {
      if (value.mediaType && value.mediaType.code == mediaType.code) {
        this.selectedDocumentTypes.push(value);
        this.selectedDocumentTypesList.push(value.label!);
      }
    });
    return this.selectedDocumentTypesList;
  }

  onClickItem(event: string): void {
    if (event) {
      this.documentTypes.forEach(value => {
        if (value.mediaType?.code === this.currentTab.code && value.label === event) {
          if (this.checkedItems.includes(event)) {
            const index = this.checkedItems.indexOf(event, 0);
            const indexSearch = this.searchParam.documentTypes!.indexOf(value.uuid!, 0);
            if (index > -1) {
              this.checkedItems.splice(index, 1);
            }
            if (indexSearch > -1) {
              this.searchParam.documentTypes!.splice(indexSearch, 1);
            }
          } else {
            this.checkedItems.push(event);
            this.searchParam.documentTypes?.push(value.uuid!);
          }
        }
      });
    }
  }

  filterProfileDocuments(): void {
    if (this.currentTab.code == MediaHelper.mediaTypes[0].code) {
      this.documents = this.profileDocuments.documents!;
      this.total = this.profileDocuments.totalDocuments!;
    } else if (this.currentTab.code == MediaHelper.mediaTypes[1].code) {
      this.documents = this.profileDocuments.videos!;
      this.total = this.profileDocuments.totalVideos!;
    } else if (this.currentTab.code == MediaHelper.mediaTypes[2].code) {
      this.documents = this.profileDocuments.links!;
      this.total = this.profileDocuments.totalLinks!;
    } else if (this.currentTab.code == MediaHelper.mediaTypes[3].code) {
      this.documents = this.profileDocuments.images!;
      this.total = this.profileDocuments.totalImages!;
    } else if (this.currentTab.code == MediaHelper.mediaTypes[4].code) {
      this.documents = this.profileDocuments.audios!;
      this.total = this.profileDocuments.totalAudios!;
    } else {
      this.documents = this.profileDocuments.documents!;
      this.total = this.profileDocuments.totalDocuments!;
    }
  }

  researchProfileDocuments(): void {
    this.postService.researchDocuments(0, 100, this.searchParam).subscribe({
      next: response => {
        this.profileDocuments = response.resource!;
        this.filterProfileDocuments();
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  getSearchText(event: string): void {
    this.searchParam.content = event;
  }

  onApplyFilters(): void {
    this.researchProfileDocuments();
    this.bottomSheet.close();
    this.searchParam.content = undefined;
  }
}
