import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sm-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {

  @Input() title!: string;
  @Input() selected: string = "";
  @Input() errorMessage: string = 'error';
  @Input() showErrorMessage: boolean = false;
  @Input() inputStatus: string = '';
  @Input() list: string[] = [];
  @Input() mandatory: boolean = false;
  @Output() smInputEvent = new EventEmitter<string>();
  
  ngOnInit(): void {
    this.selected = this.selected && this.selected != "" ? this.selected : this.list[0];
  }

  onChange(value: any) {
    this.smInputEvent.emit(value.target.value);
  }
}
