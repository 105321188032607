import {Component, OnInit} from '@angular/core';
import {MediaType} from "../../models/mediaType";
import {MediaHelper} from "../../helpers/media-helper";
import {AccountDetailsResource} from "ApiModuleAccount";
import {
  DocumentTypeResource,
  ParameterService,
  PostDetailsResource,
  PostService,
  ProfileDocumentsResource,
  SearchParam
} from "ApiModulePost";
import {LocalStorageService} from "../../services/local-storage.service";
import {Route} from "../../enums/route.enum";
import {variables} from "../../../environments/variables";
import {StringHelper} from "../../helpers/string-helper";
import {Router} from "@angular/router";

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {

  mediaTypes: MediaType[] = MediaHelper.mediaTypes;
  profileDocuments!: ProfileDocumentsResource;
  accounts: AccountDetailsResource[] = [];
  posts: PostDetailsResource[] = [];
  searchParam: SearchParam = {} as SearchParam;
  Route = Route;
  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  documentTypes: DocumentTypeResource[] = [];
  documentTypesSelected: DocumentTypeResource[] = [];
  documents: PostDetailsResource[] = [];
  selectedMediaType!: MediaType;
  total: number = 0;
  loading: boolean = false;

  page = 1;
  maxSize = 5;
  collectionSize = 1
  perPage = 6;
  calculateTotalPages = this.collectionSize / this.perPage;

  constructor(
    private localStorageService: LocalStorageService,
    private postParameterService: ParameterService,
    private router: Router,
    private postService: PostService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
    this.selectedMediaType = this.localStorageService.get(variables.media_type);
    this.searchParam.account = this.account.uuid;
    this.findDocumentTypes();
  }

  onClickItem(event: string, mediaType: MediaType): void {
    this.setActiveMediaType(mediaType)
    const current: DocumentTypeResource | undefined = this.getOptionFromLabel(event)
    this.cleanSelectedOptions()
    if (current) {
      this.updateSelectedOptions(current)
    }
    this.searchParam.documentTypes = this.getSelectedOptionsUuid()
    this.researchProfileDocuments();
  }

  getOptionFromLabel(label: string): DocumentTypeResource | undefined {
    return this.documentTypes.find(value =>
      (value.label?.trim() === label.trim()) &&
      (value.mediaType?.code === this.selectedMediaType.code));
  }

  cleanSelectedOptions() {
    this.documentTypesSelected = this.documentTypesSelected
      .filter(value => value.mediaType?.code === this.selectedMediaType.code)
  }

  updateSelectedOptions(element: DocumentTypeResource): void {
    const index = this.documentTypesSelected.indexOf(element);
    index !== -1 ?
      this.documentTypesSelected.splice(index, 1) : this.documentTypesSelected.push(element)
  }

  getSelectedOptionsUuid() {
    return this.documentTypesSelected.map(value => value.uuid!)
  }

  getSelectedOptionsLabel() {
    return this.documentTypesSelected.map(value => value.label!)
  }

  getOptionsOfType(mediaType: MediaType) {
    return this.documentTypes
      .filter(value => value.mediaType?.code === mediaType.code)
  }

  getOptionsLabel(mediaType: MediaType) {
    return this.getOptionsOfType(mediaType).map(value => value.label!)
  }


  getSearchText(event: string) {
    this.searchParam.content = event;
  }

  findDocumentTypes(): void {
    this.postParameterService.getDocumentTypes().subscribe({
      next: response => {
        this.documentTypes = response.resources!;
        this.documentTypesSelected = this.getOptionsOfType(this.selectedMediaType)
        this.searchParam.documentTypes = this.getSelectedOptionsUuid()
        this.researchProfileDocuments()
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  onClickCollapsibleTile(mediaType: MediaType): void {
    //this.localStorageService.add(variables.media_type, mediaType);
    //this.selectedMediaType = mediaType;
  }

  setActiveMediaType(mediaType: MediaType): void {
    this.localStorageService.add(variables.media_type, mediaType);
    this.selectedMediaType = mediaType;
  }

  researchProfileDocuments(): void {
    this.page = 1;
    this.postService.researchDocuments(0, 100, this.searchParam).subscribe({
      next: response => {
        this.profileDocuments = response.resource!;
        this.filterResearchProfileDocuments();
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  filterResearchProfileDocuments(): void {
    this.documents = [];
    this.total = 0;
    this.documents = this.documents.concat(this.profileDocuments.documents!);
    this.total += this.profileDocuments.totalDocuments!;
    this.documents = this.documents.concat(this.profileDocuments.videos!);
    this.total += this.profileDocuments.totalVideos!;
    this.documents = this.documents.concat(this.profileDocuments.links!);
    this.total += this.profileDocuments.totalLinks!;
    this.documents = this.documents.concat(this.profileDocuments.images!);
    this.total += this.profileDocuments.totalImages!;
    this.documents = this.documents.concat(this.profileDocuments.audios!);
    this.total += this.profileDocuments.totalAudios!;
    this.collectionSize = this.total;
    this.calculateTotalPages = this.collectionSize / this.perPage;
  }

  loadPage(event: number): void {
    this.page = event;
    this.researchProfileDocuments();
  }

  public name(account: AccountDetailsResource): string {
    return StringHelper.truncateName(account, 18);
  }

  onClickProfile(): void {
    this.localStorageService.update(variables.profile_account, this.account);
    this.router.navigate([Route.PROFILE]);
  }
}
