/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SkillResource { 
    uuid?: string;
    deleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    title?: string;
    description?: string;
    account?: string;
    category?: SkillResource.CategoryEnum;
    discipline?: string;
    keywords?: string;
    level?: SkillResource.LevelEnum;
}
export namespace SkillResource {
    export type CategoryEnum = 'CATEGORY_1' | 'CATEGORY_2';
    export const CategoryEnum = {
        _1: 'CATEGORY_1' as CategoryEnum,
        _2: 'CATEGORY_2' as CategoryEnum
    };
    export type LevelEnum = 'LEVEL_1' | 'LEVEL_2' | 'LEVEL_3' | 'LEVEL_4';
    export const LevelEnum = {
        _1: 'LEVEL_1' as LevelEnum,
        _2: 'LEVEL_2' as LevelEnum,
        _3: 'LEVEL_3' as LevelEnum,
        _4: 'LEVEL_4' as LevelEnum
    };
}


