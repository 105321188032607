<div class="skillsmates-container">
  <div class="row">
    <div class="col need-assistance">A PROPOS DE SKILLSMATES</div>
  </div>

  <div class="row" style="margin: 0 50px 20px 50px">
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 assistance-question-mark-div pointer">
      <div>
        <img src="{{ 'pdf' | image }}" class="settings-about-pdf-img" alt="présentation"
             (click)="onClickPresentation()"/>
      </div>
      <div class="pdf">Plaquette de présentation skillsmates QR CODE</div>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 assistance-contact-us-div pointer">
      <div>
        <img src="{{ 'pdf' | image }}" class="settings-about-pdf-img" alt="promotion" (click)="onClickPromotion()"/>
      </div>
      <div class="pdf">Support de promotion skillsmates</div>
    </div>
  </div>
</div>

<ng-template #presentation let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title-1"><b>Plaquette de présentation skillsmates QR CODE</b></h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
  </div>
  <div class="modal-body">
    <ngx-extended-pdf-viewer src="{{plaquetteFileUrl}}"></ngx-extended-pdf-viewer>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="d('Cross click')">Fermer</button>
  </div>
</ng-template>
<ng-template #promotion let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title-2"><b>Support de promotion skillsmates</b></h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
  </div>
  <div class="modal-body">
    <ngx-extended-pdf-viewer src="{{promotionFileUrl}}"></ngx-extended-pdf-viewer>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="d('Cross click')">Fermer</button>
  </div>
</ng-template>
