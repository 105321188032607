import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sm-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {
  @Input() image!: string;
  @Input() default!: string;
  @Input() alt!: string;
  @Input() class!: string;
  @Input() imageSize!: any;

  ngOnInit(): void {
    this.imageSize = this.imageSize == undefined ? "100%" : this.imageSize
  }

  setDefault() {
    this.image = this.default;
  }

}
