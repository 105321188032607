/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ParameterRequest } from './parameterRequest';


export interface TrainingRequest { 
    title?: string;
    description?: string;
    account: string;
    education: TrainingRequest.EducationEnum;
    schoolType?: ParameterRequest;
    schoolName: string;
    city?: string;
    schoolClass?: string;
    activityArea?: string;
    level?: string;
    teachingField?: ParameterRequest;
    teachingName?: ParameterRequest;
    startDate: string;
}
export namespace TrainingRequest {
    export type EducationEnum = 'SECONDARY' | 'HIGHER';
    export const EducationEnum = {
        Secondary: 'SECONDARY' as EducationEnum,
        Higher: 'HIGHER' as EducationEnum
    };
}


