<div class="container skillsmates-page-wrapper">
  <div class="row">
    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8">
      <a routerLink="/">
        <img alt="Skillsmates logo" class="img-fluid" src="{{ 'logo-skillsmates' | image }}"
             style="max-height: 120px;"/>
      </a>
      <p class="skillsmates-slogan" style="line-height: 1em">améliore et partage <br/>tes connaissances avec <br/>la
        communauté</p>
      <img alt="landing-page-people" class="img-fluid" src="{{ 'landing-page-people' | image }}"/>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4" style="padding: 20px;">
      <sm-alert [alert]="alert"></sm-alert>
      <div class="skillsmates-box-shadow"
           style="padding: 20px;">
        <div class="login-title text-center">{{ 'login.password_reset.confirm.title' | translate }}</div>
        <div class="form-group">
          <label>{{ 'login.password_reset.confirm.message' | translate }}</label><br>
        </div>
        <sm-input
          (valueChange)="getNewPassword($event)"
          [errorMessage]="errorMessagePassword"
          [inputStatus]="inputStatusPassword"
          [name]="'password'"
          [placeholder]="'Nouveau mot de passe'"
          [showErrorMessage]="showErrorMessagePassword"
          [showEye]="true"
          [type]="'password'"
          [value]="newPassword"/>
        <sm-input
          (valueChange)="getNewPasswordConfirm($event)"
          [errorMessage]="errorMessageConfirmPassword"
          [inputStatus]="inputStatusConfirmPassword"
          [name]="'passwordConfirm'"
          [placeholder]="'Confirmation du nouveau mot de passe'"
          [showErrorMessage]="showErrorMessageConfirmPassword"
          [showEye]="true"
          [type]="'password'"
          [value]="confirmPassword"/>
        <div style="font-size: x-small;">
          <span *ngFor="let validation of validations"
                [ngClass]="validation.cssClass()">{{ validation.message }}<br></span>
        </div>
        <br>
        <button (click)="doChangePassword()"
                *ngIf="!loading"
                class="btn btn-primary sm-border-radius register-button">
          {{ 'login.button_send' | translate }}
        </button>
        <sm-processing *ngIf="loading" [loading]="loading"></sm-processing>
      </div>
    </div>
  </div>
  <div class="row" style="">
    <div class="col-12 col-sm-12 col-md-12 col-lg-8 col-xl-8 text-center" style="margin: auto;">
      <span><a class="footer-text pointer" href="#">{{ 'login.about' | translate }}</a></span> -
      <span><a class="footer-text pointer" href="#">{{ 'login.help' | translate }}</a></span> -
      <span><a class="footer-text pointer" href="#">{{ 'login.confidentiality_policy' | translate }}</a></span> -
      <span><a (click)="onClickCGU()"
               class="footer-text pointer">{{ 'login.terms_and_conditions' | translate }}</a></span> -
      <span><a (click)="onClickFAQ()" class="footer-text pointer">{{ 'login.faq' | translate }}</a></span>
    </div>
    <div class="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 text-center" style="margin: auto; font-size: small;">
      © <strong><a [routerLink]="Route.ROOT" class="std" target="_blank">{{ 'sm.app_name' | translate }}</a></strong>.
      <strong><span
        style="color: #000;"> {{ year }} <br>Version : </span></strong> <strong
      style="color:#F00;">{{ version }}</strong>
    </div>
  </div>
</div>
