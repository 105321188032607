import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MediaType} from "../../models/mediaType";
import {MediaHelper} from "../../helpers/media-helper";
import {LocalStorageService} from "../../services/local-storage.service";
import {AccountDetailsResource, AccountService} from "ApiModuleAccount";
import {DocumentTypeResource, ParameterService, PostService} from "ApiModulePost";

type SearchParamTuple = [string[], string[]];

@Component({
  selector: 'sm-search-types',
  templateUrl: './search-types.component.html',
  styleUrls: ['./search-types.component.scss']
})
export class SearchTypesComponent implements OnInit {

  @Input() account: AccountDetailsResource = {} as AccountDetailsResource;
  @Output() smSearchParamEvent = new EventEmitter<SearchParamTuple>();
  documentTypes: DocumentTypeResource[] = [];
  selectedDocumentTypesList: string[] = [];
  selectedDocumentTypes: DocumentTypeResource[] = [];
  selectedStatusesList: string[] = ["Etudiant", "Enseignant", "Professionnel"];

  selectedMediaTypes: MediaType[] = [];
  searchType: string[] = [];
  currentSearchType!: string;
  loading: boolean = false;
  accounts: AccountDetailsResource[] = [];
  profileMediaType = MediaHelper.profile;
  mediaTypes = MediaHelper.mediaTypes;
  image: string | undefined;
  label: string | undefined;
  isAllSelected = true;
  isAllProfilesSelected = true;
  selectedMediaType: MediaType = this.profileMediaType;

  checkedItems: string[] = [];
  checkedProfileItems: string[] = [];
  checkedDocumentTypes: string[] = [];

  constructor(private accountService: AccountService,
              private localStorageService: LocalStorageService,
              private postParameterService: ParameterService,
              private postService: PostService) {
  }

  ngOnInit(): void {
    this.account = this.localStorageService.getLoggedAccount();
    this.findDocumentTypes();
  }

  findDocumentTypes(): void {
    this.postParameterService.getDocumentTypes().subscribe({
      next: response => {
        this.documentTypes = response.resources!;
      },
      error: error => {

      },
      complete: () => {
      }
    });
  }

  loadDocumentTypesDropdown(mediaType: MediaType): string[] {
    this.selectedDocumentTypesList = [];
    this.selectedDocumentTypes = [];
    this.documentTypes.forEach(value => {
      if (value.mediaType && value.mediaType.code == mediaType.code) {
        this.selectedDocumentTypes.push(value);
        this.selectedDocumentTypesList.push(value.label!);
      }
    });
    return this.selectedDocumentTypesList;
  }

  onClickCollapsibleTile(profileMediaType: MediaType): void {
    this.selectedMediaType = profileMediaType;
    this.loadDocumentTypesDropdown(profileMediaType);
  }

  onClickItem(event: string, isProfile: boolean): void {
    if (event) {
      if (isProfile) {
        this.onClickProfileItem(event)
      } else {
        this.onClickDocumentItem(event)
      }
      this.smSearchParamEvent.emit([this.checkedDocumentTypes, this.collectProfileValues()]);
    }
  }

  onClickProfileItem(event: string) {
    this.selectedStatusesList.forEach(value => {
      if (this.checkedProfileItems.includes(event)) {
        const index = this.checkedProfileItems.indexOf(event, 0)
        if (index > -1) {
          this.checkedProfileItems.splice(index, 1)
        }
      } else {
        this.checkedProfileItems.push(event)
      }
    });
  }

  onClickDocumentItem(event: string) {
    this.documentTypes.forEach(value => {
      if (value.mediaType?.code === this.selectedMediaType.code && value.label === event) {
        if (this.checkedItems.includes(event)) {
          const index = this.checkedItems.indexOf(event, 0);
          const indexSearch = this.checkedDocumentTypes!.indexOf(value.uuid!, 0);
          if (index > -1) {
            this.checkedItems.splice(index, 1);
          }
          if (indexSearch > -1) {
            this.checkedDocumentTypes!.splice(indexSearch, 1);
          }
        } else {
          this.checkedItems.push(event);
          this.checkedDocumentTypes?.push(value.uuid!);
        }
      }
    });
  }

  collectProfileValues() {
    return this.checkedProfileItems.map(this.mapProfileLabelToValue);
  }

  mapProfileLabelToValue(profileLabel: string): string {
    switch (profileLabel) {
      case "Etudiant":
        return 'STUDENT'
      case "Enseignant":
        return 'TEACHER'
      case "Professionnel":
        return 'PROFESSIONAL'
      default:
        return ''
    }


  }


}
