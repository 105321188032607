import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AccountDetailsResource,
  CertificationResponse,
  CertificationService,
  DiplomaResponse,
  DiplomaService,
  JobResponse,
  JobService,
  SkillResponse,
  SkillService,
  TrainingResponse,
  TrainingService
} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {DateHelper} from "../../helpers/date-helper";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'sm-profile-resume',
  templateUrl: './profile-resume.component.html',
  styleUrls: ['./profile-resume.component.scss']
})
export class ProfileResumeComponent implements OnInit {
  @Input() account!: AccountDetailsResource;
  @Output() smAddEvent = new EventEmitter<string>();
  @Output() smUpdateEvent = new EventEmitter<any>();
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  jobResponse: JobResponse = {} as JobResponse;
  trainingResponse: TrainingResponse = {} as TrainingResponse;
  diplomaResponse: DiplomaResponse = {} as DiplomaResponse;
  certificationResponse: CertificationResponse = {} as CertificationResponse;
  skillResponse: SkillResponse = {} as SkillResponse;
  closeResult: string = '';

  constructor(private localStorageService: LocalStorageService,
              private modalService: NgbModal,
              private jobService: JobService,
              private trainingService: TrainingService,
              private skillService: SkillService,
              private certificationService: CertificationService,
              private diplomaService: DiplomaService) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
  }

  ngOnInit() {
    this.fetchJobs();
    this.fetchTrainings();
    this.fetchDiplomas();
    this.fetchCertifications();
    this.fetchSkills();
  }

  onClickProfile(account: AccountDetailsResource): void {

  }

  fetchJobs() {
    this.jobService.findJobByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.jobResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchTrainings() {
    this.trainingService.findTrainingByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.trainingResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchDiplomas() {
    this.diplomaService.findDiplomaByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.diplomaResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchCertifications() {
    this.certificationService.findCertificationByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.certificationResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchSkills() {
    this.skillService.findSkillByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.skillResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  convertDate(date: string): string {
    return DateHelper.convertDate(date);
  }

  onClickAdd(event: string): void {
    this.smAddEvent.emit(event);
  }

  onClickUpdate(event: any) {
    this.smUpdateEvent.emit(event);
  }

  onClickDismissModal(): void {
    this.modalService.dismissAll();
  }

  open(content: any): void {
    if (this.loggedAccount.uuid === this.account.uuid) {
      this.modalService.open(content, {
        size: 'lg'
      }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  setDefault() {
    this.account.avatar = './assets/images/user.svg';
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
