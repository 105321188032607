import {Component, OnInit} from '@angular/core';
import {Route} from "../../enums/route.enum";
import {variables} from "../../../environments/variables";
import {Router} from "@angular/router";
import {LocalStorageService} from "../../services/local-storage.service";
import packageJson from "../../../../package.json";

@Component({
  selector: 'sm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public version: string = packageJson.version;
  year: number = new Date().getFullYear();
  Route = Route;

  constructor(private router: Router, private localStorageService: LocalStorageService) {
  }


  ngOnInit(): void {
  }

  onClickCGU(): void {
    this.localStorageService.add(variables.url, this.router.url);
    this.router.navigate([Route.TERMS_AND_CONDITIONS]);
  }

  onClickFAQ(): void {
    this.localStorageService.add(variables.url, this.router.url);
    this.router.navigate([Route.FAQ]);
  }
}
