<div class="footer header-box-shadow sm-menu-item">
  <div class="container">
    <div class="row" style="padding-bottom: 10px;">
      <!-- Accueil-->
      <div (click)="onClick('home')" [ngClass]="{ activeheader: getTab() === Route.DASHBOARD }" [routerLink]="[Route.DASHBOARD]"
           class="col no-margin-padding topnav-header"
           data-placement="top"
           data-toggle="tooltip"
           id="home" style="height: 60px;" title="Accueil">

        <div class="text-center" style="cursor: pointer;">
                <span class="" style="display: inline-block; padding-top: 10px;">
                  <img alt="" class="rounded float-right icon-img" src="{{ getHomeIcon() | image }}"/>
                </span>
          <p class="skillsmates-single-line" style="font-size: x-small;"><strong>Accueil</strong></p>
        </div>
      </div>
      <!-- Search-->
      <div (click)="onClick('search')" [ngClass]="{ activeheader: getTab() === Route.SEARCH }" [routerLink]="[Route.SEARCH]"
           class="col no-margin-padding topnav-header"
           data-placement="top"
           data-toggle="tooltip"
           id="search" style="height: 60px;" title="Recherche">

        <div class="text-center" style="cursor: pointer;">
                <span class="" style="display: inline-block; padding-top: 10px;">
                  <img alt="" class="rounded float-right icon-img" src="{{ getSearchIcon() | image }}"/>
                </span>
          <p class="skillsmates-single-line" style="font-size: x-small;"><strong>Rechercher</strong></p>
        </div>
      </div>
      <!-- Post-->
      <div (click)="onClickEditPost()" [ngClass]="{ activeheader: getTab() === Route.EDIT_POST }" class="col no-margin-padding topnav-header"
           data-placement="top"
           data-toggle="dropdown"
           id="post" style="height: 60px;" title="Publication">

        <div class="text-center" style="cursor: pointer;">
                <span class="" style="display: inline-block;padding-top: 10px;">
                  <img alt="" class="rounded float-right icon-img" src="./assets/images/plus-colored.png"/>
                </span>
          <p class="skillsmates-single-line" style="font-size: x-small;"><strong>Publication</strong></p>
        </div>
      </div>
      <!-- network -->
      <div (click)="onClick('network')" [ngClass]="{ activeheader: getTab() === Route.NETWORK}" [routerLink]="[Route.NETWORK]"
           class="col no-margin-padding topnav-header"
           data-placement="top"
           data-toggle="tooltip"
           id="network" style="height: 60px;" title="Réseau">

        <div class="text-center" style="cursor: pointer;">
                <span class="" style="display: inline-block;padding-top: 10px;">
                  <img alt="" class="rounded float-right icon-img" src="{{ getNetworkIcon() | image }}"/>
                    <span *ngIf="loggedAccount && loggedAccount.followers && loggedAccount.followers > 0" class=" badge badge-pill bg-danger float-right badge-border"
                          style="color: white;">{{ loggedAccount.followers }}
                    </span>
                </span>
          <p class="skillsmates-single-line" style="font-size: x-small;"><strong>Réseau</strong></p>
        </div>

      </div>
      <!-- More-->
      <div [ngClass]="{ activeheader: getTab() === Route.PROFILE }" class="col no-margin-padding topnav-header" data-placement="top"
           data-toggle="dropdown"
           id="plus" style="height: 60px;" title="Plus">

        <a (click)="openBottomSheetPlus()" [ngClass]="{ activeheader: !dropdownProfileMenu && getTab() === Route.PROFILE}"
           class="dropdown-toggle nav-link user-link collapsed"
           data-bs-toggle="collapse"
           href="#" style="height: 70px">

          <div class="text-center" style="cursor: pointer;">
            <span class="" style="display: inline-block;padding-top: 10px;">
              <img alt="" class="rounded float-right icon-img" src="{{ getPlusIcon() | image }}"/>
            </span>
            <p class="skillsmates-single-line" style="font-size: x-small;"><strong>Plus</strong></p>
          </div>
        </a>
      </div>

      <sm-bottom-sheet-plus #bottomSheet [options]="options">
        <div class="row">
          <div class="col">
            <a (click)="onClickProfileMenu('profile')" (click)="onCloseSheetPlus()"
               [routerLink]="[Route.PROFILE]" class="dropdown-item skillsmates-dropdown-menu plus-border"
               style="background-color: #E1F6FB; padding: 7px; border-radius: 7px">
              <img alt="" class="img-menu-dropdown" src="{{ 'profile' | image }}"/>
              Mon profil
            </a>

            <a (click)="onClickProfileMenu('assistance')" (click)="onCloseSheetPlus()"
               [routerLink]="[Route.ASSISTANCE]"
               class="dropdown-item skillsmates-dropdown-menu plus-border"
               style="background-color: #E1F6FB; padding: 7px; border-radius: 7px; margin-top: 15px">
              <img alt="" class="img-menu-dropdown" src="{{ 'assistance' | image }}"/>
              Assistance
            </a>

          </div>
          <div class="col">
            <a (click)="onClickProfileMenu('settings')" (click)="onCloseSheetPlus()"
               [routerLink]="[Route.SETTINGS]" class="dropdown-item skillsmates-dropdown-menu plus-border"
               style="background-color: #E1F6FB; padding: 7px; border-radius: 7px">
              <img alt="" class="img-menu-dropdown" src="{{ 'settings' | image }}"/>
              Paramètres
            </a>
            <a (click)="onClickProfileMenu('logout')" (click)="onCloseSheetPlus()"
               [routerLink]="[Route.PROFILE]"
               class="dropdown-item skillsmates-dropdown-menu plus-border"
               style="background-color: #E1F6FB; padding: 7px; border-radius: 7px; margin-top: 15px">
              <img alt="" class="img-menu-dropdown" src="{{ 'logout' | image }}"/>
              Déconnexion
            </a>
          </div>
        </div>
      </sm-bottom-sheet-plus>

    </div>
  </div>
</div>
