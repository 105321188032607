import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sm-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {

  @Input() page: number = 1;
  @Input() maxSize: number = 5;
  @Input() collectionSize: number = 1
  @Input() perPage: number = 5;
  @Output() smChangePageEvent = new EventEmitter<number>();
  calculateTotalPages = this.collectionSize / this.perPage;

  ngOnInit(): void {
  }

  changePage(event: number): void {
    this.smChangePageEvent.emit(event);
  }
}
