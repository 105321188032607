import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sm-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit {
  @Input() placeholder: string = "";
  @Input() name: string = 'name';
  @Input() title: string = "";
  @Input() hint: string = '';
  @Input() type: string = 'text';
  @Input() value!: string;
  @Input() errorMessage: string = 'error';
  @Input() showErrorMessage: boolean = false;
  @Input() inputStatus: string = '';
  @Input() mandatory: boolean = false;
  @Input() rows: number = 4;
  @Input() maxInputLength: number = 10000;
  @Input() showCounter: boolean = false;
  @Output() smInputEvent = new EventEmitter<string>();
  @Output() valueChange = new EventEmitter<string>();

  ngOnInit(): void {
  }

  handleChange(event: any): void {
    this.smInputEvent.emit(event.target.value);
  }

  onInputChange(event: string) {
    this.value = event;
    this.valueChange.emit(event);
  }

  fieldSize() {
    if (!this.value) {
      return 0
    } else {
      return this.value.length
    }
  }
}
