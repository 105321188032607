import {Component, Input, OnInit} from '@angular/core';
import {PostRequest} from "../../models/requests/postRequest";
import {DownloadService} from "../../services/download.service";

@Component({
  selector: 'sm-preview-post-media',
  templateUrl: './preview-post-media.component.html',
  styleUrls: ['./preview-post-media.component.scss']
})
export class PreviewPostMediaComponent implements OnInit {
  @Input() post!: PostRequest;
  @Input() image!: string | ArrayBuffer | null;
  previewLink!: string;

  constructor(private downloadService: DownloadService) {
  }

  ngOnInit() {
    this.downloadLink();
  }

  downloadLink(): void {
    this.downloadService.extractLink(this.post.link!).subscribe({
      next: value => {
        this.previewLink = value.images[0];
      },
      error: err => {
      },
      complete: () => {
      }
    });
  }


}
