import {Component, OnInit} from '@angular/core';
import {Route} from "../../enums/route.enum";
import {Router} from "@angular/router";
import {variables} from "../../../environments/variables";
import {PageMode} from "../../public/login/login.component";
import {LocalStorageService} from "../../services/local-storage.service";

@Component({
  selector: 'sm-offline-header',
  templateUrl: './offline-header.component.html',
  styleUrls: ['./offline-header.component.scss']
})
export class OfflineHeaderComponent implements OnInit {

  constructor(private router: Router,private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
  }

  handleGoToLogin() {
    this.localStorageService.add(variables.page_mode, PageMode.LOGIN);
    this.router.navigate([Route.LOGIN]);
  }

  handleGoToRegister() {
    this.localStorageService.add(variables.page_mode, PageMode.REGISTER);
    this.router.navigate([Route.LOGIN]);
  }
}
