import {Component} from '@angular/core';

@Component({
  selector: 'app-assistance',
  templateUrl: './assistance.component.html',
  styleUrls: ['./assistance.component.scss']
})
export class AssistanceComponent {

  selectedBlock!: string;
  displayAssistance = true;

  onClickAssistance() {
    this.selectedBlock = '';
    this.displayAssistance = true;
  }

  getSelectedBlock(block: string): void {
    this.selectedBlock = block;
    this.displayAssistance = false;
  }
}
