<sm-profile-ongoing-trainings
  *ngIf="showTrainings"
  [editMode]="editTab==='training'"
  (smEditModeEvent)="onClickEdit($event)">
</sm-profile-ongoing-trainings>

<sm-profile-diplomas
  *ngIf="showDiplomas"
  [editMode]="editTab==='diploma'"
  (smEditModeEvent)="onClickEdit($event)">
</sm-profile-diplomas>

<sm-profile-certifications
  *ngIf="showCertifications"
  [editMode]="editTab==='certification'"
  (smEditModeEvent)="onClickEdit($event)">
</sm-profile-certifications>
