<div class="skillsmates-box-shadow">
  <div (click)="onClickProfile(account)" class="row" style="margin: auto; cursor: pointer;">
    <div class="col-12 skillsmates-box-top">
      <span *ngIf="account.status" class="title">
        <img alt="" class="m-r-5" height="18" src="{{account | statusIcon}}" width="18">
      </span>
      <span (click)="onClickProfile(account)" style="float: right;">
        {{ 'account_curriculum.see_more' | translate }}
      </span>
    </div>
  </div>
  <div class="row bg-white" style="margin: 0;">
    <div class="col-12">
      <div (click)="onClickProfile(account)" class="profile-img" style="margin: auto; padding-bottom: 0">
        <a>
          <!--          <img class="avatar" src="{{account.avatar}}" alt="" style="border: white solid 2px; margin-top: -30px;">-->
          <sm-image
            [imageSize]="100"
            class="avatar"
            default="{{'user' | image}}" image="{{account.avatar}}"
            style="border: white solid 2px; margin-top: -30px;">
          </sm-image>
        </a>
      </div>
    </div>
    <div class="col-12 text-left skillsmates-single-line" style="color: #16152d; font-size: 14px;">
      <ul class="personal-info">
        <li>
          <span class="title"><img alt="" class="m-r-5" height="18" src="{{'user' | image}}" width="18"></span>
          <span class="text"><strong>{{ name(account) }}</strong></span>
        </li>
        <li *ngIf="account.currentJob">
          <span class="title"><img alt="" class="m-r-5" height="18" src="{{account | jobIcon}}" width="18"></span>
          <span class="text">{{ account.currentJob }}</span>
        </li>
        <li *ngIf="account.currentCompany">
          <span class="title"><img alt="" class="m-r-5" height="18" src="{{account | companyIcon}}" width="18"></span>
          <span class="text">{{ account.currentCompany }}</span>
        </li>
        <li *ngIf="account.city || account.country">
          <span class="title"><img alt="" class="m-r-5" height="18" src="{{'location' | image}}" width="18"></span>
          <span class="text"> <span *ngIf="account.city">{{ account.city }}</span> <span
            *ngIf="account.country"> - {{ account.country | country }}</span></span>
        </li>
      </ul>
    </div>
  </div>
  <div class="col-12" style="">
    <sm-horizontal-line [color]="'#DADADA'"></sm-horizontal-line>
  </div>
  <div class="col-12 text-left skillsmates-single-line" style="color: #16152d; font-size: 14px;">
    <ul class="personal-info" style="background-color: white;">
      <li [routerLink]="[]">
        <span class="title"><img alt="" class="m-r-5" height="18" src="{{'publications' | image}}" width="18"></span>
        <span class="text float-left"><strong>{{ account.posts }}</strong> &nbsp; Publications</span>
      </li>
      <li (click)="onClickFollowers()" [routerLink]="[]">
        <span class="title"><img alt="" class="m-r-5" height="18" src="{{'followers-black'| image}}" width="18"></span>
        <span class="text float-left"><strong>{{ account.followers }}</strong> &nbsp; Abonnés</span>
      </li>
      <li (click)="onClickFollowees()" [routerLink]="[]">
        <span class="title"><img alt="" class="m-r-5" height="18" src="{{'following-black' | image}}" width="18"></span>
        <span class="text float-left"><strong>{{ account.followees }}</strong> &nbsp; Abonnements</span>
      </li>
    </ul>
  </div>
</div>
