import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AccountDetailsResource,
  ParameterRequest,
  ParameterResource,
  ParameterService,
  TrainingRequest,
  TrainingResource,
  TrainingService
} from "ApiModuleAccount";
import {Alert} from "../../models/alert";
import {LocalStorageService} from "../../services/local-storage.service";
import {AccountHelper} from "../../helpers/account-helper";
import {variables} from "../../../environments/variables";
import {ToastrService} from "ngx-toastr";
import {ValidationCondition} from "../../models/validationCondition";

@Component({
  selector: 'sm-edit-training',
  templateUrl: './edit-training.component.html',
  styleUrls: ['./edit-training.component.scss']
})
export class EditTrainingComponent implements OnInit {
  @Input() trainingResource!: TrainingResource;
  @Output() smDoneEvent = new EventEmitter<boolean>;
  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  alert: Alert = {} as Alert;
  trainingRequest: TrainingRequest = {} as TrainingRequest;
  schoolClass: ParameterRequest = {} as ParameterRequest;
  level: ParameterRequest = {} as ParameterRequest;
  area: ParameterRequest = {} as ParameterRequest;
  activityAreas: ParameterResource[] = [];
  schoolClasses: ParameterResource[] = [];
  diplomaLevels: ParameterResource[] = [];
  validations: ValidationCondition[] = []
  higherEducationLabel: string = "Enseignement Supérieur"
  secondaryEducationLabel: string = "Enseignement Secondaire"
  processing: boolean = false

  constructor(private localStorageService: LocalStorageService,
              private trainingService: TrainingService,
              private toastrService: ToastrService,
              private parameterService: ParameterService) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
  }

  ngOnInit(): void {
    this.initValidation()
    this.populateRequest();
    this.fetchActivityAreas();
    this.fetchDiplomaLevels();
    this.fetchSchoolClasses();
  }

  populateRequest(): void {
    if (this.trainingResource) {
      this.trainingRequest.title = this.trainingResource.title!;
      this.trainingRequest.description = this.trainingResource.description!;
      this.trainingRequest.account = this.trainingResource.account!;
      this.trainingRequest.schoolName = this.trainingResource.schoolName!;
      this.trainingRequest.city = this.trainingResource.city!;
      this.trainingRequest.education = this.trainingResource.education!;
      this.trainingRequest.startDate = this.trainingResource.startDate!
      this.trainingRequest.activityArea = this.trainingResource.activityArea;
      this.trainingRequest.level = this.trainingResource.level ? this.trainingResource.level : this.levelLabels()[0];
      this.trainingRequest.schoolClass = this.trainingResource.schoolClass;
      this.trainingRequest.schoolType = AccountHelper.parameterResourceToRequest(this.trainingResource.schoolType?.resource);
    } else {
      this.trainingRequest.education = TrainingResource.EducationEnum.Secondary;
      this.trainingRequest.level = this.levelLabels()[0]
    }
  }

  getDiplomaTypeLabels(): string[] {
    return Object.values(TrainingRequest.EducationEnum)
      .map(value => this.enumToEducation(value))
  }

  getCurrentEducation(): string {
    return this.enumToEducation(this.trainingRequest.education);
  }

  getEducation(event: string): void {
    this.trainingRequest.education = this.educationToEnum(event)!;
  }

  educationToEnum(education: string) {
    switch (education) {
      case this.secondaryEducationLabel:
        return TrainingRequest.EducationEnum.Secondary
      case this.higherEducationLabel:
        return TrainingRequest.EducationEnum.Higher
      default:
        return undefined;
    }
  }

  enumToEducation(edu: TrainingRequest.EducationEnum): string {
    switch (edu) {
      case "HIGHER":
        return this.higherEducationLabel
      case "SECONDARY":
        return this.secondaryEducationLabel
    }
  }

  fetchActivityAreas(): void {
    this.parameterService.findActivityAreas().subscribe({
      next: response => {
        this.activityAreas = response.resources!;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchDiplomaLevels(): void {
    this.parameterService.findDiplomaLevels().subscribe({
      next: response => {
        this.diplomaLevels = response.resources!;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchSchoolClasses(): void {
    this.parameterService.findSchoolClasses().subscribe({
      next: response => {
        this.schoolClasses = response.resources!;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  getLevel(event: any) {
    this.trainingRequest.level = event
    this.checkLevel()
  }

  getTrainingTitle(event: string): void {
    this.trainingRequest.title = event;
    this.checkTitle()
  }

  getSchoolClass(event: string) {
    this.trainingRequest.schoolClass = event;
    this.checkSchoolClass()
  }

  getActivityArea(event: string) {
    this.trainingRequest.activityArea = event;
    this.checkDomain()
  }

  getSchoolName(event: string): void {
    this.trainingRequest.schoolName = event;
    this.checkSchoolName()
  }

  getCity(event: string): void {
    this.trainingRequest.city = event;
    this.checkCity()
  }

  getStartDate(event: string): void {
    this.trainingRequest.startDate = event;
    this.checkDate()
  }

  getDescription(event: string): void {
    this.trainingRequest.description = event;
  }

  levelLabels(): string[] {
    let result: string[] = []
    for (let i = 1; i <= 12; i++) result.push("Baccalauréat + " + i)
    return result
  }

  onClickCancel(): void {
    this.smDoneEvent.emit(false);
    this.localStorageService.delete(variables.account_attribute);
  }

  onClickSave(): void {
    if (this.iSFormValid()) {
      this.trainingRequest.account = this.account.uuid!;
      if (this.trainingResource) {
        this.sendUpdateTrainingRequest();
      } else {
        this.sendCreateTrainingRequest();
      }
    }
  }

  formatDate(inputDate: string): string {
    if (inputDate) {
      const date = new Date(inputDate);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1; // Months are 0-based
      const year = date.getUTCFullYear();
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    } else {
      return '';
    }
  }

  initValidation() {
    this.validations = []
    this.validations.push(new ValidationCondition(true, 'TITLE', ''))
    this.validations.push(new ValidationCondition(true, 'CITY', ''))
    this.validations.push(new ValidationCondition(true, 'SCHOOL', ''))
    this.validations.push(new ValidationCondition(true, 'LEVEL', ''))
    this.validations.push(new ValidationCondition(true, 'DATE', ''))
    this.validations.push(new ValidationCondition(true, 'DOMAIN', ''))
    this.validations.push(new ValidationCondition(true, 'CLASS', ''))
  }

  updateValidation(code: string, newValue: boolean, message: string) {
    this.validations.forEach(value => {
      if (value.code === code) {
        value.valid = newValue
        value.message = message
      }
    })
  }

  getCssStatus(code: string): string {
    return this.validations.find(value => value.code === code)?.cssFormStatus()!
  }

  isValid(code: string) {
    return this.validations.find(value => value.code === code)?.valid!
  }

  errorMessage(code: string) {
    return this.validations.find(value => value.code === code)?.message!
  }

  getCssStatusFinal(code: string): string {
    return 'is-'.concat(this.getCssStatus(code))
  }

  checkFieldIsPresent(field?: string) {
    return field ? field.trim().length != 0 : false
  }

  checkTitle() {
    let condition
    switch (this.trainingRequest.education) {
      case "HIGHER":
        condition = this.checkFieldIsPresent(this.trainingRequest.title)
        break
      case "SECONDARY":
        this.trainingRequest.title = undefined
        condition = true
        break
    }
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('TITLE', condition, message)
  }

  checkLevel() {
    let condition
    switch (this.trainingRequest.education) {
      case "HIGHER":
        condition = this.checkFieldIsPresent(this.trainingRequest.level)
        break;
      case "SECONDARY":
        this.trainingRequest.level = undefined
        condition = true
        break
    }
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('LEVEL', condition, message)
  }

  checkSchoolClass() {
    let condition
    switch (this.trainingRequest.education) {
      case "HIGHER":
        this.trainingRequest.schoolClass = undefined
        condition = true
        break;
      case "SECONDARY":
        condition = this.checkFieldIsPresent(this.trainingRequest.schoolClass)
        break
    }
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('CLASS', condition, message)
  }

  checkDomain() {
    this.updateValidation('DOMAIN', true, '')
  }

  checkSchoolName() {
    let condition = this.checkFieldIsPresent(this.trainingRequest.schoolName)
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('SCHOOL', condition, message)
  }

  checkCity() {
    let condition = this.checkFieldIsPresent(this.trainingRequest.city)
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('CITY', condition, message)
  }


  checkDate() {
    let condition = this.checkFieldIsPresent(this.trainingRequest.startDate)
    let message = condition ? '' : 'Ce champs est obligatoire'
    if (condition) {
      condition = this.checkDateIsInPast(this.trainingRequest.startDate)
      message = condition ? '' : 'Date invalide'
    }
    this.updateValidation('DATE', condition, message)
  }

  checkDateIsInPast(field?: string) {
    let now: Date = new Date();
    let tested: Date = new Date(field!)
    return now.getTime() > tested.getTime()
  }

  checkAllFields() {
    this.checkTitle()
    this.checkCity()
    this.checkSchoolClass()
    this.checkLevel()
    this.checkDate()
    this.checkDomain()
    this.checkSchoolName()
  }

  displayErrorAlert(message: string) {
    this.alert = {display: true, class: 'danger', title: 'Erreur', message: message}
  }

  iSFormValid() {
    this.checkAllFields()
    let result = true;
    this.validations.forEach(value => result = result && value.valid)
    if (!result) {
      this.displayErrorAlert("Certains champs sont invalides")
    }
    return result;
  }

  private sendCreateTrainingRequest() {
    this.processing = true
    this.trainingService.createTraining(this.trainingRequest).subscribe({
      next: response => {
        this.processing = false
        this.toastrService.success("La formation a été créé avec succés", 'Création formation');
        this.smDoneEvent.emit(true);
      },
      error: error => {
        this.processing = false
        this.toastrService.error("Erreur lors de la création de la formation", 'Création formation');
      },
      complete: () => {
        this.processing = false
      }
    });
  }

  private sendUpdateTrainingRequest() {
    this.processing = true
    this.trainingService.updateTraining(this.trainingResource.uuid!, this.trainingRequest).subscribe({
      next: response => {
        this.processing = false
        this.toastrService.success("La formation a été mise à jour avec succés", 'Mis à jour formation');
        this.smDoneEvent.emit(true);
      },
      error: error => {
        this.processing = false
        this.toastrService.error("Erreur lors de la mis à jour de la formation", 'Mis à jour formation');
      },
      complete: () => {
        this.processing = false
      }
    })
  }
}
