import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { AccountService } from '../api/accounts/api/account.service';
import { variables } from 'src/environments/variables';
import { AccountDetailsResource } from '../api/accounts/model/accountDetailsResource';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private storageService: LocalStorageService, private accountService: AccountService) { }

   // Observable string sources
   private missionAnnouncedSource = new Subject<AccountDetailsResource>();
   private missionConfirmedSource = new Subject<string>();
 
   // Observable string streams
   missionAnnounced$ = this.missionAnnouncedSource.asObservable();
   missionConfirmed$ = this.missionConfirmedSource.asObservable();
 
   // Service message commands
   announceMission(mission: string) {
    this.fetchAccount(mission);
   }
 
   confirmMission(astronaut: string) {
     this.missionConfirmedSource.next(astronaut);
   }

   fetchAccount(id:string): void {
    this.accountService.findAccount(id).subscribe({
      next: response => {
        this.storageService.update(variables.logged_account,response.resource!);
        this.missionAnnouncedSource.next(response.resource!);
      },
      error: error => {
      },
      complete: () => {
      }
    })
  }

}
