<div class="row skillsmates-container">
  <div class="col-12" style="margin-bottom: 10px;">
    <sm-profile-account [accountUuid]="account.uuid!"></sm-profile-account>
  </div>
</div>

<div class="row skillsmates-container skillsmates-box-shadow p-3 bg-white" style="border-radius: 30px">
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 skillsmates-box-padding sm-side-menu">
    <div class="row bg-white" style="margin: auto;  border-radius: 0 0 10px 10px;">
      <ul style="list-style: none; margin: 10px 0 10px 0">
        <div (click)="onClickAttribute(tab)" *ngFor="let tab of tabs"
             [ngStyle]="{'background-color': selectedTab === tab ? colors.light_blue + '22' : colors.white}"
             class="col-md-12 skillsmates-box-top pointer"
             style="margin: 5px;">
          <strong>{{ 'edit_profile.' + tab | translate }}</strong>
        </div>
      </ul>
      <div class="col" style="padding: 0 25px 0 25px">
        <sm-image-text
          (click)="onClickReturn()"
          [bgColor]="'#D3D3D3'"
          image="{{'left-arrow' | image}}"
          text="{{'assistance.return' | translate}}">
        </sm-image-text>
        <!--<button  class="btn btn-primary" style="color: white;width: 100%; background-color: #D3D3D3; cursor: pointer" (click)="onClickReturn()" >
          <img src="{{'left-arrow' | image}}" alt="back" height="16" style="width: 10%; text-align: left"/>
          <label style="color: black; width: 90%;cursor: pointer"> {{ 'edit_profile.return' | translate }} </label>
        </button>-->
      </div>
    </div>

  </div>

  <div class="col-12 col-sm-12 col-md-1 col-lg-1 col-xl-1 skillsmates-box-padding ">
    <div style="border-left: 1px solid #ececec; height: 100%;"></div>
  </div>

  <div class="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 skillsmates-box-padding ">
    <sm-profile-resume
      (smAddEvent)="onClickAdd($event)"
      (smUpdateEvent)="onClickUpdate($event)"
      *ngIf="selectedTab === tabs[0]">
    </sm-profile-resume>
    <sm-profile-details *ngIf="selectedTab === tabs[1]" [editMode]="editMode"></sm-profile-details>
    <sm-profile-trainings *ngIf="selectedTab === tabs[2]" [editMode]="editMode"></sm-profile-trainings>
    <sm-profile-jobs *ngIf="selectedTab === tabs[3]" [editMode]="editMode"></sm-profile-jobs>
    <sm-profile-skills *ngIf="selectedTab === tabs[4]" [editMode]="editMode"></sm-profile-skills>
    <sm-profile-biography *ngIf="selectedTab === tabs[5]" [editMode]="editMode"></sm-profile-biography>
  </div>
</div>

<div class="row skillsmates-container">
  <div class="col-12" style="margin: 10px;">
    <sm-footer></sm-footer>
  </div>
</div>

