import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {AccountDetailsResource} from "ApiModuleAccount";

@Component({
  selector: 'sm-settings-account',
  templateUrl: './settings-account.component.html',
  styleUrls: ['./settings-account.component.scss']
})
export class SettingsAccountComponent implements OnInit {

  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  editPhone: boolean = false;
  editEmail: boolean = false;
  editName: boolean = false;
  processing: boolean = false

  constructor(private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
  }

  getPhoneNumber(event: string): void {

  }

  onClickCancel(): void {
    this.editPhone = false;
    this.editEmail = false;
    this.editName = false;
  }

  onClickSave(): void {

  }

  getPassword(event: string) {

  }

  getEmail(event: string) {

  }

  onClickEditEmail() {
    this.editEmail = true;
    this.editPhone = false;
    this.editName = false;
  }

  onClickEdit(setting: string) {
    this.editName = false;
    this.editPhone = false;
    this.editEmail = false;
    switch (setting) {
      case 'name':
        this.editName = true;
        break;
      case 'email':
        this.editEmail = true;
        break;
      case 'phone':
        this.editPhone = true;
        break;
    }
  }

  getLastname(event: string): void {

  }

  getFirstname(event: string): void {

  }
}
