import {Component, Input, OnInit} from '@angular/core';
import {AccountDetailsResource, TrainingResponse} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {variables} from "../../../environments/variables";

@Component({
  selector: 'sm-profile-trainings',
  templateUrl: './profile-trainings.component.html',
  styleUrls: ['./profile-trainings.component.scss']
})
export class ProfileTrainingsComponent implements OnInit {

  @Input() editMode: boolean = false;
  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  trainingResponse: TrainingResponse = {} as TrainingResponse;
  editTab: string = '';
  showTrainings: boolean = true;
  showDiplomas: boolean = true;
  showCertifications: boolean = true;


  constructor(private localStorageService: LocalStorageService) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
  }

  ngOnInit() {

  }

  onClickAdd(event: string): void {
    this.editMode = true;
  }

  onClickDone(event: boolean): void {
    this.editMode = false;
  }

  onClickUpdate(event: string): void {
    this.trainingResponse!.resource = this.localStorageService.get(variables.account_attribute);
    this.editMode = true;
  }

  onClickEdit(event: string) {
    if (event) {
      this.hideTabs();
      switch (event) {
        case 'training':
          this.showTrainings = true;
          break;
        case 'diploma':
          this.showDiplomas = true;
          break;
        case 'certification':
          this.showCertifications = true;
          break;
      }
    } else {
      this.showTrainings = true;
      this.showDiplomas = true;
      this.showCertifications = true;
    }
    this.editTab = event;
  }

  hideTabs(): void {
    this.showTrainings = false;
    this.showCertifications = false;
    this.showDiplomas = false;
  }
}
