import {Component, Input, OnInit} from '@angular/core';
import {AccountDetailsResource, PostDetailsResource, PostService} from "ApiModulePost";
import {variables} from "../../../environments/variables";
import {Route} from "../../enums/route.enum";
import {LocalStorageService} from "../../services/local-storage.service";
import {Router} from "@angular/router";
import {StringHelper} from "../../helpers/string-helper";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'sm-shared-post-header',
  templateUrl: './shared-post-header.component.html',
  styleUrls: ['./shared-post-header.component.scss']
})
export class SharedPostHeaderComponent implements OnInit {
  @Input() shareAuthor: AccountDetailsResource = {} as AccountDetailsResource
  @Input() shareDate: string = {} as string
  @Input() post: PostDetailsResource = {} as PostDetailsResource
  @Input() createdAt: string = {} as string
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  postToDelete: PostDetailsResource = {} as PostDetailsResource;
  constructor(private router: Router, public storageService: LocalStorageService,
              private localStorageService: LocalStorageService,
              private toastrService: ToastrService,
              private postService: PostService) {}

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
  }

  getCompanyIcon(): string {
    return StringHelper.getCompanyIcon(this.shareAuthor!);
  }

  onClickProfile(): void {
    if (!this.storageService.isOffline()) {
      this.storageService.add(variables.profile_account, this.shareAuthor);
      this.router.navigate([Route.PROFILE]);
    }
  }

  onClickPost(): void {
    if (!this.storageService.isOffline()) {
      this.storageService.add(variables.post_details, this.post);
      this.storageService.add(variables.previous_route, Route.DASHBOARD);
      this.router.navigate([Route.POST]);
    }
  }

  public name(): string {
    return StringHelper.truncateName(this.shareAuthor, 25);
  }
  onClickConfirmDelete(): void {
    this.postService.deletePost(this.postToDelete.uuid!)
      .subscribe({
        next: response => {
          this.toastrService.success("Publication supprimée avec succés", "Publication");
          window.location.reload();
        },
        error: error => {
          this.toastrService.error("Erreur lors de la suppression de la publication", "Publication");
        },
        complete: () => {
        }
      });
  }

  onClickDisplay(): void {
    if (!this.storageService.isOffline()) {
      this.storageService.add(variables.post_details, this.post);
      this.storageService.add(variables.previous_route, Route.PROFILE);
      this.router.navigate([Route.POST]);
    }
  }

  onClickCopy(post: PostDetailsResource): void {
    this.copy(window.location.host + "/post-detail?id=" + this.post.uuid!);
    this.toastrService.success("Lien copié sur le presse-papier", "Lien");
  }

  ownerIsLoggedAccount(): boolean {
    if (this.storageService.isOffline()) {
      return false
    }
    return this.loggedAccount.uuid === this.post.account?.uuid
  }

  onClickUpdate(post: PostDetailsResource): void {
    if (!this.storageService.isOffline()) {
      this.storageService.add(variables.post_update, post);
      this.router.navigate([Route.EDIT_POST]);
    }
  }

  onClickDelete(post: PostDetailsResource): void {
    this.postToDelete = post;
    // this.storageService.add(variables.post_delete, post);
  }

  copy(url: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }


}
