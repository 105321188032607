<div *ngIf="elements" class="skillsmates-box-shadow" style="border-radius: 10px">
  <div (click)="onClickTitle()" class="row pointer"
       style="font-size: x-large; color: #16152d; margin: 5px;">
    <div class="col" style="font-size: x-large; color: #16152d; padding: 10px;">
      <strong>{{ title }} ({{ elements.meta?.total! }})</strong>
      <span style="float: right; font-size: medium;">
        <span>Voir plus</span>
        <a style="color: #16162d;">
          <img alt="arrow" height="24" src="{{ headTileImage | image}}"> &nbsp;
        </a>
    </span>
    </div>
  </div>
  <sm-image-text (click)="onClickAdd(elements.type)"
                 *ngIf="account.uuid === loggedAccount.uuid"
                 [bgColor]="'white'"
                 [color]="'#00ACED'"
                 [text]=addText
                 image="{{'plus-square-color' | image}}"
                 style="font-weight: bolder; font-size: x-large">
  </sm-image-text>
  <div *ngIf="!expanded">
    <sm-cursus-tile
      (smDoneEvent)="onDone($event)"
      (smUpdateEvent)="onClickUpdate($event)"
      *ngIf="elements && elements.resources && elements.resources.length > 0"
      [resource]="elements.resources[0]"
      [type]="elements.type!"
      image="{{getImage(elements.resources[0]) | image}}">
    </sm-cursus-tile>
  </div>

  <div *ngIf="expanded && elements && elements.resources && elements.resources.length > 0">
    <sm-cursus-tile
      (smUpdateEvent)="onClickUpdate($event)"
      *ngFor="let element of elements.resources"
      [resource]="element"
      [type]="elements.type!"
      image="{{getImage(element) | image}}">
    </sm-cursus-tile>
  </div>

</div>



