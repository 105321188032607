import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sm-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent implements OnInit {
  @Input() showEye: boolean = false;
  @Input() placeholder: string = "";
  @Input() name: string = 'name';
  @Input() title: string = "";
  @Input() type: string = 'text';
  @Input() errorMessage: string = 'error';
  @Input() showErrorMessage: boolean = false;
  @Input() inputStatus: string = '';
  @Input() mandatory: boolean = false;
  @Input() value!: string;
  @Input() disabled: boolean = false;
  @Input() maxInputLength: number = 255;
  @Input() showCounter: boolean = false;
  @Output() smInputEvent = new EventEmitter<string>();
  @Output() smFileEvent = new EventEmitter<File>();
  @Output() valueChange = new EventEmitter<string>();
  dateValue: Date | undefined;

  ngOnInit(): void {
    if (this.type === "date") {
      this.dateValue = new Date(this.value);
    }
  }

  handleChange(event: any) {
    if (this.type == "file") {
      this.smFileEvent.emit(event.target.files[0]);
    } else {
      this.smInputEvent.emit(event.target.value);
    }
  }

  onInputChange(event: string) {
    this.value = event;
    this.valueChange.emit(event);
  }

  onClickEye(): void {
    this.type = this.type == 'password' ? 'text' : 'password';
  }

  fieldSize() {
    if (!this.value) {
      return 0
    } else {
      return this.value.length
    }
  }
}
