import {Component} from '@angular/core';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent {

  hostname: string = document.location.origin
  CURRENT_YEAR: number = new Date().getFullYear()

}
