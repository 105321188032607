import {BaseModel} from "./responses/baseModel";
import {AccountDetailsResource} from "ApiModuleAccount";

export class Forum extends BaseModel {
  constructor() {
    super();
  }

  account!: AccountDetailsResource;
  discipline!: string;
  description!: string;
  nb: number = 0;
}
