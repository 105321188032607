import {Component, Input, OnInit} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {Route} from "../../enums/route.enum";
import {StringHelper} from "../../helpers/string-helper";
import {AccountDetailsResource} from "ApiModuleAccount";
import {variables} from "../../../environments/variables";
import {Router} from "@angular/router";
import {FollowRequest, InteractionService} from "ApiModulePost";
import {ToastrService} from "ngx-toastr";
import {ImagePipe} from "../../pipes/image.pipe";

@Component({
  selector: 'sm-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardComponent implements OnInit {

  @Input() account!: AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  Route = Route;
  hasFollowed!: boolean;
  followLabel: string = 'profile_account.follow';

  constructor(private localStorageService: LocalStorageService,
              private toastrService: ToastrService,
              private interactionService: InteractionService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.checkFollow();
  }

  getCompanyIcon(): string {
    return StringHelper.getCompanyIcon(this.account);
  }

  onClickAvatar() {
    this.localStorageService.add(variables.profile_account, this.account);
    this.router.navigate([Route.PROFILE]);
  }

  backgroundImage() {
    return this.account.avatar? this.account.avatar : new ImagePipe().transform('user')
  }

  checkFollow(): void {
    this.interactionService.checkAccountHaveFollowSocialInteraction(this.loggedAccount.uuid!, this.account.uuid!).subscribe({
      next: response => {
        this.hasFollowed = Boolean(response.data);
        this.followLabel = this.hasFollowed ? 'profile_account.followed' : 'profile_account.follow';
      },
      error: err => {
      },
      complete: () => {
      }
    });
  }

  onClickProfile(): void {
    this.localStorageService.add(variables.profile_account, this.account);
    this.router.navigate([Route.PROFILE]);
  }

  onClickFollow(): void {
    if (this.loggedAccount != undefined) {
      let followRequest: FollowRequest = {emitter: this.loggedAccount.uuid, receiver: this.account.uuid}
      this.hasFollowed ? this.unfollow(followRequest) : this.follow(followRequest)
    }
  }

  follow(request: FollowRequest) {
    this.interactionService.follow(request)
      .subscribe({
        next: response => {
          this.localStorageService.updateLoggedAccount(this.loggedAccount.uuid!)
          this.ngOnInit()
          this.toastrService.success("Abonnement avec succés", "Abonnement");
        },
        error: error => {
        },
        complete: () => {
        }
      })
  }

  unfollow(request: FollowRequest) {
    this.interactionService.unfollow(request)
      .subscribe({
        next: response => {
          this.localStorageService.updateLoggedAccount(this.loggedAccount.uuid!)
          this.ngOnInit()
          this.toastrService.success("Désabonnement avec succés", "Abonnement");
        },
        error: error => {
        },
        complete: () => {
        }
      });
  }


}
