<div class="row" style="padding-right: 5px;">
  <div class="col-2">
    <span class="user-img">
      <a (click)="onClickProfile()" *ngIf="shareAuthor" style="cursor: pointer;">
        <img alt="{{shareAuthor.lastname}}" class="rounded-circle" height="48" src="{{shareAuthor.avatar}}"
             style="background-color: whitesmoke;"
             width="48">
      </a>
      <span *ngIf="shareAuthor?.connected" class="status online"></span>
      <span *ngIf="!shareAuthor?.connected" class="status offline"></span>
    </span>
  </div>
  <div (click)="onClickPost()" class="col-9 text-left">
    <div class="row">
      <div class="col-12">
        <h4 class="doctor-name text-left skillsmates-single-line">
          <a (click)="onClickProfile()">{{ name() }}</a>
          <span>
            <img alt="" height="20" src="{{shareAuthor | statusIcon}}">
      </span>
        </h4>
      </div>
    </div>
    <div class="row">
      <div class="col-12 skillsmates-single-line">
        <div class="" style="font-size: small;">
          <span *ngIf="shareAuthor?.currentJob">
            <img alt="" height="20" src="{{shareAuthor | jobIcon}}"> {{ shareAuthor?.currentJob }} -
          </span>
          <span *ngIf="shareAuthor?.currentCompany">
            <img alt="" height="14" src="{{getCompanyIcon() | image}}"> {{ shareAuthor?.currentCompany }}
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 skillsmates-single-line">
        <span *ngIf="shareAuthor?.city">
          <img alt="" height="14" src="{{'location' | image}}"> {{ shareAuthor?.city }}
        </span>
        <span *ngIf="shareAuthor?.country">({{ shareAuthor?.country ?? '' | country }})</span> -
        <span class="date-post">{{ createdAt | timeAgo }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="!storageService.isOffline()" class="col-1">
    <div class="dropdown dropdown-action">
      <a aria-expanded="false" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" href="#"><em
        class="fa fa-ellipsis-v"></em></a>
      <div class="dropdown-menu dropdown-menu-right skillsmates-box-shadow bg-white">
        <a (click)="onClickDisplay()" class="dropdown-item skillsmates-dropdown-menu">
          <em class="fa fa-eye m-r-5"></em> {{ 'post.display' | translate }}
        </a>
        <a (click)="onClickUpdate(post)" *ngIf="ownerIsLoggedAccount()"
           class="dropdown-item skillsmates-dropdown-menu">
          <em class="fa fa-pencil m-r-5"></em> {{ 'post.edit' | translate }}
        </a>
        <a (click)="onClickCopy(post)" class="dropdown-item skillsmates-dropdown-menu">
          <em class="fa fa-link m-r-5"></em> {{ 'post.copy_link' | translate }}
        </a>
        <a (click)="onClickDelete(post)" *ngIf="ownerIsLoggedAccount()" class="dropdown-item skillsmates-dropdown-menu"
           data-bs-target="#delete_post"
           data-bs-toggle="modal" href="#">
          <em class="fa fa-trash m-r-5"></em> {{ 'post.delete' | translate }}
        </a>
      </div>
    </div>
    <!--<div class="dropdown dropdown-action">
      <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><em
        class="fa fa-ellipsis-v"></em></a>
      <div class="dropdown-menu dropdown-menu-right skillsmates-box-shadow bg-white">
        <a class="dropdown-item skillsmates-dropdown-menu" (click)="onClickDisplay()">
          <em class="fa fa-eye m-r-5"></em> {{'post.display' | translate}}
        </a>
        <a class="dropdown-item skillsmates-dropdown-menu" *ngIf="ownerIsLoggedAccount()"
           (click)="onClickUpdate(post)">
          <em class="fa fa-pencil m-r-5"></em> {{'post.edit' | translate}}
        </a>
        <a class="dropdown-item skillsmates-dropdown-menu" (click)="onClickCopy(post)">
          <em class="fa fa-link m-r-5"></em> {{'post.copy_link' | translate}}
        </a>
        <a href="#" class="dropdown-item skillsmates-dropdown-menu" data-bs-toggle="modal"
           data-bs-target="#delete_post"
           (click)="onClickDelete(post)" *ngIf="ownerIsLoggedAccount()">
          <em class="fa fa-trash m-r-5"></em> {{'post.delete' | translate}}
        </a>
      </div>
    </div>-->
  </div>
</div>

<div class="modal fade delete-modal" id="delete_post" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <img alt="" height="46" src="{{'symbole-skills-mates' | image}}" width="50">
        <h3>Voulez-vous supprimer cette publication?</h3>
        <div class="m-t-20"><a class="btn btn-white" data-bs-dismiss="modal">NON</a>
          <button (click)="onClickConfirmDelete()" class="btn btn-danger" data-bs-dismiss="modal" type="submit">OUI
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

