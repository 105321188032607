import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {variables} from "../../../environments/variables";
import {PostDetailsResource, PostDetailsResponse, PostService} from "ApiModulePost";
import {ActivatedRoute, Router} from "@angular/router";
import {Route} from "../../enums/route.enum";

@Component({
  selector: 'app-post-details',
  templateUrl: './post-details.component.html',
  styleUrls: ['./post-details.component.scss']
})
export class PostDetailsComponent implements OnInit {

  post!: PostDetailsResource;
  postResponse: PostDetailsResponse = {} as PostDetailsResponse;
  previousRoute!: Route;
  id!: string;

  constructor(private localStorageService: LocalStorageService,
              private postService: PostService,
              private activateRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.previousRoute = this.localStorageService.get(variables.previous_route);
    this.post = this.localStorageService.get(variables.post_details);
    if (this.post) {
      this.getPost(this.post.uuid!);
    } else {
      this.activateRoute.queryParams.subscribe({
        next: value => {
          this.id = value['id'];
          if (this.id) {
            this.getPost(this.id);
          }
        }
      });
    }
  }

  onClickReturn(): void {
    if (this.previousRoute) {
      this.router.navigate([this.previousRoute]);
    }
  }

  getPost(uuid: string): void {
    this.postService.getPost(uuid).subscribe({
      next: response => {
        this.postResponse = response;
        this.post = this.postResponse.resource!;
      },
      error: err => {
      },
      complete: () => {
      }
    })
  }

  onClickPost(p: PostDetailsResource): void {
    this.getPost(p.uuid!);
  }
}
