import {Component, Input, OnInit} from '@angular/core';
import {AccountDetailsResource, SkillResponse, SkillService} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {Alert} from "../../models/alert";
import {variables} from "../../../environments/variables";

@Component({
  selector: 'sm-profile-skills',
  templateUrl: './profile-skills.component.html',
  styleUrls: ['./profile-skills.component.scss']
})
export class ProfileSkillsComponent implements OnInit {
  @Input() editMode: boolean = false;
  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  skillResponse: SkillResponse = {} as SkillResponse;
  alert: Alert = {} as Alert;

  constructor(private localStorageService: LocalStorageService,
              private skillService: SkillService) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
    this.skillResponse!.resource = this.localStorageService.get(variables.account_attribute);
  }

  ngOnInit() {
    this.fetchSkills();
  }

  fetchSkills() {
    this.skillService.findSkillByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.skillResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  onClickAdd(event: string): void {
    this.editMode = true;
  }

  onClickDone(event: boolean): void {
    this.editMode = false;
    if (event) {
      this.fetchSkills();
    }
  }

  onClickUpdate(event: string): void {
    this.skillResponse!.resource = this.localStorageService.get(variables.account_attribute);
    this.editMode = true;
  }
}
