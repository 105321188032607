import {Component, Input, OnInit} from '@angular/core';
import {AccountDetailsResource, AccountFileService} from "ApiModuleAccount";
import {ImageCroppedEvent, ImageTransform} from "ngx-image-cropper";
import {Router} from "@angular/router";
import {ToastrService} from "ngx-toastr";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {LocalStorageService} from "../../services/local-storage.service";

@Component({
  selector: 'sm-edit-avatar',
  templateUrl: './edit-avatar.component.html',
  styleUrls: ['./edit-avatar.component.scss']
})
export class EditAvatarComponent implements OnInit {

  @Input() account: AccountDetailsResource = {} as AccountDetailsResource;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  croppedFile!: File;
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  zoom!: number;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  loading: boolean = false;
  originalFile!: File;

  constructor(private router: Router,
              private accountFileService: AccountFileService,
              private localStorageService: LocalStorageService,
              private toastrService: ToastrService,
              private modalService: NgbModal) {
  }

  ngOnInit(): void {
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
    this.originalFile = this.imageChangedEvent.target.files[0];
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
    this.croppedFile = this.base64ToFile(
      event.base64,
      this.imageChangedEvent.target.files[0].name,
    );
  }

  imageLoaded(): void {
    this.showCropper = true;
  }

  cropperReady(): void {
    // console.log('Cropper ready', sourceImageDimensions);
  }

  loadImageFailed(): void {
  }

  rotateLeft(): void {
    this.canvasRotation--;
    this.flipAfterRotate();
  }

  rotateRight(): void {
    this.canvasRotation++;
    this.flipAfterRotate();
  }

  flipHorizontal(): void {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }

  flipVertical(): void {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }

  resetImage(): void {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }

  zoomOut(): void {
    this.scale -= .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  zoomIn(): void {
    this.scale += .1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  scaleImage(): void {
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }

  toggleContainWithinAspectRatio(): void {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }

  updateRotation(): void {
    this.transform = {
      ...this.transform,
      rotate: this.rotation
    };
  }

  base64ToFile(data: any, filename: string): File {
    const arr = data.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename.split('.').slice(0, -1).join('.') + '.png', {type: mime});
  }

  onClickSaveProfileImage(): void {
    if (this.croppedFile && this.account) {
      this.uploadFile();
    } else {
      this.toastrService.error('Aucune image selectionnée', 'Erreur');
    }
  }

  uploadFile(): void {
    this.loading = true;
    this.modalService.dismissAll();

    this.accountFileService.saveAccountAvatar(this.account.uuid!, this.croppedFile).subscribe({
      next: async response => {
        if (response && response.resource) {
          this.toastrService.success("Mis à jour de la photo de profil", 'Avatar');
          this.account = response.resource;
          this.localStorageService.updateLoggedAccount(this.account.uuid!)
          this.localStorageService.updateProfileAccount(this.account.uuid!)
          await this.sleep(2000);
          this.loading = false;
          this.modalService.dismissAll();
          window.location.reload();
        }
      },
      error: err => {
        this.toastrService.error('Erreur lors de la mis à jour de la photo de profil', 'Avatar');
        this.loading = false;
        this.modalService.dismissAll();
      },
      complete: () => {
        this.loading = false;
        this.modalService.dismissAll();
      }
    });
  }

  sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  onClickDismissModal(): void {
    this.modalService.dismissAll();
  }

  onScaleChange(): void {
    this.scaleImage();
  }

  private flipAfterRotate(): void {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }

}
