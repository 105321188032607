import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AccountDetailsResource,
  CertificationRequest,
  CertificationResource,
  CertificationService,
  ParameterResource,
  ParameterService
} from "ApiModuleAccount";
import {Alert} from "../../models/alert";
import {AccountHelper} from "../../helpers/account-helper";
import {LocalStorageService} from "../../services/local-storage.service";
import {variables} from "../../../environments/variables";
import {ToastrService} from "ngx-toastr";
import {ValidationCondition} from "../../models/validationCondition";

@Component({
  selector: 'sm-edit-certification',
  templateUrl: './edit-certification.component.html',
  styleUrls: ['./edit-certification.component.scss']
})
export class EditCertificationComponent implements OnInit {
  @Input() certificationResource!: CertificationResource;
  @Output() smDoneEvent = new EventEmitter<boolean>;
  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  alert: Alert = {} as Alert;
  certificationRequest: CertificationRequest = {} as CertificationRequest;
  activityAreas: ParameterResource[] = [];
  activityAreasString: string[] = [];
  schoolTypes: ParameterResource[] = [];
  schoolTypesString: string[] = [];
  validations: ValidationCondition[] = []
  processing: boolean = false

  constructor(private localStorageService: LocalStorageService,
              private certificationService: CertificationService,
              private toastrService: ToastrService,
              private parameterService: ParameterService) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
  }

  ngOnInit(): void {
    this.initValidation()
    this.populateRequest();
    this.fetchActivityAreas();
    this.fetchSchoolTypes();
  }

  populateRequest(): void {
    if (this.certificationResource) {
      this.certificationRequest.title = this.certificationResource.title!;
      this.certificationRequest.description = this.certificationResource.description!;
      this.certificationRequest.account = this.certificationResource.account!;
      this.certificationRequest.schoolName = this.certificationResource.schoolName!;
      this.certificationRequest.startDate = this.certificationResource.startDate!;
      this.certificationRequest.endDate = this.certificationResource.endDate!;
      this.certificationRequest.permanent = this.certificationResource.permanent!;
      this.certificationRequest.activityArea = this.certificationResource.activityArea;
      this.certificationRequest.schoolType = this.certificationResource.schoolType;
    }
  }

  getCertificationTitle(event: string): void {
    this.certificationRequest.title = event;
    this.checkTitle()
  }

  getCertificationSchoolName(event: string): void {
    this.certificationRequest.schoolName = event;
    this.checkSchoolName()
  }

  getActivityArea(event: string) {
    this.certificationRequest.activityArea = event;
    this.checkActivityArea()
  }

  getSchoolType(event: string): void {
    this.certificationRequest.schoolType = event;
    this.checkSchoolType()
  }

  getStartDate(event: string): void {
    this.certificationRequest.startDate = event;
    this.checkStartDate()
  }

  getEndDate(event: string): void {
    this.certificationRequest.endDate = event;
    this.checkEndDate()
  }

  getCertificationDescription(event: string): void {
    this.certificationRequest.description = event;
  }

  isChecked(event: boolean) {
    this.certificationRequest.permanent = event;
    this.checkEndDate()
  }

  initValidation() {
    this.validations = []
    this.validations.push(new ValidationCondition(true, 'TITLE', ''))
    this.validations.push(new ValidationCondition(true, 'SCHOOL', ''))
    this.validations.push(new ValidationCondition(true, 'DOMAIN', ''))
    this.validations.push(new ValidationCondition(true, 'SCHOOL_TYPE', ''))
    this.validations.push(new ValidationCondition(true, 'START', ''))
    this.validations.push(new ValidationCondition(true, 'END', ''))
  }

  updateValidation(code: string, newValue: boolean, message: string) {
    this.validations.forEach(value => {
      if (value.code === code) {
        value.valid = newValue
        value.message = message
      }
    })
  }

  getCssStatus(code: string): string {
    return this.validations.find(value => value.code === code)?.cssFormStatus()!
  }

  isValid(code: string) {
    return this.validations.find(value => value.code === code)?.valid!
  }

  errorMessage(code: string) {
    return this.validations.find(value => value.code === code)?.message!
  }

  checkFieldIsPresent(field?: string) {
    return field ? field.trim().length != 0 : false
  }

  checkTitle() {
    let condition = this.checkFieldIsPresent(this.certificationRequest.title)
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('TITLE', condition, message)
  }

  checkSchoolName() {
    let condition = this.checkFieldIsPresent(this.certificationRequest.schoolName)
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('SCHOOL', condition, message)
  }

  checkActivityArea() {
    let condition = this.checkFieldIsPresent(this.certificationRequest.activityArea)
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('DOMAIN', condition, message)
  }

  checkSchoolType() {
    let condition = this.checkFieldIsPresent(this.certificationRequest.schoolType)
    let message = condition ? '' : 'Ce champs est obligatoire'
    this.updateValidation('SCHOOL_TYPE', condition, message)
  }

  checkStartDate() {
    let condition = this.checkFieldIsPresent(this.certificationRequest.startDate)
    let message = condition ? '' : 'Ce champs est obligatoire'
    if (condition) {
      condition = this.checkDateIsInPast(this.certificationRequest.startDate)
      message = condition ? '' : 'Date invalide'
    }
    this.updateValidation('START', condition, message)
  }

  checkEndDate() {
    let condition
    let message
    switch (this.certificationRequest.permanent!) {
      case true:
        condition = true
        message = ''
        break
      case false:
        condition = this.checkFieldIsPresent(this.certificationRequest.endDate)
        message = condition ? '' : 'Ce champs est obligatoire'
        if (condition) {
          condition = this.checkEndDateAfterStartDate()
          message = condition ? '' : 'Date invalide'
        }
        break
    }
    this.updateValidation('END', condition, message)
  }

  checkAllFields() {
    this.checkTitle()
    this.checkSchoolName()
    this.checkActivityArea()
    this.checkSchoolType()
    this.checkStartDate()
    this.checkEndDate()
  }

  displayErrorAlert(message: string) {
    this.alert = {display: true, class: 'danger', title: 'Erreur', message: message}
  }

  iSFormValid() {
    this.checkAllFields()
    let result = true;
    this.validations.forEach(value => result = result && value.valid)
    if (!result) {
      this.displayErrorAlert("Certains champs sont invalides")
    }
    return result;
  }

  checkDateIsInPast(field?: string) {
    let now: Date = new Date();
    let tested: Date = new Date(field!)
    return now.getTime() > tested.getTime()
  }

  checkEndDateAfterStartDate() {
    let start = new Date(this.certificationRequest.startDate)
    let end = new Date(this.certificationRequest.endDate!)
    return end.getTime() > start.getTime()
  }

  fetchActivityAreas(): void {
    this.parameterService.findActivityAreas().subscribe({
      next: response => {
        this.activityAreas = response.resources!;
        this.activityAreasString = AccountHelper.parameterToStringList(this.activityAreas);
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchSchoolTypes(): void {
    this.parameterService.findSchoolTypes().subscribe({
      next: response => {
        this.schoolTypes = response.resources!;
        this.schoolTypesString = AccountHelper.parameterToStringList(this.schoolTypes);
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  formatDate(inputDate: string): string {
    if (inputDate) {
      const date = new Date(inputDate);
      const day = date.getUTCDate();
      const month = date.getUTCMonth() + 1; // Months are 0-based
      const year = date.getUTCFullYear();
      return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
    } else {
      return '';
    }
  }

  toStringList(parameters: ParameterResource[]): string[] {
    return AccountHelper.parameterToStringList(parameters);
  }

  onClickCancel(): void {
    this.smDoneEvent.emit(false);
    this.localStorageService.delete(variables.account_attribute);
  }

  onClickSave(): void {
    if (this.iSFormValid()) {
      this.certificationRequest.account = this.account.uuid!;
      if (this.certificationResource) {
        this.sendUpdateCertificationRequest();
      } else {
        this.sendCreateCertificationRequest();
      }
    }
  }

  private sendUpdateCertificationRequest() {
    this.processing = true
    this.certificationService.updateCertification(this.certificationResource.uuid!, this.certificationRequest).subscribe({
      next: response => {
        this.processing = false
        this.toastrService.success("La certification a été mise à jour avec succés", 'Mis à jour certification');
        this.smDoneEvent.emit(true);
      },
      error: error => {
        this.processing = false
        this.toastrService.error("Erreur lors de la mise à jour de la certification", 'Mis à jour certification');
      },
      complete: () => {
        this.processing = false
      }
    });
  }

  private sendCreateCertificationRequest() {
    this.processing = true
    this.certificationService.createCertification(this.certificationRequest).subscribe({
      next: response => {
        this.processing = false
        this.toastrService.success("La certification a été créée avec succés", 'Création certification');
        this.smDoneEvent.emit(true);
      },
      error: error => {
        this.processing = false
        this.toastrService.error("Erreur lors de la création de la certification", 'Création certification');
      },
      complete: () => {
        this.processing = false
      }
    });
  }
}
