import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sm-toggle-button',
  templateUrl: './toggle-button.component.html',
  styleUrls: ['./toggle-button.component.scss']
})
export class ToggleButtonComponent implements OnInit {

  @Input() title!: string;
  @Input() checked: boolean = false;
  @Output() smCheckEvent = new EventEmitter<boolean>();

  ngOnInit(): void {
  }


  onHandleChange(event: Event): void {
    this.smCheckEvent.emit(this.checked);
  }
}
