import {Component, Input, OnInit} from '@angular/core';
import {AccountDetailsResource} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {DateHelper} from "../../helpers/date-helper";
import {AccountHelper} from "../../helpers/account-helper";
import {ModalDismissReasons, NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'sm-profile-details',
  templateUrl: './profile-details.component.html',
  styleUrls: ['./profile-details.component.scss']
})
export class ProfileDetailsComponent implements OnInit {

  @Input() account!: AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  @Input() editMode: boolean = false;
  accountHelper = AccountHelper;
  closeResult: string = '';

  constructor(private localStorageService: LocalStorageService, private modalService: NgbModal) {
    this.initData()
  }

  ngOnInit() {
  }

  initData() {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
  }

  onClickProfile(account: AccountDetailsResource): void {
  }

  convertDate(date: string): string {
    return DateHelper.convertDate(date);
  }

  onClickDone(event: boolean): void {
    this.initData()
    this.editMode = event;
  }

  onClickUpdate(): void {
    this.editMode = true;
  }

  onClickDismissModal(): void {
    this.modalService.dismissAll();
  }

  open(content: any): void {
    if (this.loggedAccount.uuid === this.account.uuid) {
      this.modalService.open(content, {
        size: 'lg'
      }).result.then((result) => {
        this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  setDefault() {
    this.account.avatar = './assets/images/user.svg';
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
