<div class="row">
  <div class="col-12" style="margin-top: 20px;">
    <div class="row" style="">
      <div class="col-5 col-sm-5 col-md-6 col-lg-6 col-xl-6">
        <div (click)="open(modalProfileImage)" class="profile-img" style="margin: auto; padding-bottom: 0">
          <a>
            <img (error)="setDefault()" alt="" class="avatar" src="{{account.avatar}}"
                 style="border: white solid 2px;">
            <!--            <img src="{{account.avatar}}" alt="">-->
          </a>
          <div *ngIf="account.uuid === loggedAccount.uuid" class="avatar-edit"
               style="background-color: white; padding: 5px; border-radius: 50%; z-index: 99;">
            <img alt="" height="24" src="{{'edit-photo' | image}}" width="24">
          </div>
        </div>
      </div>
      <div class="col">
        <sm-image-text
          (click)="onClickUpdate('detail')"
          *ngIf="account.uuid === loggedAccount.uuid"
          [bgColor]="'white'"
          [color]="'#00ACED'"
          image="{{'user' | image}}"
          style="font-weight: bolder; font-size: x-large"
          text="{{'edit_profile.update_profile' | translate}}">
        </sm-image-text>
        <div style="font-size: x-large; color: #16152d;">
          <div>{{ account.firstname }}</div>
          <strong>{{ account.lastname }}</strong>
        </div>
        <div style=" color: #16152d;">
          <p>{{ 'edit_profile.gender' | translate }} : <strong>{{ account | gender | translate }}</strong></p>
          <p>{{ 'edit_profile.status' | translate }} : <strong>{{ account | status:'label' | translate }}</strong></p>
          <p>{{ 'edit_profile.birthdate' | translate }} : <strong>{{ convertDate(account.birthdate!) }}</strong></p>
          <p>{{ 'edit_profile.home_country' | translate }} : <strong>{{ account.country! | country }}</strong></p>
          <p>{{ 'edit_profile.home_city' | translate }} : <strong>{{ account.city }}</strong></p>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="account.uuid === loggedAccount.uuid" class="col-12">
    <sm-image-text
      (click)="onClickUpdate('biography')"
      [bgColor]="'white'"
      [color]="'#00ACED'"
      image="{{'user' | image}}" style="font-weight: bolder; font-size: x-large"
      text="{{'edit_profile.update_biography' | translate}}">
    </sm-image-text>
  </div>
  <div class="col-12 p-3">
    <sm-long-text-display [text]="account.biography"></sm-long-text-display>
  </div>

  <sm-cursus (smAddEvent)="onClickAdd($event)"
             (smUpdateEvent)="onClickUpdate($event)"
             *ngIf="jobResponse"
             [elements]="jobResponse"
             addText="{{'edit_profile.add' | translate}} {{'edit_profile.job' | translate}}"
             title="{{'edit_profile.jobs' | translate}}">
  </sm-cursus>
  <sm-cursus (smAddEvent)="onClickAdd($event)"
             (smUpdateEvent)="onClickUpdate($event)"
             *ngIf="trainingResponse"
             [elements]="trainingResponse"
             addText="{{'edit_profile.add' | translate}} {{'edit_profile.training' | translate}}"
             title="{{'edit_profile.trainings' | translate}}">
  </sm-cursus>
  <sm-cursus (smAddEvent)="onClickAdd($event)"
             (smUpdateEvent)="onClickUpdate($event)"
             *ngIf="diplomaResponse"
             [elements]="diplomaResponse"
             addText="{{'edit_profile.add' | translate}} {{'edit_profile.diploma' | translate}}"
             title="{{'edit_profile.diplomas' | translate}}">
  </sm-cursus>
  <sm-cursus (smAddEvent)="onClickAdd($event)"
             (smUpdateEvent)="onClickUpdate($event)"
             *ngIf="certificationResponse"
             [elements]="certificationResponse"
             addText="{{'edit_profile.add' | translate}} {{'edit_profile.certification' | translate}}"
             title="{{'edit_profile.certifications' | translate}}">
  </sm-cursus>
  <sm-cursus (smAddEvent)="onClickAdd($event)"
             (smUpdateEvent)="onClickUpdate($event)"
             *ngIf="skillResponse"
             [elements]="skillResponse"
             addText="{{'edit_profile.add' | translate}} {{'edit_profile.skill' | translate}}"
             title="{{'edit_profile.skills' | translate}}">
  </sm-cursus>
</div>

<ng-template #modalProfileImage let-modal>
  <sm-edit-avatar [account]="loggedAccount"></sm-edit-avatar>
</ng-template>
