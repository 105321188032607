import {Component, OnInit} from '@angular/core';
import {
  AccountDetailsResource,
  CertificationResponse,
  CertificationService,
  DiplomaResponse,
  DiplomaService,
  JobResponse,
  JobService,
  SkillResponse,
  SkillService,
  TrainingResponse,
  TrainingService
} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {Router} from "@angular/router";
import {Route} from "../../enums/route.enum";
import {variables} from "../../../environments/variables";

@Component({
  selector: 'sm-account-cursus',
  templateUrl: './account-cursus.component.html',
  styleUrls: ['./account-cursus.component.scss']
})
export class AccountCursusComponent implements OnInit {
  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  jobResponse: JobResponse = {} as JobResponse;
  trainingResponse: TrainingResponse = {} as TrainingResponse;
  diplomaResponse: DiplomaResponse = {} as DiplomaResponse;
  certificationResponse: CertificationResponse = {} as CertificationResponse;
  skillResponse: SkillResponse = {} as SkillResponse;

  constructor(private localStorageService: LocalStorageService,
              private router: Router,
              private jobService: JobService,
              private trainingService: TrainingService,
              private skillService: SkillService,
              private certificationService: CertificationService,
              private diplomaService: DiplomaService) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
  }

  ngOnInit() {
    this.fetchJobs();
    this.fetchTrainings();
    this.fetchDiplomas();
    this.fetchCertifications();
    this.fetchSkills();
  }

  onClickProfile(account: AccountDetailsResource): void {

  }

  fetchJobs() {
    this.jobService.findJobByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.jobResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchTrainings() {
    this.trainingService.findTrainingByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.trainingResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchDiplomas() {
    this.diplomaService.findDiplomaByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.diplomaResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchCertifications() {
    this.certificationService.findCertificationByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.certificationResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchSkills() {
    this.skillService.findSkillByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.skillResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  onClickAdd(event: string): void {
    this.localStorageService.add(variables.add, event);
    this.router.navigate([Route.EDIT_PROFILE]);
  }

  onClickUpdate(event: string): void {
    // this.localStorageService.add(variables.edit, event);
    this.router.navigate([Route.EDIT_PROFILE]);
  }
}
