<div class="row" style="margin: 0 50px 20px 50px">
  <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 assistance-question-mark-div" (click)="onClickFaq()">
    <div>
      <img
        src="{{ 'assistance-question-mark' | image }}"
        class="assistance-question-mark-img"
        alt="question mark"
      />
    </div>
    <div class="faq">faq</div>
  </div>
  <div class="col-12 col-sm-12 col-md-2 col-lg-2 col-xl-2" style="height: 10px;"></div>
  <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 assistance-contact-us-div" (click)="onClickContactUs()">
    <div>
      <img
        src="{{ 'assistance-headphone' | image }}"
        class="assistance-contact-us-img"
        alt="question mark people"
      />
    </div>
    <div class="contact-us">contactez-nous</div>
  </div>
  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6" style="width: 100%;">
      <div class="m-t-30 text-center">
        <sm-image-text
          (click)="onClickReturnButton()"
          [bgColor]="'#D3D3D3'"
          image="{{'left-arrow' | image}}"
          text="{{'assistance.return' | translate}}">
        </sm-image-text>
      </div>
  </div>
</div>
