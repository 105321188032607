import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sm-info-text',
  templateUrl: './info-text.component.html',
  styleUrls: ['./info-text.component.scss']
})
export class InfoTextComponent implements OnInit {
  public screenWidth: number = window.innerWidth;
  @Input() text: string = "";

  constructor() {
  }

  ngOnInit(): void {
  }
}
