import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MediaType} from "../../models/mediaType";
import {variables} from "../../../environments/variables";
import {Route} from "../../enums/route.enum";
import {LocalStorageService} from "../../services/local-storage.service";
import {Router} from "@angular/router";
import {AccountDetailsResource} from "ApiModuleAccount";
import {PostDetailsResource, PostService, ProfileDocumentsResource, SearchParam} from "ApiModulePost";
import {MediaHelper} from "../../helpers/media-helper";

@Component({
  selector: 'sm-search-results-tile',
  templateUrl: './search-results-tile.component.html',
  styleUrls: ['./search-results-tile.component.scss']
})
export class SearchResultsTileComponent implements OnInit {
  @Input() accounts?: AccountDetailsResource[];
  @Input() posts?: PostDetailsResource[];
  @Input() mediaType!: MediaType;
  @Input() expand: boolean = false;
  @Input() total?: number;
  @Input() displayAllResults!: boolean;
  @Output() smMoreResultsEvent = new EventEmitter<MediaType>();
  headTileImage: string = "arrow-open-up";

  page = 1;
  maxSize = 5;
  collectionSize = 1
  perPage = 6;
  calculateTotalPages = this.collectionSize / this.perPage;
  loading: boolean = false;
  screenWidth!: number;

  isBottomSheetOpened: boolean = false;
  profileDocuments!: ProfileDocumentsResource;
  searchParam: SearchParam = {} as SearchParam;

  constructor(private localStorageService: LocalStorageService, private router: Router, private postService: PostService) {
    this.screenWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.updatePagination();
  }

  onClickTitle(): void {
    this.expand = !this.expand;
    this.headTileImage = this.expand ? "arrow-open-down" : "arrow-open-up";
  }

  onClickProfile(account: AccountDetailsResource): void {
    this.localStorageService.add(variables.profile_account, account);
    this.router.navigate([Route.PROFILE]);
  }

  onClickPost(post: PostDetailsResource): void {
    this.localStorageService.add(variables.post_details, post);
    this.localStorageService.add(variables.previous_route, Route.SEARCH);
    this.router.navigate([Route.POST_DETAILS], {queryParams: {id: post.uuid, more: true}});
  }

  onClickMoreResults(mediaType: MediaType): void {
    this.updatePagination();
    this.smMoreResultsEvent.emit(mediaType);
  }

  loadPage(event: number): void {
    this.page = event;
  }

  updatePagination(): void {
    this.page = 1;
    if (this.accounts && this.accounts.length > 0) {
      this.collectionSize = this.accounts.length;
    }

    if (this.posts && this.posts.length > 0) {
      this.collectionSize = this.posts.length;
    }
    this.calculateTotalPages = this.collectionSize / this.perPage;
  }

  showCarousel(): boolean {
    return this.screenWidth < 800 && this.displayAllResults;
  }

  onClickOpenFilters(): void {
    this.isBottomSheetOpened = !this.isBottomSheetOpened;
  }

  onApplyFilters(searchParam: SearchParam) {
    this.searchParam = searchParam;
    this.isBottomSheetOpened = false;
    this.researchProfileDocuments();
  }

  filterProfileDocuments(): void {
    if (this.mediaType.code == MediaHelper.mediaTypes[0].code) {
      this.posts = this.profileDocuments.documents!;
      this.total = this.profileDocuments.totalDocuments!;
    } else if (this.mediaType.code == MediaHelper.mediaTypes[1].code) {
      this.posts = this.profileDocuments.videos!;
      this.total = this.profileDocuments.totalVideos!;
    } else if (this.mediaType.code == MediaHelper.mediaTypes[2].code) {
      this.posts = this.profileDocuments.links!;
      this.total = this.profileDocuments.totalLinks!;
    } else if (this.mediaType.code == MediaHelper.mediaTypes[3].code) {
      this.posts = this.profileDocuments.images!;
      this.total = this.profileDocuments.totalImages!;
    } else if (this.mediaType.code == MediaHelper.mediaTypes[4].code) {
      this.posts = this.profileDocuments.audios!;
      this.total = this.profileDocuments.totalAudios!;
    } else {
      this.posts = this.profileDocuments.documents!;
      this.total = this.profileDocuments.totalDocuments!;
    }
  }

  researchProfileDocuments(): void {
    this.postService.researchDocuments(0, 100, this.searchParam).subscribe({
      next: response => {
        this.profileDocuments = response.resource!;
        this.filterProfileDocuments();
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }
}
