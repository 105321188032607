<div class="header header-box-shadow">
  <div class="row" style="margin: auto;">
    <div class="col-6 col-sm-6 col-md-3 col-lg-2 col-xl-2">
      <a [routerLink]="[Route.DASHBOARD]" class="logo" style="text-decoration: none;">
        <img alt="" height="32" src="{{ 'symbole-skills-mates' | image }}" style="border-radius: 1px"/>
        <span class="skillsmates-single-line" style="color: #16152d; font-weight: bolder; margin-left: 0;">
          {{ 'sm.app_name' | translate }}
        </span>
      </a>
    </div>

    <!--Accueil, Messages, Notif...-->
    <div class="col-3 col-sm-3 col-md-7 col-lg-8 col-xl-8">
      <div class="row" style="padding-top: 10px;">
        <!-- Search-->
        <div (click)="onClick('search')" (mouseenter)="onMouseEnter('search')" (mouseleave)="onMouseLeave('search')"
             [ngClass]="{ activeheader: getTab() === Route.SEARCH }"
             [routerLink]="[Route.SEARCH]"
             class="col no-margin-padding topnav-header sm-menu-item" data-placement="top" data-toggle="tooltip"
             id="search" style="height: 60px;" title="Recherche">

          <div class="text-center" style="cursor: pointer;">
                <span class="" style="display: inline-block;">
                  <img alt="" class="rounded float-right icon-img" height="32" src="{{ getSearchIcon() | image }}"/>
                </span>
            <p class="skillsmates-single-line" style="font-size: x-small;"><strong>Rechercher</strong></p>
          </div>
        </div>
        <!-- Accueil-->
        <div (click)="onClick('home')" (mouseenter)="onMouseEnter('home')" (mouseleave)="onMouseLeave('home')"
             [ngClass]="{ activeheader: getTab() === Route.DASHBOARD }"
             [routerLink]="[Route.DASHBOARD]"
             class="col no-margin-padding topnav-header sm-menu-item" data-placement="top" data-toggle="tooltip"
             id="home" style="height: 60px;" title="Accueil">

          <div class="text-center" style="cursor: pointer;">
                <span class="" style="display: inline-block;">
                  <img alt="" class="rounded float-right icon-img" src="{{ getHomeIcon() | image }}"/>
                </span>
            <p class="skillsmates-single-line" style="font-size: x-small;"><strong>Accueil</strong></p>
          </div>
        </div>
        <!-- Messages-->
        <div (click)="onClick('messages')" (mouseenter)="onMouseEnter('message')"
             (mouseleave)="onMouseLeave('messages')"
             [ngClass]="{ activeheader: getTab() === Route.MESSAGES}"
             [routerLink]="[Route.MESSAGES]"
             class="col no-margin-padding topnav-header" data-placement="top"
             data-toggle="tooltip"
             id="message" style="height: 60px;" title="Messages">

          <div class="text-center" style="cursor: pointer;">
                <span class="" style="display: inline-block;">
                  <img alt="" class="rounded float-right icon-img"
                       height="32" src="{{ getMessageIcon() | image }}" style="vertical-align: center;"/>
                    <span *ngIf="loggedAccount && loggedAccount.messages && loggedAccount.messages > 0"
                          class="badge badge-pill bg-danger float-right badge-border"
                          style="color: white;">{{ loggedAccount.messages }}
                    </span>
                </span>
            <p class="skillsmates-single-line sm-menu-item" style="font-size: x-small;"><strong>Messages</strong></p>
          </div>

        </div>
        <!--Notifications-->
        <div (click)="onClick('bell')" (mouseenter)="onMouseEnter('bell')" (mouseleave)="onMouseLeave('bell')"
             [ngClass]="{ activeheader: getTab() === Route.NOTIFICATIONS}"
             [routerLink]="[Route.NOTIFICATIONS]"
             class="col no-margin-padding topnav-header"
             data-placement="bottom" data-toggle="tooltip" id="bell"
             style="height: 60px;" title="Notifications">

          <div class="text-center" style="cursor: pointer;">
                <span class="image-container" style="display: inline-block;">
                  <img alt="" class="rounded float-right icon-img background-image"
                       src="{{ getNotificationIcon() | image }}"/>
                    <span *ngIf="loggedAccount && loggedAccount.notifications && loggedAccount.notifications > 0"
                          class="badge badge-pill bg-danger float-right badge-border overlay-image"
                          style="color: white;">{{ loggedAccount.notifications }}
                    </span>
                </span>
            <p class="skillsmates-single-line sm-menu-item" style="font-size: x-small;"><strong>Notifications</strong>
            </p>
          </div>
        </div>
        <!-- network -->
        <div (click)="onClick('network')" (mouseenter)="onMouseEnter('network')" (mouseleave)="onMouseLeave('network')"
             [ngClass]="{ activeheader: getTab() === Route.NETWORK}"
             [routerLink]="[Route.NETWORK]"
             class="col no-margin-padding topnav-header sm-menu-item" data-placement="top"
             data-toggle="tooltip"
             id="network" style="height: 60px;" title="Réseau">

          <div class="text-center" style="cursor: pointer;">
                <span class="image-container" style="display: inline-block;">
                  <img alt="" class="rounded float-right icon-img background-image"
                       src="{{ getNetworkIcon() | image }}"/>
                    <span *ngIf="loggedAccount && loggedAccount.followers && loggedAccount.followers > 0"
                          class=" badge badge-pill bg-danger float-right badge-border overlay-image"
                          style="color: white;">{{ loggedAccount.followers }}
                    </span>
                </span>
            <p class="skillsmates-single-line" style="font-size: x-small;"><strong>Réseau</strong></p>
          </div>

        </div>
        <!-- Plus-->
        <div (click)="onClickEditPost('m')" (mouseenter)="onMouseEnter('plus')" (mouseleave)="onMouseLeave('plus')"
             [ngClass]="{ activeheader: getTab() === Route.EDIT_POST }"
             class="col no-margin-padding topnav-header sm-menu-item" data-placement="top"
             data-toggle="dropdown"
             id="plus" style="height: 60px;" title="Publication">

          <div class="text-center" style="cursor: pointer;">
                <span class="" style="display: inline-block;">
                  <img alt="" class="rounded float-right icon-img" src="./assets/images/plus-colored.png"/>
                </span>
            <p class="skillsmates-single-line" style="font-size: x-small;"><strong>Publication</strong></p>
          </div>
        </div>
      </div>
    </div>

    <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
      <ul class="nav user-menu float-right">
        <li
          [ngClass]="{ topnav: !dropdownProfileMenu && (getTab() === Route.PROFILE || getTab() === Route.SETTINGS || getTab() === Route.ASSISTANCE)}"
          class="nav-item dropdown">
          <a (click)="onClickProfile()"
             [ngClass]="{ activeheader: !dropdownProfileMenu && (getTab() === Route.PROFILE || getTab() === Route.SETTINGS || getTab() === Route.ASSISTANCE)}"
             class="dropdown-toggle nav-link user-link collapsed"
             data-bs-toggle="collapse"
             data-bs-toggle="dropdown"
             href="#" style="height: 70px">

            <div class="row">
              <div class="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4">
              <span *ngIf="loggedAccount" class="user-img" style="">
<!--              <img class="rounded-circle" src="{{ loggedAccount.avatar }}" style="background-color: whitesmoke"-->
                <!--                   width="24" height="24" alt="{{ loggedAccount.lastname }}"/>-->

              <sm-image
                class="rounded-circle"
                default="{{'user' | image}}"
                image="{{ loggedAccount.avatar }}">
              </sm-image>
                <!--              <span class="status online"></span>-->
            </span>
              </div>
              <div class="col sm-menu-item no-margin-padding">
                          <span *ngIf="loggedAccount" class="text-center"
                                style="color: #16152d; font-weight: bolder;">{{ name() }}</span>
              </div>

              <div class="col-1 sm-menu-item no-margin-padding">
                <em *ngIf="screenWidth > 800" class="fa fa-chevron-circle-right pull-right sm-menu-item"
                    style="padding-top: 55px; margin-left: 30px; color: #16152d;">
                </em>
              </div>
            </div>

          </a>

          <!-- modal action on my compte -->
          <div *ngIf="screenWidth > 800" class="dropdown-menu header-profil-content"
               style="padding-left: 8px; padding-right: 8px;">
            <a (click)="onClickProfileMenu('profile')" [routerLink]="[Route.PROFILE]"
               class="dropdown-item skillsmates-dropdown-menu plus-border">
              <img alt="" class="img-menu-dropdown" src="{{ 'profile' | image }}"/>
              Mon profil
            </a>
            <a (click)="onClickProfileMenu('settings')" [routerLink]="[Route.SETTINGS]"
               class="dropdown-item skillsmates-dropdown-menu plus-border">
              <img alt="" class="img-menu-dropdown" src="{{ 'settings' | image }}"/>
              Paramètres
            </a>
            <a (click)="onClickProfileMenu('assistance')" [routerLink]="[Route.ASSISTANCE]"
               class="dropdown-item skillsmates-dropdown-menu plus-border">
              <img alt="" class="img-menu-dropdown" src="{{ 'assistance' | image }}"/>
              Assistance
            </a>
            <a (click)="onClickProfileMenu('logout')" [routerLink]="[Route.PROFILE]"
               class="dropdown-item skillsmates-dropdown-menu plus-border">
              <img alt="" class="img-menu-dropdown" src="{{ 'logout' | image }}"/>
              Déconnexion
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <!--  <div class="row" *ngIf="!loggedAccount.active">-->
  <!--    <div class="col-12 text-center" style="background-color: red; height: 20px; color: white;">-->
  <!--      <span class="skillsmates-single-line">-->
  <!--        Votre compte expire dans <strong>{{remainingTrialPeriod()}}</strong> jours, veuillez cliquer <a>ici</a> pour valider votre compte.-->
  <!--      </span>-->
  <!--    </div>-->
  <!--  </div>-->
</div>

