import {BaseModel} from "./responses/baseModel";

export class MediaType extends BaseModel {
  constructor() {
    super();
  }

  code!: string;
  label!: string;
  color!: string;
  picture!: string;
  image!: string;
  description!: string;
  subtitle!: string;
}
