<div class="row">
  <div class="col-12">
    <sm-image-text
      *ngIf="account.uuid === loggedAccount.uuid"
      (click)="onClickAdd('biography')"
      image="{{'plus-square-color' | image}}"
      text="{{'edit_profile.update_biography' | translate}}"
      [bgColor]="'white'"
      [color]="'#00ACED'">
    </sm-image-text>
  </div>
  <div class="col-12 p-3">
    <sm-long-text-display [text]="account.biography"></sm-long-text-display>
  </div>

  <sm-cursus *ngIf="jobResponse"
             [elements]="jobResponse"
             (smAddEvent)="onClickAdd($event)"
             (smUpdateEvent)="onClickUpdate($event)"
             addText="{{'edit_profile.add' | translate}} {{'edit_profile.job' | translate}}"
             title="{{'edit_profile.jobs' | translate}}">
  </sm-cursus>
  <sm-cursus *ngIf="trainingResponse"
             [elements]="trainingResponse"
             (smAddEvent)="onClickAdd($event)"
             addText="{{'edit_profile.add' | translate}} {{'edit_profile.training' | translate}}"
             title="{{'edit_profile.trainings' | translate}}">
  </sm-cursus>
  <sm-cursus *ngIf="diplomaResponse"
             [elements]="diplomaResponse"
             (smAddEvent)="onClickAdd($event)"
             addText="{{'edit_profile.add' | translate}} {{'edit_profile.diploma' | translate}}"
             title="{{'edit_profile.diplomas' | translate}}">
  </sm-cursus>
  <sm-cursus *ngIf="certificationResponse"
             [elements]="certificationResponse"
             (smAddEvent)="onClickAdd($event)"
             addText="{{'edit_profile.add' | translate}} {{'edit_profile.certification' | translate}}"
             title="{{'edit_profile.certifications' | translate}}">
  </sm-cursus>
  <sm-cursus *ngIf="skillResponse"
             [elements]="skillResponse"
             (smAddEvent)="onClickAdd($event)"
             addText="{{'edit_profile.add' | translate}} {{'edit_profile.skill' | translate}}"
             title="{{'edit_profile.skills' | translate}}">
  </sm-cursus>
</div>
