import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {

  constructor(public router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    // if (this.authenticationService.isAuthenticated()) {
    //   return true;
    // }
    // this.router.navigate(['/']);
    // return false;

    return true;
  }
}
