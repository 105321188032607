<div class="form-group dropdown">
  <label *ngIf="title" class="text-info" for="cbe-select">{{ title }}</label>
  <strong *ngIf="mandatory" class="text-danger" style="font-size: larger;">*</strong>
  <select (change)="onChange($event)"
          [ngClass]="{'is-valid': inputStatus=='valid','is-invalid': inputStatus=='invalid'}" [ngModel]="selected"
          class="form-control"
          id="cbe-select">
    <option *ngFor="let s of list"
            [selected]="s.trim() == selected.trim()">
      {{ s }}
    </option>
  </select>
  <small *ngIf="showErrorMessage" class="text-danger">{{ errorMessage }}</small>
</div>
