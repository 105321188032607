import {Pipe, PipeTransform} from '@angular/core';
import {AccountDetailsResource} from "ApiModuleAccount";
import {Gender} from "../enums/gender.enum";

@Pipe({
    name: 'gender'
})
export class GenderPipe implements PipeTransform {

    transform(account: AccountDetailsResource): string {
        if (account && account.gender) {
            if (account.gender === Gender[Gender.MALE]) {
                return 'sm.male';
            } else if (account.gender === Gender[Gender.FEMALE]) {
                return 'sm.female';
            } else {
                return '';
            }
        }
        return '';
    }

}
