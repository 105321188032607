import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {LocalStorageService} from "../../services/local-storage.service";

@Component({
  selector: 'app-account-created',
  templateUrl: './account-created.component.html',
  styleUrls: ['./account-created.component.scss']
})
export class AccountCreatedComponent implements OnInit {
  BASE_URL!: string;
  FACEBOOK_LINK!: string;
  INSTAGRAM_LINK!: string;
  TWITTER_LINK!: string;
  SNAPCHAT_LINK!: string;
  TIKTOK_LINK!: string;
  CURRENT_YEAR!: number
  public screenWidth: number = window.innerWidth;

  constructor(private router: Router, private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.CURRENT_YEAR = new Date().getFullYear()
    this.BASE_URL = environment.ms_resources_baseurl;
    this.FACEBOOK_LINK = environment.ms_resources_facebook;
    this.INSTAGRAM_LINK = environment.ms_resources_instagram;
    this.TWITTER_LINK = environment.ms_resources_twitter;
    this.SNAPCHAT_LINK = environment.ms_resources_snapchat;
    this.TIKTOK_LINK = environment.ms_resources_tiktok;
  }

  onClickCGU(): void {
    localStorage.setItem('url', this.router.url);
  }
}
