<div  *ngIf="post && !isShared">
  <div class="bg-white skillsmates-box-shadow">
    <div *ngTemplateOutlet="thenPost"></div>
  </div>
</div>

<div *ngIf="post && isShared">
  <div class="bg-white skillsmates-box-shadow-shared">
    <div *ngTemplateOutlet="thenPost"></div>
  </div>
</div>

<ng-template #thenPost>
  <div style="padding: 5px 20px 5px 5px;">
    <sm-post-header
      class="pointer"
      [post]="post"
      [isSharedPost]="isSharedPost"
    >
    </sm-post-header>
  </div>
  <sm-post-media [post]="post"></sm-post-media>
  <br>
  <div style="padding-right: 20px; padding-left: 20px;" class="pointer">
    <sm-post-description [post]="post"></sm-post-description>

    <!--    <hr *ngIf="!post.shares">-->

    <!--    <div class="row" *ngIf="!post.shared">-->
    <div class="row" *ngIf="true" style="margin-bottom: 10px;">
      <div class="col-12  skillsmates-single-line" style="text-align: right;">
        <span *ngIf="post">
          <span (click)="onClickDisplayLikes()" [ngClass]="{'active': displayInteractionBlock === 'INTERACTION_TYPE_LIKE'}"><strong>{{post.likes}}</strong> j'aime &nbsp;</span>&nbsp;
          <span (click)="onClickDisplayComments()" [ngClass]="{'active': displayInteractionBlock === 'INTERACTION_TYPE_COMMENT'}"><strong>{{post.comments}}</strong> commentaires</span>&nbsp;
          <span (click)="onClickDisplayShares()"><strong>{{post.shares}}</strong> partages</span>&nbsp;
        </span>
      </div>
    </div>

    <sm-horizontal-line [color]="'#DADADA'"></sm-horizontal-line>
    <!--    <div class="row" *ngIf="!post.shared">-->
    <div class="row" *ngIf="true" style="padding-top: 5px; padding-bottom: 5px;">
      <div class="col-4 widget-social skillsmates-single-line text-center" (click)="onClickLike()">
        <span *ngIf="post">
          <img
            src="{{post.liked ? './assets/images/liked.svg' : './assets/images/like.svg'}}"
            class="m-r-5 svg imgSize" alt="like"> <span class="textWidth">{{'post.like' | translate}}</span>
        </span>
      </div>
      <div class="col-4 widget-social skillsmates-single-line text-center" (click)="onClickComment()">
        <span *ngIf="post">
          <img
            src="{{post && post.comments && post.comments > 0 ? './assets/images/comment-active.svg' : './assets/images/comment-inactive.svg'}}"
            class="m-r-5 svg imgSize" alt="comment"> <span class="textWidth">{{'post.comment' | translate}}</span>
        </span>
      </div>
      <div class="col-4 widget-social skillsmates-single-line text-center" (click)="onClickShare()">
        <span>
          <img
            src="{{post.shares ? './assets/images/share-active.svg' : './assets/images/share-inactive.svg'}}"
            class="m-r-5 svg imgSize" alt="share"> <span class="textWidth">{{'post.share' | translate}}</span>
        </span>
        <sm-processing></sm-processing>
      </div>
    </div>

    <sm-horizontal-line [color]="'#DADADA'"></sm-horizontal-line>

    <div class="row" style="margin-top: 10px;" *ngIf="displayInteractionBlock === 'INTERACTION_TYPE_COMMENT'">
      <div class="form-group col-10 col-sm-10 col-md-10 col-lg-11 col-xl-11" *ngIf="loggedAccount">
        <textarea autosize Style="background: #F0F2F5; min-height: 60px;" class="form-control" autocomplete="off"
                  autofocus="" name="title"
                  rows="autosize"
                  (keyup)="keyupComment($event)"
                  [ngClass]="{ 'is-invalid': false, 'is-valid': typedComment }"
                  placeholder="Ajouter un commentaire ..." required="required" maxlength="{{maxCommentSize}}"
                  [(ngModel)]="typedComment">
        </textarea>
        <!--        <small class="text-danger" *ngIf="errors.body && !typedComment">{{errors.body}}</small>-->
        <small class="text-info float-right" *ngIf="typedComment">
          {{this.typedComment.length}} / {{this.maxCommentSize}}
        </small>
      </div>
      <div class="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1" *ngIf="loggedAccount">
        <img width="28" height="28" src="{{'send-message' | image}}" class="m-r-5" alt="send-message"
             style="float: right; cursor: pointer; padding-top: 5px;" (click)="onClickPublishComment()">
        <sm-processing [loading]="loading"></sm-processing>
      </div>
    </div>

    <!--    <sm-post-comments *ngIf="displayCommentsBlock" [post]="post"></sm-post-comments>-->
    <sm-post-interactions
      *ngIf="displayInteractionBlock === 'INTERACTION_TYPE_COMMENT'" [post]="post"
      [interactionType]="'INTERACTION_TYPE_COMMENT'">
    </sm-post-interactions>
    <sm-post-interactions
      *ngIf="displayInteractionBlock === 'INTERACTION_TYPE_LIKE'" [post]="post"
      [interactionType]="'INTERACTION_TYPE_LIKE'">
    </sm-post-interactions>
    <sm-post-interactions
      *ngIf="displayInteractionBlock === 'INTERACTION_TYPE_SHARE'" [post]="post"
      [interactionType]="'INTERACTION_TYPE_SHARE'">
    </sm-post-interactions>
  </div>
</ng-template>

<ng-template #content let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"><b>{{post.title}}</b></h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click')"></button>
  </div>
  <div class="modal-body">
    <sm-textarea
      title="{{'post.share' | translate}}"
      placeholder="{{'post.share' | translate}}"
      [value]="''"
      (smInputEvent)="getShareDescription($event)">
      [rows]="8">
    </sm-textarea>

    <sm-post-media [post]="post"></sm-post-media>
  </div>
  <div class="modal-footer">
    <sm-button [type]="'success'" text="{{'post.share' | translate}}" (click)="onClickPublishShare()"></sm-button>
  </div>
</ng-template>
