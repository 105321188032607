<div class="row" style=" color: #16152d; margin-bottom: 20px;">
  <div class="col-12 col-sm-12 col-md-7 col-lg-7 col-xl-7" style="margin: auto;">
    <span><a href="#" class="footer-text pointer">{{ 'login.about' | translate }}</a></span> -
    <span><a href="#" class="footer-text pointer">{{ 'login.help' | translate }}</a></span> -
    <span><a href="#" class="footer-text pointer">{{ 'login.confidentiality_policy' | translate }}</a></span> -
    <span><a (click)="onClickCGU()"
             class="footer-text pointer">{{ 'login.terms_and_conditions' | translate }}</a></span> -
    <span><a (click)="onClickFAQ()" class="footer-text pointer">{{ 'login.faq' | translate }}</a></span>
  </div>
  <div class="col-12 col-sm-12 col-md-5 col-lg-5 col-xl-5" style="color: #16152d;">
    © <strong><a routerLink="/" target="_blank" class="std">{{'sm.app_name' | translate}}</a></strong>.<strong><span
    style="color: #000;"> {{year}}
    Version : </span></strong> <strong style="color:#F00;">{{version}}</strong>
  </div>
</div>
