import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private httpClient: HttpClient) {
  }

  downloadImage(url: string): Observable<Blob> {
    return this.httpClient.get(url, {
      responseType: "blob"
    });
  }

  extractLink(url: string): Observable<any> {
    return this.httpClient.get("https://jsonlink.io/api/extract?url=" + url);
  }
}
