<div class="row skillsmates-container">
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 sm-side-menu">
    <div class="row header-filter">
      <div class="col-3"><img class="" style="height: 24px; width: 24px; " src="{{'filter' | image}}" alt=""></div>
      <div class="col-9 skillsmates-primary-color"><strong>FILTRES</strong></div>
    </div>
    <sm-horizontal-line [height]="5" [color]="'#16152D'"></sm-horizontal-line>
    <div class="row bg-white" style="margin: auto;  border-radius: 0 0 10px 10px; background-color: white;">
      <ul style="list-style: none; margin: 10px 0 10px 0">
        <sm-collapsible-tile
          class="p-3 pointer"
          *ngFor="let mediaType of mediaTypes"
          (click)="onClickCollapsibleTile(mediaType)"
          (smItemEvent)="onClickItem($event, mediaType)"
          [bgColor]="selectedMediaType.code === mediaType.code ? mediaType.color+'11' : 'white'"
          [checkedItems]="getSelectedOptionsLabel()"
          [isActive]="selectedMediaType.code === mediaType.code"
          [title]="mediaType.label"
          [image]="mediaType.picture"
          [list]="getOptionsLabel(mediaType)">
        </sm-collapsible-tile>
      </ul>
    </div>
  </div>
  <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9" style="background-color: {{selectedMediaType.color}}05;">

    <div class="row skillsmates-primary-color m-t-10">
      <div class="col-6 col-sm-6 col-md-6 col-lg-8 col-xl-9" style="">
        <strong>{{ total }} {{ selectedMediaType.label }} trouvés</strong>
      </div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 justify-content-end" style="">
        <a class="text-right pointer" style="text-decoration: none; color: #16152d;" (click)="onClickProfile()">
          <!--          <img class="avatar" src="{{account.avatar}}" alt="">-->
          <sm-image image="{{account.avatar}}" default="{{'user'|image}}" [class]="'avatar'"
                    [imageSize]="40"></sm-image>
          <b class="">{{ name(account) }}</b>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-9">
        <sm-input placeholder="{{'search.research' | translate}}" (smInputEvent)="getSearchText($event)"></sm-input>
      </div>
      <div class="col-3" style="margin: auto;">
        <sm-button text="{{'search.research' | translate}}" [type]="'info'"
                   (click)="researchProfileDocuments()"></sm-button>
      </div>
    </div>

    <br>
    <div class="row">
      <div class="col-5 justify-content-start">
        <nav class="">
          <select name="perPageTop" id="perPageTop" [(ngModel)]="perPage">
            <option value="6">6</option>
            <option value="12">12</option>
            <option value="18">18</option>
            <option value="24">24</option>
          </select> /page
        </nav>
      </div>
      <div class="col-7 justify-content-end">
        <nav class="">
          <ngb-pagination
            (pageChange)="loadPage($event)"
            [disabled]="false" [collectionSize]="total" [(page)]="page" [maxSize]="maxSize"
            [rotate]="true" [pageSize]="perPage" [ellipses]="false" [boundaryLinks]="true" size="sm">
            <ng-template ngbPaginationFirst><i class="fa fa-angle-double-left"></i></ng-template>
            <ng-template ngbPaginationPrevious><i class="fa fa-angle-left"></i></ng-template>
            <ng-template ngbPaginationNext><i class="fa fa-angle-right"></i></ng-template>
            <ng-template ngbPaginationLast><i class="fa fa-angle-double-right"></i></ng-template>
          </ngb-pagination>
        </nav>
      </div>
      <sm-processing [loading]="loading"></sm-processing>
      <div class="col-12" *ngIf="documents && documents.length > 0">
        <sm-document-tile
          *ngFor="let post of documents.slice((page-1)*perPage, (page)*perPage)" [post]="post">
        </sm-document-tile>
      </div>

      <div class="col-12" *ngIf="documents.length <= 0">
        <p class="skillsmates-title text-center" style="font-size: xx-large;">
          <strong>{{ 'search.no_result_found' | translate }}</strong>
        </p>
        <div class="no-result-image">
          <img src="{{'no-result-documents' | image}}" height="20%">
        </div>
      </div>
      <div class="col-5 justify-content-start">
        <nav class="">
          <select name="perPageTop" id="perPageBottom" [(ngModel)]="perPage">
            <option value="6">6</option>
            <option value="12">12</option>
            <option value="18">18</option>
            <option value="24">24</option>
          </select> /page
        </nav>
      </div>
      <div class="col-7 justify-content-end">
        <nav class="">
          <ngb-pagination
            (pageChange)="loadPage($event)"
            [disabled]="false" [collectionSize]="total" [(page)]="page" [maxSize]="maxSize"
            [rotate]="true" [pageSize]="perPage" [ellipses]="false" [boundaryLinks]="true" size="sm">
            <ng-template ngbPaginationFirst><i class="fa fa-angle-double-left"></i></ng-template>
            <ng-template ngbPaginationPrevious><i class="fa fa-angle-left"></i></ng-template>
            <ng-template ngbPaginationNext><i class="fa fa-angle-right"></i></ng-template>
            <ng-template ngbPaginationLast><i class="fa fa-angle-double-right"></i></ng-template>
          </ngb-pagination>
        </nav>
      </div>
    </div>
  </div>
</div>
