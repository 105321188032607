import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  AccountDetailsResource,
  CertificationService,
  DiplomaRequest,
  DiplomaService,
  JobService,
  SkillRequest,
  SkillResource,
  SkillService,
  TrainingService
} from "ApiModuleAccount";
import {variables} from "../../../environments/variables";
import {LocalStorageService} from "../../services/local-storage.service";
import {DateHelper} from "../../helpers/date-helper";
import CategoryEnum = SkillRequest.CategoryEnum;
import LevelEnum = SkillResource.LevelEnum;
import EducationEnum = DiplomaRequest.EducationEnum;

@Component({
  selector: 'sm-cursus-tile',
  templateUrl: './cursus-tile.component.html',
  styleUrls: ['./cursus-tile.component.scss']
})
export class CursusTileComponent implements OnInit {

  @Input() type!: string;
  @Input() resource: any;
  @Input() image!: string;
  @Output() smUpdateEvent = new EventEmitter<string>;
  @Output() smDoneEvent = new EventEmitter<string>;
  title!: string;
  subtitle!: string;
  details!: string;
  firstDescription!: string;
  secondDescription!: string;
  thirdDescription!: string;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  account: AccountDetailsResource = {} as AccountDetailsResource;

  constructor(private localStorageService: LocalStorageService,
              private skillService: SkillService,
              private jobService: JobService,
              private trainingService: TrainingService,
              private diplomaService: DiplomaService,
              private certificationService: CertificationService) {
  }

  ngOnInit() {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.get(variables.profile_account);
    this.processResource();
  }

  processResource(): void {
    this.title = this.resource.title!;
    switch (this.type) {
      case 'job':
        if ("company" in this.resource) {
          this.subtitle = this.resource.company + " - " + this.resource.city;
        }
        this.details = "Periode : ";
        if ("startDate" in this.resource) {
          this.details += this.resource.startDate ? this.convertDate(this.resource.startDate) : "";
        }
        if ("endDate" in this.resource) {
          this.details += this.resource.endDate ? " - " + this.convertDate(this.resource.endDate) : "";
        }
        this.firstDescription = "Secteur d'activité : ";
        if ("activityArea" in this.resource) {
          this.firstDescription += this.resource.activityArea ? this.resource.activityArea.resource!.label : "";
        }
        if ("activitySector" in this.resource) {
          this.firstDescription += this.resource.activitySector ? " - " + this.resource.activitySector.resource!.label : "";
        }
        this.secondDescription = "Description: ";
        this.secondDescription += this.resource.description ? this.resource.description : "";
        break;
      case 'training':
        this.subtitle = "Etablissement : ";
        if ("schoolName" in this.resource) {
          this.subtitle += this.resource.schoolName ? this.resource.schoolName : "";
        }
        if ("city" in this.resource) {
          this.subtitle += this.resource.city ? " - " + this.resource.city : "";
        }
        this.details = "Etude : ";
        if ("education" in this.resource) {
          this.details += this.resource.education ? this.getEducation(this.resource.education) : "";
        }
        this.details += " - Niveau : ";
        this.readEducationLevelLabel(true, false)
        this.secondDescription = "Description: ";
        this.secondDescription += this.resource.description ? this.resource.description : "";
        break;
      case 'diploma':
        this.subtitle = "Etablissement : ";
        if ("schoolName" in this.resource) {
          this.subtitle += this.resource.schoolName ? this.resource.schoolName : "";
        }
        if ("city" in this.resource) {
          this.subtitle += this.resource.city ? " - " + this.resource.city : "";
        }
        this.details = "Etude : ";
        if ("education" in this.resource) {
          this.details += this.resource.education ? this.getEducation(this.resource.education) : "";
        }
        this.details += " - Niveau : ";
        this.readEducationLevelLabel(false, true)
        this.firstDescription = "Periode : ";
        if ("startDate" in this.resource) {
          this.firstDescription += this.resource.startDate ? this.convertDate(this.resource.startDate) : "";
        }
        if ("endDate" in this.resource) {
          this.firstDescription += this.resource.endDate ? " - " + this.convertDate(this.resource.endDate) : "";
        }
        this.secondDescription = "Description: ";
        this.secondDescription += this.resource.description ? this.resource.description : "";
        break;
      case 'certification':
        this.subtitle = "Etablissement : ";
        if ("schoolName" in this.resource) {
          this.subtitle += this.resource.schoolName ? this.resource.schoolName : "";
        }
        if ("city" in this.resource) {
          this.subtitle += this.resource.city ? " - " + this.resource.city : "";
        }
        this.details = "Periode : ";
        if ("startDate" in this.resource) {
          this.details += this.resource.startDate ? this.convertDate(this.resource.startDate) : "";
        }
        if ("endDate" in this.resource) {
          this.details += this.resource.endDate ? " - " + this.convertDate(this.resource.endDate) : "";
        }
        this.firstDescription = "Secteur d'activité : ";
        if ("activityArea" in this.resource) {
          this.firstDescription += this.resource.activityArea ? this.resource.activityArea.resource!.label : "";
        }
        this.secondDescription = "Description: ";
        this.secondDescription += this.resource.description ? this.resource.description : "";
        break;
      case 'skill':
        this.subtitle = "";
        if ("level" in this.resource) {
          this.subtitle += this.resource.level ? this.getLevel(this.resource.level) : "";
        }
        if ("category" in this.resource) {
          this.subtitle += this.resource.category ? " - " + this.getCategory(this.resource.category) : "";
        }
        this.firstDescription = "Discipline : ";
        if ("discipline" in this.resource) {
          this.firstDescription += this.resource.discipline ? this.resource.discipline : "";
        }
        this.secondDescription = "Mots-clés: ";
        if ("keywords" in this.resource) {
          this.secondDescription += this.resource.keywords ? this.resource.keywords : "";
        }
        this.secondDescription += this.resource.description ? "  " + this.resource.description : "";
        break;
      default:
        break;
    }
  }

  readEducationLevelLabel(isTraining: boolean, isDiploma: boolean) {
    if (this.resource.education == EducationEnum.Higher) {
      this.details += this.resource.diplomaLevel;
    }
    if (this.resource.education == EducationEnum.Secondary) {
      if (isTraining) {
        this.details += this.resource.schoolClass ? this.resource.schoolClass.resource.label : "";
      }
      if (isDiploma) {
        this.details += "";
      }
    }
  }

  getCategory(code: string): string {
    let category: string = "";
    if (code) {
      switch (code) {
        case CategoryEnum._1:
          category = "Compétence à developper";
          break;
        case CategoryEnum._2:
          category = "Compétence maitrîsée"
          break;
        default:
          category = "Compétence à developper";
          break;
      }
    }
    return category;
  }

  getEducation(code: string): string {
    let education: string = "";
    if (code) {
      switch (code) {
        case EducationEnum.Secondary:
          education = "Enseignement secondaire";
          break;
        case EducationEnum.Higher:
          education = "Enseignement supérieur"
          break;
        default:
          education = "Enseignement secondaire";
          break;
      }
    }
    return education;
  }

  getLevel(code: string): string {
    let level: string = "";
    if (code) {
      switch (code) {
        case LevelEnum._1:
          level = "Notions de base";
          break;
        case LevelEnum._2:
          level = "A approfondir"
          break;
        case LevelEnum._3:
          level = "Bien";
          break;
        case LevelEnum._4:
          level = "Trés bien"
          break;
        default:
          level = "Notions de base";
          break;
      }
    }
    return level;
  }

  convertDate(date: string): string {
    return DateHelper.convertDate(date);
  }


  onClickUpdate(): void {
    this.localStorageService.add(variables.account_attribute, this.resource);
    this.smUpdateEvent.emit(this.type);
  }

  onClickDelete(): void {

  }

  onClickConfirmDelete(): void {
    switch (this.type) {
      case 'skill':
        this.skillService.deleteSkill(this.resource.uuid).subscribe({
          next: value => {
            this.smDoneEvent.emit(this.type);
          },
          error: err => {
          },
          complete: () => {
          }
        })
        break;
      case 'training':
        this.trainingService.deleteTraining(this.resource.uuid).subscribe({
          next: value => {
            this.smDoneEvent.emit(this.type);
          },
          error: err => {
          },
          complete: () => {
          }
        })
        break;
      case 'certification':
        this.certificationService.deleteCertification(this.resource.uuid).subscribe({
          next: value => {
            this.smDoneEvent.emit(this.type);
          },
          error: err => {
          },
          complete: () => {
          }
        })
        break;
      case 'diploma':
        this.diplomaService.deleteDiploma(this.resource.uuid).subscribe({
          next: value => {
            this.smDoneEvent.emit(this.type);
          },
          error: err => {
          },
          complete: () => {
          }
        })
        break;
      case 'job':
        this.jobService.deleteJob(this.resource.uuid).subscribe({
          next: value => {
            this.smDoneEvent.emit(this.type);
          },
          error: err => {
          },
          complete: () => {
          }
        })
        break;
    }
  }
}
