import {Component, Input, OnInit} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {Status} from "../../enums/status.enum";
import {TranslateService} from "@ngx-translate/core";
import {AccountDetailsResource, AccountService} from "ApiModuleAccount";
import {Router} from "@angular/router";
import {Route} from "../../enums/route.enum";
import {variables} from "../../../environments/variables";
import {FollowRequest, InteractionService} from "ApiModulePost";
import {ToastrService} from "ngx-toastr";
import {forkJoin, Observable} from "rxjs";

@Component({
  selector: 'sm-profile-account',
  templateUrl: './profile-account.component.html',
  styleUrls: ['./profile-account.component.scss']
})
export class ProfileAccountComponent implements OnInit {

  //@Input() account!: AccountDetailsResource;
  account: AccountDetailsResource = {};
  @Input() accountUuid!: string;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  accountGender!: string;
  hasFollowed!: boolean;
  followLabel: string = 'profile_account.follow';
  accountAvatar!: string;

  constructor(private localStorageService: LocalStorageService,
              private router: Router,
              private toastrService: ToastrService,
              private interactionService: InteractionService,
              private translateService: TranslateService,
              private accountService: AccountService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    //this.fetchAccount(this.accountUuid)
    this.asyncSearch()
  }

  asyncSearch(): Observable<any> {
    const observableA1 = this.checkFollow();
    const observableB2 = this.fetchAccount(this.accountUuid);
    return forkJoin([observableA1, observableB2]);
  }

  isLoggedAndIsOwner(): boolean {
    return this.isLogged() && this.isOwner()
  }

  isLoggedAndNotOwner(): boolean {
    return this.isLogged() && !this.isOwner()
  }

  isLogged(): boolean {
    return this.loggedAccount != undefined;
  }

  isOwner(): boolean {
    return this.isLogged() ? this.loggedAccount.uuid === this.account.uuid : false
  }

  displayAge(): boolean {
    if (this.account.birthdate == undefined) {
      return false
    } else {
      return !(this.account.hideBirthdate!);
    }
  }

  fetchAccount(id: string): void {
    this.accountService.findAccount(id).subscribe({
      next: response => {
        this.account = response.resource!;
        this.accountAvatar = this.account.avatar!;
        this.getGender();
        //this.checkFollow();
      },
      error: error => {
      },
      complete: () => {
      }
    })
  }

  setDefault() {
    this.account.avatar = './assets/images/user.svg';
  }

  getGender(): string | undefined {
    let placeholder!: string;
    if (this.account.gender == "MALE") {
      placeholder = "sm.male";
    } else if (this.account.gender == "FEMALE") {
      placeholder = "sm.female";
    }

    this.translateService.get(placeholder).subscribe((value: any) => {
      this.accountGender = value;
    });
    return this.accountGender;
  }

  onClickProfile(): void {
    if (this.loggedAccount) {
      this.localStorageService.update(variables.profile_account, this.account);
      this.router.navigate([Route.EDIT_PROFILE]);
    }
  }

  getIconStatus(): string {
    if (this.account.status === Status[Status.TEACHER]) {
      return './assets/images/teacher.svg';
    } else if (this.account.status === Status[Status.STUDENT]) {
      return './assets/images/student.svg';
    } else if (this.account.status === Status[Status.PROFESSIONAL]) {
      return './assets/images/professional.svg';
    } else {
      return './assets/images/student.svg';
    }
  }

  getLabelStatus(): string {
    if (this.account.status === Status[Status.TEACHER]) {
      return 'profile_account.teacher';
    } else if (this.account.status === Status[Status.STUDENT]) {
      return 'profile_account.student';
    } else if (this.account.status === Status[Status.PROFESSIONAL]) {
      return 'profile_account.professional';
    } else {
      return 'profile_account.student';
    }
  }

  onClickMessage(): void {
    this.router.navigate([Route.MESSAGES]);
  }

  checkFollow(): void {
    this.interactionService.checkAccountHaveFollowSocialInteraction(this.loggedAccount.uuid!, this.accountUuid).subscribe({
      next: response => {
        this.hasFollowed = Boolean(response.data);
        this.followLabel = this.hasFollowed ? 'profile_account.followed' : 'profile_account.follow';
      },
      error: err => {
      },
      complete: () => {
      }
    })
  }

  onClickFollow(): void {
    if (this.loggedAccount != undefined) {
      let followRequest: FollowRequest = {emitter: this.loggedAccount.uuid, receiver: this.account.uuid}
      this.hasFollowed ? this.unfollow(followRequest) : this.follow(followRequest)
    }
  }

  follow(request: FollowRequest) {
    this.interactionService.follow(request)
      .subscribe({
        next: response => {
          this.localStorageService.updateLoggedAccount(this.loggedAccount.uuid!)
          this.localStorageService.updateProfileAccount(this.account.uuid!)
          this.fetchAccount(this.accountUuid)
          this.checkFollow()
          this.toastrService.success("Abonnement avec succés", "Abonnement");
        },
        error: error => {
        },
        complete: () => {
        }
      })
  }

  unfollow(request: FollowRequest) {
    this.interactionService.unfollow(request)
      .subscribe({
        next: response => {
          this.localStorageService.updateLoggedAccount(this.loggedAccount.uuid!)
          this.localStorageService.updateProfileAccount(this.account.uuid!)
          this.fetchAccount(this.accountUuid)
          this.checkFollow()
          this.toastrService.success("Désabonnement avec succés", "Abonnement");
        },
        error: error => {
        },
        complete: () => {
        }
      });
  }


  onClickPosts(): void {
    if (this.isLogged()) {
      this.localStorageService.update(variables.profile_account, this.account);
      this.router.navigate([Route.PROFILE]);
    }
  }

  onClickNetwork(networkType: string): void {
    if (this.isLogged()) {
      this.localStorageService.update(variables.profile_account, this.account);
      this.router.navigate([Route.NETWORK]);
    }
  }

  onClickCopyProfile(): void {
    this.toastrService.success("Lien du profil copié sur le presse-papier", "Lien du profil");
    this.copy(window.location.host + "/account-details?id=" + this.account.uuid);
  }

  copy(url: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }
}
