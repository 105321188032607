/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ParameterRequest } from './parameterRequest';


export interface DiplomaRequest { 
    title: string;
    description?: string;
    account: string;
    education: DiplomaRequest.EducationEnum;
    level?: string;
    schoolType?: ParameterRequest;
    activityArea?: string;
    schoolName: string;
    city?: string;
    startDate?: string;
    endDate?: string;
}
export namespace DiplomaRequest {
    export type EducationEnum = 'SECONDARY' | 'HIGHER';
    export const EducationEnum = {
        Secondary: 'SECONDARY' as EducationEnum,
        Higher: 'HIGHER' as EducationEnum
    };
}


