<div class="skillsmates-box-shadow">
  <div class="row" style="margin: auto;">
    <div class="col-md-12 skillsmates-box-top">
      <img height="24" src="{{'follow-black' | image}}" alt="follow-black"> &nbsp;
      <strong>Ces profils vous intérèssent-ils ?</strong>
      <span class="pointer" style="float: right;" [routerLink]="Route.NETWORK" (click)="onClick(1)">
        {{'account_curriculum.see_more' | translate}}
      </span>
    </div>
  </div>

  <div class="row bg-white scrolling-wrapper" style="margin: 0;  border-radius: 0 0 10px 10px;">
    <carousel style="height: 290px; z-index: -0;">
      <div class="carousel-cell col-6 col-sm-6 col-md-4 col-lg-3" style="padding: 3px;"
           *ngFor="let account of accounts">
        <sm-profile-card [account]="account"></sm-profile-card>
      </div>
    </carousel>
  </div>
</div>
