<div class="row skillsmates-container">
  <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 sm-side-menu">
    <div class="row" style="margin: auto;">
      <div class="col-12 ">
        <strong>Catégories</strong>
      </div>
      <sm-horizontal-line></sm-horizontal-line>
    </div>
    <sm-search-types (smSearchParamEvent)="onClickItem($event)"></sm-search-types>
  </div>
  <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9">
    <div class="row skillsmates-box-shadow p-3">
      <div class="col-12">
        <sm-input
          (smInputEvent)="getSearchText($event)"
          [value]="searchParam.content!"
          placeholder="{{'search.research' | translate}}">
        </sm-input>
      </div>
      <div *ngIf="!loading" class="col-9 col-sm-6 col-md-6 col-lg-3 col-xl-3" style="margin: auto;">
        <sm-button (click)="onClickSearch()" [type]="'info'" text="{{'search.research' | translate}}"></sm-button>
      </div>

      <div *ngIf="loading" class="col-9 col-sm-6 col-md-6 col-lg-3 col-xl-3" style="margin: auto;">
        <sm-processing [loading]="loading"></sm-processing>
      </div>
    </div>

    <div class="sm-profile-attributes-2">
      <div class=" scroll">
        <sm-image-text
          (click)="clickMediaType('MEDIA_TYPE_PROFILE')"
          [bgColor]="showProfileResults ? profileMediaType.color + 'AA' : '#AAAAAA55'"
          [text]="profileMediaType.label"
          image="{{'user' | image}}">
        </sm-image-text>
        <sm-image-text
          (click)="clickMediaType(mediaType.code)" *ngFor="let mediaType of mediaTypes"
          [bgColor]="isSelected(mediaType.code) ? mediaType.color + 'AA' : '#AAAAAA55'"
          [text]="mediaType.label"
          image="{{mediaType.picture | image}}">
        </sm-image-text>
      </div>
    </div>

    <br>
    <div class="row">
      <div *ngIf="!checkNoResult()" class="col-12">
        <sm-search-results-tile
          (smMoreResultsEvent)="onClickMoreResults($event) "
          *ngIf="showProfileResults && accountResponse"
          [accounts]="accountResponse ? resourcesToDisplay(accountResponse.resources!) : []"
          [displayAllResults]="displayAllResults"
          [expand]="accountResponse && accountResponse.meta && accountResponse.meta.total ? accountResponse.meta.total > 0 : false"
          [mediaType]="profileMediaType"
          [total]="accountResponse && accountResponse.meta && accountResponse.meta.total ? accountResponse.meta.total : 0">
        </sm-search-results-tile>
        <sm-search-results-tile
          (smMoreResultsEvent)="onClickMoreResults($event)"
          *ngIf="showDocumentResults && profileDocuments"
          [displayAllResults]="displayAllResults"
          [expand]="profileDocuments && profileDocuments.totalDocuments ? profileDocuments.totalDocuments > 0 : false"
          [mediaType]="mediaTypes[0]"
          [posts]="profileDocuments ? resourcesToDisplay(profileDocuments.documents!) : []"
          [total]="profileDocuments ? profileDocuments.totalDocuments : 0">
        </sm-search-results-tile>
        <sm-search-results-tile
          (smMoreResultsEvent)="onClickMoreResults($event)"
          *ngIf="showVideoResults && profileDocuments"
          [displayAllResults]="displayAllResults"
          [expand]="profileDocuments && profileDocuments.totalVideos ? profileDocuments.totalVideos > 0 : false"
          [mediaType]="mediaTypes[1]"
          [posts]="profileDocuments ? resourcesToDisplay(profileDocuments.videos!) : []"
          [total]="profileDocuments ? profileDocuments.totalVideos : 0">
        </sm-search-results-tile>
        <sm-search-results-tile
          (smMoreResultsEvent)="onClickMoreResults($event)"
          *ngIf="showLinkResults && profileDocuments"
          [displayAllResults]="displayAllResults"
          [expand]="profileDocuments && profileDocuments.totalLinks ? profileDocuments.totalLinks > 0 : false"
          [mediaType]="mediaTypes[2]"
          [posts]="profileDocuments ? resourcesToDisplay(profileDocuments.links!) : []"
          [total]="profileDocuments ? profileDocuments.totalLinks : 0">
        </sm-search-results-tile>
        <sm-search-results-tile
          (smMoreResultsEvent)="onClickMoreResults($event)"
          *ngIf="showImageResults && profileDocuments"
          [displayAllResults]="displayAllResults"
          [expand]="profileDocuments && profileDocuments.totalImages ? profileDocuments.totalImages > 0 : false"
          [mediaType]="mediaTypes[3]"
          [posts]="profileDocuments ? resourcesToDisplay(profileDocuments.images!) : []"
          [total]="profileDocuments ? profileDocuments.totalImages : 0">
        </sm-search-results-tile>
        <sm-search-results-tile
          (smMoreResultsEvent)="onClickMoreResults($event)"
          *ngIf="showAudioResults && profileDocuments"
          [displayAllResults]="displayAllResults"
          [expand]="profileDocuments && profileDocuments.totalAudios ? profileDocuments.totalAudios > 0 : false"
          [mediaType]="mediaTypes[4]"
          [posts]="profileDocuments ? resourcesToDisplay(profileDocuments.audios!) : []"
          [total]="profileDocuments ? profileDocuments.totalAudios : 0">
        </sm-search-results-tile>
      </div>

      <div *ngIf="checkNoResult()" class="col-12 text-center"
           style="">
        <p class="skillsmates-title text-center" style="font-size: xx-large;">
          <strong>{{ 'search.no_result_found' | translate }}</strong>
        </p>
        <p class="skillsmates-title text-center" style="font-size: larger; margin: 20px;">
          <strong>{{ 'search.no_result_found_message' | translate }}</strong>
        </p>
        <img alt="" class="no-result-img" src="{{'no-result-search' | image}}">
      </div>
    </div>
  </div>
</div>
