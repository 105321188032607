import {Component, Input, OnInit} from '@angular/core';
import {PostDetailsResource} from "ApiModulePost";
import {AccountDetailsResource} from "ApiModuleAccount";
import {StringHelper} from "../../helpers/string-helper";

@Component({
  selector: 'sm-search-post',
  templateUrl: './search-post.component.html',
  styleUrls: ['./search-post.component.scss']
})
export class SearchPostComponent implements OnInit {

  @Input() post!: PostDetailsResource;

  ngOnInit(): void {
  }

  public name(account: AccountDetailsResource): string {
    return StringHelper.truncateName(account, 30);
  }
}
