import {Component, Input, OnInit} from '@angular/core';
import {PostDetailsResource} from "ApiModulePost";

@Component({
  selector: 'sm-other-post',
  templateUrl: './other-post.component.html',
  styleUrls: ['./other-post.component.scss']
})
export class OtherPostComponent implements OnInit {

  @Input() post!: PostDetailsResource;

  ngOnInit() {
  }

}
