import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'yearAgo'
})
export class YearAgoPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (value) {
      const d = new Date(value);
      const now = new Date();
      return (now.getFullYear() - d.getFullYear()).toString(10);
    }
    return "";
  }
}
