<div class="skillsmates-box-shadow card" style="padding-bottom: 5px;">
  <div class="row card-header skillsmates-box-top" style="margin: 0 1px 0 1px; cursor: pointer;">

    <div class="col-md-12 no-margin-padding  collapsed d-block" data-toggle="collapse" href="#collapse-collapsed"
         aria-expanded="true" aria-controls="collapse-collapsed" id="heading-collapsed">
      <em class="fa fa-chevron-down pull-right" style="padding-top: 10px; padding-left: 5px;"></em>
      <img height="24" src="{{'ambassador' | image}}" alt=""> &nbsp;
      <strong>Ambassadeurs</strong>
    </div>

  </div>
  <div class="row bg-white collapse" (click)="onClickOpenAmbassador('https://yamogroup.fr')"
       style="cursor: pointer; margin: auto; border-radius: 0 0 10px 10px;" id="collapse-collapsed"
       aria-labelledby="heading-collapsed">
    <div class="col-12 card-body no-margin-padding">
      <img class="image-ambassador" src="./assets/images/yamo_group.png" alt="">
    </div>
    <div class="col-12 text-center no-margin-padding" style="font-size: small; margin-bottom: 10px; font-weight: bold;">
      Nous travaillons ensemble Pour concevoir votre vision
    </div>
  </div>
</div>
