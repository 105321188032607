import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'sm-button-follow',
  templateUrl: './button-follow.component.html',
  styleUrls: ['./button-follow.component.scss']
})
export class ButtonFollowComponent implements OnInit {

  @Input() text: string = "button";
  @Input() type!: string;
  @Input() image!: string;

  ngOnInit() {
  }

  getCssClass() {
    switch (this.type) {
      case 'danger':
        return 'button-danger'
      case 'success':
        return 'button-success'
      case 'info':
        return 'button-info'
      case 'default':
        return 'button-default'
      case 'follow':
        return 'button-follow'
      case 'unfollow':
        return 'button-unfollow'
      default:
        return 'button-default'
    }


  }

}
