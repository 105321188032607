import {Component, OnInit} from '@angular/core';
import {AccountDetailsResource} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";

@Component({
  selector: 'sm-settings-security',
  templateUrl: './settings-security.component.html',
  styleUrls: ['./settings-security.component.scss']
})
export class SettingsSecurityComponent implements OnInit {
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  editPassword: boolean = false;
  deleteAccount: boolean = false;
  processing:boolean=false

  constructor(private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
  }

  onClickEdit(setting: string) {
    this.editPassword = false;
    this.deleteAccount = false;
    switch (setting) {
      case 'password':
        this.editPassword = true;
        break;
      case 'account':
        this.deleteAccount = true;
        break;
    }
  }

  onClickCancel(): void {
    this.editPassword = false;
    this.deleteAccount = false;
  }

  onClickSave(): void {

  }

  getNewPassword(event: string): void {

  }

  getConfirmNewPassword(event: string): void {

  }

  getPassword(event: string) {

  }
}
