import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountDetailsResource, SkillRequest} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {variables} from "../../../environments/variables";
import LevelEnum = SkillRequest.LevelEnum;

@Component({
  selector: 'sm-cursus',
  templateUrl: './cursus.component.html',
  styleUrls: ['./cursus.component.scss']
})
export class CursusComponent implements OnInit {

  @Input() elements!: any;
  @Input() title: string = "";
  @Input() addText: string = "";
  @Output() smAddEvent = new EventEmitter<string>();
  @Output() smUpdateEvent = new EventEmitter<string>();
  @Output() smDoneEvent = new EventEmitter<string>();

  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  account: AccountDetailsResource = {} as AccountDetailsResource;
  expanded: boolean = false;
  headTileImage: string = "arrow-open-down";

  constructor(private localStorageService: LocalStorageService) {
  }

  ngOnInit() {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.get(variables.profile_account);
  }

  onClickTitle(): void {
    this.expanded = !this.expanded;
    this.headTileImage = !this.expanded ? "arrow-open-down" : "arrow-open-up";
  }

  getImage(element: any): string {
    let image: string = "company";
    if (this.elements) {
      switch (this.elements.type) {
        case 'job':
          image = 'company';
          break;
        case 'training':
          image = 'school';
          break;
        case 'skill':
          if (element) {
            switch (element.level) {
              case LevelEnum._1:
                image = 'red-arrow'
                break;
              case LevelEnum._2:
                image = 'orange-arrow'
                break;
              case LevelEnum._3:
                image = 'green-arrow'
                break;
              case LevelEnum._4:
                image = 'green-arrow-plus'
                break;
              default:
                image = 'red-arrow'
                break;
            }
          }
          break;
        case 'certification':
          image = 'certification';
          break;
        case 'diploma':
          image = 'student';
          break;
        default:
          break;
      }
    }
    return image;
  }

  onClickAdd(type: string): void {
    this.smAddEvent.emit(type);
  }

  onClickUpdate(event: string) {
    this.smUpdateEvent.emit(event);
  }

  onDone(event: string): void {
    this.smDoneEvent.emit(event);
  }
}
