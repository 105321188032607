import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountDetailsResource, DiplomaResponse, DiplomaService} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {variables} from "../../../environments/variables";

@Component({
  selector: 'sm-profile-diplomas',
  templateUrl: './profile-diplomas.component.html',
  styleUrls: ['./profile-diplomas.component.scss']
})
export class ProfileDiplomasComponent implements OnInit {
  @Input() editMode: boolean = false;
  @Output() smEditModeEvent = new EventEmitter<string>;

  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  diplomaResponse: DiplomaResponse = {} as DiplomaResponse;

  constructor(private localStorageService: LocalStorageService,
              private diplomaService: DiplomaService,) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
  }

  ngOnInit() {
    this.fetchDiplomas();
  }

  fetchDiplomas(): void {
    this.diplomaService.findDiplomaByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.diplomaResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  onClickAdd(event: string): void {
    this.smEditModeEvent.emit(event);
    this.editMode = true;
  }

  onClickDone(event: boolean): void {
    this.editMode = false;
    if (event) {
      this.fetchDiplomas();
    }
    this.smEditModeEvent.emit('');
  }

  onClickUpdate(event: string): void {
    this.smEditModeEvent.emit(event);
    this.diplomaResponse!.resource = this.localStorageService.get(variables.account_attribute);
    this.editMode = true;
  }
}
