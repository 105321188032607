<div *ngIf="post" class="skillsmates-box-shadow p-3 m-t-10 pointer">
  <div class="row">
    <div class="col-12" style="margin-bottom: 10px;">
      <sm-post-media [post]="post" [height]="150"></sm-post-media>
    </div>
    <div class="col text-left m-t-10">
      <div class="row">
        <div class="col-12">
          <strong class=" text-left skillsmates-single-line" style="font-size: x-small;">{{post.title}}</strong>
        </div>
        <div class="col-12 date-post">
          {{'search.published' | translate}} <span class="date-post">{{post.createdAt | timeAgo}}</span>
        </div>
      </div>
    </div>
  </div>
  <sm-horizontal-line></sm-horizontal-line>
  <div class="row text-center">
    <div class="col-3">
      <img width="12" src="{{'heart' | image}}"> ({{post.likes}})
    </div>
    <div class="col-3">
      <img width="12" src="{{'share' | image}}"> ({{post.shares}})
    </div>
    <div class="col-3">
      <img width="12" src="{{'skillschat-active' | image}}"> (0)
    </div>
    <div class="col-3">
      <img width="12" src="{{'download' | image}}"> (0)
    </div>
  </div>
</div>



