import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {Alert} from "../../models/alert";
import {StringHelper} from "../../helpers/string-helper";
import {PasswordError} from "../../models/passwordError";
import {AuthenticationService} from "ApiModuleAccount";
import {ToastrService} from "ngx-toastr";
import {variables} from "../../../environments/variables";
import {LocalStorageService} from "../../services/local-storage.service";
import {Route} from "../../enums/route.enum";
import packageJson from "../../../../package.json";
import {ValidationCondition} from "../../models/validationCondition";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  resetCode: string = ""
  alert: Alert = new Alert()
  loading: boolean = false
  newPassword: string = ""
  confirmPassword: string = ""
  errorMessagePassword: string = "Mot de passe obligatoire"
  showErrorMessagePassword: boolean = false
  inputStatusPassword: string = ""
  errorMessageConfirmPassword: string = "Confirmation du mot de passe obligatoire"
  showErrorMessageConfirmPassword: boolean = false
  inputStatusConfirmPassword: string = ""
  msgErrors: PasswordError[] = []
  Route = Route
  year: number = new Date().getFullYear()
  validations: ValidationCondition[] = []
  public version: string = packageJson.version
  private sub: any

  constructor(private activateRoute: ActivatedRoute, private authenticationService: AuthenticationService,
              private toastrService: ToastrService, private localStorageService: LocalStorageService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.initValidation()
    this.sub = this.activateRoute.params.subscribe(params => {
      this.resetCode = params['id']
    })
  }

  initValidation() {
    this.validations.push(new ValidationCondition(false, 'SIZE', '- Mot de passe doit contenir entre 8 et 16 caractères'))
    this.validations.push(new ValidationCondition(false, 'DIGIT', '- Mot de passe doit contenir au moins un chiffre'))
    this.validations.push(new ValidationCondition(false, 'UPPERCASE', '- Mot de passe doit contenir au moins une lettre majuscule'))
    this.validations.push(new ValidationCondition(false, 'LOWERCASE', '- Mot de passe doit contenir au moins une lettre minuscule'))
    this.validations.push(new ValidationCondition(false, 'SPECIAL', '- Mot de passe doit contenir au moins un caractère spécial'))
    this.validations.push(new ValidationCondition(false, 'SAME', '- les 2 champs doivent contenir la même valeur'))
  }

  checkSize() {
    let condition = (this.newPassword.trim().length >= 8) && (this.newPassword.trim().length <= 16)
    this.updateValidation('SIZE', condition)
  }

  checkDigit() {
    let condition = /\d/.test(this.newPassword.trim())
    this.updateValidation('DIGIT', condition)
  }

  checkUpperCase() {
    let condition = /[A-Z]/.test(this.newPassword.trim())
    this.updateValidation('UPPERCASE', condition)
  }

  checkLowerCase() {
    let condition = /[a-z]/.test(this.newPassword.trim())
    this.updateValidation('LOWERCASE', condition)
  }

  checkSpecial() {
    let condition = /[^\w\s]/.test(this.newPassword.trim())
    this.updateValidation('SPECIAL', condition)
  }

  checkSame() {
    let condition = (this.newPassword.trim().length > 0) && (this.newPassword.trim() === this.confirmPassword.trim())
    this.updateValidation('SAME', condition)
  }

  updateValidation(code: string, newValue: boolean) {
    this.validations.forEach(value => {
      if (value.code === code) {
        value.valid = newValue
      }
    })
  }

  validateInput() {
    this.checkSize()
    this.checkDigit()
    this.checkUpperCase()
    this.checkLowerCase()
    this.checkSpecial()
    this.checkSame()
  }

  validateNewPassword() {
    let isValid = this.isValid()
    this.inputStatusPassword = isValid ? 'valid' : 'invalid'
    this.showErrorMessagePassword = !isValid
    this.errorMessagePassword = isValid ? '' : this.validations.find(value => !value.valid)?.message!
    return isValid
  }

  validateConfirm() {
    let validation = this.validations.find(value => value.code === 'SAME')
    let isValid = validation?.valid
    this.inputStatusConfirmPassword = isValid === true ? 'valid' : 'invalid'
    this.showErrorMessageConfirmPassword = isValid !== true
    this.errorMessageConfirmPassword = validation?.message!
    return isValid
  }

  isValid() {
    let result = true;
    this.validations.forEach(value => result = result && value.valid)
    return result;
  }


  onClickCGU(): void {
    this.localStorageService.add(variables.url, Route.LOGIN);
    this.localStorageService.add(variables.terms_and_conditions, 'true');
    this.router.navigate([Route.TERMS_AND_CONDITIONS])
  }

  onClickFAQ(): void {
    localStorage.setItem(variables.faq, variables.faq);
    this.router.navigate([Route.FAQ])
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe()
  }

  getNewPassword(e: any): void {
    this.newPassword = e
    this.validateInput()
  }

  getNewPasswordConfirm(e: any): void {
    this.confirmPassword = e
    this.validateInput()
  }

  isPasswordValid(): boolean {
    if (this.newPassword == null || this.newPassword.toString() == "") {
      this.inputStatusPassword = "invalid"
      this.showErrorMessagePassword = true
      this.errorMessagePassword = "Mot de passe requis"
    } else {
      this.msgErrors = StringHelper.validatePassword2(this.newPassword)
      this.showErrorMessagePassword = this.msgErrors.filter(value => !value.validated).length > 0
      this.inputStatusPassword = this.showErrorMessagePassword ? "invalid" : "valid"
      this.errorMessagePassword = !this.showErrorMessagePassword ?
        "" :
        this.msgErrors.find(value => !value.validated)?.message!
    }
    return !this.showErrorMessagePassword
  }

  isConfirmPasswordValid(): boolean {
    if (this.newPassword === this.confirmPassword) {
      this.inputStatusConfirmPassword = "valid"
      this.showErrorMessageConfirmPassword = false
      this.errorMessageConfirmPassword = ""
      return true
    } else {
      this.inputStatusConfirmPassword = "invalid"
      this.showErrorMessageConfirmPassword = true
      this.errorMessageConfirmPassword = "Les mots de passe sont différent"
      return false
    }
  }

  doChangePassword() {
    if (this.validateNewPassword() && this.validateConfirm()) {
      this.loading = true
      this.authenticationService.resetPassword(
        {password: this.newPassword.trim(), token: this.resetCode})
        .subscribe({
          next: response => {
            this.loading = false
            this.toastrService.success("Votre mot de passe a été mis à jour")
            this.router.navigate([Route.PASSWORD_RESET])
          },
          error: error => {
            this.toastrService.error("Une erreur est survenue pendant le traitement")
            this.loading = false
          },
          complete: () => {
            this.loading = false
          }
        })
    } else {
      this.alert = {
        display: true,
        class: 'danger',
        title: 'Erreur',
        message: 'Formulaire invalide',
      };
      this.loading = false;
    }
  }
}
