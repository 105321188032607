import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {variables} from "../../../environments/variables";
import {StringHelper} from "../../helpers/string-helper";
import {LocalStorageService} from "../../services/local-storage.service";
import {Route} from "../../enums/route.enum";
import {AccountDetailsResource, PostDetailsResource, PostService} from "ApiModulePost";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'sm-post-header',
  templateUrl: './post-header.component.html',
  styleUrls: ['./post-header.component.scss']
})
export class PostHeaderComponent implements OnInit {
  @Input() post: PostDetailsResource = {} as PostDetailsResource;
  @Output() smPostEvent = new EventEmitter<string>();
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  postToDelete: PostDetailsResource = {} as PostDetailsResource;
  Route = Route;
  @Input() isSharedPost: boolean = false;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastrService: ToastrService,
              public storageService: LocalStorageService,
              private postService: PostService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.storageService.getLoggedAccount();
  }

  onClickDelete(post: PostDetailsResource): void {
    this.postToDelete = post;
    // this.storageService.add(variables.post_delete, post);
  }

  onClickConfirmDelete(): void {
    this.postService.deletePost(this.postToDelete.uuid!)
      .subscribe({
        next: response => {
          this.toastrService.success("Publication supprimée avec succés", "Publication");
          window.location.reload();
        },
        error: error => {
          this.toastrService.error("Erreur lors de la suppression de la publication", "Publication");
        },
        complete: () => {
        }
      });
  }

  getCompanyIcon(): string {
    return StringHelper.getCompanyIcon(this.post.account!);
  }

  public name(account?: AccountDetailsResource): string {
    return StringHelper.truncateName(account, 25);
  }

  onClickUpdate(post: PostDetailsResource): void {
    if (!this.storageService.isOffline()) {
      this.storageService.add(variables.post_update, post);
      this.router.navigate([Route.EDIT_POST]);
    }
  }

  onClickCopy(post: PostDetailsResource): void {
    this.copy(window.location.host + "/post-detail?id=" + this.post.uuid!);
    this.toastrService.success("Lien copié sur le presse-papier", "Lien");
  }

  copy(url: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = url;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  onClickProfile(account?: AccountDetailsResource): void {
    if (!this.storageService.isOffline()) {
      this.storageService.add(variables.profile_account, account);
      this.router.navigate([Route.PROFILE]);
    }
  }

  onClickDisplay(): void {
    if (!this.storageService.isOffline()) {
      this.storageService.add(variables.post_details, this.post);
      this.storageService.add(variables.previous_route, Route.PROFILE);
      this.router.navigate([Route.POST]);
    }
  }

  onClickPost(post: PostDetailsResource): void {
    if (!this.storageService.isOffline()) {
      this.storageService.add(variables.post_details, post);
      this.storageService.add(variables.previous_route, Route.DASHBOARD);
      this.router.navigate([Route.POST]);
    }
  }

  ownerIsLoggedAccount(): boolean {
    if (this.storageService.isOffline()) {
      return false
    }
    return this.loggedAccount.uuid === this.post.account?.uuid
  }

}
