<div class="row skillsmates-container">
  <!--   partie gauche du dashboard -->
  <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3" style="padding-bottom: 20px;">
    <sm-image-text
      class="bg-white"
      *ngIf="previousRoute"
      (click)="onClickReturn()"
      [bgColor]="'#D3D3D3'"
      image="{{'left-arrow' | image}}"
      text="{{'post.return' | translate}}">
    </sm-image-text>

  </div>

  <!-- partie centrale du dashboard -->
  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 " *ngIf="post">
    <sm-post
      [post]="post">
    </sm-post>
  </div>

  <!-- partie droite du dashboard -->
  <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 ">
  </div>
</div>
