export const variables = {
  logged_account: 'logged_account',
  profile_account: 'profile_account',
  account_create: 'account_create',
  post_display: 'post_display',
  post_update: 'post_update',
  post_delete: 'post_delete',
  post_details: 'post_details',
  media_type: 'media_type',
  account_attribute: 'account_attribute',
  previous_route: 'previous_route',
  search_param: 'search_param',
  url: 'url',
  terms_and_conditions: 'terms_and_conditions',
  suggestions: 'suggestions',
  network_stats: 'network_stats',
  add: 'add',
  edit: 'edit',

  header_token: 'X-Ms-Token',
  header_id: 'X-Ms-Id',
  biography: 'biography',
  professional: 'professional',
  academics: 'academics',
  skills: 'skills',
  infos: 'infos',
  tab: 'tab',
  id: 'id',
  secondary: 'SECONDARY',
  higher: 'HIGHER',
  document: 'document',
  video: 'video',
  audio: 'audio',
  link: 'link',
  image: 'image',
  refresh: 'refresh',
  updates: 'updates',
  info_text_biography: 'Rédigez un résumé de votre profil en mettant en avant les disciplines majeurs relatives à votre parcours académique et professionnel. N\'hésitez pas aussi à partager vos centres d\'interêts, exposer vos besoins et éventuellement à promouvoir vos activités.',
  like: 'LIKE',
  share: 'SHARE',
  comment: 'COMMENT',
  favorite: 'FAVORITE',
  follower: 'FOLLOWER',
  message: 'MESSAGE',
  post: 'POST',
  article: 'ARTICLE',
  status: 'STATUS',
  current_media_type: 'current_media_type',
  unread_messages: 'unread_messages',
  max_file_size: 25,
  max_title_size: 255,
  max_comment_size: 2000,
  max_description_size: 5000,
  max_modal_post_size: 5000,
  max_biography_size: 10000,
  max_professional_description_size: 5000,
  placeholder_description_post: 'Rédigez une brève présentation de votre contenu en précisant les principaux sujets traités. Décrivez également le contexte dans lequel vous avez été améné à le réaliser ou à le rédiger afin de mettre en lumiére toute son utilité et sa pertinance.',
  formation: 'FORMATION',
  diploma: 'DIPLOMA',
  certificate: 'CERTIFICATE',
  skills_mastered: 'SKILLS MASTERED',
  skills_to_develop: 'SKILLS TO DEVELOP',
  account_emitter: 'account_emitter',
  text_to_search: 'text_to_search',
  posts: 'POSTS',
  documents: 'DOCUMENTS',
  about: 'ABOUT',
  countries: 'countries',
  faq: 'faq',
  no_filename: 'no_file.txt',
  page_mode: 'page_mode',
  setting_tab: 'setting_tab'
};
