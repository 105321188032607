import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Faq} from "../../models/faq";
import {LocalStorageService} from "../../services/local-storage.service";
import {AccountDetailsResource} from "ApiModuleAccount";

@Component({
  selector: 'sm-assistance-faq',
  templateUrl: './assistance-faq.component.html',
  styleUrls: ['./assistance-faq.component.scss']
})
export class AssistanceFaqComponent implements OnInit {

  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  @Output() selectedBlock = new EventEmitter<string>();
  buttonReturnTitle = 'Retour';
  loading = false;
  faqs: Faq[] = [];

  faqMap = new Map([
    ['Quels pays sont couvert par Skills Mates ?', 'Le nom de domaine Skills-Mates.com ne fait l’objet d’aucune restriction, par conséquent il est accessible partout dans le monde. Cependant, la plateforme est pour le moment uniquement disponible en français et ainsi son usage est facilité au public francophone. Toutefois nous envisageons de nous ouvrir à de nouvelles langues dans les années à venir.'],
    ['Quel âge dois-je avoir pour m\'inscrire ?', 'Conformément aux exigences législatives relatives à l’utilisation des plateformes numériques, nous requérons que nos utilisateurs aient au moins 13 ans et plus.'],
    ['J\'ai du mal à m\'inscrire, que dois-je faire ?', 'Veuillez nous envoyer un email à l’adresse suivante contact@skills-mates.com en précisant les difficultés ou points de blocages rencontrés lors de votre inscription. N’hésitez pas à y joindre des captures d’écran afin de nous aider à identifier et résoudre le problème dans les meilleurs délais.'],
    ['Dois-je payer des frais ?', 'Non ! L\'utilisation de Skills Mates est entièrement gratuite ! Nous envisageons de créer une cagnotte de financement participatif afin de permettre à ceux qui le souhaitent de soutenir le projet. '],
    ['Combien de temps devrais-je m\'engager ?', 'Skills Mates est un réseau social gratuit et libre d’accès. Chaque utilisateur dispose de la possibilité de se désinscrire librement de la plateforme et de supprimer son compte à tout moment sans préavis ni délai. '],
    ['Qui a accès à mes données ?', 'Nous mobilisons vos données dans la limite des dispositions énoncées dans les conditions d’utilisation afin d’optimiser votre expérience sur la plateforme. Nous serions ainsi en mesure de vous proposer les profils, les contenus et les annonces publicitaires les mieux adaptés à vos besoins. \n Le cas échéant, nous partagerons aussi vos données avec des tiers dans le respect des contraintes légales afin de réaliser nos objectifs commerciaux et des partenariats nécessaires au fonctionnement de la plateforme. Par exemple nous pourrions souscrire à la régie publicitaire de Google afin de générer des revenus. \n En accord avec notre politique de confidentialité, toute désinscription et  suppression de compte mettra un terme à l’usage de vos données sur la plateforme.'],
    ['Quelle est la politique concernant la propriété intellectuelle ?', 'Conformément à notre charte utilisateur, vous êtes responsables des contenus que vous publiez. En ce sens, lorsque vous n’êtes pas l’auteur d’un contenu, il vous revient de vous assurer que les éléments partagés sont bien en accès libre « Creatives Commons »   et non soumis à des droits d’auteur. \n A titre d’exemple, si vous souhaitez publier le cours d’un professeur, vous devez préalablement avoir obtenu son autorisation, auquel cas vous vous exposez personnellement aux éventuelles actions légales de ce dernier.'],
    ['Comment s’assurer de la validité et de la qualité des contenus publiés ?', 'Skills Mates est un réseau social de partage de connaissances où chaque membre de la communauté apporte des contenus selon ses qualités et où chacun se sert selon ses besoins. En ce sens chaque utilisateur détient toute la liberté contribuer à ce principe sans discrimination ou restriction vis-à-vis de son degré d’appétence et d’expertise. \n Pour s’assurer de la qualité d’un contenu il revient à chacun de faire preuve de vigilance et de sagacité (discernement) afin d’évaluer la qualité des contenus ou des commentaires liés aux publications en adéquation avec les différents éléments liés au profil de l’expéditeur. \n Le parcours académique et professionnel, les diplômes publiés, le nombres d’abonnés, la description des contenus et la teneur des commentaires suscités sont autant d’éléments à prendre en compte. \n Il relève du devoir de chacun des membres de la communauté d’apporter des critiques constructives sur la base de sa maitrise des sujets et de contribuer à apprécier la qualité des contenus en toute courtoisie et bonne foi. \n Nous intégrerons progressivement différents outils de notation, de validation et de modérations des contenus.'],
    ['Comment puis-je remonter un bug sur la plateforme Skills Mates ?', 'Vous pouvez nous faire part des anomalies et difficultés rencontrées sur plateforme (avec illustration si possible) grâce à la rubrique « contactez-nous » de l’assistance au sein de la plateforme ou en nous adressant un email à contact@skills-mates.com\n L’ensemble de vos remarques et propositions nous sont précieuses car elles nous aideront à améliorer l’outil. Nous nous efforcerons de vous apporter des solutions dans les meilleurs délais et dans la mesure du possible.']
  ]);

  constructor(private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.populateFaqs();
  }

  populateFaqs(): void {
    let i = 0;
    for (const [question, answer] of this.faqMap) {
      const faq: Faq = {} as Faq;
      faq.heading = 'heading' + i;
      faq.collapse = 'collapse' + i;
      faq.question = question;
      faq.answer = answer;
      faq.accordionClass = 'collapseAccordion';
      faq.showBody = false;
      this.faqs.push(faq);
      i++;
    }
  }

  onClickReturnButton(): void {
    this.selectedBlock.emit('contactUs');
  }

  trackByFunc(index: any, value: { id: any; }): any {
    return value.id;
  }

  onClickAccordion(key: any, faq: Faq): void {
    this.resetAccordion();
    if (!faq.showBody) {
      faq.showBody = true;
      faq.accordionClass = 'collapseAccordion';
    } else {
      faq.showBody = false;
      faq.accordionClass = 'expandAccordion';
    }
  }

  resetAccordion(): void {
    this.faqs.forEach(faq => faq.showBody = false);
  }

}
