<div *ngIf="post && post.postType === 'POST_TYPE_STATUS'" class="row">
  <div class="col-12">
    <p *ngIf="post.description" [innerHTML]="post.description"
       [ngClass]="{'skillsmates-overview': toggleDisplayMoreLabel}"></p>
  </div>
</div>

<div *ngIf="post && post.postType !== 'POST_TYPE_STATUS'">
  <div class="row">
    <div class="col-12">
      <div (click)="onClickPost(post)" class="link">
        <p *ngIf="post.documentType!.startsWith('MEDIA_TYPE_LINK') && post.link">
          <a (click)="onClickMediaLink()" class="skillsmates-single-line"
             style="color: #166fd4;"><strong>{{ post.link }}</strong></a> <br/>
        </p>
        <i style="font-weight: lighter;">
          <p *ngIf="post.documentTitle" class="skillsmates-sub-title"><strong>{{ post.documentTitle }}</strong></p>
        </i>
        <i style="font-weight: lighter;">
          <sm-long-text-display [post]="post" [showReadMore]="false"
                                [text]="post.documentDescription"></sm-long-text-display>
        </i>
        <p *ngIf="post.discipline" class="skillsmates-discipline"><strong>{{ post.discipline }}</strong>
        </p>
      </div>
      <div class="more">
        <p *ngIf="post.title" class="skillsmates-title"><strong>{{ post.title }}</strong></p>
        <sm-long-text-display [post]="post" [text]="post.description"></sm-long-text-display>
      </div>
    </div>
  </div>
  <div *ngIf="hasKeyword()" class="" style="overflow-wrap: break-word; word-wrap: break-word; margin-bottom: 10px;">
    <span *ngFor="let keyword of explodeKeywords(post.keywords!)" class="widget-keyword"
          style="">{{ keyword.trim() }}</span>
  </div>
</div>
