<div>
  <sm-header *ngIf="!isOffline"></sm-header>
  <sm-offline-header *ngIf="isOffline"></sm-offline-header>
  <div class="page-wrapper sm-responsive">
    <div class="content">
      <div class="row skillsmates-container skillsmates-box-shadow p-3">
        <div class="col-12 col-sm-12 col-md-3 col-lg-3 col-xl-3 skillsmates-box-padding sm-side-menu">
          <div class="row bg-white" style="margin: auto;  border-radius: 0 0 10px 10px;">
            <ul style="list-style: none; margin: 10px 0 10px 0">
              <div (click)="onClickTab(tab)" *ngFor="let tab of tabs"
                   [ngStyle]="{'background-color': selectedTab === tab ? colors.light_blue + '22' : colors.white}"
                   class="col-md-12 skillsmates-box-top pointer"
                   style="margin: 5px;">
                <img alt="" height="30" src="{{ getTabImage(tab) | image }}" style="margin-right: 10px"/>
                <strong>{{ 'settings.' + tab | translate }}</strong>
              </div>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 skillsmates-box-padding">
          <sm-settings-account *ngIf="isAccountTab()"></sm-settings-account>
          <sm-settings-security *ngIf="isSecurityTab()"></sm-settings-security>
          <sm-settings-about *ngIf="isAboutTab()"></sm-settings-about>
          <!--    <sm-settings-confidentiality *ngIf="selectedTab === tabs[2]"></sm-settings-confidentiality>-->
          <!--    <sm-settings-locations *ngIf="selectedTab === tabs[3]"></sm-settings-locations>-->
        </div>
      </div>
    </div>
  </div>
  <sm-bottom-nav-bar *ngIf="!isOffline"></sm-bottom-nav-bar>
</div>

<!--<div class="row skillsmates-container">
  <div class="col-12" style="margin: 10px;">
    <sm-footer></sm-footer>
  </div>
</div>-->
