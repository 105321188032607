import {Component, OnInit} from '@angular/core';
import {AccountDetailsResource, AuthenticationService} from "ApiModuleAccount";
import {ActivatedRoute, Router} from "@angular/router";
import {LocalStorageService} from "../../services/local-storage.service";
import {colors} from "../../../environments/colors";
import {variables} from "../../../environments/variables";
import {Route} from "../../enums/route.enum";

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  colors = colors;
  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  tabs: string[] = ["resume", "details", "trainings", "jobs", "skills", "biography"];
  selectedTab: string = this.tabs[0];
  editMode: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService
  ) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
    let addTab = this.localStorageService.get(variables.add);
    if (addTab) {
      this.localStorageService.delete(variables.add);
      this.onClickAdd(addTab);
    }

    // let editTab = this.localStorageService.get(variables.edit);
    // if (editTab) {
    //   this.localStorageService.delete(variables.edit);
    //   this.onClickUpdate(editTab);
    // }
  }

  onClickAttribute(tab: string): void {
    this.selectedTab = tab;
  }

  onClickAdd(event: string): void {
    if (event) {
      switch (event) {
        case 'detail':
          this.selectedTab = this.tabs[1];
          break;
        case 'training':
          this.selectedTab = this.tabs[2];
          break;
        case 'diploma':
          this.selectedTab = this.tabs[2];
          break;
        case 'certification':
          this.selectedTab = this.tabs[2];
          break;
        case 'job':
          this.selectedTab = this.tabs[3];
          break;
        case 'skill':
          this.selectedTab = this.tabs[4];
          break;
        case 'biography':
          this.selectedTab = this.tabs[5];
          break;
      }
      this.editMode = true;
    }
  }

  onClickUpdate(event: any) {
    this.onClickAdd(event);
  }

  onClickReturn() {
    if (this.loggedAccount) {
      //this.localStorageService.update(variables.profile_account, this.account); // n'a pas changé
      this.router.navigate([Route.PROFILE]);
    }
  }
}
