export enum Route {
  ROOT = "/",
  LOGIN = "/login",
  DASHBOARD = "/dashboard",
  PROFILE = "/profile",
  EDIT_POST = "/edit-post",
  MESSAGES = "/messages",
  NOTIFICATIONS = "/notifications",
  NETWORK = "/network",
  SEARCH = "/search",
  POST = "/post",
  POST_DETAILS = "/post-details",
  POST_DETAIL = "/post-detail",
  ASSISTANCE = "/assistance",
  SETTINGS = "/settings",
  DOCUMENTS = "/documents",
  EDIT_PROFILE = "/edit-profile",
  TERMS_AND_CONDITIONS = "/terms-and-conditions",
  FAQ = "/faq",
  ACCOUNT_CREATED = "/account-created",
  PASSWORD_RESET = "/password-reset"
}
