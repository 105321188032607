<div class="skillsmates-container">
  <div class="row">
    <div class="col need-assistance" (click)="onClickAssistance()">
      Besoin d'assistance ?
    </div>
  </div>
  <sm-assistance-dashboard
    *ngIf="displayAssistance"
    (selectedBlock)="getSelectedBlock($event)">
  </sm-assistance-dashboard>

  <sm-assistance-faq
    *ngIf="selectedBlock === 'faq'"
    (selectedBlock)="onClickAssistance()">
  </sm-assistance-faq>

  <sm-assistance-contact-us
    *ngIf="selectedBlock === 'contactUs'"
    (selectedBlock)="onClickAssistance()">
  </sm-assistance-contact-us>
</div>

<div class="row skillsmates-container">
  <div class="col-12" style="margin: 10px;">
    <sm-footer></sm-footer>
  </div>
</div>
