import {Pipe, PipeTransform} from '@angular/core';
import {ParameterResource} from "ApiModuleAccount";

@Pipe({
  name: 'arrayLabel'
})
export class ArrayLabelPipe implements PipeTransform {

  transform(resources: ParameterResource[]): string[] {
    let list: string[] = [];
    if (resources && resources.length > 0) {
      resources.forEach(value => {
        if (value && value.label) {
          list.push(value.label)
        }
      });
    }
    return list;
  }

}
