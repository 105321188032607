<div>
  <div style="padding: 10px;" (click)="onClickReturn()">
    <sm-image-text
      [bgColor]="'#D3D3D3'"
      image="{{'left-arrow' | image}}"
      text="{{'terms_and_conditions.return' | translate}}">
    </sm-image-text>
  </div>

  <div>
    <h1>1. Introduction</h1>
    <h2>1.1 Contrat</h2>
    <p>Lorsque vous utilisez nos Services, vous acceptez l’intégralité des présentes conditions.
      Votre utilisation de nos Services est également soumise à notre Politique relative aux
      cookies et à notre Politique de confidentialité, qui expliquent nos méthodes en matière de
      collecte, d’utilisation, de partage et de stockage de vos informations personnelles.
    </p>
    <p>Vous reconnaissez qu’en cliquant sur “S’inscrire”, “S’inscrire sur Skills Mates” ou un
      élément similaire, en vous inscrivant à nos services (décrits ci-dessous), en y accédant ou
      en les utilisant, vous acceptez de conclure un contrat qui vous engage légalement avec
      Skills Mates (même si vous utilisez nos Services pour le compte d’une société). Si vous
      n’acceptez pas ce contrat (le “Contrat” ou les “Conditions d’utilisation”), ne cliquez pas
      sur “S’inscrire” (ou sur un élément similaire) et abstenez-vous d’accéder à nos Services et
      de les utiliser. Si vous souhaitez résilier ce contrat, vous pouvez le faire à tout moment
      en fermant votre compte et en n’utilisant plus nos Services.
    </p>
    <h3>Services</h3>
    <p>Le présent Contrat s’applique à skills-mates.com, aux applications de la marque Skills Mates,
      à Skills Mates et aux autres sites, applications, communications et autres services en lien
      avec Skills Mates qui déclarent être proposés en vertu du présent Contrat (les “Services”),
      y compris la collecte hors site de données pour ces Services, notamment nos publicités, et
      les plug-ins “Postuler avec Skills Mates” et “Partager sur Skills Mates”. Les utilisateurs
      de nos Services inscrits sont des “Membres” et les utilisateurs non inscrits sont des
      “Visiteurs”.
    </p>
    <h3>Skills Mates</h3>
    <p>Vous concluez le présent Contrat avec Skills Mates (également désigné par “nous”).</p>
    <p>Nous utilisons le terme “Pays désignés” pour faire référence aux pays de l’Union européenne
      (UE), de l’Espace économique européen (EEE) et à la Suisse.
    </p>
    <p>Si vous résidez dans les “Pays désignés”, vous concluez le présent Contrat avec Skills Mates
      SAS (“Skills Mates France”) et Skills Mates France contrôlera les données personnelles que
      vous avez fournies à nos Services, ou que nous avons collectées par le biais de nos
      Services, ou que nous avons traitées en relation avec nos Services.
    </p>
    <p>Si vous résidez en dehors des “Pays désignés”, vous concluez le présent Contrat avec Skills
      Mates Corporation (“Skills Mates Corp.”) et Skills Mates France contrôlera les données
      personnelles que vous avez fournies à nos Services, ou que nous avons collectées par le
      biais de nos Services, ou que nous avons traitées en relation avec nos Services.
    </p>
    <h3>Le présent Contrat s’applique aux Membres et aux Visiteurs.</h3>
    <p>En tant que Visiteur ou Membre de nos Services, la collecte, l’utilisation et le partage de
      vos données personnelles sont soumis à la présente Politique de confidentialité (qui inclut
      notre Politique relative aux cookies ainsi que d’autres documents indiqués dans la Politique
      de confidentialité) et à ses versions à venir et mises à jour.
    </p>
    <h2>1.2 Membres et Visiteurs</h2>
    <p>Lorsque vous vous inscrivez aux Services Skills Mates, vous en devenez Membre. Si vous avez
      choisi de ne pas vous inscrire à nos Services, vous pouvez accéder à certaines
      fonctionnalités en tant que “Visiteur”.
    </p>
    <h2>1.3 Modification</h2>
    <h3>Nous pouvons apporter des modifications au Contrat.</h3>
    <p>Nous pouvons modifier ce Contrat, notre Politique de confidentialité et notre Politique
      relative aux cookies de temps à autre. Si nous y apportons des modifications importantes,
      nous vous en informerons via nos Services, ou par d’autres moyens, pour vous donner
      l’opportunité d’examiner les modifications avant qu’elles prennent effet. Nous reconnaissons
      que les modifications ne peuvent pas être rétroactives. Si vous vous opposez à l’une
      quelconque des modifications, vous pouvez fermer votre compte. Si vous continuez à utiliser
      nos Services après la publication ou l’envoi d’un avis concernant les modifications que nous
      avons apportées aux présentes conditions, cela signifie que vous acceptez les conditions
      mises à jour à compter de leur date d’entrée en vigueur.
    </p>
    <h1>2. Obligations</h1>
    <h2>2.1 Droit aux Services</h2>
    <p>Dans le cadre de ce Contrat, vous nous garantissez les choses suivantes :</p>
    <p>Vous avez la capacité de conclure ce Contrat et vous avez l’âge minimal requis.</p>
    <p>Les Services ne sont en aucun cas destinés aux personnes de moins de 13 ans.</p>
    <p>Pour utiliser les Services, vous devez : (1) avoir l’“Âge minimal” (défini ci-dessous) ou
      plus ; (2) être titulaire d’un seul compte Skills Mates à votre vrai nom ; et (3) ne pas
      avoir été interdit par Skills Mates d’utiliser ses Services. La création d’un compte avec
      des informations erronées constitue une violation de nos conditions, notamment dans le cas
      de comptes enregistrés au nom de tiers ou de personnes de moins de 13 ans.
    </p>
    <p>L’âge minimal est de 13 ans (“Âge minimal”). Toutefois, dans l’hypothèse où la loi exigerait
      que vous soyez plus âgé qu’indiqué pour que Skills Mates vous fournisse légalement les
      Services sans accord parental (y compris l’utilisation de vos données personnelles), l’âge
      minimal serait alors celui stipulé par la loi.
    </p>
    <h2>2.2 Votre compte</h2>
    <p>Vous garderez votre mot de passe secret.</p>
    <p>Vous ne partagerez pas un compte avec une autre personne et vous respecterez nos règles et la
      loi.
    </p>
    <p>Les membres sont des titulaires de compte. Vous acceptez : (1) d’utiliser un mot de passe
      fort et de le conserver de façon confidentielle ; (2) de ne pas transférer une ou plusieurs
      parties de votre compte (comme les relations) et (3) de vous conformer à la loi et aux
      règles décrites dans la liste À faire et à ne pas faire et dans les Politiques de la
      communauté professionnelle. Vous êtes responsable de toute utilisation de votre compte à
      moins que vous ne le clôturiez ou que vous ne signaliez une utilisation abusive.
    </p>
    <p>En ce qui concerne les relations entre vous et autrui (y compris votre employeur, votre
      établissement scolaire,), votre compte vous appartient. Cependant, si vous utilisez des
      Services achetés pour vous par un tiers (par exemple, un accès Recruteur acheté par votre
      employeur), l’acheteur du Service a le droit de contrôler les accès audit Service payant et
      d’obtenir des rapports sur son utilisation, mais il ne dispose d’aucun droit sur votre
      compte personnel.
    </p>
    <h2>2.3 Règlement</h2>
    <p>Vous vous acquitterez de vos obligations de paiement et vous acceptez que nous stockions vos
      informations de paiement. Vous acceptez que des frais et taxes puissent être ajoutés à nos
      tarifs.
    </p>
    <p>Tout remboursement est soumis à notre politique.</p>
    <p>Si vous achetez l’un de nos Services payants (“Services Premium”), vous acceptez de nous
      payer les frais et taxes applicables et de respecter les conditions supplémentaires de
      Skills Mates propres aux services payants Skills Mates. Tout défaut de paiement de ces frais
      entraînera la résiliation de vos Services payants. Vous acceptez également les éléments
      suivants :
    </p>

    <ul>
      <li>En fonction du lieu de votre transaction, des frais d’opération de change ou des prix
        différents (par exemple, les taux de change) peuvent être applicables à votre achat.
      </li>
      <li>Nous pouvons enregistrer votre méthode de paiement et continuer de vous facturer avec
        celle-ci (par exemple, votre carte de crédit) même après son expiration, pour éviter des
        interruptions de vos Services et payer les éventuels autres Services que vous avez
        achetés.
      </li>
      <li>Si vous achetez un abonnement, vous serez automatiquement facturé avec votre méthode de
        paiement au début de chaque période d’abonnement à hauteur des frais et taxes
        applicables à cette période. Pour éviter d’être facturé ultérieurement, procédez à
        l’annulation avant la date de renouvellement. Découvrez comment annuler ou suspendre vos
        Services Premium.
      </li>
      <li>L’ensemble de vos achats de Services sont soumis à la politique de remboursement de
        Skills Mates.
      </li>
      <li>Nous pouvons calculer les taxes que vous devez payer en fonction des données de
        facturation que vous nous fournissez au moment de l’achat.
      </li>
    </ul>

    <p>Vous pouvez obtenir un exemplaire de votre facture dans les paramètres de votre compte Skills
      Mates, sous “Historiques d’achats”.
    </p>

    <h2>2.4. Notifications et Messages</h2>

    <p>Vous acceptez que nous vous communiquions des notifications et des messages via nos sites
      web, applications et les coordonnées que vous nous avez fournies. Si vos coordonnées ne sont
      pas à jour, des notifications importantes sont susceptibles de ne pas vous parvenir.
    </p>
    <p>Vous acceptez que nous vous communiquions des notifications et des messages par les moyens
      suivants : (1) dans le Service ou (2) envoyés aux coordonnées que vous nous avez fournies
      (par exemple, adresse e-mail, numéro de mobile, adresse postale). Vous acceptez de tenir à
      jour vos coordonnés.1
    <p>Modifiez vos paramètres pour contrôler et limiter les messages que nous vous envoyons.

    <h2>2.5 Collecte des informations</h2>
    <p>Nous recueillons les informations personnelles suivantes :</p>
    <ul>
      <li>Nom</li>
      <li>Email</li>
      <li>Date de naissance</li>
      <li>Ville / pays de résidence</li>
      <li>Parcours professionel</li>
      <li>Photo de profil</li>
    </ul>

    <h2>2.6 Utilisation des informations</h2>
    <p>Nous utilisons vos données personnelles principalement:</p>
    <ul>
      <li><b>Authentification: </b> Votre email associé à un mot de passe sert à sécuriser l'accès votre compte </li>
      <li><b>Personnalisation: </b> Les autres informations permettent de vous offrir une expérience utilisateur
        enrichie et pers'avoir une expérience utilisateur Votre email associé à un mot de passe sert à séonnalisée</li>
    </ul>

    <h2>2.7 Gestion des données utilisateur</h2>
    <p>Les membres peuvent à tout moment supprimer leur compte et toutes les données associées via les paramètres de
      l'application.</p>

    <h2>2.8 Partage</h2>
    <p>Lorsque vous partagez des informations sur nos Services, ces informations peuvent être vues,
      copiées et utilisées par des tiers.
    <p>Nos Services permettent d’envoyer des messages et de partager des informations de multiples
      façons, notamment par le biais de votre profil, de vos articles et posts de groupes, de
      liens vers des articles d’actualité, offres d’emploi, messages. Les informations et le
      contenu que vous partagez ou publiez peuvent être vus par d’autres Membres, Visiteurs ou
      tiers (notamment en dehors des Services). Conformément aux paramètres disponibles, nous
      respecterons vos préférences concernant la visibilité du contenu et des informations (par
      exemple, le contenu des messages que vous envoyez, le partage de contenu uniquement avec des
      relations Skills Mates, la limitation de la visibilité de votre profil pour les moteurs de
      recherche ou le fait de ne pas notifier votre réseau lors de la mise à jour de votre profil
      Skills Mates).
    <p>Par défaut, aucune notification n’est envoyée à vos relations ni au public pour les activités
      de recherche d’emploi. Par conséquent, si vous postulez à un emploi via notre Service ou
      choisissez d’indiquer que vous êtes intéressé par un emploi, seul l’auteur de l’offre
      d’emploi en est informé.
    </p>
    <p>Nous n’avons aucune obligation de publier des informations ou du contenu sur notre Service.
      Nous pouvons supprimer toute information ou tout contenu avec ou sans préavis.
    </p>

    <h1>3. Vos droits et restrictions</h1>
    <h2>3.1 Votre octroi de licence à Skills Mates</h2>
    <p>Vous possédez tout le contenu, tout le feedback et toutes les données personnelles que vous
      nous fournissez mais vous nous accordez également une licence non exclusive.
    </p>
    <p>Nous respecterons vos choix quant aux personnes qui peuvent voir vos informations et
      contenus, et à l’utilisation de ces éléments pour des publicités.
    </p>
    <p>Dans le cadre de la relation entre vous et Skills Mates, vous restez propriétaire du contenu
      et des informations que vous soumettez ou publiez sur les Services, et vous accordez
      uniquement à Skills Mates et à ses sociétés affiliées la licence non exclusive suivante :
    </p>
    <p>Un droit mondial, cessible et à partir duquel une sous-licence peut être accordée,
      d’utilisation, de copie, de modification, de distribution, de publication et de traitement
      des informations et du contenu que vous fournissez via nos Services et les services de
      tiers, sans autre consentement, préavis et/ou rémunération à votre égard ou à l’égard de
      tiers. Ces droits sont restreints de la manière suivante :
    </p>

    <ol>
      <li>Vous pouvez résilier cette licence pour un contenu spécifique en supprimant ce contenu
        des Services, ou en règle générale en clôturant votre compte, (a) sauf si, dans le cadre
        des Services, vous avez partagé ce contenu avec d’autres personnes qui l’ont copié,
        partagé à nouveau ou stocké, et (b) sous réserve d’un délai raisonnable nécessaire pour
        l’éliminer de la sauvegarde et d’autres systèmes.
      </li>
      <li>Nous n’inclurons pas votre contenu dans des publicités pour les produits et services de
        tiers sans votre consentement spécifique (y compris le Contenus Sponsorisés). Toutefois,
        nous avons le droit de placer des publicités à proximité de vos données et contenus sans
        que nous soyons tenus de vous payer ou de payer à des tiers une compensation financière.
        De même, comme il est précisé dans la Politique de confidentialité, vos actions sociales
        peuvent être visibles et incluses dans des publicités. Si vous utilisez la
        fonctionnalité d’un Service, nous pouvons le mentionner en affichant votre nom et votre
        photo à des fins de promotion dans nos Services, selon vos préférences.
      </li>
      <li>Nous demanderons votre accord si nous souhaitons donner à des tiers le droit de publier
        votre contenu hors des Services. Toutefois, si vous choisissez de partager votre post
        comme “public”, nous activerons une fonctionnalité permettant aux autres Membres
        d’intégrer ce post public à des services tiers et nous permettrons à des moteurs de
        recherche de rendre ce contenu public accessible via leurs services.
      </li>
      <li>Même s’il peut nous arriver de modifier votre contenu ou sa mise en page (par exemple en
        le traduisant ou en le transcrivant, en modifiant sa taille, sa disposition ou le type
        de fichier, ou en supprimant des métadonnées), nous ne changerons pas le sens de votre
        expression.
      </li>
      <li>Comme votre contenu et vos données vous appartiennent et que nous n’en avons que des
        droits non exclusifs, vous pouvez choisir de les mettre à la disposition d’autres
        personnes, notamment au titre d’une licence Creative Commons.
      </li>
    </ol>

    <p>Vous et Skills Mates acceptez que si le contenu inclut des données personnelles, celles-ci
      sont soumises à notre Politique de confidentialité.
    </p>
    <p>Vous et Skills Mates acceptez que nous puissions accéder aux informations et données
      personnelles que vous avez fournies, les stocker, les traiter et les utiliser conformément
      aux conditions de la Politique de confidentialité et à vos préférences (y compris vos
      paramètres).
    </p>
    <p>En soumettant des suggestions ou du feedback sur les Services à Skills Mates, vous acceptez
      que nous puissions utiliser et partager (sans le faire obligatoirement) ce feedback à toute
      fin et sans vous offrir de compensation financière.
    </p>
    <p>Vous vous engagez à ne nous communiquer que des informations et du contenu que vous avez le
      droit de partager et à avoir un profil Skills Mates véridique.
    </p>
    <p>Vous vous engagez à ne fournir que du contenu et des données qui n’enfreignent aucune loi et
      ne violent les droits de personne (ni aucun droit de propriété intellectuelle). De même,
      vous garantissez la véracité des informations de votre profil. En application de la loi de
      certains pays, Skills Mates peut être obligé de retirer des contenus et informations.
    </p>

    <h2>3.2 Disponibilité des Services</h2>
    <p>Nous pouvons modifier ou mettre fin à un Service, ou modifier nos prix dans le futur.</p>
    <p>Vous pouvez changer, suspendre ou discontinuer n’importe quel Service. Nous pouvons également
      modifier nos prix en vous avertissant préalablement dans un délai raisonnable dans la mesure
      autorisée par la loi.
    </p>
    <p>Nous ne vous garantissons pas que nous stockerons ou continuerons à afficher les informations
      et le contenu que vous avez publiés. Skills Mates n’est pas un Service de stockage. Vous
      acceptez qu’aucune obligation de stocker, de maintenir ou de vous fournir une copie des
      informations ou du contenu que vous ou d’autres personnes nous ont transmis ne nous incombe,
      sauf dans la mesure où la loi applicable l’impose et tel que décrit dans notre Politique de
      confidentialité.
    </p>

    <h2>3.3 Autres sites, contenus et applications</h2>
    <p>Votre utilisation du contenu et des informations de tiers publiés sur nos Services se fait à
      vos propres risques.
    </p>
    <p>Des tiers peuvent commercialiser leurs propres produits et services par l’intermédiaire de
      nos Services. Nous ne sommes pas responsables des activités de ces tiers.
    </p>
    <p>Lors de votre utilisation des Services, vous pouvez être confronté à des informations et des
      contenus inexacts, partiels, différés, prêtant à confusion, illégaux, choquants ou autrement
      dommageables. De manière générale, Skills Mates ne relit pas le contenu publié par ses
      Membres ou d’autres personnes. Vous reconnaissez que nous ne sommes pas responsables du
      contenu et des informations des tiers (y compris ceux des autres membres). Nous ne pouvons
      pas toujours empêcher cette utilisation abusive de nos Services et vous reconnaissez que
      nous ne sommes pas responsables dans ce cas. Vous acceptez également le risque que vous ou
      votre organisation soyez associés à tort à du contenu concernant des tiers lorsque nous
      informons vos relations et les personnes qui vous suivent que vous ou votre organisation
      avez été mentionnés dans l’actualité.
    </p>
    <p>Skills Mates peut aider à connecter des Membres qui offrent leurs services (coaching
      professionnel, comptabilité, etc.) à d’autres Membres recherchant ces services. Skills Mates
      ne fournit pas et n’emploie personne pour fournir ces services. Vous devez avoir 18 ans
      révolus pour offrir, fournir ou approvisionner ces services. Vous reconnaissez que Skills
      Mates ne supervise, ne dirige, ne contrôle ou ne surveille pas les Membres qui fournissent
      ces services et acceptez que (1) Skills Mates n’est pas responsable de l’offre, de la
      fourniture ou de l’approvisionnement de ces services, (2) Skills Mates ne cautionne aucun
      Membre pour les services qu’il offre et (3) rien ne peut créer de relation d’emploi,
      d’agence ou d’entreprise commune entre Skills Mates et un Membre offrant des services. Si
      vous êtes un Membre offrant des services, vous déclarez et garantissez que vous possédez
      toutes les licences requises et que vous fournirez les services en suivant nos Politiques de
      la communauté professionnelle.
    </p>
    <p>De même, Skills Mates peut vous aider à vous inscrire et/ou à participer à des événements
      organisés par des Membres et à entrer en relation avec d’autres Membres qui participent à de
      tels événements. Vous acceptez (1) que Skills Mates ne soit pas responsable de la conduite
      des Membres ou d’autres participants à de tels événements, (2) que Skills Mates ne cautionne
      aucun des événements listés dans nos Services, (3) que Skills Mates ne contrôle ni ne
      vérifie aucun de ces événements et (4) de vous conformer aux conditions qui s’appliquent à
      de tels événements.
    </p>

    <h2>3.4 Restrictions</h2>
    <p>Nous avons le droit de restreindre la manière dont vous vous connectez et interagissez sur
      nos Services.
    </p>
    <p>Skills Mates se réserve le droit de limiter votre utilisation des Services, notamment le
      nombre de vos relations et votre faculté à contacter d’autres membres. Skills Mates se
      réserve le droit de restreindre, suspendre ou clôturer votre compte si vous ne respectez pas
      ce Contrat ou la loi ou que votre utilisation des Services est abusive (par exemple, si vous
      ne respectez pas les règles de l’article À faire et à ne pas faire ou des Politiques de la
      communauté professionnelle).
    </p>

    <h2>3.5 Droits de propriété intellectuelle</h2>
    <p>Nous vous informons de nos droits en matière de propriété intellectuelle.</p>
    <p>Skills Mates se réserve l’intégralité de ses droits de propriété intellectuelle liés aux
      Services. Les marques de commerce et logos utilisés en relation avec les Services
      appartiennent à leurs propriétaires respectifs. Skills Mates, les logos comportant la marque
      “in” et les autres marques, les marques de Service, illustrations et logos Skills Mates
      utilisés pour nos Services sont des marques de commerce ou des marques déposées de Skills
      Mates.
    </p>
    <h2>3.6 Traitement automatisé</h2>
    <p>Nous utilisons les données et informations vous concernant pour proposer, à vous et à des
      tiers, des suggestions pertinentes.
    </p>
    <p>Nous utilisons les informations et données que vous fournissez et dont nous disposons pour
      vous proposer des recommandations de relations, de contenu et des fonctionnalités pouvant
      vous être utiles. Par exemple, nous utilisons des données et des informations vous
      concernant pour vous suggérer des offres d’emploi et vous recommander auprès de recruteurs.
      En maintenant les informations de votre profil à jour et exactes, vous nous aidez à rendre
      nos recommandations plus pertinentes.
    </p>
    <h1>4. Exonération et limitation de responsabilité</h1>
    <h2>4.1 Absence de garantie</h2>
    <p>Ceci constitue notre avis de non-responsabilité quant à la qualité, sûreté ou fiabilité de
      nos Services.
    </p>
    <p>SKILLS MATES ET SES SOCIÉTÉS AFFILIÉES NE FORMULENT AUCUNE DÉCLARATION ET NE DONNENT AUCUNE
      GARANTIE CONCERNANT LES SERVICES, Y COMPRIS QUE LES SERVICES NE SERONT PAS INTERROMPUS OU
      SANS ERREUR ET FOURNISSENT LES SERVICES (Y COMPRIS LE CONTENU ET LES INFORMATIONS) “EN
      L’ÉTAT” ET “SELON LES DISPONIBILITÉS”. DANS TOUTE LA MESURE PERMISE PAR LA LOI APPLICABLE,
      SKILLS MATES ET SES SOCIÉTÉS AFFILIÉES REJETTENT TOUTE GARANTIE IMPLICITE OU LÉGALE, Y
      COMPRIS TOUTES LES GARANTIES IMPLICITES DE TITRE, D’EXACTITUDE DES DONNÉES, D’ABSENCE DE
      CONTREFAÇON, DE QUALITÉ MARCHANDE OU D’ADÉQUATION À UN USAGE PARTICULIER.
    </p>
    <h2>4.2 Exclusion de responsabilité</h2>
    <p>Restrictions à notre responsabilité juridique envers vous.</p>
    <p>DANS TOUTE LA MESURE PERMISE PAR LA LOI (ET À MOINS QUE SKILLS MATES NE CONCLUE UN ACCORD
      ÉCRIT SÉPARÉ QUI ANNULE CE CONTRAT), SKILLS MATES, Y COMPRIS SES SOCIÉTÉS AFFILIÉES, N’AURA
      AUCUNE RESPONSABILITÉ DÉCOULANT DE CE CONTRAT POUR DES PERTES DE BÉNÉFICES OU OPPORTUNITÉS
      COMMERCIALES PERDUES, LA RÉPUTATION (PAR EX. DES DÉCLARATIONS INSULTANTES OU DIFFAMATOIRES),
      LA PERTE DE DONNÉES (PAR EX. LES INTERRUPTIONS OU LES PERTES DE VOS DONNÉES OU DE VOTRE
      CONTENU, DE LEUR UTILISATION, OU DE CHANGEMENTS APPORTÉS) OU TOUS DOMMAGES INDIRECTS,
      FORTUITS, CONSÉCUTIFS, SPÉCIAUX OU PUNITIFS.
    </p>
    <p>SKILLS MATES ET SES SOCIÉTÉS AFFILIÉES N’AURONT PAS DE RESPONSABILITÉ ENVERS VOUS EN RELATION
      AVEC CE CONTRAT POUR DES MONTANTS QUI DÉPASSENT (A) LE MONTANT TOTAL DES FRAIS PAYÉS OU
      PAYABLES PAR VOUS À SKILLS MATES POUR LES SERVICES PENDANT LA DURÉE DE CE CONTRAT, LE CAS
      ÉCHÉANT, OU (B) 1 000 EUROS.
    </p>
    <h2>4.3 Base des négociations et exclusions</h2>
    <p>Les restrictions de responsabilité de cette section 4 s’inscrivent dans la base de
      négociation entre vous et Skills Mates et s’appliquent à toutes les demandes de
      dédommagement (par exemple, garantie, responsabilité civile, négligence, contrat et droit) y
      compris si Skills Mates ou ses sociétés affiliées ont été informées de l’éventualité d’un
      tel dommage, et même si ces recours en responsabilité ne remplissent pas leur ultime
      finalité.
    </p>
    <p>Ces restrictions de responsabilité ne s’appliquent pas en cas de mort ou de blessure
      personnelle, de fraude, de négligence grave ou de faute intentionnelle, ou en cas de
      négligence due à la violation d’une obligation substantielle, une obligation assez
      substantielle pour en faire la condition préalable pour fournir des services et sur lesquels
      vous pouvez raisonnablement compter, mais uniquement dans la mesure où les dommages ont été
      causés directement par la violation et étaient prévisibles à la conclusion de ce Contrat et
      dans la mesure où ils sont typiques dans le contexte de ce Contrat.
    </p>
    <h1>5. Résiliation</h1>
    <p>Les deux parties peuvent résilier ce Contrat, mais certains droits et obligations
      continueront d’exister.
    </p>
    <p>Vous et Skills Mates pouvez mettre fin à ce Contrat à tout moment en remettant un préavis à
      l’autre partie. Dès la résiliation, vous perdez le droit d’accès ou d’utilisation des
      Services. Les stipulations suivantes survivent à la résiliation :
    </p>
    <ul>
      <li>Nos droits relatifs à l’utilisation et à la divulgation de votre feedback ;</li>
      <li>Les droits des Membres et/ou Visiteurs à continuer de repartager le contenu et les
        informations que vous avez partagés par les Services ;
      </li>
      <li>Les articles 4, 6 et 8.2 de ce Contrat ;</li>
      <li>Toute somme due par l’une ou l’autre partie préalablement à la résiliation reste due
        après la résiliation.
      </li>
    </ul>
    <p>Vous pouvez consulter notre assistance clientèle pour clôturer votre compte.</p>

    <h1>6. Droit applicable et règlement des litiges</h1>
    <p>Dans le cas peu probable d’un différend d’ordre juridique survenant entre nous, Vous et
      Skills Mates acceptez de le résoudre devant les juridictions de Paris en France en vertu du
      droit Français.
    </p>
    <p>Cette section ne peut en aucun cas vous priver des protections des consommateurs obligatoires
      en vertu de la législation du pays dans lequel vous obtenez les Services, c’est-à-dire où
      vous résidez habituellement. Si vous résidez dans un des Pays désignés : vous et Skills
      Mates France acceptez que les lois d’France, à l’exclusion des règles sur les conflits de
      lois, régissent exclusivement tout litige en lien avec le présent Contrat et/ou les
      Services. Vous et Skills Mates France acceptez que les réclamations et litiges puissent être
      réglés uniquement à Paris, en France, et nous acceptons chacun la compétence in personam des
      tribunaux situés à Paris, en France.
    </p>
    <p>Pour ceux qui sont situés en dehors des Pays désignés, y compris en dehors des États-Unis :
      vous et Skills Mates acceptez que les lois Françaises, à l’exclusion de ses règles sur les
      conflits de lois, régissent exclusivement les litiges en lien avec le présent Contrat et/ou
      les Services. Vous et Skills Mates acceptez que toutes les réclamations et tous les litiges
      puissent être réglés uniquement dans les tribunaux régionaux ou nationaux de Paris, France,
      et vous et Skills Mates acceptez chacun la compétence in personam de ces tribunaux.
    </p>

    <h1>7. Conditions d’ordre général</h1>
    <p>Voici des détails importants sur ce Contrat.</p>
    <p>Si une juridiction ayant autorité sur le présent Contrat décide qu’une ou plusieurs clauses
      ne sont pas exécutoires, vous et nous acceptons que cette juridiction les modifie tout en
      conservant leur finalité. Si la juridiction n’est pas en mesure de le faire, vous et nous
      acceptons de demander à la juridiction de supprimer cette partie non exécutoire et de faire
      appliquer le reste du Contrat.
    </p>
    <p>Le présent Contrat (y compris toutes autres conditions auxquelles nous pourrions vous
      soumettre si vous utilisez une fonctionnalité des Services) est le seul Contrat entre nous
      concernant les Services. Il annule et remplace tous les Contrats antérieurs pour les
      Services.
    </p>
    <p>Si nous n’agissons pas suite à une violation de ce Contrat, cela ne signifie pas que Skills
      Mates renonce à faire valoir ses droits en application du présent Contrat. Vous ne pouvez
      pas céder ou transférer ce Contrat (ni votre adhésion aux Services ou leur utilisation) à
      une autre personne sans notre consentement. Toutefois, vous acceptez que Skills Mates puisse
      céder le présent Contrat à ses sociétés affiliées ou à un acquéreur sans votre consentement.
      Le présent Contrat ne contient aucune stipulation pour autrui.
    </p>
    <p>Vous convenez que les adresses mentionnées dans l’article 10 constituent le seul moyen de
      nous faire parvenir une notification juridique.
    </p>
    <h1>8. Bonnes pratique a faire et Mauvaises pratique à ne pas faire sur Skills Mates</h1>
    <h2>8.1 Bonnes pratique à faire</h2>
    <p>Skills Mates est une communauté de professionnels. Cette liste “À faire et à ne pas faire”
      ainsi que nos Politiques de la communauté professionnelle délimitent ce que vous pouvez
      faire et ne pas faire sur nos Services.
    </p>
    <p>Vous vous engagez à :</p>
    <ol>
      <li>Respecter toutes les lois applicables, y compris, de manière non limitative, les lois en
        matière de protection de la vie privée, les lois relatives aux droits de propriété
        intellectuelle, les lois relatives aux e-mails non sollicités, les lois relatives au
        contrôle des exportations, les lois fiscales et les exigences réglementaires ;
      </li>
      <li>Nous communiquer des informations exactes et à les mettre à jour ;</li>
      <li>Utiliser votre nom réel sur votre profil ;</li>
      <li>Utiliser les Services de façon professionnelle, en « bon père de famille ».</li>
    </ol>

    <h2>8.2. Mauvaises pratique à ne pas faire</h2>
    <p>Vous vous engagez à ne pas :</p>
    <ol>
      <li>Créer une fausse identité sur Skills Mates, contrefaire votre identité, créer un profil
        de Membre pour n’importe qui d’autre que vous-même, ou utiliser ou tenter d’utiliser le
        compte d’un autre ;
      </li>
      <li>Développer, prendre en charge ou utiliser des logiciels, des dispositifs, des scripts,
        des robots ou tout autre moyen ou processus (notamment des robots d’indexation, des
        modules d’extension de navigateur et compléments, ou toute autre technologie) visant à
        effectuer du web scraping des Services ou à copier par ailleurs des profils et d’autres
        données des Services ;
      </li>
      <li>Remplacer toute fonctionnalité de sécurité ou contourner ou éviter tout contrôle d’accès
        ou utiliser des limites de Service (comme des limitations sur des recherches de mot-clé
        ou des vues de profil) ;
      </li>
      <li>Copier, utiliser, divulguer ou distribuer toute information obtenue auprès des Services,
        que ce soit directement ou par le biais de tiers (tels que des moteurs de recherche),
        sans le consentement de Skills Mates ;
      </li>
      <li>Divulguer des informations que vous n’avez pas l’autorisation de communiquer (comme des
        données à caractère personnel d’autres personnes, y compris votre employeur) ;
      </li>
      <li>Violer les droits de propriété intellectuelle d’autrui, concernant notamment les droits
        d’auteur, brevets, marques, secrets commerciaux ou tout autre droit de propriété. Par
        exemple, ne pas copier ni distribuer (sauf par le biais de la fonctionnalité de partage
        mise à disposition) les posts ou tout contenu de membres sans leur permission, qu’ils
        peuvent donner en publiant sous une licence Creative Commons ;
      </li>
      <li>Violer les droits de propriété intellectuelle ou d’autres droits de Skills Mates, ce qui
        comprend, mais sans s’y limiter, (i) la copie ou la distribution de nos vidéos
        d’apprentissage ou d’autres supports ou (ii) la copie ou la distribution de nos
        technologies, à moins qu’elles n’aient été diffusées sous licence open source, (iii)
        l’utilisation du terme “Skills Mates” ou de nos logos dans un nom commercial, une
        adresse e-mail ou une URL, sauf de la manière décrite dans les Consignes sur
        l’utilisation des marques ;
      </li>
      <li>Publier quoi que ce soit qui contienne des virus, vers informatiques ou tout autre code
        dangereux ;
      </li>
      <li>Faire de l’ingénierie inverse, décompiler, désassembler, déchiffrer ou, de manière
        générale, tenter d’obtenir le code source des Services ou de toute technologie connexe
        qui n’est pas en open source ;
      </li>
      <li>Sous-entendre ou déclarer que vous êtes affilié ou sponsorisé par Skills Mates sans
        notre consentement exprès (par exemple, vous faire passer pour un formateur agréé Skills
        Mates) ;
      </li>
      <li>Louer, proposer en bail, prêter, commercialiser, vendre/revendre ou monnayer autrement
        les Services ou données associées ou y accéder sans l’accord de Skills Mates ;
      </li>
      <li>Créer des liens profonds vers nos Services à toute fin autre que de promouvoir votre
        profil ou un groupe sur nos Services, sans l’accord de Skills Mates ;
      </li>
      <li>Utiliser des bots informatiques ou d’autres méthodes automatisées afin d’accéder aux
        Services, ajouter ou télécharger des contacts, envoyer ou rediriger des messages ;
      </li>
      <li>Surveiller la disponibilité, la performance ou la fonctionnalité des Services à des fins
        concurrentielles ;
      </li>
      <li>Utiliser la technique du “framing”, de la “mise en miroir” ou, d’une manière générale,
        simuler l’apparence ou le fonctionnement des Services ;
      </li>
      <li>Recouvrir ou modifier autrement les Services ou leur apparence (par exemple en insérant
        des éléments dans les Services ou en supprimant, en couvrant ou en masquant une
        publicité incluse sur les Services) ;
      </li>
      <li>Perturber le fonctionnement des Services ou imposer une charge disproportionnée sur
        ceux-ci (p.ex. spam, attaque par déni de service, virus, algorithme de jeu) ; et/ou
        violer les Politiques de la communauté professionnelle ou toute condition supplémentaire
        en rapport avec un Service spécifique fourni, lors de votre inscription à un tel Service
        ou de votre première utilisation de ce Service.
      </li>
    </ol>
    <h1>9. Plaintes relatives au contenu</h1>
    <p>Coordonnées pour les plaintes relatives au contenu publié par nos Membres.</p>
    <p>Nous respectons les droits de propriété intellectuelle d’autrui. Nous exigeons que les
      membres publient des informations exactes qui ne contreviennent pas aux droits de propriété
      intellectuelle de tiers. Nous disposons d’une politique et d’une procédure de plainte
      concernant le contenu publié par nos Membres.
    </p>
    <h1>10. Comment nous contacter</h1>
    <p>Nos coordonnées. Notre Assistance clientèle fournit également des informations sur nos
      Services.
    </p>
    <p>Pour les questions d’ordre général, vous pouvez nous contacter en ligne. Pour les mentions
      légales ou les actes de procédure, vous pouvez nous écrire à ces adresses.
    </p>
  </div>

  <div style="padding: 10px;" (click)="onClickReturn()">
    <sm-image-text
      [bgColor]="'#D3D3D3'"
      image="{{'left-arrow' | image}}"
      text="{{'terms_and_conditions.return' | translate}}">
    </sm-image-text>
  </div>
</div>
