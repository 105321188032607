import {Component, Input, OnInit} from '@angular/core';
import {
  AccountDetailsResource,
  AccountService,
  CertificationResponse,
  CertificationService,
  DiplomaResponse,
  DiplomaService,
  JobResponse,
  JobService,
  SkillResource,
  SkillResponse,
  SkillService,
  TrainingResponse,
  TrainingService
} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import LevelEnum = SkillResource.LevelEnum;

@Component({
  selector: 'sm-account-curriculum',
  templateUrl: './account-curriculum.component.html',
  styleUrls: ['./account-curriculum.component.scss']
})
export class AccountCurriculumComponent implements OnInit {

  @Input() account!: string;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  jobResponse: JobResponse = {} as JobResponse;
  trainingResponse: TrainingResponse = {} as TrainingResponse;
  diplomaResponse: DiplomaResponse = {} as DiplomaResponse;
  certificationResponse: CertificationResponse = {} as CertificationResponse;
  skillResponse: SkillResponse = {} as SkillResponse;
  biography: string = "";

  constructor(private localStorageService: LocalStorageService,
              private jobService: JobService,
              private trainingService: TrainingService,
              private skillService: SkillService,
              private certificationService: CertificationService,
              private diplomaService: DiplomaService,
              private accountService: AccountService) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    /* this.account = this.loggedAccount; */
  }

  ngOnInit() {
    this.fetchAccount();
    this.fetchJobs();
    this.fetchTrainings();
    this.fetchDiplomas();
    this.fetchCertifications();
    this.fetchSkills();
  }

  fetchJobs() {
    this.jobService.findJobByAccount(this.account ?? "").subscribe({
      next: response => {
        this.jobResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchTrainings() {
    this.trainingService.findTrainingByAccount(this.account ?? "").subscribe({
      next: response => {
        this.trainingResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchDiplomas() {
    this.diplomaService.findDiplomaByAccount(this.account ?? "").subscribe({
      next: response => {
        this.diplomaResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchCertifications() {
    this.certificationService.findCertificationByAccount(this.account ?? "").subscribe({
      next: response => {
        this.certificationResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchSkills() {
    this.skillService.findSkillByAccount(this.account ?? "").subscribe({
      next: response => {
        this.skillResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  fetchAccount(): void {
    this.accountService.findAccount(this.account).subscribe({
      next: response => {
        this.biography = response.resource?.biography!;
      },
      error: error => {
      },
      complete: () => {
      }
    })
  }

  getLevel(code: string): string {
    let level: string = "";
    if (code) {
      switch (code) {
        case LevelEnum._1:
          level = "Notions de base";
          break;
        case LevelEnum._2:
          level = "A approfondir"
          break;
        case LevelEnum._3:
          level = "Bien";
          break;
        case LevelEnum._4:
          level = "Trés bien"
          break;
        default:
          level = "Notions de base";
          break;
      }
    }
    return level;
  }

  getSkillImage(level?: SkillResource.LevelEnum): string {
    switch (level) {
      case "LEVEL_1":
        return 'red-arrow'
      case "LEVEL_2":
        return 'orange-arrow'
      case "LEVEL_3":
        return 'green-arrow'
      case "LEVEL_4":
        return 'green-arrow-plus'
      default :
        return 'red-arrow'
    }
  }

  computeTotalTrainings(trainings: TrainingResponse, diplomas: DiplomaResponse, certifications: CertificationResponse): number {
    let total: number = 0;
    total += (trainings.meta ? trainings.meta.total : 0) ?? 0;
    total += (diplomas.meta ? diplomas.meta.total : 0) ?? 0;
    total += (certifications.meta ? certifications.meta.total : 0) ?? 0;
    return total;
  }

  isLogged(): boolean {
    return this.loggedAccount != undefined
  }

}
