import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sm-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit {

  @Input() text: string = "button";
  @Input() type: string = "default";
  @Input() image!: string;
  @Output() smButtonEvent = new EventEmitter<void>();

  ngOnInit() {
  }

  onClick(): void {
    this.smButtonEvent.emit();
  }
}
