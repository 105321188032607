import {AfterViewInit, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {AccountDetailsResource} from "ApiModuleAccount";
import {DashboardPostResource, PostDetailsResource, PostService} from "ApiModulePost";

@Component({
  selector: 'sm-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss']
})
export class PostsComponent implements OnInit, AfterViewInit {

  @Input() account: string = '';
  @Input() isDashboard: boolean = true;
  posts: PostDetailsResource[] = [];
  dashboardPosts: DashboardPostResource[] = []
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  @ViewChild('scrollFrame', {static: false}) scrollFrame!: ElementRef;
  @ViewChildren('item') itemElements!: QueryList<any>;
  loading: boolean = false;
  notEmptyPost = true;
  notScrollY = true;
  page: number = 0;
  perPage: number = 20;
  private scrollContainer: any;

  constructor(private storageService: LocalStorageService, private postService: PostService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.storageService.getLoggedAccount();
  }

  ngAfterViewInit(): void {
    if (this.isDashboard) {
      this.fetchDashboardPosts();
    } else {
      this.fetchAccountPosts();
    }
  }

  fetchDashboardPosts(): void {
    this.postService.getDashboardAccountPosts(this.account!, this.page, this.perPage)
      .subscribe({
        next: response => {
          if (response.resources && response.resources.length > 0) {
            this.dashboardPosts = this.dashboardPosts.concat(response.resources!)
            this.notEmptyPost = true;
          } else {
            this.notEmptyPost = false;
          }
          this.loading = false;
          this.notScrollY = true;
          this.getDOMElements();
        },
        error: error => {
        },
        complete: () => {
        }
      });
  }

  fetchAccountPosts(): void {
    this.postService.getAccountPostsAndShares(this.account!, this.page, this.perPage)
      .subscribe({
        next: response => {
          if (response.resources && response.resources.length > 0) {
            this.dashboardPosts = this.dashboardPosts.concat(response.resources!)
            if (this.loggedAccount == undefined) {
              this.dashboardPosts = this.dashboardPosts.slice(0, 3)
            }
            this.notEmptyPost = true;
          } else {
            this.notEmptyPost = false;
          }
          this.loading = false;
          this.notScrollY = true;
          this.getDOMElements();
        },
        error: error => {
        },
        complete: () => {
        }
      });
  }

  onScroll(): void {
    if (this.notScrollY && this.notEmptyPost && this.loggedAccount) {
      this.loading = true;
      this.notScrollY = false;
      this.page++;
      if (this.isDashboard) {
        this.fetchDashboardPosts();
      } else {
        this.fetchAccountPosts();
      }
    }
  }

  getDOMElements(): void {
    this.scrollContainer = this.scrollFrame.nativeElement;
    this.itemElements.changes.subscribe(_ => this.onItemElementsChanged());
  }

  private onItemElementsChanged(): void {
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    this.scrollContainer.scroll({
      top: this.scrollContainer.scrollHeight,
      left: 0,
      behavior: 'smooth'
    });
  }

  protected hasNoPost(): boolean {
    if (this.dashboardPosts == undefined) return true
    else return this.dashboardPosts.length <= 0;
  }


}
