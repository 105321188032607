<div class="row" style="padding: 10px 10px 0 10px;">
  <div class="col-12">
    <sm-processing [loading]="loading"></sm-processing>
    <div *ngFor="let m of interactions">
      <sm-post-interaction
        (removeInteractionEvent)="removeInteractionEvent($event)"
        [interaction]="m">
      </sm-post-interaction>
    </div>
  </div>
</div>
