import {InfoNetworkType} from "../models/infoNetworkType";


export class NetworkHelper {

  static networkTypes: InfoNetworkType[] = [
    {
      type: 'SUGGESTIONS',
      color: '#16152D',
      picture: './assets/images/follow-black.svg',
      position: 1,
      title: 'Suggestions',
      value: 0,
      description: 'En ligne'
    },
    {
      type: 'FOLLOWERS',
      color: '#16152D',
      picture: './assets/images/followers-black.svg',
      position: 2,
      title: 'Abonnés',
      value: 0,
      description: 'Ils vous suivent'
    },
    {
      type: 'FOLLOWEES',
      color: '#16152D',
      picture: './assets/images/following-black.svg',
      position: 3,
      title: 'Abonnements',
      value: 0,
      description: 'Vous les suivez'
    },
    // {
    //   type: 'aroundyou',
    //   color: '#16152D',
    //   picture: './assets/images/around-me.svg',
    //   position: 4,
    //   title: 'Autour de vous',
    //   value: 0,
    //   description: 'Autour de vous'
    // },
    // {
    //   type: 'favorites',
    //   color: '#16152D',
    //   picture: './assets/images/favoris.svg',
    //   position: 5,
    //   title: 'Favoris',
    //   value: 0,
    //   description: 'Favoris'
    // },
    {
      type: 'ONLINE',
      color: '#16152D',
      picture: './assets/images/online.svg',
      position: 6,
      title: 'En ligne',
      value: 0,
      description: 'En ligne'
    }
  ];

  static getNetworkTypeByPosition(position: string): any {
    let selectedNetworkType;
    NetworkHelper.networkTypes.forEach(elt => {
      if (elt.position.toString(10) === position) {
        selectedNetworkType = elt;
      }
    });
    return selectedNetworkType ? selectedNetworkType : NetworkHelper.networkTypes[0];
  }
}
