<sm-cursus *ngIf="certificationResponse && !editMode"
           [elements]="certificationResponse"
           (smAddEvent)="onClickAdd($event)"
           (smUpdateEvent)="onClickUpdate($event)"
           (smDoneEvent)="onClickDone(true)"
           addText="{{'edit_profile.add' | translate}} {{'edit_profile.certification' | translate}}"
           title="{{'edit_profile.certifications' | translate}}">
</sm-cursus>

<sm-edit-certification
  *ngIf="editMode"
  [certificationResource]="certificationResponse.resource!"
  (smDoneEvent)="onClickDone($event)">
</sm-edit-certification>
