import {Component, OnInit} from '@angular/core';
import {PostDetailsResource} from "ApiModulePost";
import {Route} from "../../enums/route.enum";
import {LocalStorageService} from "../../services/local-storage.service";
import {Router} from "@angular/router";
import {variables} from "../../../environments/variables";

@Component({
  selector: 'sm-post-display',
  templateUrl: './post-display.component.html',
  styleUrls: ['./post-display.component.scss']
})
export class PostDisplayComponent implements OnInit {
  post!: PostDetailsResource;
  previousRoute!: Route;

  constructor(private localStorageService: LocalStorageService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.previousRoute = this.localStorageService.get(variables.previous_route);
    this.post = this.localStorageService.get(variables.post_details);
  }

  onClickReturn(): void {
    if (this.previousRoute) {
      this.router.navigate([this.previousRoute]);
    }
  }
}
