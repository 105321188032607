<div class="row" style="color: #16152d; margin-bottom: 5px;">
  <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3">
    <div (click)="onClickAvatar()" class="profile-img" style="margin: auto;">
      <a>
        <img (error)="setDefault()" alt="" class="avatar" src="{{profileAvatar}}"
             style="border: white solid 2px;">

        <!--        <sm-image-->
        <!--          class="avatar"-->
        <!--          style="border: white solid 2px;"-->
        <!--          image="{{account.avatar}}"-->
        <!--          default="{{'user' | image}}">-->
        <!--        </sm-image>-->
      </a>
    </div>
  </div>
  <div class="col-9 col-sm-9 col-md-9 col-lg-9 col-xl-9 no-margin-padding">
    <div (click)="onClickAvatar()" class="no-margin-padding" style="font-size: medium;">
      <span>{{ account.firstname }}</span> &nbsp; <strong>{{ account.lastname }}</strong>
    </div>
    <div class="sm-profile-attributes-1 no-margin-padding">
      <div class=" scroll">
        <sm-image-text
          *ngIf="account.status"
          [bgColor]="'white'"
          image="{{account | status: 'icon'}}"
          text="{{account | status: 'label' | translate}}">
        </sm-image-text>
        <sm-image-text
          *ngIf="account.currentJob"
          [bgColor]="'white'"
          [text]="account.currentJob!"
          image="{{account | jobIcon}}">
        </sm-image-text>
        <sm-image-text
          *ngIf="account.currentCompany"
          [bgColor]="'white'"
          [text]="account.currentCompany!"
          image="{{account | companyIcon}}">
        </sm-image-text>
        <sm-image-text
          *ngIf="account.city || account.country"
          [bgColor]="'white'"
          image="{{'location' | image}}"
          text="{{account.city}} ({{account.country! | country}})">
        </sm-image-text>
      </div>
    </div>
    <div class="row text-center no-margin-padding">
      <div class="col no-margin-padding">
        <strong class="account-stats-number">{{ account.posts }}</strong> <br>
        <span class="account-stats-attributes">{{ "profile_account.posts" | translate }}</span>
      </div>
      <div class="col no-margin-padding">
        <strong class="account-stats-number">{{ account.followers }}</strong> <br>
        <span class="account-stats-attributes">{{ "profile_account.followers" | translate }}</span>
      </div>
      <div class="col no-margin-padding">
        <strong class="account-stats-number">{{ account.followees }}</strong> <br>
        <span class="account-stats-attributes"> {{ "profile_account.followees" | translate }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"></div>
      <div class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
        <sm-button-follow
          (click)="onClickFollow()"
          *ngIf="loggedAccount.uuid != account.uuid"
          [type]="hasFollowed ? 'unfollow' : 'follow'"
          image="{{'follow-black' | image}}"
          text="{{followLabel | translate}}">
        </sm-button-follow>
      </div>
      <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3"></div>
    </div>
  </div>
</div>
