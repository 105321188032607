<div class="header header-box-shadow">
  <div class="row" style="margin: auto;">
    <div class="col-6 col-sm-6 col-md-7 col-lg-8 col-xl-8">
      <a class="logo" style="text-decoration: none;">
        <img src="{{ 'symbole-skills-mates' | image }}" height="32" alt="" style="border-radius: 1px"/>
        <span class="skillsmates-single-line" style="color: #16152d; font-weight: bolder; margin-left: 0;">
          {{'sm.app_name' | translate}}
        </span>
      </a>
    </div>

    <!--Accueil, Messages, Notif...-->
    <div class="col">
      <div class="row" style="padding-top: 10px;">
        <div class="col">
          <sm-button
            [type]="'info'"
            text="{{ 'login.sign_in' | translate }}"
            (smButtonEvent)="handleGoToLogin()">
          </sm-button>
        </div>
        <div class="col sm-side-menu">
          <sm-button
            style="margin-top: 5px;"
            [type]="'success'"
            text="{{ 'login.register' | translate }}"
            (smButtonEvent)="handleGoToRegister()">
          </sm-button>
        </div>
      </div>
    </div>

  </div>
</div>

