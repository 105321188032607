import {
  AccountCreationRequest,
  AccountDetailsResource,
  ParameterRequest,
  ParameterResource,
  SkillRequest,
  SkillResource
} from "ApiModuleAccount";
import LevelEnum = SkillRequest.LevelEnum;
import CategoryEnum = SkillResource.CategoryEnum;
import GenderEnum = AccountDetailsResource.GenderEnum;
import StatusEnum = AccountCreationRequest.StatusEnum;

export class AccountHelper {

  public static statuses: string[] = ['Etudiant', 'Enseignant', 'Professionnel'];
  public static genders: string[] = ['Homme', 'Femme'];

  static getLevel(level: string): LevelEnum {
    if (level) {
      switch (level) {
        case LevelEnum._1:
          return LevelEnum._1;
        case LevelEnum._2:
          return LevelEnum._2;
        case LevelEnum._3:
          return LevelEnum._3;
        case LevelEnum._4:
          return LevelEnum._4;
        default:
          return LevelEnum._1;
      }
    } else {
      return LevelEnum._1;
    }
  }

  static getCategory(category: string): CategoryEnum {
    if (category) {
      switch (category) {
        case CategoryEnum._1:
          return CategoryEnum._1;
        case CategoryEnum._2:
          return CategoryEnum._2;
        default:
          return CategoryEnum._1;
      }
    } else {
      return CategoryEnum._1;
    }
  }

  static getStatusString(status: StatusEnum | undefined): string | undefined {
    if (status) {
      switch (status) {
        case "STUDENT":
          return this.statuses[0];
        case "TEACHER":
          return this.statuses[1];
        case "PROFESSIONAL":
          return this.statuses[2];
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  static getGenderString(gender: GenderEnum | undefined): string | undefined {
    if (gender) {
      switch (gender) {
        case "MALE":
          return this.genders[0];
        case "FEMALE":
          return this.genders[1];
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  static getStatusEnum(status: string): StatusEnum | undefined {
    if (status) {
      switch (status) {
        case this.statuses[0]:
          return StatusEnum.Student;
        case this.statuses[1]:
          return StatusEnum.Teacher;
        case this.statuses[2]:
          return StatusEnum.Professional;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  static getGenderEnum(gender: string): GenderEnum | undefined {
    if (gender) {
      switch (gender) {
        case this.genders[0]:
          return GenderEnum.Male;
        case this.genders[1]:
          return GenderEnum.Female;
        default:
          return undefined;
      }
    } else {
      return undefined;
    }
  }

  static parameterToStringList(resources: ParameterResource[]): string[] {
    let list: string[] = [];
    if (resources && resources.length > 0) {
      resources.forEach(value => {
        if (value && value.label) {
          list.push(value.label)
        }
      });
    }
    return list;
  }

  static stringToParameter(resources: ParameterResource[], str: string): ParameterRequest | undefined {
    let parameter: ParameterRequest = {} as ParameterRequest;
    parameter.label=str;
    parameter.uuid="";
    parameter.code="";
    if (str && resources && resources.length > 0) {
      resources.forEach(value => {
        if (value.label === str) {
          parameter.uuid = value.uuid!;
          parameter.label = value.label;
          parameter.code = value.code!;
        }
      });
    }
    return parameter;
  }

  static parameterResourceToRequest(resource: ParameterResource | undefined): ParameterRequest | undefined {
    if (resource) {
      let request: ParameterRequest;
      request = {} as ParameterRequest;
      request.uuid = resource.uuid!;
      request.code = resource.code!;
      request.label = resource.label!;
      return request;
    }
    return undefined;
  }
}
