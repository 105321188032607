import {Injectable} from '@angular/core';
import {variables} from "../../environments/variables";
import {AccountService} from "ApiModuleAccount";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private accountService: AccountService) {
  }

  add(key: string, value: any): void {
    localStorage.setItem(
      key,
      JSON.stringify(value)
    );
  }

  update(key: string, value: any): void {
    let oldValue: any = localStorage.getItem(key);
    if (oldValue) {
      this.delete(key);
    }
    this.add(key, value);
  }

  updateLoggedAccount(accountUuid: string): void {
    this.accountService.findAccount(accountUuid).subscribe({
      next: response => {
        this.update(variables.logged_account, response.resource)
      },
      error: err => {
      },
      complete: () => {
      }
    })
  }

  updateProfileAccount(accountUuid: string): void {
    this.accountService.findAccount(accountUuid).subscribe({
      next: response => {
        this.update(variables.profile_account, response.resource)
      },
      error: err => {
      },
      complete: () => {
      }
    })
  }

  
  get(key: string): any {
    const param: any = localStorage.getItem(key);
    return param ? JSON.parse(param) : null;
  }

  getLoggedAccount(): any {
    return this.get(variables.logged_account);
  }

  getProfileAccount(): any {
    return this.get(variables.profile_account);
  }

  delete(key: string): void {
    localStorage.removeItem(key);
  }

  clear(): void {
    localStorage.clear();
  }

  isOffline(): boolean {
    return !this.getLoggedAccount()
  }
}
