import {Component, OnInit} from '@angular/core';
import {MediaType} from "../../models/mediaType";
import {MediaHelper} from "../../helpers/media-helper";
import {AccountDetailsResource, AccountDetailsResponse, AccountService} from "ApiModuleAccount";
import {
  PostDetailsResource,
  PostDetailsResponse,
  PostService,
  ProfileDocumentsResource,
  SearchParam
} from "ApiModulePost";
import {LocalStorageService} from "../../services/local-storage.service";
import {variables} from "../../../environments/variables";
import {ToastrService} from "ngx-toastr";
import {forkJoin, Observable} from "rxjs";

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  mediaTypes: MediaType[] = MediaHelper.mediaTypes;
  profileMediaType: MediaType = MediaHelper.profile;
  accountResponse!: AccountDetailsResponse;
  profileDocuments!: ProfileDocumentsResource;
  accounts: AccountDetailsResource[] = [];
  posts: PostDetailsResource[] = [];
  postResponse: PostDetailsResponse = {} as PostDetailsResponse;
  searchParam: SearchParam = {} as SearchParam;

  displayAllResults: boolean = true;
  showProfileResults: boolean = true;
  showDocumentResults: boolean = true;
  showVideoResults: boolean = true;
  showImageResults: boolean = true;
  showAudioResults: boolean = true;
  showLinkResults: boolean = true;
  messageDisplayResults!: string;
  showNoResult: boolean = false;
  loading: boolean = false;

  constructor(private accountService: AccountService,
              private toastrService: ToastrService,
              private localStorageService: LocalStorageService,
              private postService: PostService) {
  }

  ngOnInit(): void {
    let param: SearchParam = this.localStorageService.get(variables.search_param);
    if (param) {
      this.searchParam = param;
      this.searchParam.statuses = []
      if (this.hasSearchCriteria()) {
        this.asyncSearch()
      }
    }
  }

  clickMediaType(code: string) {
    // this.isSelected(code) ? this.showAll() : this.showThis(code)
    this.showThis(code);
  }

  showAll() {
    this.showProfileResults = true
    this.showDocumentResults = true
    this.showVideoResults = true
    this.showAudioResults = true
    this.showLinkResults = true
    this.showImageResults = true
    this.displayAllResults = true
  }

  hideAll() {
    this.showProfileResults = false
    this.showDocumentResults = false
    this.showVideoResults = false
    this.showAudioResults = false
    this.showLinkResults = false
    this.showImageResults = false
    this.displayAllResults = false
  }

  showThis(code: string) {
    // this.hideAll()
    switch (code) {
      case 'MEDIA_TYPE_PROFILE':
        this.showProfileResults = !this.showProfileResults;
        break
      case 'MEDIA_TYPE_DOCUMENT':
        this.showDocumentResults = !this.showDocumentResults;
        break
      case 'MEDIA_TYPE_VIDEO':
        this.showVideoResults = !this.showVideoResults;
        break
      case 'MEDIA_TYPE_LINK':
        this.showLinkResults = !this.showLinkResults;
        break
      case 'MEDIA_TYPE_IMAGE':
        this.showImageResults = !this.showImageResults;
        break
      case 'MEDIA_TYPE_AUDIO':
        this.showAudioResults = !this.showAudioResults;
        break
      default:
        break
    }
  }

  isSelected(code: string): boolean {
    switch (code) {
      case 'MEDIA_TYPE_PROFILE':
        return this.showProfileResults;
      case 'MEDIA_TYPE_DOCUMENT':
        return this.showDocumentResults;
      case 'MEDIA_TYPE_VIDEO':
        return this.showVideoResults;
      case 'MEDIA_TYPE_LINK':
        return this.showLinkResults;
      case 'MEDIA_TYPE_IMAGE':
        return this.showImageResults;
      case 'MEDIA_TYPE_AUDIO':
        return this.showAudioResults;
      default:
        return false
    }
  }

  searchAccounts(): void {
    this.accountResponse = {}
    this.loading = true;
    this.accountService.searchAccount(0, 100, this.searchParam).subscribe({
      next: response => {
        this.accountResponse = response;
        this.loading = false;
      },
      error: error => {
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    })
  }

  researchProfileDocuments(): void {
    this.profileDocuments = {}
    this.loading = true;
    this.postService.researchDocuments(0, 100, this.searchParam).subscribe({
      next: response => {
        this.profileDocuments = response.resource!;
        this.loading = false;
      },
      error: error => {
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  asyncSearch(): Observable<any> {
    const observableA1 = this.searchAccounts();
    const observableB2 = this.researchProfileDocuments();
    return forkJoin([observableA1, observableB2]);
  }

  /*fetchResults() {
    this.asyncSearch().subscribe({
      next: response => {
        this.showAll()
      },
      error: error => {
      },
      complete: () => {
      }
    })
  }*/

  onClickSearch(): void {
    if (this.hasSearchCriteria()) {
      this.localStorageService.update(variables.search_param, this.searchParam);
      this.asyncSearch()
    } else {
      this.toastrService.warning("Veuillez choisir les criteres de recherche", "Criteres");
    }
  }

  getSearchText(event: string) {
    this.searchParam.content = event;
  }

  onClickItem(event: any): void {
    const checkedDocumentTypes = event[0];
    const collectProfileValues = event[1];

    if (collectProfileValues) {
      this.searchParam.statuses = collectProfileValues;
    }
    if (checkedDocumentTypes) {
      this.searchParam.documentTypes = checkedDocumentTypes
    }
    this.localStorageService.update(variables.search_param, this.searchParam)
    this.asyncSearch()
  }

  onClickMoreResults(event: MediaType): void {
    if (event) {
      this.resetVisibility();
      switch (event.code) {
        case this.profileMediaType.code:
          this.showProfileResults = true;
          break;
        case this.mediaTypes[0].code:
          this.showDocumentResults = true;
          break;
        case this.mediaTypes[1].code:
          this.showVideoResults = true;
          break;
        case this.mediaTypes[2].code:
          this.showLinkResults = true;
          break;
        case this.mediaTypes[3].code:
          this.showImageResults = true;
          break;
        case this.mediaTypes[4].code:
          this.showAudioResults = true;
          break;
        default:
          break;
      }
    }
  }

  resetVisibility(): void {
    this.displayAllResults = !this.displayAllResults;
    this.showProfileResults = this.displayAllResults;
    this.showDocumentResults = this.displayAllResults;
    this.showVideoResults = this.displayAllResults;
    this.showImageResults = this.displayAllResults;
    this.showAudioResults = this.displayAllResults;
    this.showLinkResults = this.displayAllResults;
  }

  hasSearchCriteria(): boolean {
    let result: boolean = true;
    /*if (this.searchParam) {
      Object.values(this.searchParam).forEach(value => {
        result &&= value.length > 0;
      });
    }*/
    return result;
  }

  resourcesToDisplay(resources: any[]): any[] {
    return this.displayAllResults ? resources.slice(0, 3) : resources;
  }

  checkNoResult(): boolean {
    this.showNoResult = this.accountResponse && this.accountResponse.meta?.total! <= 0 &&
      this.profileDocuments && this.profileDocuments.totalDocuments! <= 0 &&
      this.profileDocuments.totalVideos! <= 0 &&
      this.profileDocuments.totalLinks! <= 0 &&
      this.profileDocuments.totalAudios! <= 0 &&
      this.profileDocuments.totalImages! <= 0;

    return this.showNoResult;
  }
}
