<sm-cursus *ngIf="diplomaResponse && !editMode"
           [elements]="diplomaResponse"
           (smAddEvent)="onClickAdd($event)"
           (smUpdateEvent)="onClickUpdate($event)"
           (smDoneEvent)="onClickDone(true)"
           addText="{{'edit_profile.add' | translate}} {{'edit_profile.diploma' | translate}}"
           title="{{'edit_profile.diplomas' | translate}}">
</sm-cursus>

<sm-edit-diploma
  *ngIf="editMode"
  [diplomaResource]="diplomaResponse.resource!"
  (smDoneEvent)="onClickDone($event)">
</sm-edit-diploma>
