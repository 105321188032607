import {Component} from '@angular/core';

@Component({
  selector: 'sm-settings-locations',
  templateUrl: './settings-locations.component.html',
  styleUrls: ['./settings-locations.component.scss']
})
export class SettingsLocationsComponent {

}
