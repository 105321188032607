import {Component, Input, OnInit} from '@angular/core';
import {AccountDetailsResource} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {ToastrService} from "ngx-toastr";
import {InteractionCreateRequest, InteractionService} from "ApiModulePost";
import {Router} from "@angular/router";
import {Route} from "../../enums/route.enum";
import {StringHelper} from "../../helpers/string-helper";
import {variables} from "../../../environments/variables";

@Component({
  selector: 'sm-profile-mobile-card',
  templateUrl: './profile-mobile-card.component.html',
  styleUrls: ['./profile-mobile-card.component.scss']
})
export class ProfileMobileCardComponent implements OnInit {

  @Input() account!: AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  Route = Route;
  hasFollowed!: boolean;
  followLabel: string = 'profile_account.follow';
  profileAvatar!: string;

  constructor(private localStorageService: LocalStorageService,
              private toastrService: ToastrService,
              private interactionService: InteractionService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.profileAvatar = this.account.avatar!;
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.checkFollow();
  }

  getCompanyIcon(): string {
    return StringHelper.getCompanyIcon(this.account);
  }

  onClickAvatar() {
    this.localStorageService.add(variables.profile_account, this.account);
    this.router.navigate([Route.PROFILE]);
  }

  checkFollow(): void {
    this.interactionService.checkAccountHaveFollowSocialInteraction(this.loggedAccount.uuid!, this.account.uuid!).subscribe({
      next: response => {
        this.hasFollowed = Boolean(response.data);
        this.followLabel = this.hasFollowed ? 'profile_account.followed' : 'profile_account.follow';
      },
      error: err => {
      },
      complete: () => {
      }
    })
  }

  onClickFollow(): void {
    if (this.loggedAccount) {
      let interaction: InteractionCreateRequest = {} as InteractionCreateRequest;
      interaction.uuid = "";
      interaction.active = true;
      interaction.entity = this.account.uuid;
      interaction.emitter = this.loggedAccount.uuid;
      interaction.receiver = this.account?.uuid;
      interaction.content = "";
      interaction.interactionType = 'INTERACTION_TYPE_FOLLOWER';

      this.hasFollowed = !this.hasFollowed;
      let message: string = this.hasFollowed ? "Abonnement avec succés" : "Desabonnement avec succés";

      this.interactionService.saveInteraction(interaction)
        .subscribe({
          next: response => {
            this.toastrService.success(message, "Abonnement");
          },
          error: error => {
          },
          complete: () => {
          }
        });
    }
  }

  setDefault(): void {
    this.profileAvatar = "./assets/images/user.svg";
  }
}
