<div class="row">
  <sm-alert [alert]="alert"></sm-alert>
  <div class="col-12 p-3">
    <strong>{{ 'edit_profile.details' | translate }}</strong>
  </div>
  <div class="col-12">
    <sm-input
      (smInputEvent)="getFirstname($event)"
      [inputStatus]="inputStatusFirstname"
      [mandatory]="true"
      [showErrorMessage]="showErrorMessageFirstname"
      [type]="'text'"
      [value]="account.firstname!"
      errorMessage="{{'edit_profile.firstname' | translate}}"
      placeholder="{{'edit_profile.firstname' | translate}}"
      title="{{'edit_profile.firstname' | translate}}">
    </sm-input>
    <sm-input
      (smInputEvent)="getLastname($event)"
      [inputStatus]="inputStatusLastname"
      [mandatory]="true"
      [showErrorMessage]="showErrorMessageLastname"
      [type]="'text'"
      [value]="account.lastname!"
      errorMessage="{{'edit_profile.lastname' | translate}}"
      placeholder="{{'edit_profile.lastname' | translate}}"
      title="{{'edit_profile.lastname' | translate}}">
    </sm-input>

    <div class="row">
      <div class="col">
        <sm-input
          (smInputEvent)="getBirthdate($event)"
          [inputStatus]="inputStatusBirthdate"
          [mandatory]="true"
          [showErrorMessage]="showErrorMessageBirthdate"
          [type]="'date'"
          [value]="selectedDate"
          errorMessage="{{'edit_profile.birthdate' | translate}}"
          placeholder="{{'edit_profile.birthdate' | translate}}"
          title="{{'edit_profile.birthdate' | translate}}">
        </sm-input>
      </div>
      <div class="col">
        <sm-toggle-button (smCheckEvent)="getHideBirthdate($event)" [checked]="account.hideBirthdate!"
                          title="{{'edit_profile.hidden_info' | translate}}">
        </sm-toggle-button>
      </div>
    </div>
    <sm-dropdown
      (smInputEvent)="getGender($event)"
      [list]="genders"
      [selected]="getCurrentGender()"
      title="{{'edit_profile.gender' | translate}}">
    </sm-dropdown>
    <sm-dropdown
      (smInputEvent)="getStatus($event)"
      [list]="statuses"
      [selected]="getCurrentStatus()"
      title="{{'edit_profile.status' | translate}}">
    </sm-dropdown>
    <div class="form-group">
      <small
        class="text-info">{{ 'edit_profile.home_country' | translate }}</small>
      <strong class="text-danger" style="font-size: larger;">*</strong>
      <input (selectionchange)="getCountry($event)" [value]="getCountryName(account.country!)" autocomplete="off"
             autofocus="" class="form-control"
             list="countries"
             name="countries"
             placeholder="{{'edit_profile.home_country' | translate}}"
             required
             style="border-radius: 6px"
      />
      <datalist id="countries">
        <option *ngFor="let opt of getCountryLabels()" [value]="opt">
      </datalist>
    </div>


    <sm-input
      (smInputEvent)="getCity($event)"
      [type]="'text'"
      [value]="account.city!"
      placeholder="{{'edit_profile.home_city' | translate}}"
      title="{{'edit_profile.home_city' | translate}}">
    </sm-input>
  </div>

  <div class="row">
    <div class="col m-t-10">
      <sm-button (click)="onClickCancel()" text="{{'edit_profile.cancel' | translate}}"></sm-button>
    </div>
    <div class="col m-t-10">
      <sm-button (click)="onClickSave()" [type]="'success'"
                 text="{{'edit_profile.validate' | translate}}"></sm-button>
    </div>
  </div>
</div>
