<sm-cursus *ngIf="trainingResponse && !editMode"
           [elements]="trainingResponse"
           (smAddEvent)="onClickAdd($event)"
           (smUpdateEvent)="onClickUpdate($event)"
           (smDoneEvent)="onClickDone(true)"
           addText="{{'edit_profile.add' | translate}} {{'edit_profile.training' | translate}}"
           title="{{'edit_profile.trainings' | translate}} {{'edit_profile.ongoing' | translate | lowercase}}">
</sm-cursus>

<sm-edit-training
  *ngIf="editMode"
  [trainingResource]="trainingResponse.resource!"
  (smDoneEvent)="onClickDone($event)">
</sm-edit-training>
