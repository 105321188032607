import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'sm-settings-about',
  templateUrl: './settings-about.component.html',
  styleUrls: ['./settings-about.component.scss']
})
export class SettingsAboutComponent implements OnInit {

  @ViewChild('presentation') popupViewPresentation !: ElementRef;
  @ViewChild('promotion') popupViewPromotion !: ElementRef;

  promotionFileUrl: string = 'https://skillsmatesmedia.s3.us-east-2.amazonaws.com/multimedia/documents/promotion.pdf'
  plaquetteFileUrl: string = 'https://skillsmatesmedia.s3.us-east-2.amazonaws.com/multimedia/documents/plaquette.pdf'

  ngOnInit(): void {
  }

  constructor(private modalService: NgbModal) {
  }

  onClickPresentation(): void {
    this.modalService.open(this.popupViewPresentation, {size: 'lg'});
  }

  onClickPromotion(): void {
    this.modalService.open(this.popupViewPromotion, {size: 'lg'});
  }

}
