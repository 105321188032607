<nav class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-2 pr-0 d-flex">
  <ngb-pagination
    (pageChange)="changePage($event)"
    [disabled]="false" [collectionSize]="collectionSize" [(page)]="page" [maxSize]="maxSize"
    [rotate]="true" [pageSize]="perPage" [ellipses]="false" [boundaryLinks]="true" size="sm">
    <ng-template ngbPaginationFirst><i class="fa fa-angle-double-left"></i></ng-template>
    <ng-template ngbPaginationPrevious><i class="fa fa-angle-left"></i></ng-template>
    <ng-template ngbPaginationNext><i class="fa fa-angle-right"></i></ng-template>
    <ng-template ngbPaginationLast><i class="fa fa-angle-double-right"></i></ng-template>
  </ngb-pagination>
</nav>
