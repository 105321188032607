import {Component, Input, OnInit} from '@angular/core';
import {PostDetailsResource} from "ApiModulePost";
import {AccountDetailsResource} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {Route} from "../../enums/route.enum";
import {variables} from "../../../environments/variables";
import {Router} from "@angular/router";

@Component({
  selector: 'sm-document-tile',
  templateUrl: './document-tile.component.html',
  styleUrls: ['./document-tile.component.scss']
})
export class DocumentTileComponent implements OnInit {

  @Input() post: PostDetailsResource = {} as PostDetailsResource;
  displayMoreLabel: string = "";
  displayMoreIcon: string = "";
  toggleDisplayMoreLabel: boolean = true;
  loggedAccount!: AccountDetailsResource;
  Route = Route;

  constructor(private localStorageService: LocalStorageService, private router: Router) {
  }

  ngOnInit(): void {
    this.displayMoreLabel = 'Afficher la suite';
    this.displayMoreIcon = 'fa fa-sort-desc';
    this.toggleDisplayMoreLabel = true;
    this.loggedAccount = this.localStorageService.getLoggedAccount();
  }

  onClickMore(): void {
    this.toggleDisplayMoreLabel = !this.toggleDisplayMoreLabel;
    this.displayMoreLabel = this.toggleDisplayMoreLabel ? 'Afficher la suite' : 'Afficher moins';
    this.displayMoreIcon = this.toggleDisplayMoreLabel ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
  }

  onClickDisplay(): void {

  }

  onClickUpdate(post: PostDetailsResource): void {

  }

  onClickCopy(post: PostDetailsResource): void {

  }

  onClickDelete(post: PostDetailsResource): void {

  }

  onClickPost(): void {
    this.localStorageService.add(variables.post_details, this.post);
    // this.localStorageService.add(variables.previous_route, Route.DOCUMENTS);
    this.localStorageService.add(variables.previous_route, window.location.pathname);
    this.router.navigate([Route.POST]);
  }
}
