import {Component, Input, OnInit} from '@angular/core';
import {SkillRequest} from "ApiModuleAccount";


@Component({
  selector: 'sm-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss']
})
export class CurriculumComponent implements OnInit {

  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() image!: string;

  ngOnInit(): void {
  }

}
