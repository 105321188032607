<div class="row skillsmates-container" style="margin-bottom: 10px;">
  <div class="col-12" style="margin-bottom: 10px;">
    <sm-profile-account [accountUuid]="account.uuid!"></sm-profile-account>
  </div>

  <sm-horizontal-line [color]="'white'" [height]="2" style=""></sm-horizontal-line>
</div>


<div class="row skillsmates-container" style="padding-top: 1px;">
  <!--   partie gauche du dashboard -->
  <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 sm-side-menu">
    <div class="">
      <sm-account-curriculum (click)="onClickProfile(account)" [account]="account.uuid!"></sm-account-curriculum>
    </div>
  </div>

  <!-- partie centrale du dashboard -->
  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
    <sm-add-post *ngIf="loggedAccount.uuid === account.uuid" class="sm-side-menu"></sm-add-post>
    <div class="bg-white skillsmates-box-shadow sm-tabs">
      <div class="row" style="padding-top: 1px; border-radius: 10px; background-color: white;">

        <div (click)="onClickTab(tab)" *ngFor="let tab of tabs"
             [ngStyle]="{'background-color': currentTab === tab ? '#16152D' : '#FFFFFF'}"
             class="col center no-margin-padding d-flex justify-content-center">
                    <span class="">
                        <a [ngStyle]="{'color': currentTab === tab ? '#FFFFFF': '#16152D'}" class="btn">
                            <div class="row">
                                <div class="col-12 no-margin-padding">
                                    <span class="user-img">
                                        <img [ngClass]="{'img-invert': currentTab === tab}" alt="" height="32"
                                             src="{{getIcon(tab) | image}}">
                                    </span>
                                </div>
                                <div class="col-12 no-margin-padding">
                                    <h5 class="doctor-name">{{ tab }}</h5>
                                </div>
                            </div>
                        </a>
                    </span>
        </div>

      </div>
    </div>
    <sm-posts *ngIf="currentTab == tabs[0]" [account]="account.uuid!" [isDashboard]="false"></sm-posts>
    <sm-account-shelf *ngIf="currentTab == tabs[1]" class="sm-tabs"></sm-account-shelf>
    <sm-account-cursus *ngIf="currentTab == tabs[2]" class="sm-tabs"></sm-account-cursus>
  </div>

  <!-- partie droite du dashboard -->
  <div class="col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 sm-side-menu">
    <div *ngIf="profileDocuments">
      <sm-account-documents
        (click)="onClickDocuments(mediaTypes[0])"
        [mediaType]="mediaTypes[0]"
        [posts]="profileDocuments.documents"
        [total]="profileDocuments.totalDocuments">
      </sm-account-documents>
      <sm-account-documents
        (click)="onClickDocuments(mediaTypes[1])"
        [mediaType]="mediaTypes[1]"
        [posts]="profileDocuments.videos"
        [total]="profileDocuments.totalVideos">
      </sm-account-documents>
      <sm-account-documents
        (click)="onClickDocuments(mediaTypes[2])"
        [mediaType]="mediaTypes[2]"
        [posts]="profileDocuments.links"
        [total]="profileDocuments.totalLinks">
      </sm-account-documents>
      <sm-account-documents
        (click)="onClickDocuments(mediaTypes[3])"
        [mediaType]="mediaTypes[3]"
        [posts]="profileDocuments.images"
        [total]="profileDocuments.totalImages">
      </sm-account-documents>
      <sm-account-documents
        (click)="onClickDocuments(mediaTypes[4])"
        [mediaType]="mediaTypes[4]"
        [posts]="profileDocuments.audios"
        [total]="profileDocuments.totalAudios">
      </sm-account-documents>
    </div>
    <div (click)="scrollToTop()" *ngIf="pageYoffset > 0" class="scroll-to-top">
      <button class="btn"><em class="fa fa-arrow-up"></em></button>
    </div>
  </div>
</div>
