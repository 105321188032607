<div class="row skillsmates-container">
  <div class="col">
    <div class="topnav-dropdown-header">
      <span><strong>Toutes les notifications</strong></span>
    </div>
    <div class="">
      <ul class="notification-list">
        <li class="notification-message" *ngFor="let notification of interactions" style="margin: 10px;">
          <sm-interaction-detail
            [interaction]="notification">
          </sm-interaction-detail>
          <hr>
        </li>
      </ul>
    </div>
  </div>
  <div class="row" *ngIf="interactions && interactions.length <= 0">
    <div class="col-sm-12" style="padding-bottom: 20px;">
      <div class="see-all">
        <a class="see-all-btn">Aucune notification</a>
      </div>
    </div>
  </div>
</div>
