<div class="bg-white sm-tabs">
  <div class="row" style="padding-top: 10px; border-radius: 10px;">
    <div class="col center no-margin-padding d-flex justify-content-center" (click)="onClickTab(tab)"
         *ngFor="let tab of tabs"
         [ngStyle]="{'border-bottom': currentTab === tab ? '2px solid ' + currentTab.color : '#FFFFFF'}">
          <span class="">
            <a class="btn"
               [ngStyle]="{'color': currentTab === tab ? '#FFFFFF': currentTab.color + '22'}">
              <div class="row">
                <div class="col-12 no-margin-padding">
                  <span class="user-img">
                    <img *ngIf="currentTab.code === tab.code" height="32" src="{{tab.picture | image}}" alt=""
                         [ngClass]="{'img-invert': currentTab.code === tab.code}">
                    <img *ngIf="currentTab.code !== tab.code" height="32" src="{{tab.image | image}}" alt="">
                  </span>
                </div>
                <div class="col-12 no-margin-padding">
                  <h5 class="doctor-name" style="color: #16152d;">
                    {{tab.label}}
                  </h5>
                </div>
              </div>

            </a>
          </span>
    </div>
  </div>
  <div class="row pointer" (click)="openBottomSheet()">
    <div class="col-12" style="background-color: {{currentTab.color + '22'}}; padding: 5px; color: white;">
      <span><img src="{{'right-arrow' | image}}" alt="" height="10"></span>
      <span style="color: #16152d">Type de {{currentTab.label}} ({{total}})</span>
      <span class="float-end"><img src="{{'arrow-open-down' | image}}" alt="" height="20"></span>
    </div>
  </div>

  <div class="row" *ngIf="documents" style="padding-bottom: 20px;">
    <div class="col-12" *ngFor="let post of documents">
      <sm-document-tile  [post]="post"></sm-document-tile>
      <sm-horizontal-line [color]="'#DADADA'" [height]="2"></sm-horizontal-line>
    </div>
  </div>
</div>

<sm-bottom-sheet [options]="options" #bottomSheet>
  <div class="row">
    <div class="col-12">
      <sm-input [type]="'text'" placeholder="{{'search.research' | translate}}" (smInputEvent)="getSearchText($event)"></sm-input>
    </div>
    <div *ngFor="let item of selectedDocumentTypesList">
      <label class="collapsible-item">
        <input type="checkbox" [checked]="checkedItems.includes(item)" (click)="onClickItem(item)"/> {{item}}
      </label>
    </div>
    <div class="col-12">
      <sm-button [type]="'info'" text="{{'document.apply_filters' | translate}}" (smButtonEvent)="onApplyFilters()"></sm-button>
    </div>
  </div>
</sm-bottom-sheet>
