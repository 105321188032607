<div class="skillsmates-box-shadow" style="padding: 10px; background-color: white;">
  <div class="row" style="margin: auto; cursor: pointer;">
    <div *ngIf="isLogged()" class="col-12">
      <span style="float: right;">
        <a style="color: #16162d;"> {{ 'account_curriculum.see_more' | translate }}
          <!--          <img height="18" src="{{'plus_circle' | image}}" alt="voir plus">-->
        </a>
      </span>
    </div>
    <div class="col-12 curriculum-box-top">
      <strong>{{ 'account_curriculum.skills' | translate }}</strong> ({{ skillResponse.meta?.total ?? 0 }})
    </div>
    <div *ngIf="skillResponse && skillResponse.resources">
      <sm-curriculum
        *ngFor="let skill of skillResponse.resources.slice(0, 2)"
        [subtitle]="getLevel(skill.level!)"
        [title]="skill.title!"
        image="{{getSkillImage(skill.level) | image}}">
      </sm-curriculum>
    </div>
  </div>

  <sm-horizontal-line [color]="'#DADADA'"></sm-horizontal-line>

  <div class="row" style="margin: auto; cursor: pointer;">
    <div class="col-12 curriculum-box-top">
      <strong>{{ 'account_curriculum.trainings' | translate }}</strong>
      ({{ computeTotalTrainings(trainingResponse, diplomaResponse, certificationResponse) }})
    </div>
    <div *ngIf="trainingResponse && trainingResponse.resources">
      <sm-curriculum
        *ngFor="let training of trainingResponse.resources.slice(0, 1)"
        [subtitle]="training.schoolName!"
        [title]="training.title!"
        image="{{'school' | image}}">
      </sm-curriculum>
    </div>

    <div *ngIf="diplomaResponse && diplomaResponse.resources">
      <sm-curriculum
        *ngFor="let resource of diplomaResponse.resources.slice(0, 1)"
        [subtitle]="resource.schoolName!"
        [title]="resource.title!"
        image="{{'student' | image}}">
      </sm-curriculum>
    </div>

    <div *ngIf="certificationResponse && certificationResponse.resources">
      <sm-curriculum
        *ngFor="let resource of certificationResponse.resources.slice(0, 1)"
        [subtitle]="resource.schoolName!"
        [title]="resource.title!"
        image="{{'certification' | image}}">
      </sm-curriculum>
    </div>

  </div>

  <sm-horizontal-line [color]="'#DADADA'"></sm-horizontal-line>

  <div class="row" style="margin: auto; cursor: pointer;">
    <div class="col-12 curriculum-box-top">
      <strong>{{ 'account_curriculum.jobs' | translate }}</strong> ({{ jobResponse.meta?.total ?? 0 }})
    </div>

    <div *ngIf="jobResponse && jobResponse.resources">
      <sm-curriculum
        *ngFor="let resource of jobResponse.resources.slice(0, 2)"
        [subtitle]="resource.company!"
        [title]="resource.title!"
        image="{{'company' | image}}">
      </sm-curriculum>
    </div>
  </div>

  <sm-horizontal-line [color]="'#DADADA'"></sm-horizontal-line>

  <div class="row" style="margin: auto; cursor: pointer;">
    <div class="col-12 curriculum-box-top">
      <strong>{{ 'account_curriculum.biography' | translate }}</strong>
    </div>
    <div class="col-12">
      <p [innerHTML]="biography" class="skillsmates-max-lines"></p>
    </div>
  </div>
</div>
