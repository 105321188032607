import {Component, Input} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {InteractionResource, InteractionResponse, InteractionService, PostDetailsResource,} from "ApiModulePost";
import {AccountDetailsResource} from "ApiModuleAccount";

@Component({
  selector: 'sm-post-comments',
  templateUrl: './post-comments.component.html',
  styleUrls: ['./post-comments.component.scss']
})
export class PostCommentsComponent {
  @Input() post: PostDetailsResource = {} as PostDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  loading: boolean = false;
  comments: InteractionResource[] = [];
  interactionResponse: InteractionResponse = {} as InteractionResponse;

  constructor(private interactionService: InteractionService,
              private storageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.storageService.getLoggedAccount();
    this.fetchComments();
  }

  removeCommentEvent(comment: InteractionResource): void {
    this.comments = this.comments.filter(obj => obj !== comment);
  }

  fetchComments(): void {
    this.interactionService.findInteractionsByTypeAndEntity('INTERACTION_TYPE_COMMENT', this.post.uuid!, 0, 10).subscribe({
      next: response => {
        this.interactionResponse = response;
        this.comments = this.interactionResponse.resources!;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }
}
