<div class="skillsmates-box-shadow card" style="padding-bottom: 5px;">
  <div class="row card-header skillsmates-box-top" style="margin: 0 1px 0 1px; cursor: pointer;">

    <div class="col-md-12 no-margin-padding  collapsed d-block" data-toggle="collapse"
         href="#collapse-collapsed-partner" aria-expanded="true" aria-controls="collapse-collapsed-partner"
         id="heading-collapsed-partner">
      <em class="fa fa-chevron-down pull-right" style="padding-top: 10px; padding-left: 5px;"></em>
      <img height="24" src="{{'partner' | image}}" alt=""> &nbsp;
      <strong>Partenaires</strong>
    </div>

  </div>
  <div class="row bg-white collapse" (click)="onClickOpenPartner('https://www.ac-deco.com')"
       style="cursor: pointer; margin: auto; border-radius: 0 0 10px 10px;" id="collapse-collapsed-partner"
       aria-labelledby="heading-collapsed-partner">
    <div class="col-12 card-body no-margin-padding">
      <img class="image-partner" src="./assets/images/ac-deco.png" alt="">
    </div>
    <div class="col-12 text-center card-body no-margin-padding"
         style="font-size: small; margin-bottom: 10px; font-weight: bold;">
      Retrouvez des objets de décor variés et Accesoires pour votre maison sur ac-deco
    </div>
  </div>
</div>
