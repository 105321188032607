<div class="row" style="padding: 10px 10px 0 10px;">
  <div class="col-12">
    <sm-processing [loading]="loading"></sm-processing>
    <div *ngFor="let m of comments">
      <sm-post-comment
        (removeCommentEvent)="removeCommentEvent($event)"
        [comment]="m">
      </sm-post-comment>
    </div>
  </div>
</div>
