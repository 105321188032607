import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {PostDetailsResource} from "ApiModulePost";
import {ActivatedRoute, Router} from "@angular/router";
import {Route} from "../../enums/route.enum";
import {LocalStorageService} from 'src/app/services/local-storage.service';
import {variables} from "../../../environments/variables";


@Component({
  selector: 'sm-post-description',
  templateUrl: './post-description.component.html',
  styleUrls: ['./post-description.component.scss']
})
export class PostDescriptionComponent implements OnInit, OnChanges {

  @Input() post: PostDetailsResource = {} as PostDetailsResource;
  displayMoreLabel: string = "";
  displayMoreIcon: string = "";
  toggleDisplayMoreLabel: boolean = true;

  constructor(private storageService: LocalStorageService,
              private router: Router,
              private activatedRoute: ActivatedRoute,) {

  }

  ngOnInit(): void {
    this.displayMoreLabel = 'Afficher la suite';
    this.displayMoreIcon = 'fa fa-sort-desc';
    this.toggleDisplayMoreLabel = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }


  onClickPost(post: PostDetailsResource): void {
    this.storageService.add(variables.post_details, post);
    this.storageService.add(variables.previous_route, Route.DASHBOARD);
    this.router.navigate([Route.POST]);
  }

  onClickMore(): void {
    this.toggleDisplayMoreLabel = !this.toggleDisplayMoreLabel;
    this.displayMoreLabel = this.toggleDisplayMoreLabel ? 'Afficher la suite' : 'Afficher moins';
    this.displayMoreIcon = this.toggleDisplayMoreLabel ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
  }

  explodeKeywords(keywords: string): string[] {
    if (keywords && keywords.length > 0) {
      return keywords.trim().replace(/#/g, ',').replace(/;/g, ',').split(',');
    }
    return [];
  }

  onClickMediaLink(): void {
    window.open(this.post.link, '_blank');
  }


  hasKeyword(): boolean {
    if (this.post.keywords == undefined)
      return false
    else {
      return this.post.keywords.trim() != "";
    }
  }

}
