import {Component, OnInit} from '@angular/core';
import {variables} from "../../../environments/variables";
import {Router} from "@angular/router";
import {ViewportScroller} from "@angular/common";
import {LocalStorageService} from "../../services/local-storage.service";
import {MediaHelper} from "../../helpers/media-helper";
import {AccountDetailsResource, AuthenticationService, SearchParam} from "ApiModuleAccount";
import {PostService, ProfileDocumentsResource} from "ApiModulePost";
import {MediaType} from "../../models/mediaType";
import {Route} from "../../enums/route.enum";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  loading: boolean = false;
  id: string | null = "";
  pageYoffset = 0;
  posts: string = variables.posts;
  documents: string = variables.documents;
  about: string = variables.about;
  tab: string = this.posts;
  mediaTypes = MediaHelper.mediaTypes;
  currentTab: string = "publication";
  tabs: string[] = ["Mes publications", "Mon classeur", "Mon cursus"];

  items: string[] = [];
  isloading = false;
  currentPage = 0;
  itemsPerPage = 10;


  searchParam: SearchParam = {} as SearchParam;
  profileDocuments!: ProfileDocumentsResource;

  constructor(
    private router: Router,
    private scroll: ViewportScroller,
    private authenticationService: AuthenticationService,
    protected localStorageService: LocalStorageService,
    private postService: PostService
  ) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.get(variables.profile_account);
    this.currentTab = this.tabs[0];

    this.fetchPosts();
  }

  onClickTab(tab: string): void {
    this.currentTab = tab;
  }

  getIcon(tab: string): string {
    if (tab == this.tabs[0]) {
      return "publication";
    } else if (tab == this.tabs[1]) {
      return "shelf";
    } else if (tab == this.tabs[2]) {
      return "user";
    } else {
      return "publication";
    }
  }

  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  fetchPosts(): void {
    this.searchParam.account = this.account.uuid;
    this.postService.researchDocuments(0, 3, this.searchParam).subscribe({
      next: response => {
        this.profileDocuments = response.resource!;
      },
      error: error => {

      },
      complete: () => {
      }
    });
  }

  onClickDocuments(mediaType: MediaType) {
    this.localStorageService.add(variables.media_type, mediaType);
    this.localStorageService.update(variables.profile_account, this.account);
    this.router.navigate([Route.DOCUMENTS]);
  }

  onClickProfile(account: AccountDetailsResource): void {
    this.localStorageService.update(variables.profile_account, account);
    this.router.navigate([Route.EDIT_PROFILE]);
  }
}
