import {Component, OnInit} from '@angular/core';
import {SmSelection} from "../../models/smSelection";
import {AccountDetailsResource} from "ApiModuleAccount";

@Component({
  selector: 'sm-selections',
  templateUrl: './selections.component.html',
  styleUrls: ['./selections.component.scss']
})
export class SelectionsComponent implements OnInit {

  accounts: AccountDetailsResource[] = [];
  selections: SmSelection[] = [];

  constructor() {
  }

  ngOnInit(): void {
    // this.initSelections();
  }

  initSelections(): void {
    let selection: SmSelection = {} as SmSelection;
    selection.account = {} as AccountDetailsResource;
    selection.description = 'Optimisation du rangement par la methode des 5S';
    selection.image = 'pdf';
    selection.liked = 12;
    selection.shared = 28;
    selection.comments = 87;
    selection.downloads = 6;
    this.selections.push(selection);

    selection = {} as SmSelection;
    selection.account = {} as AccountDetailsResource;
    selection.description = 'Pourquoi Bill Gates soutient-il la cryptomonnaie XRP Ripple?';
    selection.image = 'video-rouge';
    selection.liked = 6;
    selection.shared = 45;
    selection.comments = 12;
    selection.downloads = 8;
    this.selections.push(selection);

    selection = {} as SmSelection;
    selection.account = {} as AccountDetailsResource;
    selection.description = 'Illustration du theoreme de Pythagore';
    selection.image = 'photo-vert';
    selection.liked = 7;
    selection.shared = 75;
    selection.comments = 63;
    selection.downloads = 9;
    this.selections.push(selection);
  }
}
