import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BottomSheetComponent} from './bottom-sheet/bottom-sheet.component';
import {BrowserModule} from "@angular/platform-browser";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { BottomSheetPlusComponent } from './bottom-sheet-plus/bottom-sheet-plus.component';


@NgModule({
  declarations: [
    BottomSheetComponent,
    BottomSheetPlusComponent,
  ],
  exports: [BottomSheetComponent, BottomSheetPlusComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule
  ]
})
export class BottomSheetModule {
}
