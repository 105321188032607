import {Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Route} from "../../enums/route.enum";
import {LocalStorageService} from "../../services/local-storage.service";
import {variables} from "../../../environments/variables";
import packageJson from "../../../../package.json";

@Component({
  selector: 'sm-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  public version: string = packageJson.version;
  year: number = new Date().getFullYear();
  Route = Route;

  constructor(private router: Router, private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
  }

  onClickCGU(): void {
    this.localStorageService.add(variables.url, this.router.url);
    this.router.navigate([Route.TERMS_AND_CONDITIONS]);
  }

  onClickFAQ(): void {
    this.localStorageService.add(variables.url, this.router.url);
    this.router.navigate([Route.FAQ]);
  }

  onClickAbout(): void {
    this.localStorageService.add(variables.url, this.router.url);
    this.localStorageService.add(variables.setting_tab, 'about');
    this.router.navigate([Route.SETTINGS]);
  }
}
