<div *ngIf="post" class="skillsmates-box-shadow p-3 m-t-10 pointer">
  <div class="row">
    <div class="col-12" style="margin-bottom: 10px;">
      <sm-post-media [height]="150" [post]="post"></sm-post-media>
    </div>
    <div class="col-3 col-sm-2 col-md-2 col-lg-3 col-xl-3 m-t-10">
    <span class="user-img">
      <a style="cursor: pointer; ">
        <sm-image [class]="'avatar'" [imageSize]="35" default="{{'user'|image}}"
                  image="{{post.account?.avatar}}"></sm-image>
      </a>
    </span>
    </div>
    <div class="col text-left m-t-10">
      <div class="row">
        <div class="col-12">
          <strong class=" text-left skillsmates-two-lines" style="font-size: x-small;">{{ post.title }}</strong>
        </div>
        <div class="col-12 date-post">
          {{ 'search.published' | translate }} <span class="date-post">{{ post.createdAt | timeAgo }}</span>
        </div>
      </div>
    </div>

    <div class="col-12">
      <p class="skillsmates-two-lines" style="font-size: x-small; height: 30px;">{{ post.description }}</p>
      <span class="text"><strong>{{ name(post.account!) }}</strong></span>
    </div>
  </div>
  <sm-horizontal-line></sm-horizontal-line>
  <div class="row text-center" style="margin-bottom: 10px;">
    <div class="col-3">
      <img alt="" class="post-icon" src="{{'heart' | image}}"> ({{ post.likes }})
    </div>
    <div class="col-3 ">
      <img alt="" class="post-icon" src="{{'share' | image}}"> ({{ post.shares }})
    </div>
    <div class="col-3 ">
      <img alt="" class="post-icon" src="{{'skillschat-active' | image}}"> ({{ post.comments }})
    </div>
    <div class="col-3 ">
      <img alt="" class="post-icon" src="{{'download' | image}}"> (0)
    </div>
  </div>
</div>



