<div *ngIf="account" class="row" style="color: #16152d; padding-top: 40px;">
  <div class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3 container no-margin-padding">
    <div (click)="onClickProfile()" class="profile-img centered-element ">
      <a>
        <img (error)="setDefault()" alt="" class="avatar" src="{{account.avatar}}"
             style="border: white solid 2px; z-index: 99;">

        <!--        <sm-image-->
        <!--          class="avatar"-->
        <!--          default="{{'user' | image}}"-->
        <!--          image="{{account.avatar}}"-->
        <!--          style="border: white solid 2px; z-index: 99;">-->
        <!--        </sm-image>-->
      </a>
    </div>
  </div>
  <div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6">
    <div class="row">
      <div class="no-margin-padding" style="font-size: large; text-align: left">
        <span>{{ account.firstname }}</span>&nbsp;<strong>{{ account.lastname }}</strong>
      </div>
    </div>
    <!--<div style="font-size: small;">
      <span>{{ account | gender | translate }}</span>
      <span *ngIf="displayAge()"> | {{ account.birthdate | yearAgo }} {{ "profile_account.years" | translate }}</span>
    </div>-->
    <div class="row">
      <div (click)="onClickPosts()" class="col-auto no-margin-padding pointer">
        <strong class="account-stats-number">{{ account.posts ?? 0 }}</strong>
        <span class="account-stats-attributes">{{ "profile_account.posts" | translate }}</span>
      </div>
      <div (click)="onClickNetwork('follower')" class="col-auto pointer">
        <strong class="account-stats-number">{{ account.followers ?? 0 }}</strong>
        <span class="account-stats-attributes">{{ "profile_account.followers" | translate }}</span>
      </div>
      <div (click)="onClickNetwork('followee')" class="col-auto no-margin-padding pointer account-style">
        <strong class="account-stats-number">{{ account.followees ?? 0 }}</strong>
        <span class="account-stats-attributes"> {{ "profile_account.followees" | translate }}</span>
      </div>
    </div>

    <div class="sm-profile-attributes-1">
      <div class="col">
        <sm-image-text *ngIf="account.status"
                       class="row"
                       image="{{account | statusIcon}}"
                       text="{{'profile_account.status' | translate}} {{getLabelStatus() | translate}}">
        </sm-image-text>
        <sm-image-text *ngIf="account.currentJob"
                       class="row"
                       image="{{account | jobIcon}}"
                       text="{{'profile_account.class' | translate}} {{account.currentJob}}">
        </sm-image-text>
        <sm-image-text *ngIf="account.currentCompany"
                       class="row"
                       image="{{account | companyIcon}}"
                       text="{{'profile_account.enterprise' | translate}} {{account.currentCompany}}">
        </sm-image-text>
        <sm-image-text *ngIf="account.city || account.country"
                       class="row"
                       image="{{'location' | image}}"
                       text="{{account.city}}, {{account.country! | country}}">
        </sm-image-text>
      </div>
    </div>
  </div>
  <!--<div class="col-8 col-sm-8 col-md-6 col-lg-6 col-xl-6">
    <div style="font-size: x-large;">
      <span>{{ account.firstname }}</span> &nbsp; <strong>{{ account.lastname }}</strong>
    </div>
    <div style="font-size: small;">
      <span>{{ account | gender | translate }}</span>
      <span *ngIf="displayAge()"> | {{ account.birthdate | yearAgo }} {{ "profile_account.years" | translate }}</span>
    </div>
    <div class="row text-center">
      <div class="col no-margin-padding pointer" (click)="onClickPosts()">
        <strong class="account-stats-number">{{ account.posts ?? 0 }}</strong> <br>
        <span class="account-stats-attributes">{{ "profile_account.posts" | translate }}</span>
      </div>
      <div class="col no-margin-padding pointer" (click)="onClickNetwork('follower')">
        <strong class="account-stats-number">{{ account.followers ?? 0 }}</strong> <br>
        <span class="account-stats-attributes">{{ "profile_account.followers" | translate }}</span>
      </div>
      <div class="col no-margin-padding pointer" (click)="onClickNetwork('followee')">
        <strong class="account-stats-number">{{ account.followees ?? 0 }}</strong> <br>
        <span class="account-stats-attributes"> {{ "profile_account.followees" | translate }}</span>
      </div>
    </div>

    <div class="sm-profile-attributes-1">
      <div class=" scroll">
        <sm-image-text
          *ngIf="account.status"
          image="{{account | statusIcon}}"
          text="{{getLabelStatus() | translate}}">
        </sm-image-text>
        <sm-image-text
          *ngIf="account.currentJob"
          image="{{account | jobIcon}}"
          [text]="account.currentJob">
        </sm-image-text>
        <sm-image-text
          *ngIf="account.currentCompany"
          image="{{account | companyIcon}}"
          [text]="account.currentCompany">
        </sm-image-text>
        <sm-image-text
          *ngIf="account.city || account.country"
          image="{{'location' | image}}"
          text="{{account.city}} ({{account.country! | country}})">
        </sm-image-text>
      </div>
    </div>

  </div>-->
  <div class="row sm-profile-attributes-2">
    <div class="col-12 scroll ">
      <sm-image-text [text]="account.status!" image="{{account | statusIcon}}"></sm-image-text>
      <sm-image-text [text]="account.currentJob!" image="{{account | jobIcon}}"></sm-image-text>
      <sm-image-text [text]="account.currentCompany!" image="{{account | companyIcon}}"></sm-image-text>
      <sm-image-text image="{{'location' | image}}" text="{{account.city}} ({{account.country}})"></sm-image-text>
    </div>
  </div>
  <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 sm-profile-attributes-2">
    <div class="row">
      <div *ngIf="isLoggedAndIsOwner()" class="col button-profile">
        <sm-button-gradient
          (click)="onClickProfile()"
          [completed]="60"
          image="{{'edit' | image}}"
          text="{{'profile_account.profile_completed_to' | translate}} 60%">
        </sm-button-gradient>
      </div>
      <div *ngIf="isLoggedAndNotOwner()" class="col button-profile">
        <sm-button-follow
          [type]="hasFollowed ? 'unfollow' : 'follow'" data-bs-target="#follow_unfollow"
          data-bs-toggle="modal"
          image="{{'follow-black' | image}}"
          text="{{followLabel | translate}}">
        </sm-button-follow>
      </div>
      <div *ngIf="isLoggedAndNotOwner()" class="col button-profile">
        <sm-button-follow
          (click)="onClickMessage()"
          [type]="'default'"
          image="{{'message' | image}}"
          text="{{'profile_account.message' | translate}}">
        </sm-button-follow>
      </div>
      <div *ngIf="isLogged()" class="col button-profile">
        <sm-button-follow
          (click)="onClickCopyProfile()"
          [type]="'default'"
          image="{{'share' | image}}"
          text="{{'profile_account.share_profile' | translate}}">
        </sm-button-follow>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3 sm-profile-attributes-1">
    <div class="row">
      <div *ngIf="isLoggedAndIsOwner()" class="col button-profile">
        <sm-button-gradient
          (click)="onClickProfile()"
          [completed]="60"
          image="{{'edit' | image}}"
          text="{{'profile_account.profile_completed_to' | translate}} 60%">
        </sm-button-gradient>
      </div>
    </div>
    <div class="row">
      <div *ngIf="isLoggedAndNotOwner()" class="col button-profile">
        <sm-button-follow
          [type]="hasFollowed ? 'unfollow' : 'follow'"
          data-bs-target="#follow_unfollow" data-bs-toggle="modal"
          href="#"
          image="{{'follow-black' | image}}"
          text="{{followLabel | translate}}">
        </sm-button-follow>
      </div>
    </div>
    <div class="row">
      <div *ngIf="isLogged()" class="col button-profile">
        <sm-button-follow
          (click)="onClickCopyProfile()"
          [type]="'default'"
          image="{{'share' | image}}"
          text="{{'profile_account.share_profile' | translate}}">
        </sm-button-follow>
      </div>
    </div>
    <div class="row">
      <div *ngIf="isLoggedAndNotOwner()" class="col button-profile">
        <sm-button-follow
          (click)="onClickMessage()"
          [type]="'default'"
          image="{{'skillschat-inactive' | image}}"
          text="{{'profile_account.message' | translate}}">
        </sm-button-follow>
      </div>
    </div>
  </div>
</div>


<div class="modal fade delete-modal" id="follow_unfollow" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <img alt="" height="46" src="{{'symbole-skills-mates' | image}}" width="50">
        <h3>Voulez-vous vous {{ hasFollowed ? 'désabonner de ' : 'abonner à ' }}
          {{ account.firstname + ' ' + account.lastname }}?</h3>
        <div class="m-t-20"><a class="btn btn-white" data-bs-dismiss="modal">NON</a>
          <button (click)="onClickFollow()" class="btn btn-danger" data-bs-dismiss="modal" type="submit">OUI
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
