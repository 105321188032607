<div class="row m-l-5 m-r-10" *ngIf="alert.display">
  <div class="col-12 alert alert-{{alert.class}}">
    <div class="row">
      <button type="button" class="col-2 btn-close" style="border: 1px #3E3637 solid"
              data-bs-dismiss="modal" aria-label="Close"
              (click)="onClickClose()">
      </button>
      <strong class="col-10">{{alert.title}}!</strong>
    </div>

    <div class="row m-t-5">
      <span>{{alert.message}}.</span>
    </div>
  </div>
</div>
