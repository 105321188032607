<div class="row" style="background-color: {{bgColor}}">
  <div class="col-md-12 skillsmates-box-top" style="background-color: {{bgColor}}" (click)="onClickTitle()">
    <img height="24" src="{{ image | image}}" alt="image"> &nbsp;
    <strong>{{title}}</strong>
    <span style="float: right;">
        <a style="color: #16162d;">
          <img height="24" src="{{ headTileImage | image}}" alt="arrow"> &nbsp;
        </a>
    </span>
  </div>
  <div class="col-12">
    <div class="form-group" *ngIf="expand">
      <div *ngFor="let item of list">
        <label class="collapsible-item">
          <input type="checkbox" [checked]="isItemSelected(item)" (click)="onClickItem(item)"/> {{item}}
        </label>
      </div>
    </div>
  </div>
</div>
