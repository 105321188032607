import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {AccountDetailsResource} from "ApiModuleAccount";
import {PostDetailsResource, PostDetailsResponse, PostService} from "ApiModulePost";
import {Route} from "../../enums/route.enum";
import {ActivatedRoute, Router} from "@angular/router";
import {variables} from "../../../environments/variables";

@Component({
  selector: 'app-post-detail',
  templateUrl: './post-detail.component.html',
  styleUrls: ['./post-detail.component.scss']
})
export class PostDetailComponent implements OnInit {

  loggedAccount: AccountDetailsResource = {}
  post: PostDetailsResource = {};
  postResponse: PostDetailsResponse = {} as PostDetailsResponse;
  previousRoute!: Route;
  id!: string;
  isOffline: boolean = true

  constructor(public localStorageService: LocalStorageService,
              private postService: PostService,
              private activateRoute: ActivatedRoute,
              private router: Router) {
  }

  ngOnInit(): void {
    this.isOffline = this.localStorageService.isOffline()
    this.loggedAccount = this.localStorageService.getLoggedAccount()
    this.previousRoute = this.localStorageService.get(variables.previous_route);
    this.activateRoute.queryParams.subscribe({
      next: value => {
        this.id = value['id']
        if (this.id) {
          this.getPost(this.id)
        }
      }
    })


    /*this.post = this.localStorageService.get(variables.post_details);
    if (this.post) {
      this.getPost(this.post.uuid!);
    } else {
      this.activateRoute.queryParams.subscribe({
        next: value => {
          this.id = value['id'];
          if (this.id) {
            this.getPost(this.id);
          }
        }
      });
    }*/
  }


  onClickReturn(): void {
    if (this.previousRoute) {
      this.router.navigate([this.previousRoute]);
    }
  }

  getPost(uuid: string): void {
    this.postService.getPost(uuid).subscribe({
      next: response => {
        this.postResponse = response;
        this.post = this.postResponse.resource!;
      },
      error: err => {
      },
      complete: () => {
      }
    })
  }

  onClickPost(p: PostDetailsResource): void {
    this.getPost(p.uuid!);
  }

  handleButton1() {
    this.router.navigate([Route.LOGIN]);
  }


}
