import {Pipe, PipeTransform} from '@angular/core';
import {environment} from "../../environments/environment";
import {AccountDetailsResource} from "ApiModuleAccount";

@Pipe({
  name: 'avatar'
})
export class AvatarPipe implements PipeTransform {

  transform(account: AccountDetailsResource): string {
    if (account && account.avatar) {
      return environment.aws_s3_multimedia_bucket + account.uuid + '/' + account.avatar.toLowerCase();
    } else {
      return './assets/images/user.svg';
    }
  }
}
