import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {AccountDetailsResource, AssistanceCreationRequest, AssistanceService} from "ApiModuleAccount";
import {Alert} from "../../models/alert";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'sm-assistance-contact-us',
  templateUrl: './assistance-contact-us.component.html',
  styleUrls: ['./assistance-contact-us.component.scss']
})
export class AssistanceContactUsComponent implements OnInit {

  @Output() selectedBlock = new EventEmitter<string>();
  assistanceRequest: AssistanceCreationRequest = {} as AssistanceCreationRequest;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  alert: Alert = {} as Alert;

  showErrorMessageTopic: boolean = false;
  inputStatusTopic: string = '';
  loading: boolean = false;

  processing: boolean = false

  constructor(private localStorageService: LocalStorageService,
              private toastrService: ToastrService,
              private assistanceService: AssistanceService) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
  }

  ngOnInit(): void {
  }

  onClickCancel(): void {
    this.selectedBlock.emit('contactUs');
  }

  onClickSave(): void {
    if (this.validateAssistance().length <= 0) {
      this.loading = true;
      this.assistanceRequest.account = this.loggedAccount.uuid!;
      this.processing = true
      this.assistanceService.createAssistance(this.assistanceRequest).subscribe({
        next: response => {
          this.processing = false
          this.toastrService.success("Nous avons bien pris en compte votre message. Merci", 'Message');
          this.loading = false;
          this.assistanceRequest = {} as AssistanceCreationRequest;
          this.selectedBlock.emit('contactUs');
        },
        error: error => {
          this.processing = false
          this.toastrService.error("Erreur lors de l'envoi de votre message", 'Message');
          this.loading = false;
        },
        complete: () => {
          this.processing = false
          this.loading = false;
        }
      });
    }
  }

  getMessage(event: string) {
    this.assistanceRequest.message = event;
  }


  getTopic(event: string) {
    this.assistanceRequest.topic = event;
  }

  validateAssistance(): string[] {
    let errors: string[] = []
    if (!this.assistanceRequest.topic) {
      errors.push("TOPIC");
      this.showErrorMessageTopic = true;
      this.inputStatusTopic = 'invalid';
    } else {
      this.showErrorMessageTopic = false;
      this.inputStatusTopic = 'valid';
    }

    if (errors.length > 0) {
      this.alert = {
        display: true,
        class: 'danger',
        title: 'Erreur',
        message: 'Certains champs sont obligatoires'
      }
    }

    return errors;
  }

  initErrors() {
    this.alert = {} as Alert;
    this.showErrorMessageTopic = false;
    this.inputStatusTopic = '';
  }
}
