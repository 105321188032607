import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {MediaType} from "../../models/mediaType";
import {MediaHelper} from "../../helpers/media-helper";
import {LocalStorageService} from "../../services/local-storage.service";
import {AccountDetailsResource} from "ApiModuleAccount";
import {Router} from "@angular/router";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {PostRequest} from "../../models/requests/postRequest";
import {variables} from "../../../environments/variables";
import {PostFileService} from "ApiModulePost";
import {v4 as uuid} from 'uuid';
import {ToastrService} from "ngx-toastr";
import {Route} from "../../enums/route.enum";

@Component({
  selector: 'sm-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.scss']
})
export class AddPostComponent implements OnInit {
  mediaTypes: MediaType[] = MediaHelper.mediaTypes;
  loading: boolean = false;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  @Input() height: number = 300;
  @ViewChild('add_status') popupview !: ElementRef;
  processing: boolean = false;
  postRequest: PostRequest = {} as PostRequest;
  image!: string | ArrayBuffer | null;
  selectedFile!: File | null;
  private modalReference!: NgbModalRef;

  constructor(private localStorageService: LocalStorageService,
              private postFileService: PostFileService,
              private toastrService: ToastrService,
              private router: Router,
              private modalService: NgbModal) {
    this.selectedFile = null;
  }

  ngOnInit(): void {
    this.loading = true;
    this.loggedAccount = this.localStorageService.getLoggedAccount();
  }

  onClickAddStatus(): void {
    this.modalReference = this.modalService.open(this.popupview, {size: 'lg'});
  }

  getStatusDescription(event: string): void {
    this.postRequest.description = event;
  }

  onFileSelected(event: any) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.selectedFile = selectedFile;
      this.postRequest.file = this.selectedFile!;
      this.previewImage();
    }
  }

  onClickPublish(): void {
    this.postRequest.account = this.loggedAccount.uuid;
    if (!this.postRequest.uuid) {
      this.postRequest.uuid = uuid();
    }
    if (!this.selectedFile) {
      const arrayOfBlob = new Array<Blob>();
      arrayOfBlob.push(new Blob([''], {type: 'application/txt'}));
      this.selectedFile = new File(arrayOfBlob, variables.no_filename);
    }
    this.postRequest.file = this.selectedFile;
    this.postFileService.savePost(
      this.postRequest.uuid,
      this.postRequest.description!,
      "keywords",
      this.postRequest.description!,
      "MEDIA_TYPE_STATUS_01",
      "POST_TYPE_STATUS",
      this.postRequest.link ?? 'https://skills-mates.com',
      this.postRequest.account!,
      this.postRequest.file!).subscribe({
      next: response => {
        this.toastrService.success("La publication a été créé avec succés", 'Création publication');
        this.localStorageService.update(variables.profile_account, this.loggedAccount);
        this.modalReference.close();
        this.selectedFile = null;
        this.router.navigate([Route.DASHBOARD]);
        window.location.reload();
      },
      error: error => {
        this.toastrService.error("Erreur lors de la création de la publication", 'Création publication');
      },
      complete: () => {
      }
    });
  }

  previewImage(): void {
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(this.selectedFile);
      reader.onload = (event) => {
        this.image = reader.result;
      };
    }
  }
}
