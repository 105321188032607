<a (click)="onClickNotification(interaction)" style="cursor: pointer;">
  <div class="media row">
    <div class="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 no-margin-padding">
      <span *ngIf="interaction.emitter" class="avatar">
<!--        <img alt="user notif" src="{{interaction.emitter.avatar}}" class="img-fluid">-->
        <sm-image
          [imageSize]="50"
          class="img-fluid"
          default="{{'user' | image}}"
          image="{{interaction.emitter.avatar}}">
        </sm-image>
        <span class="status online"></span>
      </span>
    </div>
    <div class="col no-margin-padding">
      <p [innerHTML]="generateTitle(interaction)" class="noti-details" style="color: black"></p>
    </div>
    <div class="col-2 col-sm-2 col-md-2 col-lg-1 col-xl-1 no-margin-padding">
      <strong class="noti-time float-left" style="font-size: x-small;">
        {{ interaction.createdAt | timeAgo }}
      </strong>
    </div>
  </div>
</a>
