import {Component, Input, OnInit} from '@angular/core';
import {Alert} from "../../models/alert";

@Component({
  selector: 'sm-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {
  @Input()
  alert: Alert = new Alert;

  constructor() {
  }

  ngOnInit(): void {
  }

  onClickClose(): void {
    this.alert.display = false;
  }
}
