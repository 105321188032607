<div class="skillsmates-box-shadow" [ngStyle]="{
      'background-image': 'url(' + backgroundImage() + ')',
      'height': '280px',
      'display':'flex',
      'border-radius': '10px',
      'flex-direction':'column',
      'background-size': 'cover',
      'background-position': 'center',
      'background-repeat': 'no-repeat'
    }">

  <div
    style="flex: 1; width: 100%; object-fit: cover; background-color: rgba(0, 0, 0, 0.41);
    border-top-left-radius: 10px; border-top-right-radius: 10px;">

  </div>


  <div
    style="display: flex; flex-direction: column; justify-content: flex-end; background-color: rgba(0, 0, 0, 0.41);
    border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;">
    <div (click)="onClickProfile()" class="row" style="margin: 0;">
      <div class="col-12 text-center skillsmates-single-line" style="color: whitesmoke; font-size: small;">
        <strong>{{ account.firstname }} {{ account.lastname }}</strong>
      </div>
    </div>
    <div (click)="onClickProfile()" class="row"
         style="margin: 0; border-radius: 0 0 10px 10px; padding-top: 5px;">
      <div *ngIf="account.currentJob" class="col-3">
        <img alt="" height="20" src="{{account | jobIcon}}" style="vertical-align: baseline;">
      </div>
      <div *ngIf="account.currentJob" class="col-9" style="margin-left: -10%;">
      <span class="title skillsmates-single-line"
            style="font-size: 98%; color: whitesmoke">{{ account.currentJob }}</span>
      </div>


      <div *ngIf="account.currentCompany" class="col-3">
        <img alt="" height="20" src="{{getCompanyIcon() | image}}" style="vertical-align: baseline;">
      </div>
      <div *ngIf="account.currentCompany" class="col-9" style="margin-left: -10%;">
      <span class="text skillsmates-single-line"
            style="font-size: 98%; color: whitesmoke">{{ account.currentCompany }}</span>
      </div>

      <div *ngIf="account.country" class="col-3">
        <img alt="" height="20" src="{{'location' | image}}" style="vertical-align: baseline;">
      </div>
      <div *ngIf="account.country" class="col-9" style="margin-left: -10%;">
      <span class="text skillsmates-single-line" style="color: white"> <span
        *ngIf="account.city">{{ account.city }}</span>
        <span style="font-size: 98%;"> - {{ account.country | country }}</span>
      </span>
      </div>

    </div>
    <div class="row" style="margin: 0; border-radius: 0 0 10px 10px;">
      <div *ngIf="loggedAccount.uuid != account.uuid" class="col-12" style="margin-bottom: 5px;">
        <sm-button-follow
          (click)="onClickFollow()"
          [type]="hasFollowed ? 'unfollow' : 'follow'"
          image="{{'follow-black' | image}}"
          text="{{followLabel | translate}}">
        </sm-button-follow>
      </div>
    </div>
  </div>


</div>

