import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {Route} from "../../enums/route.enum";
import {AccountDetailsResource, InteractionResource, InteractionService} from "ApiModulePost";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'sm-post-comment',
  templateUrl: './post-comment.component.html',
  styleUrls: ['./post-comment.component.scss']
})
export class PostCommentComponent implements OnInit {

  @Input() comment: InteractionResource = {} as InteractionResource;
  @Output() removeCommentEvent = new EventEmitter<InteractionResource>();
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  account: AccountDetailsResource = {} as AccountDetailsResource;
  Route = Route;

  constructor(private interactionService: InteractionService,
              private toastrService: ToastrService,
              private storageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.storageService.getLoggedAccount();
    this.account = this.storageService.getProfileAccount();
  }

  onClickDelete(): void {
    this.interactionService.deleteInteraction(this.comment.uuid!).subscribe({
      next: response => {
        this.toastrService.success("Commentaire supprimé", "Commentaire");
        this.removeCommentEvent.emit(this.comment);
      },
      error: error => {
        this.toastrService.success("Erreur lors de la suppression", "Commentaire");
      },
      complete: () => {

      }
    });
  }
}
