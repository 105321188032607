import {Component, OnInit} from '@angular/core';
import {variables} from "../../../environments/variables";
import {LocalStorageService} from "../../services/local-storage.service";
import {AccountDetailsResource, AccountDetailsResponse, NetworkService, SearchParam} from "ApiModuleAccount";

@Component({
  selector: 'sm-accounts-online',
  templateUrl: './accounts-online.component.html',
  styleUrls: ['./accounts-online.component.scss']
})
export class AccountsOnlineComponent implements OnInit {
  accountResponse: AccountDetailsResponse = {} as AccountDetailsResponse;
  nbOnlineAccounts: number = 0;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  searchParam: SearchParam = {} as SearchParam;
  expand: boolean = false;

  constructor(private localStorageService: LocalStorageService,
              private networkService: NetworkService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.searchParam.account = this.loggedAccount.uuid;
    this.searchParam.networkType = 'ONLINE';
    this.findAccounts(this.searchParam, 0, 20);
  }

  onClick(): void {
    this.expand = !this.expand;
  }

  onClickOnline(): void {
  }

  onClickProfile(account: AccountDetailsResource): void {
    this.localStorageService.add(variables.profile_account, account);
  }

  findAccounts(searchParam: SearchParam, offset: number, limit: number) {
    this.networkService.searchNetworkAccounts(offset, limit, searchParam).subscribe({
      next: response => {
        this.accountResponse = response!;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }
}
