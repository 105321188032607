import {Component, OnInit} from '@angular/core';
import {variables} from "../../../environments/variables";
import {StringHelper} from "../../helpers/string-helper";
import {Router} from "@angular/router";
import {LocalStorageService} from "../../services/local-storage.service";
import {Route} from "../../enums/route.enum";
import {AccountDetailsResource, AccountService} from "ApiModuleAccount";
import {environment} from "../../../environments/environment";
import { NotificationsService } from 'src/app/services/notifications.service';
import { Observable, Subscription } from 'rxjs';
import { CommentsService } from 'src/app/services/comments.service';

@Component({
  selector: 'sm-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  dropdownProfileMenu = false;
  showModalPost = false;
  showModalAccount = false;
  searchImageIcon = 'search';
  messageImageIcon = 'message';
  plusImageIcon = 'plus-mobile';
  homeImageIcon = 'home';
  bellImageIcon = 'bell';
  networkImageIcon = 'network';
  selectedImageIcon = 'home';
  textToSearch!: string;
  displayPopupMessage = false;
  displayPopupBell = false;
  displayPopupNetwork = false;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  Route = Route;
  screenHeight!: number;
  screenWidth!: number;
  subscription:Subscription;

  constructor(private router: Router, 
    private storageService: LocalStorageService, 
    private notificationsService:NotificationsService, 
    private accountService: AccountService) {
    this.screenWidth = window.innerWidth;
    this.subscription = notificationsService.missionAnnounced$.subscribe(value=>{
      this.loggedAccount = value
    })
  }

  ngOnInit(): void {
    this.loggedAccount = this.storageService.getLoggedAccount();
    this.remainingTrialPeriod();

    this.notificationsService.missionAnnounced$.subscribe({
      next(value) {  
      },
    })

  }

  fetchAccount(): void {
    this.accountService.findAccount(this.loggedAccount.uuid!).subscribe({
      next: response => {
        this.loggedAccount = response.resource!;
        this.storageService.update(variables.logged_account,this.loggedAccount);
      },
      error: error => {
      },
      complete: () => {
      }
    })
  }

  getTab(): string {
    return StringHelper.urlToTab(this.router.url);
  }

  onClickProfile(): void {
    if (this.screenWidth > 800) {
      this.dropdownProfileMenu = !this.dropdownProfileMenu;
    } else {
      this.dropdownProfileMenu = false;
      this.storageService.add(variables.profile_account, this.loggedAccount);
      this.router.navigate([Route.PROFILE]);
    }
  }

  public name(): string {
    return StringHelper.truncateName(this.loggedAccount, 12);
  }

  public remainingTrialPeriod(): number {
    const date1: Date = new Date(this.loggedAccount.createdAt!);
    const date2 = new Date();
    const oneDay = 1000 * 60 * 60 * 24;
    const diffInTime = date2.getTime() - date1.getTime();
    return environment.trialDuration - Math.round(diffInTime / oneDay);
  }

  onClickProfileMenu(menu: string): void {
    this.showModalAccount = false;
    this.showModalPost = false;
    this.dropdownProfileMenu = false;
    this.resetImageIcon();

    switch (menu) {
      case "profile":
        this.storageService.update(variables.profile_account, this.storageService.getLoggedAccount());
        this.router.navigate([Route.PROFILE]);
        break;
      case "settings":
        break;
      case "assistance":
        break;
      case "logout":
        this.storageService.clear();
        this.router.navigate([Route.LOGIN]);
        break;
      default:
        break;
    }
  }

  resetImageIcon(): void {
    this.homeImageIcon = 'home';
    this.searchImageIcon = 'search';
    this.bellImageIcon = 'bell';
    this.networkImageIcon = 'network';
  }

  onClickSearch(): void {
    localStorage.setItem(variables.text_to_search, this.textToSearch);
    // this.router.navigate([this.routes.get('search_results')]);
  }

  onClick(tab: string): void {
    this.storageService.update(variables.profile_account, this.loggedAccount);
    this.selectedImageIcon = tab;
    this.showModalPost = false;
    if (tab === 'plus-mobile') {
      this.showModalAccount = !this.showModalAccount;
    }
  }

  onClickEditPost(infoMediaType: string): void {
    this.router.navigate([Route.EDIT_POST]);
  }

  displayPopup(label: string): void {
    this.displayPopupMessage = false;
    this.displayPopupBell = false;
    this.displayPopupNetwork = false;
    switch (label) {
      case 'message':
        this.displayPopupMessage = true;
        break;
      case 'bell':
        this.displayPopupBell = true;
        break;
      case 'network':
        this.displayPopupNetwork = true;
        break;
    }
  }

  onMouseLeave(tab: string): void {
    switch (tab) {
      case 'home':
        this.homeImageIcon = 'home';
        break;
      case 'message':
        this.messageImageIcon = 'message';
        break;
      case 'search':
        this.searchImageIcon = 'search';
        break;
      case 'bell':
        this.bellImageIcon = 'bell';
        break;
      case 'network':
        this.networkImageIcon = 'network';
        break;
      case 'plus-mobile':
        this.plusImageIcon = 'plus-mobile';
        break;
    }
    this.resetImageIcon();
    this.onMouseEnter(this.selectedImageIcon);
  }

  onMouseEnter(tab: string): void {
    switch (tab) {
      case 'home':
        this.homeImageIcon = 'home-selected';
        break;
      case 'search':
        this.searchImageIcon = 'search-selected';
        break;
      case 'message':
        this.messageImageIcon = 'message-selected';
        break;
      case 'bell':
        this.bellImageIcon = 'bell-selected';
        break;
      case 'network':
        this.networkImageIcon = 'network-selected';
        break;
      case 'plus-mobile':
        this.plusImageIcon = 'plus-mobile-selected';
        break;
    }
  }

  getHomeIcon(): string {
    if (window.location.pathname.indexOf('dashboard') > 0) {
      return 'home-selected';
    } else {
      return 'home';
    }
  }

  getMessageIcon(): string {
    if (window.location.pathname.indexOf('message') > 0) {
      return 'skillschat-active';
    } else {
      return 'skillschat-inactive';
    }
  }

  getNotificationIcon(): string {
    if (window.location.pathname.indexOf('notification') > 0) {
      return 'bell-selected';
    } else {
      return 'bell';
    }
  }

  getNetworkIcon(): string {
    if (window.location.pathname.indexOf('network') > 0) {
      return 'network-selected';
    } else {
      return 'network';
    }
  }

  getSearchIcon(): string {
    if (window.location.pathname.indexOf('search') > 0) {
      return 'search-selected';
    } else {
      return 'search';
    }
  }

  getPlusIcon(): string {
    if (window.location.pathname.indexOf('profile') > 0 || window.location.pathname.indexOf('setting') > 0 || window.location.pathname.indexOf('assistance') > 0) {
      return 'plus-mobile-selected';
    } else {
      return 'plus-mobile';
    }
  }
}
