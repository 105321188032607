<div class="row">
  <sm-alert [alert]="alert"></sm-alert>
  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
    <div class="write-us">
      merci de nous <br/>
      écrire pour tout <br/>
      besoin
    </div>
    <div>
      <img
        src="{{ 'assistance-lady' | image }}"
        class="assistance-contact-us-img"
        alt="question lady"/>
    </div>
  </div>
  <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
    <sm-input
      [type]="'text'"
      [mandatory]="true"
      title="{{'assistance.subject' | translate}}"
      placeholder="{{'assistance.subject' | translate}}"
      [showErrorMessage]="showErrorMessageTopic"
      errorMessage="{{'assistance.subject' | translate}}"
      [inputStatus]="inputStatusTopic"
      (smInputEvent)="getTopic($event)">
    </sm-input>
    <sm-textarea
      [mandatory]="true"
      title="{{'assistance.message' | translate}}"
      [value]="''"
      (smInputEvent)="getMessage($event)">
    </sm-textarea>

    <div class="row">
      <div class="col m-t-10">
        <sm-button text="{{'edit_profile.cancel' | translate}}" (click)="onClickCancel()"></sm-button>
      </div>
      <div class="col m-t-10" *ngIf="!processing">
        <sm-button text="{{'login.button_send' | translate}}" [type]="'success'" (click)="onClickSave()"/>
      </div>
      <div class="col m-t-10" *ngIf="processing">
        <sm-processing [loading]="processing"/>
      </div>
    </div>
  </div>
</div>
