import {Component, OnInit} from '@angular/core';
import {Forum} from "../../models/forum";
import {AccountDetailsResource} from "ApiModuleAccount";

@Component({
  selector: 'sm-forum',
  templateUrl: './forum.component.html',
  styleUrls: ['./forum.component.scss']
})
export class ForumComponent implements OnInit {
  accounts: AccountDetailsResource[] = [];
  forums: Forum[] = [];
  expand: boolean = false;

  constructor() {
  }

  ngOnInit(): void {
    // this.initForum();
  }

  initForum(): void {
    let forum: Forum = {} as Forum;
    forum.account = {} as AccountDetailsResource;
    forum.discipline = 'Informatique';
    forum.description = 'How to convert image to URI to Byte[] (Byte array) in React Native?';
    forum.nb = 24;
    this.forums.push(forum);

    forum = {} as Forum;
    forum.account = {} as AccountDetailsResource;
    forum.discipline = 'Finance';
    forum.description = 'Comment calculer le délai de recuperation d\'un investissement?';
    forum.nb = 8;
    this.forums.push(forum);

    forum = {} as Forum;
    forum.account = {} as AccountDetailsResource;
    forum.discipline = 'Energie';
    forum.description = 'Comment accelerer la bio-decomposition des dechets organiques à partir d\'algues?';
    forum.nb = 18;
    this.forums.push(forum);
  }

  onClick(): void {
    // this.expand = !this.expand;
  }
}
