<div class="skillsmates-box-shadow card" style="padding-bottom: 5px;">
  <div class="row card-header skillsmates-box-top" style="margin: 0 1px 0 1px; cursor: pointer;" (click)="onClick()">

    <div class="col-md-12 no-margin-padding  collapsed d-block" data-toggle="collapse" href="#collapse-collapsed"
         aria-expanded="true" aria-controls="collapse-collapsed" id="heading-collapsed">
      <em class="fa fa-chevron-down pull-right" style="padding-top: 10px; padding-left: 5px;"></em>
      <img height="24" src="{{'online' | image}}" alt=""> &nbsp;
      <strong>En ligne</strong>
      ({{accountResponse && accountResponse.meta ? accountResponse.meta.total : 0}})
    </div>

  </div>
  <div class="row bg-white" style="padding: 10px 0 10px 0; margin: 0; border-radius: 0 0 10px 10px;"
       *ngIf="expand && accountResponse.meta && accountResponse.meta.total! > 0">
    <div class="col">
      <div class="row" style="padding: 10px 0 10px 10px; margin: 0; border-radius: 0 0 10px 10px;"
           *ngFor="let account of accountResponse.resources">
        <div class="col-3" (click)="onClickProfile(account)">
          <a>
            <img src="{{account.avatar}}" class="avatarOnline" alt="">
          </a>
        </div>
        <div class="col-9 text online-single-line" (click)="onClickProfile(account)"><a>
          <b>{{account.firstname}} {{account.lastname}}</b></a></div>
      </div>
    </div>
  </div>
</div>


