<div class="row m-t-30 m-b-10" style=" " *ngIf="!editName">
  <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">{{'settings.name' | translate}} :</div>
  <div class="col-7 col-sm-7 col-md-7 col-lg-8 col-xl-8">
    <strong>{{loggedAccount.lastname}} {{loggedAccount.firstname}}</strong>
  </div>
  <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
    <sm-button text="{{'settings.edit' | translate}}" (smButtonEvent)="onClickEdit('name')"></sm-button>
  </div>
</div>
<div class="row m-b-10" *ngIf="editName">
  <div class="col-12">
    <div class="row">
      <div class="col">
        <sm-input
          [type]="'text'"
          title="{{'settings.lastname' | translate}}"
          placeholder="{{'settings.lastname' | translate}}"
          [value]="loggedAccount.lastname!"
          errorMessage="{{'settings.lastname' | translate}}"
          (smInputEvent)="getLastname($event)">
        </sm-input>
      </div>
      <div class="col">
        <sm-input
          [type]="'text'"
          title="{{'settings.firstname' | translate}}"
          placeholder="{{'settings.firstname' | translate}}"
          [value]="loggedAccount.firstname!"
          errorMessage="{{'settings.firstname' | translate}}"
          (smInputEvent)="getFirstname($event)">
        </sm-input>
      </div>
    </div>

    <sm-input
      [showEye]="true"
      placeholder="{{'sm.password' | translate}}"
      name="{{'sm.password' | translate}}"
      [type]="'password'"
      (smInputEvent)="getPassword($event)">
    </sm-input>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col m-t-10">
        <sm-button text="{{'edit_profile.cancel' | translate}}" (click)="onClickCancel()"></sm-button>
      </div>
      <div class="col m-t-10">
        <sm-button text="{{'edit_profile.validate' | translate}}" [type]="'success'"
                   (click)="onClickSave()"></sm-button>
      </div>
    </div>
  </div>
</div>


<sm-horizontal-line></sm-horizontal-line>
<div class="row m-t-10 m-b-10" style=" " *ngIf="!editEmail">
  <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">{{'settings.email' | translate}} :</div>
  <div class="col-7 col-sm-7 col-md-7 col-lg-8 col-xl-8">
    <strong>{{loggedAccount.email}}</strong>
  </div>
  <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
    <sm-button text="{{'settings.edit' | translate}}" (smButtonEvent)="onClickEdit('email')"></sm-button>
  </div>
</div>
<div class="row m-b-10" *ngIf="editEmail">
  <div class="col-12">
    <sm-input
      [type]="'text'"
      title="{{'settings.email' | translate}}"
      placeholder="{{'settings.email' | translate}}"
      [value]="loggedAccount.email!"
      errorMessage="{{'settings.email' | translate}}"
      (smInputEvent)="getEmail($event)">
    </sm-input>

    <sm-input
      [showEye]="true"
      placeholder="{{'sm.password' | translate}}"
      name="{{'sm.password' | translate}}"
      [type]="'password'"
      (smInputEvent)="getPassword($event)">
    </sm-input>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col m-t-10">
        <sm-button text="{{'edit_profile.cancel' | translate}}" (click)="onClickCancel()"></sm-button>
      </div>
      <div class="col m-t-10">
        <sm-button text="{{'edit_profile.validate' | translate}}" [type]="'success'"
                   (click)="onClickSave()"></sm-button>
      </div>
    </div>
  </div>
</div>


<sm-horizontal-line></sm-horizontal-line>
<div class="row m-t-10 m-b-10" style=" " *ngIf="!editPhone">
  <div class="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2">{{'settings.phone' | translate}} :</div>
  <div class="col-7 col-sm-7 col-md-7 col-lg-8 col-xl-8">
    <strong>{{loggedAccount.phoneNumber}}</strong>
  </div>
  <div class="col-3 col-sm-3 col-md-3 col-lg-2 col-xl-2">
    <sm-button text="{{'settings.edit' | translate}}" (smButtonEvent)="onClickEdit('phone')"></sm-button>
  </div>
</div>
<div class="row" *ngIf="editPhone">
  <div class="col-12">
    <sm-input
      [type]="'text'"
      title="{{'settings.phone' | translate}}"
      placeholder="{{'settings.phone' | translate}}"
      [value]="loggedAccount.phoneNumber!"
      errorMessage="{{'settings.phone' | translate}}"
      (smInputEvent)="getPhoneNumber($event)">
    </sm-input>

    <sm-input
      [showEye]="true"
      placeholder="{{'sm.password' | translate}}"
      name="{{'sm.password' | translate}}"
      [type]="'password'"
      (smInputEvent)="getPassword($event)">
    </sm-input>
  </div>
  <div class="col-12">
    <div class="row">
      <div class="col m-t-10">
        <sm-button text="{{'edit_profile.cancel' | translate}}" (click)="onClickCancel()"></sm-button>
      </div>
      <div class="col m-t-10" *ngIf="!processing">
        <sm-button text="{{'edit_profile.validate' | translate}}" [type]="'success'" (click)="onClickSave()"/>
      </div>
      <div class="col m-t-10" *ngIf="processing">
        <sm-processing [loading]="processing"/>
      </div>
    </div>
  </div>
</div>
