export const environment = {
  ms_resources_baseurl: 'https://skillsmatesresources.s3.us-east-2.amazonaws.com',
  ms_resources_facebook: 'https://www.facebook.com',
  ms_resources_tiktok: 'https://www.tiktok.com',
  ms_resources_instagram: 'https://www.instagram.com',
  ms_resources_twitter: 'https://twitter.com',
  ms_resources_snapchat: 'https://www.snapchat.com',

  itemsPerPage: 32,
  itemsPerPageSearch: 24,
  itemsPerPageDocument: 32,
  trialDuration: 30,

  aws_s3_multimedia_bucket: 'https://skillsmatesmedia.s3.us-east-2.amazonaws.com/multimedia/',
  SKILLSMATES_API_BASE_PATH_ACCOUNT: 'https://api-skillsmates.com:9001',
  SKILLSMATES_API_BASE_PATH_POST: 'https://api-skillsmates.com:9002',
  SKILLSMATES_ENV: 'prod'
};
