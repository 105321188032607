<button
  class="btn btn-primary sm-border-radius button-style"
  [ngClass]="{'button-danger': type === 'danger',
    'button-success' : type === 'success',
    'button-info' : type === 'info',
    'button-default' : type === 'default'}"
  (click)="onClick()">
  {{ text }}
  <img *ngIf="image" height="16" src="{{image}}" alt="{{image}}">
</button>
