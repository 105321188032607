import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'sm-button-gradient',
  templateUrl: './button-gradient.component.html',
  styleUrls: ['./button-gradient.component.scss']
})
export class ButtonGradientComponent implements OnInit {
  @Input() text: string = "button";
  @Input() type: string = "default";
  @Input() image!: string;
  @Input() completed: number = 50;
  @Output() smButtonEvent = new EventEmitter<void>();

  ngOnInit() {
    let button = document.getElementById("button-gradient");
    if (button) {
      button.style.backgroundImage = "linear-gradient(to right, #00FF00 " + 50 + "%, white " + 50 + "%)";
    }
  }

  onClick(): void {
    this.smButtonEvent.emit();
  }
}
