<div class="skillsmates-box-shadow card" style="padding-bottom: 5px;">
  <div class="row card-header skillsmates-box-top" style="margin: 0 1px 0 1px; cursor: pointer;">

    <div class="col-md-12 no-margin-padding  collapsed d-block" data-toggle="collapse"
         href="#collapse-collapsed-skillsbox" aria-expanded="true" aria-controls="collapse-collapsed-skillsbox"
         id="heading-collapsed-skillsbox">
      <em class="fa fa-chevron-down pull-right" style="padding-top: 10px; padding-left: 5px;"></em>
      <img height="24" src="{{'library' | image}}" alt="Library"> &nbsp;
      <strong>SKILLSBOX</strong> ({{selections.length}})
    </div>

  </div>
  <div class="row bg-white collapse" style="margin: auto; border-radius: 0 0 10px 10px;"
       id="collapse-collapsed-skillsbox" aria-labelledby="heading-collapsed-skillsbox">

    <div class="col-12 card-body">
      <div class="text-center" *ngFor="let selection of selections; let i = index;">
        <sm-selection-account [selection]="selection" [isLastOne]="selections.length === i+1"></sm-selection-account>
      </div>
    </div>
  </div>
</div>
