<div class="bottom-sheet-bg-overlay" *ngIf="flags.isBottomSheetEnabled" (click)="close()"></div>
<div class="bottom-sheet-container" *ngIf="flags.isBottomSheetEnabled"
     [style.backgroundColor]="options?.backgroundColor" [style.color]="options?.fontColor" [@slideUpToggle]>
  <div>
    <div class="bottom-sheet-header text-center">
      <div style="padding-bottom: 10px; border-top: 5px solid #16152d; width: 50%; margin: auto;"></div>
      <h4 class="title">{{options?.title}}</h4>
    </div>
    <div class="bottom-sheet-content">
      <ng-content></ng-content>
    </div>
    <div class="bottom-sheet-footer" (click)="close()"
         *ngIf="flags.isCloseButtonEnabled">
      {{options.closeButtonTitle}}
    </div>
  </div>
</div>
