<div class="row p-2 bg-white" style="margin-bottom: 5px;">
  <div class="col-3 col-sm-3 col-md-2 col-lg-2 col-xl-2">
    <sm-post-media [post]="post" [height]="150"></sm-post-media>
  </div>
  <div class="col-9 col-sm-9 col-md-10 col-lg-10 col-xl-10 pointer" (click)="onClickPost()">
    <div class="row">
      <div class="col-12">
        <p><span class="skillsmates-label"></span><strong>{{post.title}}</strong></p>
      </div>
      <div class="col12">
        <p [ngClass]="{'skillsmates-overview': toggleDisplayMoreLabel}" *ngIf="post.description"
           [innerHTML]="post.description"></p>
        <p (click)="onClickMore()" *ngIf="post && post.description && post.description.length > 250">
          <a style="cursor: pointer; font-weight: bold; font-size: small; color: green;">{{displayMoreLabel}} </a>
          <i class="{{displayMoreIcon}}" aria-hidden="true" style="color: green"></i>
        </p>
      </div>
      <div class="col-12 text-left date-post">
        {{"document.published" | translate}} : <span class="date-post">{{post.createdAt | timeAgo}}</span>
      </div>
    </div>
  </div>
</div>
