export class NetworkStats {
  constructor() {
    this.suggestions = 0;
    this.followers = 0;
    this.followees = 0;
    this.online = 0;
  }

  suggestions: number = 0;
  followers: number = 0;
  followees: number = 0;
  online: number = 0;
}
