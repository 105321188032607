/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface NetworkResource { 
    uuid?: string;
    deleted?: boolean;
    createdAt?: string;
    updatedAt?: string;
    active?: boolean;
    firstname?: string;
    lastname?: string;
    email?: string;
    address?: string;
    phoneNumber?: string;
    birthdate?: string;
    gender?: NetworkResource.GenderEnum;
    biography?: string;
    city?: string;
    country?: string;
    status?: NetworkResource.StatusEnum;
    connected?: boolean;
    connectedAt?: string;
    role?: NetworkResource.RoleEnum;
}
export namespace NetworkResource {
    export type GenderEnum = 'MALE' | 'FEMALE';
    export const GenderEnum = {
        Male: 'MALE' as GenderEnum,
        Female: 'FEMALE' as GenderEnum
    };
    export type StatusEnum = 'STUDENT' | 'TEACHER' | 'PROFESSIONAL';
    export const StatusEnum = {
        Student: 'STUDENT' as StatusEnum,
        Teacher: 'TEACHER' as StatusEnum,
        Professional: 'PROFESSIONAL' as StatusEnum
    };
    export type RoleEnum = 'ADMIN' | 'USER';
    export const RoleEnum = {
        Admin: 'ADMIN' as RoleEnum,
        User: 'USER' as RoleEnum
    };
}


