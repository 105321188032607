import {Component, HostListener, OnInit} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {AccountDetailsResource} from "ApiModuleAccount";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;

  pageYoffset = 0;

  @HostListener('window:scroll', ['$event']) onScroll(event: any): void {
    this.pageYoffset = window.pageYOffset;
  }

  constructor(private localStorageService: LocalStorageService,) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
  }

  scrollToTop(): void {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }
}
