import {Component, OnInit} from '@angular/core';
import {AccountDetailsResource, AuthenticationService} from "ApiModuleAccount";
import {Router} from "@angular/router";
import {LocalStorageService} from "../../services/local-storage.service";
import {colors} from "../../../environments/colors";
import {variables} from "../../../environments/variables";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  colors = colors;
  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  tabs: string[] = ["account", "security", "about"];
  selectedTab: string = this.tabs[0];
  editMode: boolean = false;
  isOffline: boolean = true;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private localStorageService: LocalStorageService
  ) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.isOffline = this.localStorageService.isOffline()
    this.getSelectedTab()
    this.account = this.localStorageService.getProfileAccount();
  }

  getSelectedTab() {
    if (this.isOffline) {
      this.tabs = ["about"]
      this.selectedTab = this.tabs[0]
    } else {
      this.selectedTab = this.localStorageService.get(variables.setting_tab)
      this.selectedTab = this.tabs.includes(this.selectedTab) ? this.selectedTab : this.tabs[0]
    }
  }

  getTabImage(tab: string) {
    switch (tab) {
      case 'account':
        return 'user'
      case 'security':
        return 'security'
      case 'about':
        return 'info'
      default:
        return ''
    }
  }

  onClickTab(tab: string): void {
    this.selectedTab = tab;
  }

  isAboutTab() {
    return this.selectedTab === 'about'
  }

  isSecurityTab() {
    return this.selectedTab === 'security'
  }

  isAccountTab() {
    return this.selectedTab === 'account'
  }
}
