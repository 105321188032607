import {Component, Input} from '@angular/core';

@Component({
  selector: 'sm-horizontal-line',
  templateUrl: './horizontal-line.component.html',
  styleUrls: ['./horizontal-line.component.scss']
})
export class HorizontalLineComponent {
  @Input() color: string = '#16152d';
  @Input() height: number = 1;
}
