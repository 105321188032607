<div class="row bg-white" style="margin: auto;  border-radius: 0 0 10px 10px;">
  <ul *ngIf="!loading" style="list-style: none; margin: 10px 0 10px 0">

    <sm-collapsible-tile
      (click)="onClickCollapsibleTile(profileMediaType)"
      (smItemEvent)="onClickItem($event, true)"
      [bgColor]="selectedMediaType.code === profileMediaType.code ? profileMediaType.color+'11' : 'white'"
      [checkedItems]="checkedProfileItems"
      [image]="profileMediaType.picture"
      [list]="selectedStatusesList"
      [title]="profileMediaType.label">
    </sm-collapsible-tile>

    <sm-collapsible-tile
      (click)="onClickCollapsibleTile(mediaType)"
      (smItemEvent)="onClickItem($event, false)"
      *ngFor="let mediaType of mediaTypes"
      [bgColor]="selectedMediaType.code === mediaType.code ? mediaType.color+'11' : 'white'"
      [checkedItems]="checkedItems"
      [image]="mediaType.picture"
      [list]="loadDocumentTypesDropdown(mediaType)"
      [title]="mediaType.label"
      class="p-3">
    </sm-collapsible-tile>
  </ul>
</div>
