import {Component, Input, OnInit} from '@angular/core';
import {Forum} from "../../models/forum";
import {StringHelper} from "../../helpers/string-helper";
import {AccountDetailsResource} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";

@Component({
  selector: 'sm-forum-account',
  templateUrl: './forum-account.component.html',
  styleUrls: ['./forum-account.component.scss']
})
export class ForumAccountComponent implements OnInit {
  @Input() forum: Forum = new Forum();
  @Input() isLastOne: boolean = true;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;

  constructor(private storageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.storageService.getLoggedAccount();
  }

  public name(account: AccountDetailsResource): string {
    return StringHelper.truncateName(account, 18);
  }
}
