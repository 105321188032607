import {ChangeDetectorRef, Component, Input} from '@angular/core';
import {BottomSheetConfig} from "../utility/bottom-sheet.interface";

@Component({
  selector: 'sm-bottom-sheet-plus',
  templateUrl: './bottom-sheet-plus.component.html',
  styleUrls: ['./bottom-sheet-plus.component.scss']
})
export class BottomSheetPlusComponent {
  flags: any = {
    isBottomSheetEnabled: false
  };
  @Input() options!: BottomSheetConfig;
  constructor(private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.flags.isCloseButtonEnabled = this.options.enableCloseButton ? true : false;
    this.options.closeButtonTitle = this.options.closeButtonTitle ? this.options.closeButtonTitle : "Close"
  }
  open() {
    this.flags.isBottomSheetEnabled = true;
    this.changeDetector.detectChanges();
  }

  close() {
    this.flags.isBottomSheetEnabled = false;
    this.changeDetector.detectChanges();
  }
}
