<div *ngIf="mediaType" class="skillsmates-box-shadow pointer" style="border: 2px solid {{mediaType.color}};">
  <div class="row" style="margin: auto; cursor: pointer;">
    <div [style.background-color]="mediaType.color" class="col"
         style="color: white; padding: 12px;  border-radius: 5px 5px 0 0;">
      <strong (click)="onClickMore(mediaType)">{{mediaType.label}}</strong> ({{total}})
      <span style="float: right;">
        <a style="color: white;"> {{'account_curriculum.see_more' | translate}}</a>
      </span>
    </div>
  </div>
  <div class="row bg-white account-documents-body">
    <div class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 text-center" *ngFor="let post of posts"
         style="margin:0; top: 50%;">
      <div *ngIf="posts && posts.length > 0">
        <div *ngIf="isImage(post)" class="no-margin-padding">
          <div style="padding: 0; border-right:2px solid whitesmoke; margin: 0 1px 0 0;"><img
            src="{{post.thumbnail}}" width="64" height="64" alt=""></div>
        </div>
        <div *ngIf="isPdf(post)" class="no-margin-padding">
          <div style="border-right:2px solid whitesmoke; margin-right: 1px;">
            <img src="{{'pdf' | image}}" width="60" height="60" alt="">
          </div>
        </div>
        <p class="skillsmates-two-lines text-center" style="font-size: xx-small; margin: auto;">{{post.title}}</p>
      </div>
    </div>

  </div>
</div>
