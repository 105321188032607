<!--<button-->
<!--  id="button-gradient"-->
<!--  class="btn btn-primary sm-border-radius button-style"-->
<!--  [ngClass]="{'button-danger': type === 'danger',-->
<!--    'button-success' : type === 'success',-->
<!--    'button-info' : type === 'info',-->
<!--    'button-default' : type === 'default'}"-->
<!--  (click)="onClick()">-->
<!--  {{ text }}-->
<!--  <img *ngIf="image" height="16" src="{{image}}" alt="{{image}}">-->
<!--</button>-->


<button
  id="button-gradient"
  class="btn btn-primary sm-border-radius button-style"
  [ngClass]="{'button-danger': type === 'danger',
    'button-success' : type === 'success',
    'button-info' : type === 'info',
    'button-default' : type === 'default'}">
  <div class="row">
    <div class="col skillsmates-single-line">{{ text }}</div>
    <div class="col-4 col-sm-4 col-md-3 col-lg-3 col-xl-3" *ngIf="image">
      <img height="16" width="16" src="{{image}}" alt="{{image}}">
    </div>
  </div>
</button>



