import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {Route} from "../../enums/route.enum";
import {InfoNetworkType} from "../../models/infoNetworkType";
import {NetworkHelper} from "../../helpers/network-helper";
import {NetworkStats} from "../../models/networkStats";
import {variables} from "../../../environments/variables";
import {Router} from "@angular/router";
import '@angular/localize/init';
import {AccountDetailsResource, AccountService, MetaResponse, NetworkService, SearchParam} from "ApiModuleAccount";

@Component({
  selector: 'app-network',
  templateUrl: './network.component.html',
  styleUrls: ['./network.component.scss']
})
export class NetworkComponent implements OnInit {

  account!: AccountDetailsResource;
  networkTypes: InfoNetworkType[] = NetworkHelper.networkTypes;
  selectedNetworkType!: InfoNetworkType;
  currentNetworkType!: string;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  accounts: AccountDetailsResource[] = [];
  meta: MetaResponse = {} as MetaResponse;
  networkStats: NetworkStats = new NetworkStats();
  Route = Route;
  title: string = "votre réseau";
  loading: boolean = false;
  searchParam: SearchParam = {} as SearchParam;


  page = 1;
  maxSize = 5;
  collectionSize = 1
  perPage = 12;
  calculateTotalPages = this.collectionSize / this.perPage;

  constructor(private localStorageService: LocalStorageService,
              private accountService: AccountService,
              private networkService: NetworkService,
              private router: Router) {
    this.networkStats.suggestions = localStorageService.get(variables.suggestions);
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
    if (!this.profileAndLoggedAccountAreSame()) {
      this.networkTypes = this.networkTypes.filter(value => value.type === 'FOLLOWERS' || value.type === 'FOLLOWEES')
    }
    this.title = this.loggedAccount.uuid == this.account.uuid ? "   Votre réseau" : "   Le réseau de " + this.account.firstname + " " + this.account.lastname;
    this.selectedNetworkType = this.networkTypes[0];
    this.currentNetworkType = this.selectedNetworkType.type;
    this.searchParam.account = this.account.uuid;
    this.searchParam.networkType = this.selectedNetworkType.type;
    this.findAccounts(this.searchParam, this.page - 1, this.perPage);
    this.updateNetworkTypesCount()
    let networkStats = this.localStorageService.get(variables.network_stats);
    if (!networkStats) {
      this.localStorageService.add(variables.network_stats, this.networkStats);
    }
  }

  profileAndLoggedAccountAreSame(): boolean {
    return this.loggedAccount.uuid === this.account.uuid
  }

  updateNetworkTypesCount() {
    this.networkTypes.forEach(value => {
      if (value.type === 'FOLLOWERS') {
        value.value = this.account.followers!
      }
      if (value.type === 'FOLLOWEES') {
        value.value = this.account.followees!
      }
      if (value.type === 'SUGGESTIONS') {
        value.value = this.localStorageService.get(variables.suggestions)
      }
    })
  }

  onClickTab(networkType: InfoNetworkType): void {
    this.page = 1;
    this.selectedNetworkType = networkType;
    this.currentNetworkType = networkType.type;
    this.searchParam.networkType = networkType.type;
    this.findAccounts(this.searchParam, this.page - 1, this.perPage);
  }

  findAccounts(searchParam: SearchParam, offset: number, limit: number) {
    this.loading = true;
    this.networkService.searchNetworkAccounts(offset, limit, searchParam).subscribe({
      next: response => {
        this.meta = response.meta!;
        this.accounts = response.resources!;
        this.setNetworkStats();
        this.loading = false;
      },
      error: error => {
        this.loading = false;
      },
      complete: () => {
        this.loading = false;
      }
    });
  }

  updatePagination(): void {
    this.findAccounts(this.searchParam, this.page - 1, this.perPage);
  }

  setNetworkStats(): void {
    this.updateNetworkStats(this.currentNetworkType)
    switch (this.currentNetworkType) {
      case "SUGGESSIONS":
        this.networkStats.suggestions = this.meta.total!;
        break;
      case "FOLLOWERS":
        this.networkStats.followers = this.meta.total!;
        break;
      case "FOLLOWEES":
        this.networkStats.followees = this.meta.total!;
        break;
      case "ONLINE":
        this.networkStats.online = this.meta.total!;
        break;
      default:
        break;
    }
    this.collectionSize = this.meta.total!;
    this.calculateTotalPages = this.collectionSize / this.perPage;
    this.localStorageService.update(variables.network_stats, this.networkStats);
  }

  updateNetworkStats(networkType: string) {
    this.networkTypes.forEach(value => {
      if (value.type === networkType) {
        value.value = this.meta.total!
      }
    })
  }

  countAccountsByNetworkType(networkType: InfoNetworkType): number {
    let networkStats: NetworkStats = this.localStorageService.get(variables.network_stats);
    if (networkStats) {
      switch (networkType.type) {
        case "SUGGESTIONS":
          return networkStats.suggestions;
        case "FOLLOWERS":
          return networkStats.followers;
        case "FOLLOWEES":
          return networkStats.followees;
        case "ONLINE":
          return networkStats.online;
        default:
          return 0;
      }
    } else {
      return 0;
    }
  }

  onClickProfile(account: AccountDetailsResource): void {
    this.localStorageService.add(variables.profile_account, account);
    this.router.navigate([Route.PROFILE]);
  }

  loadPage(event: number): void {
    this.page = event;
    this.findAccounts(this.searchParam, this.page - 1, this.perPage);
  }

  setDefault() {
    this.account.avatar = './assets/images/user.svg';
  }
}
