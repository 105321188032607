<div class="row">
  <sm-alert [alert]="alert"></sm-alert>
  <div class="col-12 p-3">
    <strong>{{ 'edit_profile.training' | translate }} {{ 'edit_profile.ongoing' | translate }} </strong>
  </div>
  <div class="col-12">
    <sm-dropdown
      (smInputEvent)="getEducation($event)"
      [list]="getDiplomaTypeLabels()"
      [mandatory]="true"
      [selected]="getCurrentEducation()"
      title="{{'edit_profile.education' | translate}}">
    </sm-dropdown>
    <sm-input (smInputEvent)="getTrainingTitle($event)"
              *ngIf="trainingRequest.education === 'HIGHER'"
              [errorMessage]="errorMessage('TITLE')"
              [inputStatus]="getCssStatus('TITLE')"
              [showErrorMessage]="!isValid('TITLE')"
              [type]="'text'"
              [value]="trainingRequest.title!"
              placeholder="{{'edit_profile.training_tile' | translate}}"
              title="{{'edit_profile.training_tile' | translate}}">
    </sm-input>
    <sm-dropdown (smInputEvent)="getLevel($event)"
                 *ngIf="trainingRequest.education === 'HIGHER'"
                 [list]="levelLabels()"
                 [ngClass]="getCssStatusFinal('LEVEL')"
                 [selected]="trainingRequest.level!"
                 title="{{'edit_profile.school_level' | translate}}">
    </sm-dropdown>
    <sm-input (smInputEvent)="getSchoolClass($event)"
              *ngIf="trainingRequest.education === 'SECONDARY'"
              [errorMessage]="errorMessage('CLASS')"
              [inputStatus]="getCssStatus('CLASS')"
              [mandatory]="true"
              [showErrorMessage]="!isValid('CLASS')"
              [type]="'text'"
              [value]="trainingRequest.schoolClass!"
              placeholder="{{'edit_profile.school_class' | translate}}"
              title="{{'edit_profile.school_class' | translate}}">
    </sm-input>
    <sm-input
      (smInputEvent)="getActivityArea($event)"
      [errorMessage]="errorMessage('DOMAIN')"
      [inputStatus]="getCssStatus('DOMAIN')"
      [mandatory]="false"
      [showErrorMessage]="!isValid('DOMAIN')"
      [type]="'text'"
      [value]="trainingRequest.activityArea!"
      placeholder="{{'edit_profile.optional_study_field' | translate}}"
      title="{{'edit_profile.optional_study_field' | translate}}">
    </sm-input>
    <sm-input
      (smInputEvent)="getSchoolName($event)"
      [errorMessage]="errorMessage('SCHOOL')"
      [inputStatus]="getCssStatus('SCHOOL')"
      [mandatory]="true"
      [showErrorMessage]="!isValid('SCHOOL')"
      [type]="'text'"
      [value]="trainingRequest.schoolName!"
      placeholder="{{'edit_profile.school_name' | translate}}"
      title="{{'edit_profile.school_name' | translate}}">
    </sm-input>
    <sm-input
      (smInputEvent)="getCity($event)"
      [errorMessage]="errorMessage('CITY')"
      [inputStatus]="getCssStatus('CITY')"
      [mandatory]="true"
      [showErrorMessage]="!isValid('CITY')"
      [type]="'text'"
      [value]="trainingRequest.city!"
      placeholder="{{'edit_profile.location' | translate}}"
      title="{{'edit_profile.location' | translate}}">
    </sm-input>
    <sm-input
      (smInputEvent)="getStartDate($event)"
      [errorMessage]="errorMessage('DATE')"
      [inputStatus]="getCssStatus('DATE')"
      [mandatory]="true"
      [name]="'start_date'"
      [showErrorMessage]="!isValid('DATE')"
      [type]="'date'"
      [value]="formatDate(trainingRequest.startDate!)"
      placeholder="{{'edit_profile.start_date' | translate}}"
      title="{{'edit_profile.start_date' | translate}}">
    </sm-input>
    <sm-textarea
      (smInputEvent)="getDescription($event)"
      [value]="trainingRequest.description ? trainingRequest.description : ''"
      placeholder="{{'edit_profile.training_description' | translate}}"
      title="{{'edit_profile.training_description' | translate}}">
    </sm-textarea>
  </div>

  <div class="row">
    <div class="col m-t-10">
      <sm-button (click)="onClickCancel()" text="{{'edit_profile.cancel' | translate}}"></sm-button>
    </div>
    <div *ngIf="!processing" class="col m-t-10">
      <sm-button (click)="onClickSave()" [type]="'success'" text="{{'edit_profile.validate' | translate}}"></sm-button>
    </div>
    <div *ngIf="processing" class="col m-t-10">
      <sm-processing [loading]="processing"/>
    </div>
  </div>
</div>
