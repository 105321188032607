import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'sm-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {
  }

  onClickOpenPartner(url: string): void {
    window.open(url, '_blank');
  }
}
