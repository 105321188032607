import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {Router} from "@angular/router";
import {variables} from "../../../environments/variables";

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent implements OnInit {
  constructor(private localStorageService: LocalStorageService, private router: Router) {
  }

  ngOnInit(): void {
  }

  onClickReturn(): void {
    this.router.navigate([this.localStorageService.get(variables.url)]);
  }
}
