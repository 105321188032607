/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec, HttpContext 
        }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

// @ts-ignore
import { PostCreateResponse } from '../model/postCreateResponse';

// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class PostFileService {

    protected basePath = 'http://localhost:7002';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string|string[], @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (Array.isArray(basePath) && basePath.length > 0) {
                basePath = basePath[0];
            }

            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }

    // @ts-ignore
    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key, (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * @param uuid 
     * @param title 
     * @param keywords 
     * @param description 
     * @param documentType 
     * @param postType 
     * @param link 
     * @param account 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public savePost(uuid: string, title: string, keywords: string, description: string, documentType: string, postType: string, link: string, account: string, file: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;utf-8', context?: HttpContext}): Observable<PostCreateResponse>;
    public savePost(uuid: string, title: string, keywords: string, description: string, documentType: string, postType: string, link: string, account: string, file: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<PostCreateResponse>>;
    public savePost(uuid: string, title: string, keywords: string, description: string, documentType: string, postType: string, link: string, account: string, file: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<PostCreateResponse>>;
    public savePost(uuid: string, title: string, keywords: string, description: string, documentType: string, postType: string, link: string, account: string, file: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (uuid === null || uuid === undefined) {
            throw new Error('Required parameter uuid was null or undefined when calling savePost.');
        }
        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling savePost.');
        }
        if (keywords === null || keywords === undefined) {
            throw new Error('Required parameter keywords was null or undefined when calling savePost.');
        }
        if (description === null || description === undefined) {
            throw new Error('Required parameter description was null or undefined when calling savePost.');
        }
        if (documentType === null || documentType === undefined) {
            throw new Error('Required parameter documentType was null or undefined when calling savePost.');
        }
        if (postType === null || postType === undefined) {
            throw new Error('Required parameter postType was null or undefined when calling savePost.');
        }
        if (link === null || link === undefined) {
            throw new Error('Required parameter link was null or undefined when calling savePost.');
        }
        if (account === null || account === undefined) {
            throw new Error('Required parameter account was null or undefined when calling savePost.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling savePost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (uuid !== undefined) {
            localVarFormParams = localVarFormParams.append('uuid', <any>uuid) as any || localVarFormParams;
        }
        if (title !== undefined) {
            localVarFormParams = localVarFormParams.append('title', <any>title) as any || localVarFormParams;
        }
        if (keywords !== undefined) {
            localVarFormParams = localVarFormParams.append('keywords', <any>keywords) as any || localVarFormParams;
        }
        if (description !== undefined) {
            localVarFormParams = localVarFormParams.append('description', <any>description) as any || localVarFormParams;
        }
        if (documentType !== undefined) {
            localVarFormParams = localVarFormParams.append('documentType', <any>documentType) as any || localVarFormParams;
        }
        if (postType !== undefined) {
            localVarFormParams = localVarFormParams.append('postType', <any>postType) as any || localVarFormParams;
        }
        if (link !== undefined) {
            localVarFormParams = localVarFormParams.append('link', <any>link) as any || localVarFormParams;
        }
        if (account !== undefined) {
            localVarFormParams = localVarFormParams.append('account', <any>account) as any || localVarFormParams;
        }
        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skillsmates/posts`;
        return this.httpClient.request<PostCreateResponse>('post', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * @param postId 
     * @param title 
     * @param keywords 
     * @param description 
     * @param documentType 
     * @param postType 
     * @param link 
     * @param account 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updatePost(postId: string, title: string, keywords: string, description: string, documentType: string, postType: string, link: string, account: string, file: Blob, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;utf-8', context?: HttpContext}): Observable<PostCreateResponse>;
    public updatePost(postId: string, title: string, keywords: string, description: string, documentType: string, postType: string, link: string, account: string, file: Blob, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpResponse<PostCreateResponse>>;
    public updatePost(postId: string, title: string, keywords: string, description: string, documentType: string, postType: string, link: string, account: string, file: Blob, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;utf-8', context?: HttpContext}): Observable<HttpEvent<PostCreateResponse>>;
    public updatePost(postId: string, title: string, keywords: string, description: string, documentType: string, postType: string, link: string, account: string, file: Blob, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json;charset&#x3D;utf-8', context?: HttpContext}): Observable<any> {
        if (postId === null || postId === undefined) {
            throw new Error('Required parameter postId was null or undefined when calling updatePost.');
        }
        if (title === null || title === undefined) {
            throw new Error('Required parameter title was null or undefined when calling updatePost.');
        }
        if (keywords === null || keywords === undefined) {
            throw new Error('Required parameter keywords was null or undefined when calling updatePost.');
        }
        if (description === null || description === undefined) {
            throw new Error('Required parameter description was null or undefined when calling updatePost.');
        }
        if (documentType === null || documentType === undefined) {
            throw new Error('Required parameter documentType was null or undefined when calling updatePost.');
        }
        if (postType === null || postType === undefined) {
            throw new Error('Required parameter postType was null or undefined when calling updatePost.');
        }
        if (link === null || link === undefined) {
            throw new Error('Required parameter link was null or undefined when calling updatePost.');
        }
        if (account === null || account === undefined) {
            throw new Error('Required parameter account was null or undefined when calling updatePost.');
        }
        if (file === null || file === undefined) {
            throw new Error('Required parameter file was null or undefined when calling updatePost.');
        }

        let localVarHeaders = this.defaultHeaders;

        let localVarHttpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (localVarHttpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json;charset=utf-8'
            ];
            localVarHttpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (localVarHttpHeaderAcceptSelected !== undefined) {
            localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);
        }

        let localVarHttpContext: HttpContext | undefined = options && options.context;
        if (localVarHttpContext === undefined) {
            localVarHttpContext = new HttpContext();
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let localVarFormParams: { append(param: string, value: any): any; };
        let localVarUseForm = false;
        let localVarConvertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        localVarUseForm = canConsumeForm;
        if (localVarUseForm) {
            localVarFormParams = new FormData();
        } else {
            localVarFormParams = new HttpParams({encoder: this.encoder});
        }

        if (title !== undefined) {
            localVarFormParams = localVarFormParams.append('title', <any>title) as any || localVarFormParams;
        }
        if (keywords !== undefined) {
            localVarFormParams = localVarFormParams.append('keywords', <any>keywords) as any || localVarFormParams;
        }
        if (description !== undefined) {
            localVarFormParams = localVarFormParams.append('description', <any>description) as any || localVarFormParams;
        }
        if (documentType !== undefined) {
            localVarFormParams = localVarFormParams.append('documentType', <any>documentType) as any || localVarFormParams;
        }
        if (postType !== undefined) {
            localVarFormParams = localVarFormParams.append('postType', <any>postType) as any || localVarFormParams;
        }
        if (link !== undefined) {
            localVarFormParams = localVarFormParams.append('link', <any>link) as any || localVarFormParams;
        }
        if (account !== undefined) {
            localVarFormParams = localVarFormParams.append('account', <any>account) as any || localVarFormParams;
        }
        if (file !== undefined) {
            localVarFormParams = localVarFormParams.append('file', <any>file) as any || localVarFormParams;
        }

        let responseType_: 'text' | 'json' | 'blob' = 'json';
        if (localVarHttpHeaderAcceptSelected) {
            if (localVarHttpHeaderAcceptSelected.startsWith('text')) {
                responseType_ = 'text';
            } else if (this.configuration.isJsonMime(localVarHttpHeaderAcceptSelected)) {
                responseType_ = 'json';
            } else {
                responseType_ = 'blob';
            }
        }

        let localVarPath = `/skillsmates/posts/${this.configuration.encodeParam({name: "postId", value: postId, in: "path", style: "simple", explode: false, dataType: "string", dataFormat: undefined})}`;
        return this.httpClient.request<PostCreateResponse>('put', `${this.configuration.basePath}${localVarPath}`,
            {
                context: localVarHttpContext,
                body: localVarConvertFormParamsToString ? localVarFormParams.toString() : localVarFormParams,
                responseType: <any>responseType_,
                withCredentials: this.configuration.withCredentials,
                headers: localVarHeaders,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
