import {Component, Input, OnInit} from '@angular/core';
import {InteractionResource, InteractionService} from "ApiModulePost";
import {variables} from "../../../environments/variables";
import {Route} from "../../enums/route.enum";
import {LocalStorageService} from "../../services/local-storage.service";
import {Router} from "@angular/router";
import {AccountDetailsResource} from "ApiModuleAccount";

@Component({
  selector: 'sm-interaction-detail',
  templateUrl: './interaction-detail.component.html',
  styleUrls: ['./interaction-detail.component.scss']
})
export class InteractionDetailComponent implements OnInit {

  @Input() interaction: InteractionResource = {} as InteractionResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;

  constructor(private interactionService: InteractionService,
              private localStorageService: LocalStorageService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
  }

  onClickNotification(notification: InteractionResource): void {
    this.interactionService.deleteInteraction(notification.uuid!).subscribe({
      next: response => {
        if (this.loggedAccount.notifications) {
          this.loggedAccount.notifications--;
        }
        this.localStorageService.update(variables.logged_account, this.loggedAccount);
        this.redirectToPost(notification);
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  redirectToPost(notification: InteractionResource): void {
    switch (notification.interactionType) {
      case 'INTERACTION_TYPE_LIKE':
      case 'INTERACTION_TYPE_COMMENT':
      case 'INTERACTION_TYPE_SHARE':
      case 'INTERACTION_TYPE_POST':
        this.localStorageService.add(variables.post_details, notification.post);
        this.localStorageService.add(variables.previous_route, Route.NOTIFICATIONS);
        this.router.navigate([Route.POST_DETAILS]);
        break;
      case 'INTERACTION_TYPE_FOLLOWER':
      case 'INTERACTION_TYPE_FAVORITE':
        this.localStorageService.add(variables.profile_account, notification.emitter);
        this.router.navigate([Route.PROFILE]);
        break;
      case 'MESSAGE':
        // redirect to message
        break;
      default:
        break;
    }
  }

  displayPostTitle(notification: InteractionResource): string {
    return '<strong style="color: #16152d; font-weight: bold;">\'\'' +
      notification.post?.title + '\'\'</strong>';
  }

  displayInteractionEmitterName(notification: InteractionResource): string {
    return '<strong style="color: #16152d; font-weight: bold;">'
      + notification.emitter?.firstname + ' ' + notification.emitter?.lastname +
      ' </strong>'
  }

  generateTitle(notification: InteractionResource): string {
    let title = this.displayInteractionEmitterName(notification);
    switch (notification.interactionType) {
      case "INTERACTION_TYPE_LIKE":
        return title + ' a aimé votre publication ' + this.displayPostTitle(notification)
      case 'INTERACTION_TYPE_COMMENT':
        return title + ' a commenté votre publication ' + this.displayPostTitle(notification)
      case 'INTERACTION_TYPE_FOLLOWER':
        return title + ' est votre nouvel abonné';
      case 'INTERACTION_TYPE_SHARE':
        return title + ' a partagé votre publication ' + this.displayPostTitle(notification)
      case 'INTERACTION_TYPE_FAVORITE':
        return title + ' vous a mis dans ses favoris';
      case 'INTERACTION_TYPE_MESSAGE':
        return title + ' vous a envoyé un message';
      case 'INTERACTION_TYPE_POST':
        return title + ' a publié un nouvel article ' + this.displayPostTitle(notification)
      default:
        return '';
    }
  }
}
