<div class="row">
  <div class="col-1">
    <span class="user-img">
      <a (click)="onClickProfile(interaction.emitter)">
        <sm-image
          [imageSize]="35"
          class="img-fluid"
          default="{{'user' | image}}"
          image="{{interaction.emitter?.avatar}}">
        </sm-image>
      </a>
      <span class="status online"></span>
    </span>
  </div>
  <div class="col-10">
    <div class="comment">
      <div
        style="font-weight: bold">{{ interaction.emitter?.firstname }} {{ interaction.emitter?.lastname }}
      </div>
      <div *ngIf="interaction.interactionType === 'INTERACTION_TYPE_COMMENT'"
           style="word-wrap: break-word;">{{ interaction.content }}
      </div>
    </div>
    <div style="font-size: 12px; text-align: right">{{ interaction.createdAt | timeAgo }}</div>
  </div>
  <span *ngIf="loggedAccount && interaction.emitter!.uuid === loggedAccount.uuid"
        class="col-1">
    <a class="icon-action" data-bs-target="#delete_attribute" data-bs-toggle="modal" style="">
      <span class="action-circle large">
        <em class="material-icons">delete</em>
      </span>
    </a>
  </span>
</div>

<div class="modal fade delete-modal" id="delete_attribute" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <img alt="" height="46" src="{{'symbole-skills-mates' | image}}" width="50">
        <h3>Voulez-vous supprimer ce commentaire?</h3>
        <div class="m-t-20"><a class="btn btn-white" data-bs-dismiss="modal">NON</a>
          <button (click)="onClickDelete()" class="btn btn-danger" data-bs-dismiss="modal" type="submit">OUI
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
