<sm-post *ngIf="!isShared()" [post]="dashboardPost.post!"/>
<div class="bg-white skillsmates-box-shadow" *ngIf="isShared()">
  <div style="padding: 5px 5px 5px 5px;">
    <sm-shared-post-header class="pointer" [shareAuthor]="dashboardPost.shareAuthor!"
                           [shareDate]="dashboardPost.createdAt!" [post]="dashboardPost.post!"
                           [createdAt]="dashboardPost.createdAt!"/>
    <sm-long-text-display [showReadMore]="true"
                          [text]="dashboardPost.sharedDescription"></sm-long-text-display>
    <div style="margin-top: 2%;">
      <sm-post [post]="dashboardPost.post!"
               [isShared]="isShared()"/>
    </div>
  </div>
</div>
