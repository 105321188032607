export * from './infoController.service';
import { InfoControllerService } from './infoController.service';
export * from './interaction.service';
import { InteractionService } from './interaction.service';
export * from './parameter.service';
import { ParameterService } from './parameter.service';
export * from './post.service';
import { PostService } from './post.service';
export * from './postFile.service';
import { PostFileService } from './postFile.service';
export const APIS = [InfoControllerService, InteractionService, ParameterService, PostService, PostFileService];
