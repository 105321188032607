import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountDetailsResource, InteractionResource, InteractionService} from "ApiModulePost";
import {ToastrService} from "ngx-toastr";
import {LocalStorageService} from "../../services/local-storage.service";
import {Route} from "../../enums/route.enum";
import {variables} from "../../../environments/variables";
import {Router} from "@angular/router";

@Component({
  selector: 'sm-post-interaction',
  templateUrl: './post-interaction.component.html',
  styleUrls: ['./post-interaction.component.scss']
})
export class PostInteractionComponent implements OnInit {
  @Input() interaction: InteractionResource = {} as InteractionResource;
  @Output() removeInteractionEvent = new EventEmitter<InteractionResource>();
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  account: AccountDetailsResource = {} as AccountDetailsResource;
  profile: any;
  Route = Route;

  constructor(private interactionService: InteractionService,
              private toastrService: ToastrService,
              private storageService: LocalStorageService,
              private router: Router) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.storageService.getLoggedAccount();
    this.account = this.storageService.getProfileAccount();
    this.profile = this.interaction.emitter?.avatar;
  }


  onClickDelete(): void {
    this.interactionService.deleteInteraction(this.interaction.uuid!).subscribe({
      next: response => {
        this.toastrService.success("Commentaire supprimé", "Commentaire");
        this.removeInteractionEvent.emit(this.interaction);
      },
      error: error => {
        this.toastrService.success("Erreur lors de la suppression", "Commentaire");
      },
      complete: () => {

      }
    });
  }

  onClickProfile(account?: AccountDetailsResource): void {
    if (!this.storageService.isOffline()) {
      this.storageService.add(variables.profile_account, account);
      this.router.navigate([Route.PROFILE]);
    }
  }
}
