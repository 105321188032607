import {Pipe, PipeTransform} from '@angular/core';
import {Status} from "../enums/status.enum";
import {AccountDetailsResource} from "ApiModuleAccount";

@Pipe({
  name: 'jobIcon'
})
export class JobIconPipe implements PipeTransform {

  transform(account?: AccountDetailsResource, ...args: string[]): unknown {
    if (account && account.status) {
      if (args && args[0]) {
        if (account.status === Status[Status.TEACHER]) {
          return 'fa fa-graduation-cap';
        } else if (account.status === Status[Status.STUDENT]) {
          return 'fa fa-graduation-cap';
        } else {
          return 'fa fa-graduation-cap';
        }
      } else {
        if (account.status === Status[Status.TEACHER]) {
          return './assets/images/teacher-new.svg';
        } else if (account.status === Status[Status.STUDENT]) {
          return './assets/images/training.svg';
        } else {
          return './assets/images/job.svg';
        }
      }
    } else {
      return './assets/images/job.svg';
    }
  }

}
