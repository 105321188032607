<div class="row skillsmates-box-shadow">
  <div class="col-md-12 skillsmates-box-top" (click)="onClickTitle()" style="background-color: {{mediaType.color + '11'}};">
    <img height="24" src="{{ mediaType.picture | image}}" alt="image"> &nbsp;
    <strong>{{mediaType.label}} ({{total}})</strong>
    <span style="float: right;">
        <a style="color: #16162d;">
          <img height="24" src="{{ headTileImage | image}}" alt="arrow"> &nbsp;
        </a>
    </span>
  </div>

  <div class="col-12" *ngIf="mediaType.code == 'MEDIA_TYPE_PROFILE'">

    <div class="row" *ngIf="accounts && accounts.length > 3">
      <!--<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <sm-processing [loading]="loading"></sm-processing>
      </div>-->
      <div class="col-5 col-sm-5 col-md-5 col-lg-3 col-xl-3 text-left">
        <nav class="mt-10 pr-0 d-flex justify-content-start m-r-2">
          <select name="perPageP1" id="perPageP1" [(ngModel)]="perPage">
            <option value="6">6</option>
            <option value="12">12</option>
            <option value="18">18</option>
            <option value="24">24</option>
          </select> /page
        </nav>
      </div>
      <div class="col-7 col-sm-7 col-md-7 col-lg-9 col-xl-9 text-right">
        <nav class="mt-2 pr-0 d-flex justify-content-end m-r-10">
          <ngb-pagination
            (pageChange)="loadPage($event)"
            [disabled]="false" [collectionSize]="accounts!.length ?? 1" [(page)]="page" [maxSize]="maxSize"
            [rotate]="true" [pageSize]="perPage" [ellipses]="false" [boundaryLinks]="true" size="sm">
            <ng-template ngbPaginationFirst><i class="fa fa-angle-double-left"></i></ng-template>
            <ng-template ngbPaginationPrevious><i class="fa fa-angle-left"></i></ng-template>
            <ng-template ngbPaginationNext><i class="fa fa-angle-right"></i></ng-template>
            <ng-template ngbPaginationLast><i class="fa fa-angle-double-right"></i></ng-template>
          </ngb-pagination>
        </nav>
      </div>
    </div>

    <div class="row" *ngIf="!showCarousel() && expand && accounts && accounts.length > 0">
      <div class="col-12 col-sm-6 col-md-4 col-lg-4 col-xl-4"
           *ngFor="let account of accounts.slice((page-1)*perPage, page*perPage)">
        <sm-profile-card [account]="account" (click)="onClickProfile(account)"></sm-profile-card>
      </div>

      <div class="col-12" style="margin: auto;" *ngIf="accounts.length <= 0">
        Aucun profil trouvé
      </div>
    </div>

    <div *ngIf="showCarousel() && expand && accounts && accounts.length > 0" class="row bg-white scrolling-wrapper" style="margin: 0;  border-radius: 0 0 10px 10px;">
      <carousel style="height: 310px; z-index: -0;">
        <div class="carousel-cell col-6 col-sm-6 col-md-4 col-lg-3" style="padding: 3px;" *ngFor="let account of accounts">
          <sm-profile-card [account]="account" (click)="onClickProfile(account)"></sm-profile-card>
        </div>
      </carousel>
    </div>
    <div class="row" *ngIf="accounts && accounts.length > 3">
      <!--<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <sm-processing [loading]="loading"></sm-processing>
      </div>-->
      <div class="col-5 col-sm-5 col-md-5 col-lg-3 col-xl-3 text-left">
        <nav class="mt-10 pr-0 d-flex justify-content-start m-r-2">
          <select name="perPageP2" id="perPageP2" [(ngModel)]="perPage">
            <option value="6">6</option>
            <option value="12">12</option>
            <option value="18">18</option>
            <option value="24">24</option>
          </select> /page
        </nav>
      </div>
      <div class="col-7 col-sm-7 col-md-7 col-lg-9 col-xl-9 text-right">
        <nav class="mt-2 pr-0 d-flex justify-content-end m-r-10">
          <ngb-pagination
            (pageChange)="loadPage($event)"
            [disabled]="false" [collectionSize]="accounts!.length ?? 1" [(page)]="page" [maxSize]="maxSize"
            [rotate]="true" [pageSize]="perPage" [ellipses]="false" [boundaryLinks]="true" size="sm">
            <ng-template ngbPaginationFirst><i class="fa fa-angle-double-left"></i></ng-template>
            <ng-template ngbPaginationPrevious><i class="fa fa-angle-left"></i></ng-template>
            <ng-template ngbPaginationNext><i class="fa fa-angle-right"></i></ng-template>
            <ng-template ngbPaginationLast><i class="fa fa-angle-double-right"></i></ng-template>
          </ngb-pagination>
        </nav>
      </div>
    </div>
  </div>

  <div class="col-12" *ngIf="mediaType.code != 'MEDIA_TYPE_PROFILE'">

    <div class="row" *ngIf="posts && posts.length > 3">
      <!--<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <sm-processing [loading]="loading"></sm-processing>
      </div>-->
      <div class="col-5 col-sm-5 col-md-5 col-lg-3 col-xl-3 text-left">
        <nav class="mt-10 pr-0 d-flex justify-content-start m-r-2">
          <select name="perPage1" id="perPage1" [(ngModel)]="perPage">
            <option value="6">6</option>
            <option value="12">12</option>
            <option value="18">18</option>
            <option value="24">24</option>
          </select> /page
        </nav>
      </div>
      <div class="col-7 col-sm-7 col-md-7 col-lg-9 col-xl-9 text-right">
        <nav class="mt-2 pr-0 d-flex justify-content-end m-r-10">
          <ngb-pagination
            (pageChange)="loadPage($event)"
            [disabled]="false" [collectionSize]="posts!.length ?? 1" [(page)]="page" [maxSize]="maxSize"
            [rotate]="true" [pageSize]="perPage" [ellipses]="false" [boundaryLinks]="true" size="sm">
            <ng-template ngbPaginationFirst><i class="fa fa-angle-double-left"></i></ng-template>
            <ng-template ngbPaginationPrevious><i class="fa fa-angle-left"></i></ng-template>
            <ng-template ngbPaginationNext><i class="fa fa-angle-right"></i></ng-template>
            <ng-template ngbPaginationLast><i class="fa fa-angle-double-right"></i></ng-template>
          </ngb-pagination>
        </nav>
      </div>
    </div>

    <div class="row" *ngIf="!showCarousel() && expand && posts && posts.length > 0">
      <div class="col-12" *ngIf="screenWidth < 800" (click)="onClickOpenFilters()">
        <sm-image-text
          image="{{'filter' | image}}"
          text="{{'search.filters'|translate}}">
        </sm-image-text>
      </div>
      <div class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4"
           *ngFor="let post of posts.slice((page-1)*perPage, page*perPage)">
        <sm-search-post [post]="post" (click)="onClickPost(post)"></sm-search-post>
      </div>
      <div class="col-12" style="margin: auto;" *ngIf="posts.length <= 0">
        Aucun document trouvé
      </div>
    </div>

    <div *ngIf="showCarousel() && expand && posts && posts.length > 0" class="row bg-white scrolling-wrapper" style="margin: 0;  border-radius: 0 0 10px 10px;">
      <carousel style="height: 350px; z-index: -0;">
        <div class="carousel-cell col-6 col-sm-6 col-md-4 col-lg-3" style="padding: 3px;" *ngFor="let post of posts">
          <sm-search-post [post]="post" (click)="onClickPost(post)"></sm-search-post>
        </div>
      </carousel>
    </div>
    <div class="row" *ngIf="posts && posts.length > 3">
      <!--<div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
        <sm-processing [loading]="loading"></sm-processing>
      </div>-->
      <div class="col-5 col-sm-5 col-md-5 col-lg-3 col-xl-3 text-left">
        <nav class="mt-10 pr-0 d-flex justify-content-start m-r-2">
          <select name="perPage" id="perPage" [(ngModel)]="perPage">
            <option value="6">6</option>
            <option value="12">12</option>
            <option value="18">18</option>
            <option value="24">24</option>
          </select> /page
        </nav>
      </div>
      <div class="col-7 col-sm-7 col-md-7 col-lg-9 col-xl-9 text-right">
        <nav class="mt-2 pr-0 d-flex justify-content-end m-r-10">
          <ngb-pagination
            (pageChange)="loadPage($event)"
            [disabled]="false" [collectionSize]="posts!.length ?? 1" [(page)]="page" [maxSize]="maxSize"
            [rotate]="true" [pageSize]="perPage" [ellipses]="false" [boundaryLinks]="true" size="sm">
            <ng-template ngbPaginationFirst><i class="fa fa-angle-double-left"></i></ng-template>
            <ng-template ngbPaginationPrevious><i class="fa fa-angle-left"></i></ng-template>
            <ng-template ngbPaginationNext><i class="fa fa-angle-right"></i></ng-template>
            <ng-template ngbPaginationLast><i class="fa fa-angle-double-right"></i></ng-template>
          </ngb-pagination>
        </nav>
      </div>
    </div>
  </div>

  <div class="col-12" style="margin: 10px;"
       *ngIf="(accounts && accounts.length > 0) || (posts && posts.length > 0)"
        (click)="onClickMoreResults(mediaType)">
        <span style="float: right;">
        <strong class="pointer" *ngIf="!displayAllResults">{{'search.return_results' | translate}}</strong>
        <strong class="pointer" *ngIf="displayAllResults">{{'search.see_more_results' | translate}}</strong>
        <a style="color: #16162d;">
          <img height="24" src="{{ 'arrow-right' | image}}" alt="arrow"> &nbsp;
        </a>
    </span>
  </div>
</div>

<sm-bottom-sheet-filters
  *ngIf="isBottomSheetOpened"
  [currentMediaType]="mediaType"
  (smFilterEvent)="onApplyFilters($event)">
</sm-bottom-sheet-filters>
