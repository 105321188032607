<div *ngIf="hasNoPost(); else hasPosts">
  <p class="skillsmates-title text-center" style="font-size: xx-large;">
    <strong>{{ 'search.no_result_found' | translate }}</strong>
  </p>
  <div class="no-result-image">
    <img src="{{'no-result-documents' | image}}" width="50%"
         style="display: block; margin-left: auto; margin-right: auto">
  </div>
</div>
<ng-template #hasPosts>
  <div #scrollFrame
       (scrolled)="onScroll()"
       [infiniteScrollDistance]="2"
       [infiniteScrollThrottle]="10"
       class="search-results"
       infiniteScroll>
    <!--<div *ngIf="isDashboard">-->
    <div>
      <div *ngFor="let dashboardPost of dashboardPosts">
        <sm-dashboard-post [dashboardPost]="dashboardPost"/>
      </div>
    </div>

    <!--<div *ngIf="!isDashboard">
      <div *ngFor="let post of posts">
        <sm-post [post]="post"/>
      </div>
    </div>-->
  </div>
</ng-template>


<sm-processing></sm-processing>

