import {Component, OnInit} from '@angular/core';
import {LocalStorageService} from "../../services/local-storage.service";
import {AccountDetailsResource} from "ApiModuleAccount";
import {InteractionResource, InteractionService} from "ApiModulePost";
import {Router} from "@angular/router";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  interactions: InteractionResource [] = [];

  constructor(private interactionService: InteractionService,
              private router: Router,
              private localStorageService: LocalStorageService) {
  }

  ngOnInit(): void {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.findInteractions();
  }

  findInteractions() {
    this.interactionService.findAccountInteractions(this.loggedAccount.uuid!, 0, 100).subscribe({
      next: response => {
        this.interactions = response.resources!;
      },
      error: error => {
      },
      complete: () => {
      }
    })
  }
}
