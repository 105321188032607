<div class="p-3">
  <div class="row">
    <div class="col-2">
      <img height="64" src="{{image}}" alt="image" class="">
    </div>
    <div class="col-9">
      <strong *ngIf="title">{{title}}</strong>
      <p *ngIf="subtitle">{{subtitle}}</p>
      <p *ngIf="details">{{details}}</p>
    </div>
    <div class="col-1" *ngIf="loggedAccount.uuid === account.uuid">
      <div class="dropdown dropdown-action">
        <a href="#" class="action-icon dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"><em
          class="fa fa-ellipsis-v"></em></a>
        <div class="dropdown-menu dropdown-menu-right skillsmates-box-shadow bg-white">
          <a class="dropdown-item skillsmates-dropdown-menu"
             (click)="onClickUpdate()">
            <em class="fa fa-pencil m-r-5"></em> {{'edit_profile.edit' | translate}}
          </a>
          <a href="#" class="dropdown-item skillsmates-dropdown-menu" data-bs-toggle="modal"
             data-bs-target="#delete_attribute"
             (click)="onClickDelete()">
            <em class="fa fa-trash m-r-5"></em> {{'edit_profile.delete' | translate}}
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12" *ngIf="firstDescription">{{firstDescription}}</div>
    <div class="col-12" *ngIf="secondDescription">{{secondDescription}}</div>
    <div class="col-12"></div>
  </div>
</div>

<div id="delete_attribute" class="modal fade delete-modal" role="dialog">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-body text-center">
        <img src="{{'symbole-skills-mates' | image}}" alt="" width="50" height="46">
        <h3>Voulez-vous supprimer?</h3>
        <div class="m-t-20"><a class="btn btn-white" data-bs-dismiss="modal">NON</a>
          <button type="submit" class="btn btn-danger" data-bs-dismiss="modal" (click)="onClickConfirmDelete()">OUI
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

