import {Pipe, PipeTransform} from '@angular/core';
import {Status} from "../enums/status.enum";
import {AccountDetailsResource} from "ApiModuleAccount";

@Pipe({
  name: 'companyIcon'
})
export class CompanyIconPipe implements PipeTransform {

  transform(account: AccountDetailsResource, ...args: unknown[]): string {
    if (account && account.status) {
      if (args && args[0]) {
        if (account.status === Status[Status.TEACHER]) {
          return 'fa fa-graduation-cap';
        } else if (account.status === Status[Status.STUDENT]) {
          return 'fa fa-graduation-cap';
        } else {
          return 'fa fa-graduation-cap';
        }
      } else {
        if (account.status === Status[Status.TEACHER]) {
          return './assets/images/school.svg';
        } else if (account.status === Status[Status.STUDENT]) {
          return './assets/images/school.svg';
        } else {
          return './assets/images/company.svg';
        }
      }
    } else {
      return './assets/images/company.svg';
    }
  }

}
