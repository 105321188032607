import {Pipe, PipeTransform} from '@angular/core';
import {AccountDetailsResource} from "ApiModuleAccount";
import {Status} from "../enums/status.enum";

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {

  transform(account: AccountDetailsResource, ...args: unknown[]): string {
    if (account && account.status) {
      if (args && args[0] && args[0] == 'label') {
        if (account.status === Status[Status.TEACHER]) {
          return 'profile_account.teacher';
        } else if (account.status === Status[Status.STUDENT]) {
          return 'profile_account.student';
        } else if (account.status === Status[Status.PROFESSIONAL]) {
          return 'profile_account.professional';
        } else {
          return 'profile_account.student';
        }
      } else if (args && args[0] && args[0] == 'icon') {
        if (account.status === Status[Status.TEACHER]) {
          return './assets/images/teacher.svg';
        } else if (account.status === Status[Status.STUDENT]) {
          return './assets/images/student.svg';
        } else {
          return './assets/images/professional.svg';
        }
      } else {
        return '';
      }
    } else {
      return '';
    }
  }
}
