import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AccountDetailsResource, CertificationResponse, CertificationService} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {variables} from "../../../environments/variables";

@Component({
  selector: 'sm-profile-certifications',
  templateUrl: './profile-certifications.component.html',
  styleUrls: ['./profile-certifications.component.scss']
})
export class ProfileCertificationsComponent implements OnInit {
  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  certificationResponse: CertificationResponse = {} as CertificationResponse;
  @Input() editMode: boolean = false;
  @Output() smEditModeEvent = new EventEmitter<string>;

  constructor(private localStorageService: LocalStorageService,
              private certificationService: CertificationService,) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
  }

  ngOnInit() {
    this.fetchCertifications();
  }

  fetchCertifications(): void {
    this.certificationService.findCertificationByAccount(this.account.uuid ?? "").subscribe({
      next: response => {
        this.certificationResponse = response;
      },
      error: error => {
      },
      complete: () => {
      }
    });
  }

  onClickAdd(event: string): void {
    this.editMode = true;
    this.smEditModeEvent.emit(event);
  }

  onClickDone(event: boolean): void {
    this.editMode = false;
    if (event) {
      this.fetchCertifications();
    }
    this.smEditModeEvent.emit('');
  }

  onClickUpdate(event: string): void {
    this.certificationResponse!.resource = this.localStorageService.get(variables.account_attribute);
    this.editMode = true;
    this.smEditModeEvent.emit(event);
  }
}
