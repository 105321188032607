import {Component, Input, OnInit, SimpleChanges} from '@angular/core';
import {PostDetailsResource} from "ApiModulePost";
import {ActivatedRoute, Router} from "@angular/router";
import {Route} from "../../enums/route.enum";
import {LocalStorageService} from 'src/app/services/local-storage.service';
import {variables} from "../../../environments/variables";


@Component({
  selector: 'sm-long-text-display',
  templateUrl: './long-text-display.component.html',
  styleUrls: ['./long-text-display.component.scss']
})
export class LongTextDisplayComponent implements OnInit {
  @Input() text: string | undefined;
  @Input() post: PostDetailsResource = {} as PostDetailsResource;
  @Input() showReadMore: boolean = true;
  displayMoreLabel: string = "";
  displayMoreIcon: string = "";
  toggleDisplayMoreLabel: boolean = true;

  constructor(private storageService: LocalStorageService,
              private router: Router,
              private activatedRoute: ActivatedRoute,) {
  }

  ngOnInit(): void {
    this.displayMoreLabel = 'Afficher la suite';
    this.displayMoreIcon = 'fa fa-sort-desc';
    this.toggleDisplayMoreLabel = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
  }

  onClickPost(post: PostDetailsResource): void {
    this.storageService.add(variables.post_details, post);
    this.storageService.add(variables.previous_route, Route.DASHBOARD);
    this.router.navigate([Route.POST]);
  }

  onClickMore(): void {
    this.toggleDisplayMoreLabel = !this.toggleDisplayMoreLabel;
    this.displayMoreLabel = this.toggleDisplayMoreLabel ? 'Afficher la suite' : 'Afficher moins';
    this.displayMoreIcon = this.toggleDisplayMoreLabel ? 'fa fa-sort-desc' : 'fa fa-sort-asc';
  }
}
