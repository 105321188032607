<div>
  <div class="modal-header  align-items-center container" style="background-color:#DBDADC; height: 40px;" tag="h4">

    <h4 class="modal-title" id="modal-basic-title-document" style="color: black">Photo de profil</h4>

    <button type="button" class="close m-0 p-0" aria-label="Close" (click)="onClickDismissModal()">
      <span aria-hidden="true"><img width="18" height="18" src="{{'close' | image}}" class="m-r-5" alt=""></span>
    </button>
  </div>
  <div style="width: 100%; height: 2px; border-bottom: 2px solid #16152D; text-align: center;"></div>
  <div class="modal-body">

    <div class="row">
      <div class="col-9">
        <div class="row">
          <div class="col-12" style="margin: auto;">
            <image-cropper
              [imageChangedEvent]="imageChangedEvent"
              [maintainAspectRatio]="true"
              [containWithinAspectRatio]="containWithinAspectRatio"
              [aspectRatio]="1"
              [resizeToWidth]="256"
              [cropperMinWidth]="128"
              [onlyScaleDown]="true"
              [roundCropper]="false"
              [canvasRotation]="canvasRotation"
              [transform]="transform"
              [alignImage]="'left'"
              [style.display]="showCropper ? null : 'none'"
              format="png"
              (imageCropped)="imageCropped($event)"
              (imageLoaded)="imageLoaded()"
              (cropperReady)="cropperReady()"
              (loadImageFailed)="loadImageFailed()">
            </image-cropper>
          </div>
          <br>
          <div class="col-12" *ngIf="imageChangedEvent">
            <div class="row">
              <div class="col-1" style="cursor: pointer;" (click)="zoomOut()">
                <img src="{{'minus' | image}}" class="" width="24" alt="minus">
              </div>
              <div class="col-10">
                <input type="range" style="width: 470px;" class="form-range" min="0.1" max="2" step="0.1"
                       [(ngModel)]="scale" (change)="onScaleChange()">
              </div>
              <div class="col-1" style="cursor: pointer;" (click)="zoomIn()">
                <img src="{{'plus_circle' | image}}" class="" width="24" alt="Plus">
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="col-3">
        <div class="row">
          <div class="col-12 text-center" style="margin-bottom: 20px;">
            <button type="button" class="btn btn-secondary btn-sm btn-outline-light"
                    style="color: #16152d; background-color: #DBDADC;" (click)="chooseFile.click()">Ajouter une image
            </button>
            <input type="file" style="display: none" accept="" #chooseFile (change)="fileChangeEvent($event)">
            <div *ngIf="imageChangedEvent">{{imageChangedEvent.target.files[0].name}}</div>
          </div>
          <div class="col-6" style="padding: 5px;" *ngIf="imageChangedEvent">
            <div class="text-center" style="cursor: pointer;" (click)="rotateLeft()">
              <img src="{{'rotate-left' | image}}" class="" width="32" alt="Rotate left">
              <p class="skillsmates-two-lines" style="font-size: 12px; line-height: 1em; ">Tourner à <br> gauche</p>
            </div>
            <div class="text-center" style="cursor: pointer;" (click)="flipVertical()">
              <img src="{{'flip-vertical' | image}}" class="" width="32" alt="flip vertical">
              <p class="skillsmates-two-lines" style="font-size: 12px; line-height: 1em; "> Basculer <br>verticalement
              </p>
            </div>
            <div class="text-center" style="cursor: pointer;">
              <img src="{{'crop' | image}}" class="" width="32" alt="crop">
              <p class="skillsmates-two-lines" style="font-size: 12px; line-height: 1em; "> Recadrer</p>
            </div>
          </div>
          <div class="col-6" style="padding: 5px;" *ngIf="imageChangedEvent">
            <div class="text-center" style="cursor: pointer;" (click)="rotateRight()">
              <img src="{{'rotate-right' | image}}" class="" width="32" alt="Rotate right">
              <p class="skillsmates-two-lines" style="font-size: 12px; line-height: 1em; ">Tourner à <br> droite</p>
            </div>
            <div class="text-center" style="cursor: pointer;" (click)="flipHorizontal()">
              <img src="{{'flip-horizontal' | image}}" class="" width="32" alt="flip horizontal">
              <p class="skillsmates-two-lines" style="font-size: 12px; line-height: 1em; "> Basculer <br>horizontalement
              </p>
            </div>
            <div class="text-center" style="cursor: pointer;" (click)="resetImage()">
              <img src="{{'delete-pp' | image}}" class="" width="32" alt="delete pp">
              <p class="skillsmates-two-lines" style="font-size: 12px; line-height: 1em; ">Effacer</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="modal-footer">
          <sm-button
            *ngIf="!loading"
            [type]="'success'"
            text="{{'edit_profile.validate' | translate}}"
            (click)="onClickSaveProfileImage()">
          </sm-button>
        </div>
        <div style="padding-bottom: 10px; width: 90%; margin: auto;">
          <sm-processing [loading]="loading"></sm-processing>
        </div>
      </div>
    </div>

  </div>
</div>








