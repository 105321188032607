import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'sm-autocomplete-input',
  templateUrl: './autocomplete-input.component.html',
  styleUrls: ['./autocomplete-input.component.scss']
})
export class AutocompleteInputComponent {

  @Input() title!: string;
  @Input() name!: string;
  @Input() placeholder!: string;
  @Input() value!: string;
  @Input() mandatory: boolean = false;
  @Input() showErrorMessage: boolean = false;
  @Input() inputStatus!: string;
  @Input() errorMessage!: string;
  @Input() options: string[] = []
  @Output() smInputEvent = new EventEmitter<string>();

  listOfOptions: string[] = []

  ngOnInit() {
    // this.setOptions();
  }

  // setOptions() {
  //   setTimeout(() => {
  //     this.listOfOptions = this.options;
  //   }, 1000)
  // }

  handleChange(event: any) {
    this.smInputEvent.emit(event.target.value);
  }
}
