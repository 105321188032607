import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MediaType} from "../../models/mediaType";
import {Router} from "@angular/router";
import {variables} from "../../../environments/variables";
import {Route} from "../../enums/route.enum";

@Component({
  selector: 'sm-media-type',
  templateUrl: './media-type.component.html',
  styleUrls: ['./media-type.component.scss']
})
export class MediaTypeComponent implements OnInit {
  @Input() mediaType: MediaType = new MediaType();
  @Input() selected: boolean = false;
  @Output() selectMediaType = new EventEmitter<MediaType>();
  closeResult: string | undefined;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  onClickEditPost(mediaType: MediaType): void {
    localStorage.setItem(variables.current_media_type, JSON.stringify(this.mediaType));
    this.router.navigate([Route.EDIT_POST]);
  }
}
