<div class="row">
  <sm-alert [alert]="alert"></sm-alert>
  <div class="col-12 p-3">
    <strong style="color: black">{{ 'edit_profile.degree_obtained' | translate }}</strong>
  </div>
  <div class="col-12">
    <sm-dropdown
      (smInputEvent)="getEducation($event)"
      [list]="getDiplomaLabels()"
      [selected]="getCurrentEducation()"
      title="{{'edit_profile.education' | translate}}">
    </sm-dropdown>
    <sm-dropdown (smInputEvent)="getLevel($event)"
                 *ngIf="diplomaRequest.education === 'HIGHER'"
                 [list]="levelLabels()"
                 [ngClass]="getCssStatusFinal('LEVEL')"
                 [selected]="diplomaRequest.level!"
                 title="{{'edit_profile.school_level' | translate}} {{'edit_profile.diploma' | translate}}">
    </sm-dropdown>
    <sm-input
      (smInputEvent)="getDiplomaTitle($event)"
      [errorMessage]="errorMessage('TITLE')"
      [inputStatus]="getCssStatus('TITLE')"
      [mandatory]="true"
      [showErrorMessage]="!isValid('TITLE')"
      [type]="'text'"
      [value]="diplomaRequest.title!"
      placeholder="{{'edit_profile.title' | translate}} du {{'edit_profile.diploma' | translate}}"
      title="{{'edit_profile.title' | translate}} du {{'edit_profile.diploma' | translate}}">
    </sm-input>
    <sm-input
      (smInputEvent)="getDiplomaSchoolName($event)"
      [errorMessage]="errorMessage('SCHOOL')"
      [inputStatus]="getCssStatus('SCHOOL')"
      [mandatory]="true"
      [showErrorMessage]="!isValid('SCHOOL')"
      [type]="'text'"
      [value]="diplomaRequest.schoolName!"
      placeholder="{{'edit_profile.school_name' | translate}}"
      title="{{'edit_profile.school_name' | translate}}">
    </sm-input>
    <sm-input
      (smInputEvent)="getDiplomaCity($event)"
      [errorMessage]="errorMessage('CITY')"
      [inputStatus]="getCssStatus('CITY')"
      [mandatory]="true"
      [showErrorMessage]="!isValid('CITY')"
      [type]="'text'"
      [value]="diplomaRequest.city!"
      placeholder="{{'edit_profile.location' | translate}}"
      title="{{'edit_profile.location' | translate}}">
    </sm-input>
    <sm-input
      (smInputEvent)="getActivityArea($event)"
      [errorMessage]="errorMessage('DOMAIN')"
      [inputStatus]="getCssStatus('DOMAIN')"
      [mandatory]="true"
      [showErrorMessage]="!isValid('DOMAIN')"
      [type]="'text'"
      [value]="diplomaRequest.activityArea!"
      placeholder="{{'edit_profile.teaching_field' | translate}} {{'edit_profile.diploma' | translate}}"
      title="{{'edit_profile.teaching_field' | translate}} {{'edit_profile.diploma' | translate}}">
    </sm-input>
    <div class="row">
      <div class="col">
        <sm-input
          (smInputEvent)="getStartDate($event)"
          [errorMessage]="errorMessage('DATE')"
          [inputStatus]="getCssStatus('DATE')"
          [mandatory]="true"
          [name]="'issued_date'"
          [showErrorMessage]="!isValid('DATE')"
          [type]="'date'"
          [value]="formatDate(diplomaRequest.startDate!)"
          placeholder="{{'edit_profile.issued_date' | translate}}"
          title="{{'edit_profile.issued_date' | translate}}">
        </sm-input>
      </div>
    </div>
    <sm-textarea
      (smInputEvent)="getDiplomaDescription($event)"
      [value]="diplomaRequest.description ? diplomaRequest.description : ''"
      placeholder="{{'edit_profile.description' | translate}} ({{'edit_profile.optional' | translate}})"
      title="{{'edit_profile.description' | translate}} ({{'edit_profile.optional' | translate}})">
    </sm-textarea>
  </div>
  <div class="row">
    <div class="col m-t-10">
      <sm-button (click)="onClickCancel()" text="{{'edit_profile.cancel' | translate}}"></sm-button>
    </div>
    <div *ngIf="!processing" class="col m-t-10">
      <sm-button (click)="onClickSave()" [type]="'success'" text="{{'edit_profile.validate' | translate}}"></sm-button>
    </div>
    <div *ngIf="processing" class="col m-t-10">
      <sm-processing [loading]="processing"/>
    </div>
  </div>
</div>
