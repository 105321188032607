import {AfterViewChecked, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {BottomSheetConfig} from "../../modules/bottom-sheet/utility/bottom-sheet.interface";
import {DocumentTypeResource, ParameterService, PostService, SearchParam} from "ApiModulePost";
import {BottomSheetComponent} from "../../modules/bottom-sheet/bottom-sheet/bottom-sheet.component";
import {AccountDetailsResource, AccountService} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import {MediaType} from "../../models/mediaType";

@Component({
  selector: 'sm-bottom-sheet-filters',
  templateUrl: './bottom-sheet-filters.component.html',
  styleUrls: ['./bottom-sheet-filters.component.scss']
})
export class BottomSheetFiltersComponent implements OnInit, AfterViewChecked {

  @Input() currentMediaType!: MediaType;
  @Output() smFilterEvent = new EventEmitter<SearchParam>();

  account: AccountDetailsResource = {} as AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  checkedItems: string[] = [];
  documentTypes: DocumentTypeResource[] = [];
  selectedDocumentTypesList: string[] = [];
  selectedDocumentTypes: DocumentTypeResource[] = [];

  searchParam: SearchParam = {} as SearchParam;
  options!: BottomSheetConfig;
  @ViewChild('bottomSheet') bottomSheet!: BottomSheetComponent;

  constructor(private accountService: AccountService,
              private localStorageService: LocalStorageService,
              private postParameterService: ParameterService,
              private postService: PostService) {
  }

  ngOnInit(): void {
    this.options = {
      title: "Tous les filtres",
      backgroundColor: "#ffffff",
      fontColor: "#363636"
    }

    this.account = this.localStorageService.getProfileAccount();
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.searchParam.account = this.account.uuid;
    this.searchParam.documentTypes = [];
    // this.currentTab = MediaHelper.mediaTypes[0];
    // this.researchProfileDocuments();
    this.findDocumentTypes()
  }

  ngAfterViewChecked(): void {
    this.bottomSheet.open();
  }

  getSearchText(event: string): void {
    this.searchParam.content = event;
  }

  onClickItem(event: string): void {
    if (event) {
      this.documentTypes.forEach(value => {
        if (value.mediaType?.code === this.currentMediaType.code && value.label === event) {
          if (this.checkedItems.includes(event)) {
            const index = this.checkedItems.indexOf(event, 0);
            const indexSearch = this.searchParam.documentTypes!.indexOf(value.uuid!, 0);
            if (index > -1) {
              this.checkedItems.splice(index, 1);
            }
            if (indexSearch > -1) {
              this.searchParam.documentTypes!.splice(indexSearch, 1);
            }
          } else {
            this.checkedItems.push(event);
            this.searchParam.documentTypes?.push(value.uuid!);
          }
        }
      });
    }
  }

  onApplyFilters(): void {
    this.smFilterEvent.emit(this.searchParam);
  }

  findDocumentTypes(): void {
    this.postParameterService.getDocumentTypes().subscribe({
      next: response => {
        this.documentTypes = response.resources!;
        this.loadDocumentTypesDropdown(this.currentMediaType)
      },
      error: error => {

      },
      complete: () => {
      }
    });
  }

  loadDocumentTypesDropdown(mediaType: MediaType): string[] {
    this.selectedDocumentTypesList = [];
    this.selectedDocumentTypes = [];
    this.documentTypes.forEach(value => {
      if (value.mediaType && value.mediaType.code == mediaType.code) {
        this.selectedDocumentTypes.push(value);
        this.selectedDocumentTypesList.push(value.label!);
      }
    });
    return this.selectedDocumentTypesList;
  }
}
