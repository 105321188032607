<div class="contain">
  <div class="row">
    <div class="col-5 entete">
      <img alt="logo skillsmates" src="assets/images/logo-skillsmates.svg"/>
    </div>
    <div class="col-7">
      <img class="page" src="assets/images/landing-page-people.svg">
    </div>
  </div>
  <div class="row mon-texte">
    <p class="compte"><img src="assets/images/images.jpeg"> compte créé</p>
    <p class="created" style="font-weight: normal">Votre compte SkillsMates a bien été créé.</p>
    <p class="message">Un mail de validation de votre compte vous a été envoyé. Veuillez consulter votre
      boite de réception ou vos spams et cliquez sur le lien pour valider le compte.</p>
  </div>
  <div class="row bottom">
    <div class="col-6">
      <p>Apropos - Aide - Politique de confidentilité <br> conditions d'utilisation</p>
    </div>
    <div class="col-6">
      <img alt="logo skillsmates"
           src="assets/images/symbole_skillsmates.svg"/><span>&copy; Skills Mates {{ CURRENT_YEAR }}</span>
    </div>
  </div>
</div>
