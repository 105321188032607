import {Component, Input, OnInit} from '@angular/core';
import {AccountDetailsResource, AccountService, AccountUpdateBiographyRequest} from "ApiModuleAccount";
import {LocalStorageService} from "../../services/local-storage.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {variables} from "../../../environments/variables";

@Component({
  selector: 'sm-profile-biography',
  templateUrl: './profile-biography.component.html',
  styleUrls: ['./profile-biography.component.scss']
})
export class ProfileBiographyComponent implements OnInit {
  @Input() account!: AccountDetailsResource;
  loggedAccount: AccountDetailsResource = {} as AccountDetailsResource;
  public Editor = ClassicEditor;
  @Input() editMode: boolean = false;
  processing: boolean = false
  maxCharacters: number = 2000

  constructor(private localStorageService: LocalStorageService, private accountService: AccountService) {
    this.loggedAccount = this.localStorageService.getLoggedAccount();
    this.account = this.localStorageService.getProfileAccount();
  }

  ngOnInit() {
  }

  onClickUpdate(): void {
    this.editMode = true;
  }

  onClickCancel(): void {
    this.editMode = false;
  }

  onClickValidate(): void {
    let biographyRequest: AccountUpdateBiographyRequest = {uuid: this.account.uuid!, biography: this.account.biography}
    this.processing = true
    this.accountService.updateBiography(this.loggedAccount.uuid!, biographyRequest).subscribe({
      next: response => {
        this.processing = false
        this.editMode = false;
        this.account = response.resource!;
        this.localStorageService.update(variables.logged_account, this.account);
      },
      error: err => {
        this.processing = false
        this.editMode = false;
      },
      complete: () => {
        this.processing = false
        this.editMode = false;
      }
    });
  }
}
